import {
  SET_LOADER
} from '../action_types';

const initialState = {
  loader: {
    data: false
  },
}

const loader = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADER:
      return {
        ...state,
        loader: {
          ...state.loader,
          ...payload
        }
      };
    default: return state;
  }
}

export default loader;