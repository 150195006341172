import { DateRangePicker } from "materialui-daterange-picker";

const Index = ({
  open = false,
  setRange = () => {},
  selectedRange={}
}) => {

  return (
    <DateRangePicker
      open={open}
      toggle= {() => {}}
      initialDateRange={selectedRange}
      onChange={(range) => {
        setRange(range);
      }}
    />
  )
}

export default Index;
