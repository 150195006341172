import {
  CHANGE_PASSWORD,
  FORGOT,
  SET_CURRENT_USER,
  VERIFY,
} from "../action_types";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";

export const setCurrentUser = (data) => ({
  type: SET_CURRENT_USER,
  payload: {
    data,
    hasError: data ? !data.response ? false : true : false,
    type: SET_CURRENT_USER,
    isLoading: false,
  },
});

export const signIn = (data) => async (dispatch) => {
  try {
    let res = await axios({
      method: "POST",
      url: `/user/login`,
      data,
    });
    let result = { ...res.data.data };
    if (!res.data.data.isTempPassword) {
      result.tokenTime = new Date().getTime();
      setAuthToken(result.accessToken);
      localStorage.setItem("user", JSON.stringify(result));
    }
    dispatch(setCurrentUser(result));
  } catch (err) {
    dispatch(setCurrentUser(err));
  }
};
export const changePassword = (data) => async (dispatch) => {
  try {
    let res = await axios({
      method: "POST",
      url: `/user/change-password`,
      data,
    });
    dispatch({
      type: CHANGE_PASSWORD,
      payload: {
        data: res.data,
        type: CHANGE_PASSWORD,
        isLoading: false,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: CHANGE_PASSWORD,
      payload: {
        data: err,
        type: CHANGE_PASSWORD,
        isLoading: false,
        hasError: true,
      },
    });
  }
};
export const verify = (data) => async (dispatch) => {
  try {
    let res = await axios({
      method: "POST",
      url: `/user/verify`,
      data,
    });
    dispatch({
      type: VERIFY,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        type: VERIFY,
        isLoading: false,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: VERIFY,
      payload: {
        data: err,
        type: VERIFY,
        isLoading: false,
        hasError: true,
      },
    });
  }
};
export const forgot = (data) => async (dispatch) => {
  try {
    let res = await axios({
      method: "POST",
      url: `/user/forgot`,
      data,
    });
    dispatch({
      type: FORGOT,
      payload: {
        data: res.data,
        type: FORGOT,
        isLoading: false,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: FORGOT,
      payload: {
        data: { err },
        type: FORGOT,
        isLoading: false,
        hasError: true,
      },
    });
  }
};
export const clearData = () => async (dispatch) => {
  dispatch(setCurrentUser(null));
};
export const checkInvite = (params) => async (dispatch) => {
  try {
    if (params) setAuthToken(params);
    let res = await axios({
      method: "GET",
      url: `/user/check_invite`,
    });
    let result = res.data.data;
    setAuthToken(result.accessToken);
    // localStorage.setItem('user', JSON.stringify(result));
    dispatch(setCurrentUser(result));
  } catch (err) {
    dispatch(setCurrentUser(null));
  }
};
