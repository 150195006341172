import {
  LIST_ROLE,
  GET_ROLE,
  ADD_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listRoles = (params) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/role`,
      params
    });
    dispatch({
      type: LIST_ROLE,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: LIST_ROLE,
      payload: {
        data: [],
        meta: err?.data?.meta,
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const getRole = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/role/${id}`,
    });
    dispatch({
      type: GET_ROLE,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: GET_ROLE,
      payload: {
        data: {},
        meta: err?.data?.meta,
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addRole = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/role`,
      data
    });
    dispatch({
      type: ADD_ROLE,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: ADD_ROLE,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      }
    });
  }
}

export const updateRole = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/role/${id}`,
      data
    });
    dispatch({
      type: UPDATE_ROLE,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_ROLE,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      }
    });
  }
}

export const deleteRole = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/role/${id}`,
    });
    dispatch({
      type: DELETE_ROLE,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: DELETE_ROLE,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      }
    });
  }
}
export const clearData = (type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: {
      data: {},
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
}