import React from 'react'
import { IconButton } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core';
// import '../../styles/style.sass';
import './css/style.css';

const Index = ({ color = '', disabled = false, icon = '', onClick = null, props={} }) => {
  return (
    <StylesProvider injectFirst >
      <IconButton
        {...props}
        color={color}
        disabled={disabled}
        onClick={(e) => {
          e.cancelBubble = true;
          if (e.stopPropagation) e.stopPropagation();
          if (onClick) {
            onClick(e)
          }
        }}
      >
        {icon}
      </IconButton>
    </StylesProvider>
  )
}

export default Index;
