import React from 'react'
import { Button, makeStyles } from '@material-ui/core';
// import '../../styles/style.sass';
import './css/style.css';

const useStyles = makeStyles({
  button: props => ({
    width: props.width,
    marginLeft: props.marginLeft,
    marginRight: props.marginRight
  })
});

const Index = ({
  text = 'Button',
  width = 'auto',
  customClass = '',
  onClick = null,
  marginLeft = '0.5rem',
  marginRight = '0.5rem',
  ...rest
}) => {
  const classes = useStyles({ width, marginLeft, marginRight });
  return (
    // <StylesProvider injectFirst>
    <Button {...rest} className={`${classes.button} lgs-tbn ${customClass}`} onClick={onClick ? onClick : () => { }} style={{ ...rest.style, ...{ border: '2px solid #fff' }  }}>
      {text}
    </Button>
    // </StylesProvider>
  )
}

export default Index;
