import { 
  SET_DATE_RANGE
} from '../action_types';

const initialState = {
}

const dateRange = (state=initialState, action)  => {
  const { type, payload } = action;

  switch(type) {
    case SET_DATE_RANGE: 
      return {
        ...payload
      };
    default: return state;
  }
}

export default dateRange;