import {
  CHANGE_PASSWORD,
  FORGOT,
  SET_CURRENT_USER,
  VERIFY,
} from '../action_types';

const initialState = {
  data: null,
  isLoading: false,
  hasError: false
}

const auth = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        ...payload,
      };
    case FORGOT:
      return {
        ...state,
        ...payload,
      };
    case VERIFY:
      return {
        ...state,
        ...payload,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        ...payload,
      };
    default: return state;
  }
}

export default auth;