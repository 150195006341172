import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cdbHeader: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    margin: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    // marginTop: theme.spacing(2),
    // marginRight: theme.spacing(6),
    paddingTop: theme.spacing(1),
    borderRadius: '16px 16px 2px 2px',
  },
  cdbPaperDiv: {
    display: 'flex',
    margin: theme.spacing(2),
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    // marginLeft: theme.spacing(2),
  },
  cdbPaper: {
  }
}))

export default useStyles;