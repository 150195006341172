const TABLE_HEADER = {
  vin: {
    data: 'VIN',
    width: '150px'
  },
  tag: {
    data: 'Tag',
    // width: '240px'
  },
  regNo: {
    data: 'Reg no',
    width: '110px',
  },
  platform: {
    data: 'Platform',
    // width: '150px',
  },
  make: {
    data: 'Make',
  },
  model: {
    data: 'Model',
  },
  variant: {
    data:'Variant',
  },
  year: {
    data: 'Year',
    width: '75px',
  }
}

export {
  TABLE_HEADER,
}