import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IconButton, TextField, Button as ButtonCustom } from "../../../commons/mui";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BRM } from "../../Projects/AllProjects/CONSTANTS";
import { ArrowBack } from "@material-ui/icons";
import { clearTicketData, getTicket, updateTicket } from "../../../redux/actions/ticket";
import { addNote, clearNotesData, listNotes } from "../../../redux/actions/note";
import { ADD_NOTE, GET_TICKET, UPDATE_TICKET } from "../../../redux/action_types";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: 290,
    },
  },
  input: {
    marginTop: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "1rem",
    },
  },
  res: {
    [theme.breakpoints.down("sm")]: {
      // justifyContent: "center",
      padding: '0 0.6rem'
    },
  },
}));
const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}))(Button);
export default function Index() {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const classes = useStyles();
  const ticket = useSelector((state) => state.ticket);
  const notes = useSelector((state) => state.note);
  const auth = useSelector((state) => state.auth);
  const { ticketId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [ticketDetail, setTicketDetail] = useState({});
  const [notesList, setNotesList] = useState([]);
  const [noteText, setNoteText] = useState("");
  const [openAddNote, setOpenAddNote] = useState(false);
  useEffect(() => {
    if (ticketId)
      dispatch(getTicket(ticketId));
  }, []);
  useEffect(() => {
    if (ticket.detail?.data?._id) {
      dispatch(clearTicketData(GET_TICKET))
      setTicketDetail({ ...ticketDetail, ...ticket.detail?.data })
      dispatch(listNotes({ ticketId }));
    }
    if (ticket.update?.data?._id) {
      dispatch(clearTicketData(UPDATE_TICKET));
      dispatch(getTicket(ticketId));
    }
  }, [ticket]);
  useEffect(() => {
    if (notes.list?.data) {
      setNotesList(notes.list?.data)
    }
    // console.log(notes)
    if (notes.add?.data?._id) {
      dispatch(clearNotesData(ADD_NOTE))
      setOpenAddNote(false);
      dispatch(listNotes({ ticketId }));
    }
  }, [notes]);
  const onTicketClose = () => {
    dispatch(updateTicket({ status: 'close' }, ticketId));
  }
  const openModal = () => {
    setOpenAddNote(true);
  }

  return (
    <Grid>
      <Grid>
        <Grid>
          <h2>
            <IconButton
              color="primary"
              onClick={() => { history.push(auth.data.role.name === BRM ? '/tickets' : '/super-tickets') }}
              icon=<ArrowBack />
            ></IconButton>
            Ticket Detail
          </h2>
        </Grid>
        <Grid
          spacing={3}
          className={`${classes.res}`}
          container
          // component={"form"}
          noValidate
        >
          <Grid item sm={12}>
            <h2>
              Ticket ID: #{ticketDetail?.ticketNo}
              <span className={`status status-${ticketDetail.status}`}>
                {ticketDetail.status}
              </span>
            </h2>
            <h3>
              Date Submitted:{" "}
              {new Date(ticketDetail.createdAt).toLocaleString("en-US", options)}
            </h3>
            <h3>Type: {ticketDetail.type}</h3>
            <h3>Created By: {ticketDetail.userId?.firstName + (ticketDetail.userId?.lastName ? ' ' + ticketDetail.userId?.lastName : '')}</h3>
            <hr />
          </Grid>

          <Grid item sm={12} className="ticket-desc">
            <h3>Description of Issue</h3>
            <p>{ticketDetail.description}</p>
          </Grid>
          <Grid item sm={12} className="ticket-page">
            <h2>Notes</h2>
            {ticketDetail.status !== "close" && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                // startIcon={<FaPlus>Add Notes</FaPlus>}
                onClick={() => { openModal() }}
                style={{ marginBottom: 30 }}
              >
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>
                Add Note
              </Button>

            )}
            {notesList.map((note) => (
              <Grid
                item
                className="note"
                style={{
                  backgroundColor: note.isStaff ? "rgba(0,0,0,0.7)" : "#fff",
                  color: note.isStaff ? "#fff" : "#000",
                }}
              >
                <h3>
                  Note from {note.isStaff || (note.userId ? note.userId.isAdmin : false) ? <span>Staff</span> : <span>{(note.userId ? note.userId.name : '') || auth.data.firstName + ' ' + (auth.data.lastName)}</span>}
                </h3>
                <p>{note.text}</p>
                <Grid className="note-date">
                  {new Date(note.createdAt).toLocaleString("en-US", options)}
                </Grid>
              </Grid>
            ))}
            {ticketDetail.status !== "close" && (
              <DeleteButton variant="contained" color="red" onClick={onTicketClose} className="btn btn-block btn-danger">
                Close Ticket
              </DeleteButton>
            )}
          </Grid>
          {/* <div className="ticket-page">
              <header className="ticket-header">

                <h2>Notes</h2>
              </header>

              {ticketDetail.status !== "close" && (
                <button onClick={openModal} className="btn">
                  <FaPlus /> Add Note
                </button>
              )}

               <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Add Note"
            >
              <h2>Add Note</h2>
              <button className="btn-close" onClick={closeModal}>
                X
              </button>
              <form onSubmit={onNoteSubmit}>
                <div className="form-group">
                  <textarea
                    name="noteText"
                    id="noteText"
                    className="form-control"
                    placeholder="Note Text"
                    value={noteText}
                    onChange={(e) => setNoteText(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-group">
                  <button className="btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </Modal>

              {notesList.map((note) => (
                <div
                  className="note"
                  style={{
                    backgroundColor: note.isStaff ? "rgba(0,0,0,0.7)" : "#fff",
                    color: note.isStaff ? "#fff" : "#000",
                  }}
                >
                  <h4>
                    Note from {note.isStaff || (note.userId ? note.userId.isAdmin : false) ? <span>Staff</span> : <span>{(note.userId ? note.userId.name : '') || auth.data.firstName + ' ' + (auth.data.lastName)}</span>}
                  </h4>
                  <p>{note.text}</p>
                  <div className="note-date">
                    {new Date(note.createdAt).toLocaleString("en-US", options)}
                  </div>
                </div>
              ))}

              {ticketDetail.status !== "close" && (
                <button onClick={onTicketClose} className="btn btn-block btn-danger">
                  Close Ticket
                </button>
              )}
            </div> */}
        </Grid>

      </Grid>
      <Dialog
        open={openAddNote}
        onClose={() => { setNoteText(""); setOpenAddNote(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: 300 }}
        className="create-note"
      // fullScreen
      >
        <DialogTitle id="alert-dialog-title">
          {"Add Note"}
        </DialogTitle>
        <DialogContent style={{}}>
          <TextField
            variant="filled"
            label="Note Text"
            value={noteText}
            // isError={errors.noteText}
            multiline
            minRows={5}
            onChange={(e) => {
              setNoteText(e.target.value);
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <ButtonCustom
            onClick={(e) => {
              let data = {
                ticketId: ticketId,
                text: noteText,
                isStaff: auth.data?.role.name === BRM ? true : false,
                staffId: auth.data?._id,
                userId: auth.data?._id,
              };
              dispatch(addNote(data));
            }}
            text="Submit"
            variant="contained"
            color="primary"
            disabled={!noteText}
          />
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
