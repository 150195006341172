import { createTheme } from '@material-ui/core';

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '14px',
          height: '14px',
        },
        '*::-webkit-scrollbar-track': {
          background: 'transparent',
          boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: "#e0e0e0",
          borderRadius: '40px',
          border: "#e0e0e0",
        },
        '*::-webkit-scrollbar-corner': {
          background: 'inherit',
        },
      },
    },
  },
  palette: {
    primary : {
      main: '#000000',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#7C746D',
      contrastText: '#FFFFFF'
    },
    accent: {
      main: '#EEEEEE',
      dark: "#E0E0E0",
      contrastText: '#000000',
    },
    red: {
      main: '#F62E00',
      contrastText: '#FFFFFF',
    },
    amber: {
      main: '#F7931E',
      contrastText: '#FFFFFF',
    },
    green: {
      main: '#00AC69',
      contrastText: '#FFFFFF',
    },
    cyan: {
      main: '#65CAE3',
      contrastText: '#FFFFFF',
    },
    yellow: {
      main: '#FFFF00',
      contrastText: '#000',
    },
    purple: {
      main: '#641675',
      contrastText: '#FFFFFF',
    }
  }
});

export default theme;