import { SET_THEME } from '../action_types';

const initialState = {
  selected: 'theme-default'
}

const theme = (state=initialState, action)  => {
  const { type, payload } = action;

  switch(type) {
    case SET_THEME: 
      return {
        ...state,
        ...payload
      };
    default: return state;
  }
}

export default theme;