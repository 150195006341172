const TABLE_HEADER = {
    name: {
        data: "Name",
        width: "150px",
    },
    emailId: {
        data: "Email",
        width: "150px",
    },
    role: {
        data: "Role",
        width: "150px",
    },
    // projects: {
    //     data: 'Projects',
    //     width: '150px',
    //     component: 'link',
    //     label:'Projects'
    // }
};

export { TABLE_HEADER };
