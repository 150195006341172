import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Collapse, Grid, Typography, useTheme } from '@material-ui/core';
import useStyles from './jss/style';
import globalStyles from '../../styles/jss/style';
import { Link } from 'react-router-dom';
import {
  Drawer, Divider,
  List, ListItem,
  ListItemIcon, ListItemText,
  IconButton,
} from '@material-ui/core';

import {
  Mail as MailIcon,
  MoveToInbox as InboxIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import navItems from './CONSTANTS';
import { Tooltip } from '../../commons/mui';
import { setLocalCompany } from '../../redux/actions/localCompany';
import { setCurrentUser } from '../../redux/actions/auth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { decrypt } from 'n-krypta';


const Index = ({
  drawerState = false,
  toggleDrawerState = null,
  handleDrawerClose = null,
  path = '/',
  user = null,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const globalClasses = globalStyles();
  const classes = useStyles();
  const [collOpen, setCollOpen] = useState({});
  const [userData, setUserData] = useState({});
  const [menuList, setMenuList] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const dispatch = useDispatch();
  const localCompany = useSelector((state) => state.localCompany);

  useEffect(() => {
    if (!localCompany.companyId.data) {
      let companyId = localStorage.getItem('comapnyId');
      if (companyId) {
        dispatch(setLocalCompany(companyId))
      }
    }
  }, [])
  useEffect(() => {
    if (localCompany.companyId?.data)
      setCompanyId(localCompany.companyId?.data);
    let menus = navItems(localCompany.companyId?.data || '');
    // console.log(localCompany, navItems(localCompany.companyId?.data || ''))
    setMenuList(menus.filter(a => a.access === userData.role?.name))
  }, [localCompany, userData])

  useEffect(() => {

    setUserData(user);

  }, [user])
  const handleClick = (e, item, i) => {
    if (item.action === 'logout') {
      e.preventDefault();
      dispatch(setCurrentUser(null));
      localStorage.removeItem("user");
      console.log(companyId, decrypt(companyId, "secret"))
      if (companyId) {
        console.log("/company-login/" + decrypt(companyId, "secret"))
        history.push("/company-login/" + decrypt(companyId, "secret"));
      } else {
        history.push("/login");
      }
    } else {
      // toggleDrawerState();
      if (item.children) e.preventDefault();
      let newColl = collOpen[i] ? false : true
      setCollOpen({
        [i]: newColl
      });
    }
  }

  const generateItem = (item, i) => {

    const parent = item.link === '/logout' ? <Tooltip key={"tooltip-" + i} title={drawerState ? '' : item.title} Component={() => <ListItem
      button
      key={i}
      component={Link}
      onClick={(e) => handleClick(e, item, i)}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
      {item.children && (collOpen[i] ? <ExpandMoreIcon /> : <ChevronLeftIcon />)}
    </ListItem>
    }
    /> : <Tooltip key={"tooltip-" + i} title={drawerState ? '' : item.title} Component={() => <ListItem
      button
      key={i}
      component={Link}
      to={item.link === '/logout' ? '' : item.link}
      onClick={(e) => handleClick(e, item, i)}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
      {item.children && (collOpen[i] ? <ExpandMoreIcon /> : <ChevronLeftIcon />)}
    </ListItem>
    }
    />
    // console.log(accessRole)
    const children = item.children && item.children.length > 0 && item.children.map((child, j) =>
      <Tooltip
        title={drawerState ? '' : child.title}
        Component={() => <ListItem
          button
          key={j}
          component={Link}
          to={child.link}
        // onClick={(e) => toggleDrawerState(false)}
        >
          <ListItemIcon
            className={classes.nested}>{child.icon}</ListItemIcon>
          <ListItemText
            className={classes.nested} primary={child.title} />
        </ListItem>
        }
      />
    )

    if (!item.children) return parent;
    else {
      return <>
        {parent}
        <Collapse in={collOpen[i]} timeout='auto' unmountOnExit>
          {children}
        </Collapse>
      </>
    }
  }


  return (
    userData && userData.accessToken && !userData.isTempPassword ?
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerState,
          [classes.drawerClose]: !drawerState,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerState,
            [classes.drawerClose]: !drawerState,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <Grid container direction="column" className={`${globalClasses.stretchHeight}`}>
          <Grid item style={{ flex: 1 }}>
            <List >
              {
                menuList?.map((item, i) => {
                  return (
                    generateItem(item, i)
                  );
                })
              }
            </List>
          </Grid>
        </Grid>
      </Drawer>
      : null
  )
}

export default Index;
