import { callAPI } from "../../utils/apiHelper";
import {
  LIST_PLAN_ACTIVITY_LOG,
  GET_PLAN_ACTIVITY_LOG,
  ADD_PLAN_ACTIVITY_LOG,
  UPDATE_PLAN_ACTIVITY_LOG,
  DELETE_PLAN_ACTIVITY_LOG
} from "../action_types";

export const listPlanActivityLogs = (params) => async (dispatch) => {
  callAPI(dispatch, `/plan_activity_logs`, 'GET', LIST_PLAN_ACTIVITY_LOG, params, true);
};


export const getPlanActivityLog = (id) => async (dispatch) => {
  callAPI(dispatch, `/plan_activity_logs/${id}`, 'GET', GET_PLAN_ACTIVITY_LOG, {}, true);
};

export const addPlanActivityLog = (data) => async (dispatch) => {
  callAPI(dispatch, `/plan_activity_logs`, 'POST', ADD_PLAN_ACTIVITY_LOG, {}, false, data);
};


export const updatePlanActivityLog = (data, id) => async (dispatch) => {
  callAPI(dispatch, `/plan_activity_logs/${id}`, 'PUT', UPDATE_PLAN_ACTIVITY_LOG, {}, false, data);
};


export const deletePlanActivityLog = (id) => async (dispatch) => {
  callAPI(dispatch, `/plan_activity_logs/${id}`, 'DELETE', DELETE_PLAN_ACTIVITY_LOG, {}, false);
};
export const clearPlanActivityLogData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_PLAN_ACTIVITY_LOG,
    payload: {
      data: null,
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
}; 
