import React from 'react';
import { Radio, FormControlLabel} from '@material-ui/core';
// import '../../styles/style.sass';
import './css/style.css';


const Index = ({value= '', label='', disabled=false, color='default', name=''}) => {
  
  return (
      <FormControlLabel 
        value={value} 
        disabled={disabled}
        control={<Radio color={color}  />
      }>
        label={label}
      </FormControlLabel>
  );
}

export default Index;
