import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTheme } from '../redux/actions/theme';
import useStyles from '../styles/jss/style';
import Dropdown from 'react-dropdown';
import '../styles/ui.css';
// import { 
//   Label, Input,
//   Dropdown as CustomDD, DropdownBs,
//   TextArea, Button,
//   AddButton, ToggleSwitch 
// } from '../commons/ui';
import { ToggleSwitch } from '../commons/ui';
import { 
    TextField, Button,
    Checkbox, IconButton,
    RadioButton, Dialog,
    ErrorDialog, FormDialog,
    Tooltip, TwoInputDialog,
    Table, AutoComplete, DynamicForm,
    SvgIcon, Pagination
  } from '../commons/mui';
import { 
    FilterList, AddCircleOutline,
    Settings, AttachFile,
    Attachment, GetApp,
    Delete, Warning, Block,
    Cancel,
  } from '@material-ui/icons';
import { 
  Car as CarIcon
} from '../assets/icons/custom';

import Bay from './Cards/Bay';
import { Grid, useTheme } from '@material-ui/core';

const Ui = () => {
  const theme = useTheme();
  const classes = useStyles();
  // const dispatch = useDispatch();

  // DROPDOWN onChange function
  let ddSample = [
    {
      value:'sbc',
      label: 'Bangalore',
    },
    {
      value:'mas',
      label: 'Chennai',
    },
    {
      value:'dli',
      label: 'Delhi',
    },
    {
      value:'bct',
      label: 'Mumbai',
    },
    {
      value:'hyb',
      label: 'Hyderabad',
    },
  ];
  const [ddSelectedCity, setDDSelectedCity] = useState('sbc');
  const onDDChange = (e) => {
    setDDSelectedCity(e.target.value);
  }
  
  // Subscribing to theme
  // const theme = useSelector(state => state.theme);
  // // TOGGLESWITCH onChange function - (Refer for theme switch too)
  // const onToggle = (state) => {
  //   dispatch(setTheme(state ? 'theme-dark': 'theme-default'));
  //   localStorage.setItem('theme', state ? 'theme-dark': 'theme-default');
  // }

  // Checkbox onChange function
  const [cbState, setcbState] = useState(true);
  const onCBChange = (e) => {
    // console.log(cbState, e.target.name);
    setcbState(e.target.checked);
  }

  // TextField onChange Function
  const [tFState, setTFState] = useState('');
  const onTFChange = (e) => {
    setTFState(e.target.value);
  }

  const [tFState1, setTFState1] = useState('');
  const onTFChange1 = (e) => {
    setTFState1(e.target.value);
  }

  // Bay Card content function
  const bayCardContent = (text) => {
    return <div>{text}</div>
  }

  // Button onClick Function
  const onBtnClick = () => {
    // console.log('Button Clicked');
  }

  // Icon Button onClick Function
  const onIconBtnClick = () => {
    // console.log('Icon Button Clicked');
  }

  // For Add Location Dialog
  const onLocationModalSubmit = (data) => {
    if(data){
      // console.log(data);
    } else {
      alert('Location empty!')
    }
  }

  // Bay onBayDeleteClick Function
  const onBayDelete = (e) => {
    alert('Bay deleted');
  }

  // Table Data  
  const createData = (id, name, location, type, coordinates, bays) => ({id, name, location, type, coordinates, bays});
  const rows = [
    createData(
      0,
      'Workshop 1',
      'Bangalore, India',
      'Type 1',
      '67.81, 39.01',
      18 
    ),
    createData(
      1,
      'Workshop 2',
      'Mumbai, India',
      'Type 1',
      '73.63, 88.01',
      10 
    ),
    createData(
      2,
      'Workshop 3',
      'Chennai, India',
      'Type 3',
      '93.0, 59.08',
      15 
    ),
    createData(
      3,
      'Workshop 4',
      'Bangalore, India',
      'Type 2',
      '68.03, 39.47',
      5 
    ),
    createData(
      4,
      'Workshop 5',
      'Mumbai, India',
      'Type 3',
      '12.57, 13.86',
      12  
    ),
    createData(
      5,
      'Workshop 6',
      'Bangalore, India',
      'Type 4',
      '69.81, 40.63',
      7  
    ),
    createData(
      6,
      'Workshop 7',
      'Bangalore, India',
      'Type 1',
      '67.81, 39.01',
      23
    ),
  ];

  // Table Head
  const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
    { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'coordinates', numeric: false, disablePadding: false, label: 'Coordinates' },
    { id: 'bays', numeric: true, disablePadding: false, label: 'No of Bays' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
  ];

  // Table Delete item
  const onDeleteWorkShop = (e, id) => {
    alert(`Deleted Workshop with id ${id}`);
  }

  const [acValue, setACValue] = useState('');

  // Add Make Form functions
  const [newMakeData, setNewMakeData] = useState({name: '', description: ''});
  const [forceCloseNewMakeDialog, setForceCloseNewMakeDialog] = useState(false);
  const onNewMakeDataChange = (e) => setNewMakeData(old => (
    {
      ...old,
      [e.target.name]: e.target.value
    }
  ))

  const resetNewMakeData = () => setNewMakeData({name:'', description:''});

  // For Pagination
  const [page, setPage] = useState(1);

  return (
    <div className="ui-gallery">
      <h4 className="ui-component-headings">Theme</h4>
      <div className='ui-components'>
        <ToggleSwitch 
          // checked={theme.selected === 'theme-dark'} onChange={onToggle}
          />
      </div>
      {/* <h4 className="ui-component-headings">Labels</h4>
      <div className='ui-components'>
        <Label />
      </div>
      <h4 className="ui-component-headings">Inputs</h4>
      <div className="ui-components">
        <Input />
      </div>
      <h4 className="ui-component-headings">TextArea</h4>
      <div className="ui-components">
        <TextArea />
      </div>
      <h4 className="ui-component-headings">DropDowns</h4>
      <div className="ui-components">
        <CustomDD 
          options={ddSample}
          placeHolder={`Select City`}
          onChange={onDDChange}
          selected={ddSelectedIndex}
        />
      </div>
      <div className="ui-components">
        <div className='rdd-custom'>
        <Dropdown
          options={ddSample}
          placeHolder={`Select City`}
          // onChange={onDDChange}
          // selected={ddSelectedIndex}
        />
        </div>
      </div>
      <h4 className="ui-component-headings">Buttons</h4>
      <div className="ui-components">
        <Button />
      </div>
      <h4 className="ui-component-headings">Circular Buttons</h4>
      <div className="ui-components">
        <AddButton />
      </div> */}
      <h4 className="ui-component-headings">Buttons</h4>
      <div className="ui-component-block">
        <div className="ui-components">
          <Button width='400px' text='Default Text Button' onClick={onBtnClick}/>
        </div>
        <div className="ui-components">
          <Button width='400px' text='Default Containted Button' variant='contained' onClick={onBtnClick}/>
        </div>
        <div className="ui-components">
          <Button width='400px' text='Primary Containted Button' variant='contained' color='primary' onClick={onBtnClick}/>
        </div>
        <div className="ui-components">
          <Button width='400px' text='DisabledContainted Button' variant='contained'disabled={true}/>
        </div>
        <div className="ui-components">
          <Button width='400px' text='Secondary Containted Button' variant='contained' color='secondary' onClick={onBtnClick}/>
        </div>
      </div>
      <h4 className="ui-component-headings">Checkboxes</h4>
      <div className="ui-component-block">
        <div className="ui-components">
          <Checkbox 
            text="Default"
            checked={cbState} 
            onChange={onCBChange} 
          />
          <Checkbox 
            text="Primary"
            checked={cbState} 
            color='primary'
            onChange={onCBChange} 
          />
          <Checkbox 
            text="Disabled"
            checked={cbState} 
            color='secondary'
            disabled={true}
          />
          <Checkbox 
            text="Secondary"
            checked={cbState} 
            color='secondary'
            onChange={onCBChange} 
          />
        </div>
      </div>
      <h4 className="ui-component-headings">Textfield</h4>
      <div className="ui-component-block">
        <form autoComplete='off'>
          <div className="ui-components">
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField 
                label='Standard'
                value={tFState}
                onChange={onTFChange}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField 
                label='Filled'
                variant='filled'
                value={tFState}
                onChange={onTFChange}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField 
                label='Outlined'
                variant='outlined'
                value={tFState}
                onChange={onTFChange}
              />
            </div>
          </div>
          <div className="ui-components">
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField 
                label='Standard Disabled'
                color='secondary'
                disabled={true}
                value={tFState}
                onChange={onTFChange}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField 
                label='Filled Disabled'
                variant='filled'
                color='secondary'
                disabled={true}
                value={tFState}
                onChange={onTFChange}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField 
                label='Outlined Disabled'
                variant='outlined'
                color='secondary'
                disabled={true}
                value={tFState}
                onChange={onTFChange}
              />
            </div>
          </div>
          <div className="ui-components">
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField 
                label='Standard Secondary'
                color='secondary'
                value={tFState}
                onChange={onTFChange}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField 
                label='Filled Secondary'
                variant='filled'
                color='secondary'
                value={tFState}
                onChange={onTFChange}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField 
                label='Outlined Secondary'
                variant='outlined'
                color='secondary'
                value={tFState}
                onChange={onTFChange}
              />
            </div>
          </div>
        </form>
      </div> 
      <h4 className="ui-component-headings">Password</h4>
      <div className="ui-component-block">
        <form autoComplete='off'>
        <div className="ui-components">
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField
                label='Standard Password'
                color='secondary'
                type='password'
                value={tFState1}
                onChange={onTFChange1}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField
                label='Filled Password'
                variant='filled'
                color='secondary'
                type='password'
                value={tFState1}
                onChange={onTFChange1}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField
                label='Outlined Password'
                variant='outlined'
                color='secondary'
                type='password'
                value={tFState1}
                onChange={onTFChange1}
              />
            </div>
          </div>
        </form>
      </div>
      <h4 className="ui-component-headings">Search</h4>
      <div className="ui-component-block">
        <form noValidate autoComplete="off">
          <div className="ui-components">
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField
                label='Standard Search'
                color='secondary'
                type='search'
                value={tFState}
                onChange={onTFChange}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField
                label='Filled Search'
                variant='filled'
                color='secondary'
                type='search'
                value={tFState}
                onChange={onTFChange}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField
                label='Outlined Search'
                variant='outlined'
                color='secondary'
                type='search'
                value={tFState}
                onChange={onTFChange}
              />
            </div>
            </div>
        </form>
      </div>
      <h4 className="ui-component-headings">Validation</h4>
      <div className="ui-component-block">
        <form noValidate autoComplete="off">
          <div className="ui-components">
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField
                label='Standard Error'
                color='secondary'
                value={'Error'}
                helperText='Incorrect entry'
                error={true}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField
                label='Filled Error'
                variant='filled'
                color='secondary'
                value={'Error'}
                helperText='Incorrect entry'
                error={true}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField
                label='Outlined Error'
                variant='outlined'
                color='secondary'
                value={'Error'}
                helperText='Incorrect entry'
                error={true}
              />
            </div>
            </div>
        </form>
      </div>
      <h4 className="ui-component-headings">Dropdown</h4>
      <div className="ui-component-block">
        <form noValidate autoComplete="off">
          <div className="ui-components">
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField
                label='Standard Dropdown'
                color='secondary'
                value={ddSelectedCity}
                select={true}
                options={ddSample}
                helperText='Please select a city'
                onChange={onDDChange}
                customClass={'width-200px'}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField
                label='Filled Dropdown'
                variant='filled'
                color='secondary'
                value={ddSelectedCity}
                select={true}
                options={ddSample}
                helperText='Please select a city'
                onChange={onDDChange}
                customClass={'width-200px'}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField
                label='Outlined Dropdown'
                variant='outlined'
                color='secondary'
                value={ddSelectedCity}
                select={true}
                options={ddSample}
                helperText='Please select a city'
                onChange={onDDChange}
                customClass={'width-200px'}
              />
            </div>
            </div>
        </form>
      </div>
      <h4 className="ui-component-headings">Autocomplete</h4>
      <div className="ui-component-block">
        <div className="ui-components" style={{width:'60vw'}}>
          <Grid container style={{width:'100%'}}>
            <Grid item sm={4}>
              <div className={`${classes.fullWidth} ${classes.marginX}`}>
                <AutoComplete
                  multiple
                  label='Multi Select'
                  variant='filled'
                  color='primary'
                  // freeSolo
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item sm={4}>
              <div className={`${classes.fullWidth} ${classes.marginX}`}>
                <AutoComplete
                  label='Single Select'
                  variant='filled'
                  color='primary'
                  // freeSolo
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item container sm={4}>
              <div className={`${classes.fullWidth} ${classes.marginX}`}>
                <AutoComplete
                  label='Disabled Options'
                  variant='filled'
                  color='primary'
                  value={acValue}
                  onChange={(event, newValue) => setACValue(newValue)}
                  getOptionDisabled={(option) => option.title === 'Option 1' || option.title === 'Option 2' }
                  // freeSolo
                  fullWidth
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <h4 className="ui-component-headings">Custom Svg Icons</h4>
      <div className="ui-component-block">
        <div className="ui-components">
          <Tooltip 
            title='Default Icon'
            placement='bottom'
            Component={() => 
              <SvgIcon 
                component={CarIcon}
                // viewBox='9 0 6 24'
              />
            } 
          />
          <Tooltip 
            title={`color = 'primary'`}
            placement='bottom'
            Component={() => 
              <SvgIcon 
                component={CarIcon}
                color='primary'
                // styleObj={{height:'32px', transform: 'rotate(-90deg)'}}
              />
            } 
          />
          <Tooltip 
            title={`color = 'secondary'`}
            placement='bottom'
            Component={() => 
              <SvgIcon 
                component={CarIcon}
                color='secondary'
              />
            } 
          />
          <Tooltip 
            title={<div><h1>Height without width</h1>color = 'secondary'<br/>
            height = '48px'<br />viewBox = '9 0 6 24'</div>}
            placement='bottom'
            Component={() => 
              <SvgIcon 
                component={CarIcon}
                color='secondary'
                height='48px'
                // width='48px'
                viewBox='9 0 6 24'
              />
            } 
          />
          <Tooltip 
            title={<div>color = 'primary'<br/>
              height = '48px'<br />width = '48px'</div>}
            placement='bottom'
            Component={() => 
              <SvgIcon 
                component={CarIcon}
                color='primary'
                height='48px'
                width='48px'
                // transform='rotate(-90deg)'
              />
            } 
          />
          <Tooltip 
            title={<div>color = 'secondary'<br />viewBox = '9 0 6 24'<br/>
              height = '48px'<br />width = '48px'<br />
              transform = 'rotate(-90deg)'</div>}
            placement='bottom'
            Component={() => 
              <SvgIcon 
                component={CarIcon}
                color='secondary'
                height='48px'
                width='48px'
                transform='rotate(-90deg)'
              />
            } 
          />
        </div>
      </div>
      <h4 className="ui-component-headings">Icon Buttons</h4>
      <div className="ui-component-block">
        <div className="ui-components">
          <IconButton color="primary" icon={<FilterList/>} onClick={onIconBtnClick}></IconButton>
          <IconButton color="secondary" icon={<FilterList/>} onClick={onIconBtnClick}></IconButton>
          <IconButton color="default" icon={<FilterList/>} onClick={onIconBtnClick}></IconButton>
          <IconButton color="primary" icon={<AddCircleOutline/>} onClick={onIconBtnClick}></IconButton>
          <IconButton color="secondary" icon={<AddCircleOutline/>} onClick={onIconBtnClick}></IconButton>
          <IconButton color="default" icon={<AddCircleOutline/>} onClick={onIconBtnClick}></IconButton>
          <IconButton color="primary" icon={<Settings/>} onClick={onIconBtnClick}></IconButton>
          <IconButton color="secondary" icon={<Settings/>} onClick={onIconBtnClick}></IconButton>
          <IconButton color="default" icon={<Settings/>} onClick={onIconBtnClick}></IconButton>
          <IconButton color="default" icon={<AttachFile/>} onClick={onIconBtnClick}></IconButton>
          <IconButton color="default" icon={<Attachment/>} onClick={onIconBtnClick}></IconButton>
          <IconButton color="default" icon={<GetApp />} onClick={onIconBtnClick}></IconButton>
          <IconButton color="default" icon={<Delete/>} onClick={onIconBtnClick}></IconButton>
          <IconButton color="default" icon={<Warning/>} onClick={onIconBtnClick}></IconButton>
          <IconButton color="default" icon={<Block/>} disabled={true}></IconButton>
        </div>
      </div>
      <h4 className="ui-component-headings">Radio Buttons</h4>
      <div className="ui-component-block">
        <div className="ui-components">
         <RadioButton value="female" label="Female" color="secondary" />
         <RadioButton value="male" label="Male" disabled={true} />
        
         </div>
      </div>
      <h4 className="ui-component-headings">Dialog From Buttons</h4>
      <div className="ui-component-block">
        <div className="ui-components">
          <Dialog buttonName="Simple Modal" body="Data will be saved on clicking CONFIRM" title="Please Confirm to proceed" firstActionbutton="Cancel" secondActionbutton="Confirm"/>
          <ErrorDialog buttonName="Error Modal" body="An Error Occurred" title="Error!" actionbutton="Ok"/>
        </div>
        <div className="ui-components">
          <FormDialog 
              Component={(props) => <Button {...props} width='400px' variant='contained' color='primary' text='Add Location'/>}
              title="Add a new location"
              firstActionbutton="Cancel"
              secondActionbutton="Submit"
              alert={false}
              onSecondActionClick={onLocationModalSubmit}
              tfLabel='Workshop Location'
            />
        </div>
        <div className="ui-components">
          <TwoInputDialog 
            Component={(props) => <Button {...props} width='400px' variant='contained' color='primary' text='Add Country And City'/>}
            title="Add a new location"
            firstActionbutton="Cancel"
            secondActionbutton="Submit"
            onSecondActionClick={onLocationModalSubmit}
            tfLabel1='Country'
            tfLabel2='City'
          />
        </div>
        <div className="ui-components">
        </div>
      </div>
      <h4 className="ui-component-headings">Dialog From Icon Buttons</h4>
      <div className="ui-component-block">
        <div className="ui-components">
          <FormDialog 
            Component={(props) => <IconButton {...props} color="primary" icon={<AddCircleOutline style={{ height:'40', width:'40' }} />}/>}
            title="Add a new location"
            firstActionbutton="Cancel"
            secondActionbutton="Submit"
            alert={false}
            onSecondActionClick={onLocationModalSubmit}
            tfLabel='Workshop Location'
          />
          <TwoInputDialog 
            Component={(props) => <IconButton {...props} color="primary" icon={<AddCircleOutline style={{ height:'40', width:'40' }} />}/>}
            title="Add a new location"
            firstActionbutton="Cancel"
            secondActionbutton="Submit"
            btnVariant="contained"
            btnColor="primary"
            onSecondActionClick={onLocationModalSubmit}
            tfLabel1='Country'
            tfLabel2='City'
          />
        </div>
      </div>
      <h4 className="ui-component-headings">Bay Card</h4>
      <div className="ui-component-block">
        <div className="ui-components">
          <div style={{marginLeft: '1rem', marginRight:'1rem'}}>
            <Bay title='Green' color={ theme.palette.green } titleAlign='center' width='250px' height='80px' content={bayCardContent('Petrol')}/>
          </div>
          <div style={{marginLeft: '1rem', marginRight:'1rem'}}>
            <Bay title='Amber' color={ theme.palette.amber } titleAlign='center' width='250px' height='80px' content={bayCardContent('Petrol')}/>
          </div>
          <div style={{marginLeft: '1rem', marginRight:'1rem'}}>
            <Bay title='Red' color={ theme.palette.red } titleAlign='center' width='250px' height='80px' content={bayCardContent('Petrol')}/>
          </div>
        </div>
        <div className="ui-components">
          <div style={{marginLeft: '1rem', marginRight:'1rem'}}>
            <Bay 
              title='secondary'
              color={ theme.palette.green }
              width='250px'
              height='80px'
              content={bayCardContent('Petrol')}
              Action={() => <Cancel />}
              onAction={onBayDelete}
            />
          </div>
        </div>
      </div>
      <h4 className="ui-component-headings">Tooltip</h4>
      <div className="ui-component-block">
        <div className="ui-components">
          <Tooltip arrow Component={() => <Button variant='contained' color='primary'/>} />
        </div>
        <div className="ui-components">
          <Tooltip title='Add Workshop Location' arrow Component={() => <IconButton color="primary" icon={<AddCircleOutline style={{ height:'40', width:'40' }} />}/>} />
        </div>
      </div>
      <h4 className="ui-component-headings">Pagination</h4>
      <div className="ui-component-block">
        <div className="ui-components">
          <Pagination 
            count={100}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
      <h4 className="ui-component-headings">Table</h4>
      <div className="ui-component-block">
        <div className="ui-components">
          <Table 
            rows={rows}
            headCells={headCells}
            link='/workshop/'
            title='Workshops'
            minHeight='calc(100vh - 150px)'
            // maxHeight='400px'
            onDelete={onDeleteWorkShop}
          />
        </div>
      </div>
    </div>
  )
}

export default Ui;
