import { 
  LIST_VARIANT,
  GET_VARIANT,
  ADD_VARIANT,
  UPDATE_VARIANT,
  DELETE_VARIANT
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listVariants = (params) => async dispatch => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: 'GET',
      url: `/variant`,
      params
    });
    dispatch({
      type: LIST_VARIANT,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: LIST_VARIANT,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const getVariant = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/variant/${id}`,
    });
    dispatch({
      type: GET_VARIANT,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: GET_VARIANT,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addVariant = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/variant`,
      data
    });
    dispatch({
      type: ADD_VARIANT,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: ADD_VARIANT,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const updateVariant = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/variant/${id}`,
      data
    });
    dispatch({
      type: UPDATE_VARIANT,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: UPDATE_VARIANT,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const deleteVariant = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/variant/${id}`,
    });
    dispatch({
      type: DELETE_VARIANT,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: DELETE_VARIANT,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}