import { Grid, Typography } from "@material-ui/core";
import React from "react";

export default function Index() {
  return (
    <Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2.5rem",
          alignItems: "center",
        }}
      >
        <h2>EverSign Signature </h2>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Typography style={{ textAlign: 'center' }}>Signature is successfully done Click on Verify Signature.</Typography>
        </Grid>
      </Grid>

    </Grid>
  );
}
