import { 
  LIST_WORKSHOP,
  GET_WORKSHOP,
  ADD_WORKSHOP,
  UPDATE_WORKSHOP,
  DELETE_WORKSHOP
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listWorkshops = (params) => async dispatch => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: 'GET',
      url: `/workshop`,
      params
    });
    dispatch({
      type: LIST_WORKSHOP,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: LIST_WORKSHOP,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const getWorkshop = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/workshop/${id}`,
    });
    dispatch({
      type: GET_WORKSHOP,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: GET_WORKSHOP,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addWorkshop = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/workshop`,
      data
    });
    dispatch({
      type: ADD_WORKSHOP,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: ADD_WORKSHOP,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const updateWorkshop = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/workshop/${id}`,
      data
    });
    dispatch({
      type: UPDATE_WORKSHOP,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: UPDATE_WORKSHOP,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const deleteWorkshop = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/workshop/${id}`,
    });
    dispatch({
      type: DELETE_WORKSHOP,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: DELETE_WORKSHOP,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}