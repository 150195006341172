import {
  LIST_PLAN_ACTIVITY_LOG,
  GET_PLAN_ACTIVITY_LOG,
  ADD_PLAN_ACTIVITY_LOG,
  UPDATE_PLAN_ACTIVITY_LOG,
  DELETE_PLAN_ACTIVITY_LOG
} from '../action_types';

const initialState = {
  list: {
    pagination: {},
    totalCount: 0,
    data: [],
    isLoading: true,
    hasError: false
  },
  detail: {
    data: {},
    isLoading: true,
    hasError: false
  }
}

const project = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LIST_PLAN_ACTIVITY_LOG:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload
        }
      };
    case GET_PLAN_ACTIVITY_LOG:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload
        }
      };
    case ADD_PLAN_ACTIVITY_LOG:
      return {
        ...state,
        add: {
          ...state.add,
          ...payload,
          isLoading: false,
          hasError: payload.hasError,
        }
      }
    case UPDATE_PLAN_ACTIVITY_LOG:
      return {
        ...state,
        update: {
          ...state.update,
          ...payload
        }
      }
    case DELETE_PLAN_ACTIVITY_LOG:
      return {
        ...state,
        delete: {
          ...state.delete,
          ...payload
        }
      }
    default: return state;
  }
}

export default project;