const TABLE_HEADER = {
  // referenceView: {
  //   data: "Reference",
  //   width: "150px",
  // },
  questionView: {
    data: "Question",
    width: "150px",
  },
  typeView: {
    data: "Type",
    width: "150px",
  },
};
const QUESTION_TYPE = [
  { label: "Quantitative", value: "Quantitative" },
  { label: "Yes/No", value: "Option" },
];
const TEMPLATE_TYPES = [
  { label: "Project", value: "P" },
  { label: "Inspector", value: "T" },
];
export { TABLE_HEADER, QUESTION_TYPE, TEMPLATE_TYPES };