import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Pagination, Table } from "../../../commons/mui";
import { deleteLocation, listLocations } from "../../../redux/actions/location";
import { TABLE_HEADER } from "./CONSTANTS";
import { DIALOGS } from "../../../commons/CONSTANTS";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 310,
    [theme.breakpoints.down("sm")]: {
      width: 290,
    },
  },
  input: {
    marginTop: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "1rem",
    },
  },
}));

export default function Index() {
  const [field, setField] = useState({
    city: "",
    country: "",
    address: "",
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [partList, setpartList] = useState([]);
  const [editBtn, setEditBtn] = useState(false);
  const [index, setIndex] = useState(null);
  const [id, setid] = useState(null);
  const location = useSelector((state) => state.location);
  const [userList, setuserList] = useState([]);
  const [page, setPage] = useState(1);
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: "sample",
    text: "sample",
    open: false,
    btn1: {
      text: "No",
      onClick: () => {},
    },
    btn2: {
      text: "Yes",
      onClick: () => {},
    },
  });

  const setFields = (type, val) => {
    Object.keys(field).map((itm) => {
      if (itm == type) {
        setField({ ...field, [itm]: val });
      }
    });
  };

  useEffect(() => {
    dispatch(listLocations({ skip: (page - 1) * 10 }));
  }, [page]);

  useEffect(() => {
    if (location.list.data) {
      setpartList(location.list.data);
    }
  }, [location]);

  const onDelete = (i, e, item) => {
    // console.log(item, i, item.name || item.projectNo)
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: "Please confirm to delete",
      text: `You are about to delete location. Are you sure ?`,
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => { dispatch(deleteLocation(item._id));  setTimeout(() => {
              dispatch(listLocations({ skip: (page - 1) * 10 }));
            }, 500);},

      },
    }));
  };

  return (
    <Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2.5rem",
          alignItems: "center",
        }}
      >
        <h2>Location List</h2>
        <Button
          width="200px"
          text="Add Location"
          variant="contained"
          color="primary"
          onClick={() => history.push("/add/location")}
        />
      </Grid>
      {DIALOGS.CONFIRM_DIALOG({
        title: showConfirmDialog.title,
        text: showConfirmDialog.text,
        open: showConfirmDialog.open,
        setOpen: setShowConfirmDialog,
        btn1: showConfirmDialog.btn1,
        btn2: showConfirmDialog.btn2,
        container: `${classes.paddingX}`,
      })}
      <Grid style={{ marginTop: "1rem" }}>
        <Table
          header={TABLE_HEADER}
          data={partList}
          onDelete={onDelete}
          // onDelete={(i, e, row) => {
          //   dispatch(deleteLocation(row._id));
          //   setTimeout(() => {
          //     dispatch(listLocations({ skip: (page - 1) * 10 }));
          //   }, 500);
          // }}
          onEdit={(i, row) => {
            history.push(`/edit/location/${row._id}`)
          }}
        />
      </Grid>
      <Grid
          item
          container
          style={{ justifyContent: "center" }}
        >
          <div className="ui-components">
            <Pagination
              count={location.list.totalCount}
              page={page}
              setPage={setPage}
            />
          </div>
        </Grid>
    </Grid>
  );
}
