import { callAPI } from "../../utils/apiHelper";
import {
  LIST_OBJECT_CHECKPOINT,
  GET_OBJECT_CHECKPOINT,
  ADD_OBJECT_CHECKPOINT,
  UPDATE_OBJECT_CHECKPOINT,
  DELETE_OBJECT_CHECKPOINT
} from "../action_types";

export const listCheckpoints = (params) => async (dispatch) => {
  callAPI(dispatch,`/object_checkpoints`, 'GET',LIST_OBJECT_CHECKPOINT,params,true);
};


export const getCheckpoint = (id) => async (dispatch) => {
  callAPI(dispatch,`/object_checkpoints/${id}`, 'GET',GET_OBJECT_CHECKPOINT,{},true);
};

export const addCheckpoint = (data) => async (dispatch) => {
  callAPI(dispatch,`/object_checkpoints`, 'POST',ADD_OBJECT_CHECKPOINT,{},false,data);
};


export const updateCheckpoint = (data, id) => async (dispatch) => {
  callAPI(dispatch,`/object_checkpoints/${id}`, 'PUT',UPDATE_OBJECT_CHECKPOINT,{},false,data);
};


export const deleteCheckpoint = (id) => async (dispatch) => {
  callAPI(dispatch,`/object_checkpoints/${id}`, 'DELETE',DELETE_OBJECT_CHECKPOINT,{},false);
};
export const clearCheckpointData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_OBJECT_CHECKPOINT,
    payload: {
      data: null,
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
}; 
