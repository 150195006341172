import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Pagination, Table } from "../../../commons/mui";
import { TABLE_HEADER } from "./CONSTANTS";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { listProjects, updateProject } from "../../../redux/actions/project";
import moment from 'moment';


export default function Index() {
  const history = useHistory();
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const project = useSelector((state) => state.project);

  useEffect(() => {
    if (auth.data._id) {
      dispatch(listProjects({ auditBy: auth.data._id }));
    }
  }, [auth]);
  useEffect(() => {
    if (auth.data._id) {
      dispatch(listProjects({ auditBy: auth.data._id, skip: 10 * (page - 1) }));
    }
  }, [page]);

  useEffect(() => {
    if (project.list.data) {
      let temp = project.list.data.map((a) => {
        a.customer = a.customer
          ? (a.customer.firstName ? (a.customer.firstName +
            (a.customer.lastName ? " " + a.customer.lastName : "")) : a.customer.emailId)
          : "";
        a.dateCreated = moment().format('YYYY-MM-DD hh:mm A');
        return a;
      });
      setdata(temp);
    }
  }, [project]);

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2.5rem",
          alignItems: "center",
        }}
      >
        <h2>Project List</h2>
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        <Table
          header={TABLE_HEADER}
          data={data}
          startBtn={true}
          onStart={(e, i, row) => {
            if (row.status === 'Awaiting to Start Audit') {
              dispatch(updateProject({ status: 'Awaiting to Complete Audit' }, row._id))
            }
            history.push(`/audit/${row._id}`);
          }}
        />
        <Grid
          item
          container
          style={{ justifyContent: "center" }}
        >
          <div className="ui-components">
            <Pagination
              count={project.list.totalCount}
              page={page}
              setPage={setPage}
            />
          </div>
        </Grid>
      </Grid>

    </div>
  );
}
