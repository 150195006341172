import {
  Grid,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Pagination, Table } from "../../../commons/mui";
import { TABLE_HEADER } from "./CONSTANTS";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAssessmentQuestion,
  listAssessmentQuestions,
} from "../../../redux/actions/assessmentQuestion";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    [theme.breakpoints.down("sm")]: {
      width: 290,
    },
  },
  input: {
    marginTop: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "1rem",
    },
  },
}));

export default function Index() {
  const [field, setField] = useState({
    question: "",
    type: "",
    reference: "",
    controlMeasure: "",
  });
  const [partList, setpartList] = useState([]);
 
  const dispatch = useDispatch();
  
  const history = useHistory();

  const assessment = useSelector((state) => state.assessmentQuestion);
  const [page, setPage] = useState(1);

  const setFields = (type, val) => {
    Object.keys(field).map((itm) => {
      if (itm == type) {
        setField({ ...field, [itm]: val });
      }
    });
  };

  useEffect(() => {
    if (assessment.list.data) {
      setpartList(assessment.list.data);
    }
  }, [assessment]);

  useEffect(() => {
    dispatch(listAssessmentQuestions({ skip: (page - 1) * 10 }));
  }, [page]);

  return (
    <Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2.5rem",
          alignItems: "center",
        }}
      >
        <Grid>
          <h2>Questions List</h2>
        </Grid>
        <Button
          width="200px"
          text="Add Question"
          variant="contained"
          color="primary"
          onClick={() => history.push("/assessment/create")}
        />
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        <Table
          header={TABLE_HEADER}
          data={partList}
          onDelete={(i, e, row) => {
            dispatch(deleteAssessmentQuestion(row._id));
            setTimeout(() => {
              dispatch(listAssessmentQuestions({limit: 5, skip: (page - 1) * 5}));
            }, 500);
          }}
          onEdit={(i, row) => {
            history.push(`/edit/assessment/${row._id}`);
          }}
        />
      </Grid>
      <Grid
          item
          container
          style={{ justifyContent: "center" }}
        >
          <div className="ui-components">
            <Pagination
              count={assessment.list.totalCount}
              page={page}
              setPage={setPage}
            />
          </div>
        </Grid>
    </Grid>
  );
}
