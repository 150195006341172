import { combineReducers } from 'redux';
import theme from './theme';
import account from './account';
import auth from './auth';
import bay from './bay';
import bayType from './bayType';
import bookBay from './bookBay';
import costCentre from './costCentre';
import dashboard from './dashboard';
import cars from './cars';
import modelsPerformance from './modelsPerformance';
import dateRange from './dateRange';
import jobcard from './jobcard';
import jobType from './jobType';
import jobParameter from './jobParameter';
import location from './location';
import platform from './platform';
import make from './make';
import model from './model';
import vehicleModules from './vehicleModule';
import partComponent from './partComponent';
import newPart from './newPart';
import partType from './partType';
import role from './role';
import taskStatus from './taskStatus';
import team from './team';
import teamType from './teamType';
import user from './user';
import variant from './variant';
import vehicle from './vehicle';
import wipStatus from './wipStatus';
import wipVehicle from './wipVehicle';
import wipVehicleTask from './wipVehicleTask';
import workshop from './workshop';
import workshopType from './workshopType';
// iTechQ start
import grid from './grid';
import parts from './part';
import company from './company';
import assessmentQuestion from './assessmentQuestion';
import file from './file';
import project from './project';
import assessmentTemplate from './assessmentTemplate';
import companyRole from './companyRole';
import projectAssessments from './projectAssessment';
import projectPlan from './projectPlan';
import loader from './loading';
import projectPlanAssessment from './projectPlanAssessment';
import localCompany from './localCompany';
import technicianPlan from './technicianPlan';
import technicianPlanHistory from './technicianPlanLog';
import accident from './accident';
import ticket from './ticket';
import note from './note';
import objectCheckpoint from './objectCheckpoint';
import objectCheckpointLog from './objectCheckpointLog';
import planActivity from './planActivity';
import planActivityLog from './planActivityLog';

export default combineReducers({
  account,
  auth,
  bay,
  bayType,
  bookBay,
  costCentre,
  dashboard,
  cars,
  modelsPerformance,
  dateRange,
  jobcard,
  jobType,
  jobParameter,
  location,
  platform,
  make,
  model,
  vehicleModules,
  variant,
  vehicle,
  partComponent,
  newPart,
  partType,
  role,
  taskStatus,
  team,
  teamType,
  user,
  wipStatus,
  wipVehicle,
  wipVehicleTask,
  workshop,
  workshopType,
  theme, //not needed. Delete later
  //iTechQ start
  grid,
  parts,
  company,
  assessmentQuestion,
  file,
  project,
  assessmentTemplate,
  companyRole,
  projectAssessments,
  projectPlan,
  loader,
  projectPlanAssessment,
  localCompany,
  technicianPlan,
  accident,
  technicianPlanHistory,
  ticket,
  note,
  objectCheckpoint,
  objectCheckpointLog,
  planActivity,
  planActivityLog
});