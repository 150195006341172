import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Divider,
  Grid, Hidden, useTheme,
  withStyles, Typography
} from '@material-ui/core';
import { 
  TextField as CustomTextField,
  Button,
  AutoComplete as CustomAutoComplete,
  IconButton,
  DynamicForm, Table
} from '../../../commons/mui';
import useStyles from '../../../styles/jss/style';
import { 
  TABLE_HEADER,
} from './CONSTANTS';
import {
  DIALOGS,
} from '../../../commons/CONSTANTS';
import {
  JobcardTemplate
} from '../';
import { deleteJobcard, listJobcards, getJobcard } from '../../../redux/actions/jobcard';

const TextField = withStyles((theme) => ({
  root: {
    marginBottom:theme.spacing(3),
  }
}))(CustomTextField);

const AutoComplete = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  }
}))(CustomAutoComplete);

const Index = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const jobcards = useSelector(state => state.jobcard);
  const [data, setData] = useState([]);
  const [showJobcard, setShowJobcard] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title:'sample',
    text:'sample',
    open: false,
    btn1: {
      text: 'No',
      onClick: () => {}
    },
    btn2: {
      text: 'Yes',
      onClick: ()=>{}
    }
  });
  const [showInfoDialog, setShowInfoDialog] = useState({
    title:'sample',
    open: false,
    children: <div></div>,
    btn1: {
      text: 'Close',
      onClick: () => {}
    },
  });

  useEffect(() => {
    dispatch( listJobcards() )
  }, []);

  useEffect(() => {
    const construct = async () => {
      if(jobcards.list.data && jobcards.list.data.length > 0) {
        let temp = await constructTableData(jobcards.list.data);
        setData(temp);
      } else setData([]);
    }
    construct();
  }, [jobcards]);
  
  useEffect(() => {
    if(showJobcard && jobcards.detail.data) {
      setShowInfoDialog(old => ({
        ...old,
        title: 'Jobcard',
        open: true,
        children: <JobcardTemplate data={jobcards.detail.data}/>
      }));
    }
  }, [showJobcard, jobcards.detail.data])

  const constructTableData = async (raw) => {
    let result = [];
    for(let i = 0; i < raw.length; i++) {
      let jobcard = raw[i];
      let row = {
        _id: jobcard._id,
        jobcardId: <div  onClick={(e) => {
            if(e.preventDefault) e.preventDefault();
            if(e.stopPropagation) e.stopPropagation();
            dispatch(getJobcard(jobcard?._id))
            setShowJobcard(true);
          }}
          className={`${classes.cursorPointer} ${classes.underline}`}
        >
          {jobcard.jobcardId}
        </div>,
        workshop: jobcard.workshop?.name || '',
        startDate: jobcard?.wipVehicle?.startTime ? (new Date(jobcard.wipVehicle.startTime)).toLocaleString() : '',
        jobType: jobcard.jobType?.name || '',
        vin: jobcard.vin?.vin || '',
        status: jobcard?.wipVehicle?.wipStatus?.name || '',
        endDate: jobcard?.wipVehicle?.endTime ? (new Date(jobcard.wipVehicle.endTime)).toLocaleString() : '',
      }
      result.push(row);
    }
    return result;
  }

  const onEdit = (e, item, i) => {
    // alert(`Edit clicked on ${item.jobcardId}`)
  }

  const onDelete = (e, item, i) => {
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: 'Please confirm to delete',
      text: `You are about to delete jobcard "${item.jobcardId}".  Are you sure ?`,
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => dispatch(deleteJobcard(item._id)),
      }
    }));
  }

  return (
    <Grid container className={`${classes.paddingX} ${classes.stretchHeight}`}>
      <Grid item container className={`${classes.height5}`}>
        <Typography variant='h6' align='left' gutterBottom>List of Jobcards</Typography>
      </Grid>
      <Grid item container className={`${classes.height95}`} direction='column'>
        <Grid item container direction='row' className={`${classes.fullWidth} ${classes.stretchHeight}`} >
          <Grid item container xs={12} direction='row' className={`${classes.stretchHeight}`}>
            <div className={`${classes.fullWidth} ${classes.stretchHeight}`}>
              <Grid item container xs={12} justifyContent='space-between' className={`${classes.height90} ${classes.hideScrollbar}`} style={{overflowY: 'scroll', whiteSpace:'nowrap'}}>
                <Table
                  header={TABLE_HEADER}
                  data={data}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  editPrefix='jobcard/edit/'
                />
              </Grid>
              <Grid item container xs={12} className={`${classes.height10}`} direction='column' alignItems='center' justifyContent='center'>
                <Grid item container xs={6} justifyContent='flex-end'>
                  {/* <Button text='Cancel' variant='contained'/> */}
                </Grid>
                <Grid item container xs={6} justifyContent='flex-start'>
                  {/* <Button type='submit' text='Submit' variant='contained' color='primary'/> */}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {
        DIALOGS.CONFIRM_DIALOG({
          title: showConfirmDialog.title,
          text: showConfirmDialog.text,
          open: showConfirmDialog.open,
          setOpen: setShowConfirmDialog,
          btn1:showConfirmDialog.btn1,
          btn2:showConfirmDialog.btn2,
          container: `${classes.paddingX}`,
        })
      }   
      {
        DIALOGS.INFO_DIALOG({
          ...showInfoDialog,
          setOpen: setShowInfoDialog,
          container: `${classes.paddingX}`,
        })
      }       
    </Grid>
  )
}

export default Index