const TABLE_HEADER = {
    name: {
      data: "Name",
      width: "150px",
    },
    role: {
      data: "Role",
      width: "150px",
    },
    emailId: {
      data: 'Email',
      width: '150px'
    }
  };
  
  export { TABLE_HEADER };
  