import {
  LIST_PART,
  GET_PART,
  ADD_PART,
  UPDATE_PART,
  DELETE_PART,
  // UPLOAD_PART,
} from "../action_types";

const initialState = {
  list: {
    pagination: {},
    totalCount: 0,
    data: [],
    isLoading: true,
    hasError: false,
  },
  detail: {
    data: {},
    isLoading: true,
    hasError: false,
  },
};

const part = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LIST_PART:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
        },
      };
    case GET_PART:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload,
        },
      };
    case ADD_PART:
      return {
        ...state,
        add: {
          ...state.add,
          ...payload,
        },
      };
    // case UPLOAD_PART:
    //   return {
    //     ...state,
    //     detail: {
    //       ...state.detail,
    //       ...payload,
    //     },
    //   };
    case UPDATE_PART:
      return {
        ...state,
        update: {
          ...state.update,
          ...payload
        },
      };
    case DELETE_PART:
      return {
        ...state,
        delete: {
          ...state.delete,
          ...payload
        },
      };
    default:
      return state;
  }
};

export default part;
