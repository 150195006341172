import React, { useState } from 'react'
import { InputAdornment, Paper, Typography } from '@material-ui/core';
import useGlobalStyles from '../../../../styles/jss/style';
import useComponentStyles from './jss/style';
import { IconButton, TextField } from '../../../../commons/mui';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

const Index = ({name='Category', value='', onChange=null, onBlur=null, onFocus=null, onClick=()=>{}}) => {
  const globalStyles = useGlobalStyles();
  const classes = useComponentStyles();
  const [disabled, setDisabled] = useState(true);
  // const [tfValue, setTFValue] = useState(value);
  let timer;
  let clickCount = 0;
  
  const handleClick = (e) => {
    clearTimeout(timer);
    clickCount++;
    timer = setTimeout(() => {
      if(clickCount === 2) {
        setDisabled(false);
        onClick(e);
      }
      clickCount=0;
    }, 200);
  }

  const onTFChange = (e) => {
    onChange(e.target.value);
  }
  
  return (
    <>
      <div className={`${classes.cdbHeader} ${globalStyles.fullWidth}`}>
        <Typography align='center'>{`${name.charAt(0).toUpperCase()}${name.slice(1)}`}</Typography> 
      </div>
      <div className={`${classes.cdbPaperDiv}`}>
        <Paper className={`${classes.cdbPaper}`} elevation={3}>
          {/* <Typography align='center'>{`${value}`}</Typography> */}
          <TextField
            name='name' 
            label={`${name.charAt(0).toUpperCase()}${name.slice(1)} name`}
            variant='outlined'
            InputLabelProps={{
              shrink: true,
            }}
            disabled={disabled}
            value={value}
            // defaultValue={value}
            onClick={handleClick}
            onChange={onChange}
            onBlur={()=> {
              // if(!onBlur(tfValue)) setTFValue(value);
              onBlur(value);
              setDisabled(true)
            }}
            onFocus={() => {
              // onFocus()
            }}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position='end'>
                  
            //     </InputAdornment>
            //   )
            // }}
          />
        </Paper>
        {/* {
          disabled ? 
          <IconButton color='primary' onClick={() => setDisabled(false)} icon={<EditIcon/>}/>
          : <IconButton color='primary' onClick={() => setDisabled(true)} icon={<CloseIcon/>}/>
        } */}
      </div>
    </>
  )
}

export default Index
