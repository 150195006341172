import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid, useTheme,
  withStyles, Typography
} from '@material-ui/core';
import { 
  TextField as CustomTextField,
  Button,
} from '../../../commons/mui';
import useStyles from '../../../styles/jss/style';
import {
  DIALOGS,
} from '../../../commons/CONSTANTS';
import { checkInvite } from '../../../redux/actions/auth';
import { updateUser } from '../../../redux/actions/user';

const TextField = withStyles((theme) => ({
  root: {
    marginBottom:theme.spacing(3),
  }
}))(CustomTextField);


const Index = (props) => {
  const token = props?.match?.params?.token || null;
  const [_id, setId] = useState(null);
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth.data);
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title:'sample',
    text:'sample',
    open: false,
    btn1: {
      text: 'No',
      onClick: () => {}
    },
    btn2: {
      text: 'Yes',
      onClick: ()=>{}
    }
  });

  useEffect(() => {
    if(token) dispatch(checkInvite(token));
  }, [token]);

  useEffect(() => {
    // if(auth?.accessToken){
    //   let temp = roles.find(item => item._id === (auth?.role?._id || auth?.role)) || null;
    //   switch(temp?.name) {
    //     case 'Admin': history.push('/jobcard'); break;
    //     case 'Supervisor': history.push('/s_cars'); break;
    //     case 'Rework Engineer': history.push('/myjobs'); break;
    //     case 'Rework Manager': history.push('/cars'); break;
    //     default: break;
    //   }
    // }
    if(auth?.accessToken) {
      setId(auth._id);
    }
  }, [auth]);

  const onSubmit = (e) => {
    e.preventDefault();
    let body = {
      password: e.target[1].value,
      isVerified: true,
      isRegistered: true,
    }
    dispatch(updateUser(body, _id));
  }
  
  return (
    <Grid container className={`${classes.paddingX} ${classes.stretchHeight}`}>
      {/* <Grid item container className={`${classes.height5}`}>
        <Typography variant='h6' align='left' gutterBottom>Login</Typography>
      </Grid> */}
      <Grid item container direction='column'>
        <Grid item container direction='row' className={`${classes.fullWidth} ${classes.stretchHeight}`} >
          <Grid item container xs={12} direction='row' className={`${classes.stretchHeight}`}>
            <form onSubmit={(e) => onSubmit(e)} className={`${classes.fullWidth} ${classes.stretchHeight} ${classes.verticalAlign}`} style={{display: 'flex'}}>
              {/* <Grid item container xs={12} justifyContent='space-between' className={`${classes.height90} ${classes.hideScrollbar}`} style={{overflowY: 'scroll', whiteSpace:'nowrap'}}>
              </Grid> */}
              {/* <Grid item container xs={12} className={`${classes.height10}`} direction='column' alignItems='center' justifyContent='center'>
                <Grid item container xs={6} justifyContent='flex-end'>
                  <Button text='Cancel' variant='contained'/>
                </Grid>
                <Grid item container xs={6} justifyContent='flex-start'>
                  <Button type='submit' text='Submit' variant='contained' color='primary'/>
                </Grid>
              </Grid> */}
              <Grid item container direction='column'
                justifyContent='center' alignContent='center' 
                style={{background: theme.palette.accent.main, width: '400px'}}
                className={`${classes.paddingX} ${classes.paddingY}`}
                >
                <Grid key={"g-1"} item className={`${classes.marginX} ${classes.paddingY}`}>
                  <Typography variant='h5' align='center'>Profile Setup</Typography>
                </Grid>
                <Grid key={"g-2"} item className={`${classes.marginX} ${classes.paddingY}`}>
                  <TextField name='emailId' value={auth?.emailId} type='email' fullWidth variant='standard' label='Email' disabled={true}/>
                </Grid>
                <Grid key={"g-3"} item className={`${classes.marginX} ${classes.paddingBottom}`}>
                  <TextField name='password' fullWidth variant='standard' label='Enter Password' type='password'/>
                </Grid>
                <Grid key={"g-4"} item className={`${classes.marginX} ${classes.paddingBottom}`}>
                  <TextField name='cnf-password' fullWidth variant='standard' label='Re-enter Password' type='password'/>
                </Grid>
                <Grid key={"g-5"} item className={`${classes.marginX} ${classes.paddingBottom}`}>
                  <Button type='submit' marginLeft='0px' marginRight='0px' width='300px' variant='contained' color='primary' text='Sign In'/>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid> 
      {
        DIALOGS.CONFIRM_DIALOG({
          title: showConfirmDialog.title,
          text: showConfirmDialog.text,
          open: showConfirmDialog.open,
          setOpen: setShowConfirmDialog,
          btn1:showConfirmDialog.btn1,
          btn2:showConfirmDialog.btn2,
          container: `${classes.paddingX}`,
        })
      }     
    </Grid>
  )
}

export default Index;