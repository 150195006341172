const TABLE_HEADER = {
    ticketNo: {
      data: "Ticket ID",
      width: "150px",
    },
    type: {
      data: "Type",
      width: "150px",
    },
    description: {
      data: "Description",
      width: "150px",
    },
    status: {
      data: "Status",
      width: "150px",
    },
  };
  
  export { TABLE_HEADER };
  