const TABLE_HEADER = {
  question: {
    data: "Assessment",
    // width: '240px'
  },
  // assessmentAnswer: {
  //   data: 'Result'
  // },
  hazard: {
    data: 'Hazard',
  },
  controlMeasure: {
    data: 'Control Measeure',
  },
  riskAcceptable: {
    data: 'Risk Accepted',
  }
};
const TABLE_HEADER_PLAN = {
  question: {
    data: "Assessment",
    // width: '240px'
  },

  assessmentAnswerView: {
    data: 'Answer',
  },
  commentView: {
    data: 'Comment',
  }
};

const TABLE_HEADER_1 = {
  question: {
    data: "Assessment",
    // width: '240px'
  },
  assessmentAnswer: {
    data: 'Action',
  },
  comment: {
    data: 'Comment',
  },
}

export { TABLE_HEADER, TABLE_HEADER_1, TABLE_HEADER_PLAN };
