import {
  LIST_WORK_PLAN,
  GET_WORK_PLAN,
  ADD_WORK_PLAN,
  UPDATE_WORK_PLAN,
  DELETE_WORK_PLAN,
  SIGN_WORK_PLAN,
  LIST_WORK_PLAN_REPORT,
  ADD_WORK_PLAN_INSPECTOR,
  LIST_WORK_PLAN_VEHICLE_REPORT,
} from "../action_types";

const initialState = {
  list: {
    pagination: {},
    totalCount: 0,
    data: [],
    isLoading: true,
    hasError: false,
  },
  detail: {
    data: {},

    isLoading: true,
    hasError: false,
  },
};

const projectAssessments = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LIST_WORK_PLAN:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
        },
      };
    case LIST_WORK_PLAN_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          ...payload
        }
      };
    case LIST_WORK_PLAN_VEHICLE_REPORT:
      return {
        ...state,
        vehicleReports: {
          ...state.vehicleReports,
          ...payload
        }
      };
    case GET_WORK_PLAN:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload,
        },
      };
    case ADD_WORK_PLAN:
      return {
        ...state,
        add: {
          ...state.add,
          ...payload,
        },
      };
    case ADD_WORK_PLAN_INSPECTOR:
      return {
        ...state,
        addInspactor: {
          ...state.addInspactor,
          ...payload,
        },
      };
    case UPDATE_WORK_PLAN:
      return {
        ...state,
        update: {
          ...state.update,
          ...payload
        },
      };
    case SIGN_WORK_PLAN:
      return {
        ...state,
        sign: {
          ...state.sign,
          ...payload
        },
      };
    case DELETE_WORK_PLAN:
      return {
        ...state,
        delete: {
          ...state.delete,
          ...payload
        },
      };
    default:
      return state;
  }
};

export default projectAssessments;
