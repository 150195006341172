import React from 'react';
import { Paper, makeStyles, InputBase } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { AutoComplete, TextField } from '../../commons/mui';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // width: '100%',
    // justifyContent: 'center'
  },
  paper: {
    width: '400px',
    // margin:theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(4),
    // width: '100%',
    borderRadius: '50px',
  },
  adornment: {
    paddingBottom: '.1rem'
  },
  textField: {
    ['& fieldset']: {
      borderRadius: '50px'
    }
  }
}));

const Index = ({...rest}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}> 
      <Paper className={classes.paper} elevation={3}>
        <AutoComplete 
          {...rest}
          variant='outlined'
          freeSolo
          textFieldParams={{
              color:'primary',
              className:classes.textField
            }
          }
          renderInput={(params) => 
            <div className={classes.textField}>
              <TextField 
              {...params}
              placeholder={rest.placeholder || 'Search'}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true
              }}
              />
            </div>
          }
        />
      </Paper>
    </div>
  )
}

export default Index;
