const TABLE_HEADER = {
  name: {
    data: 'Plan Name',
    width: '100px'
  },
  quantity: {
    data: 'Quantity',
    width: '50px'
  },
  ok: {
    data: 'Ok',
    width: '50px'
  },
  nok: {
    data: 'Not Ok',
    width: '100px'
  },
  processed: {
    data: 'Processed',
    width: '50px'
  },
  status: {
    data: 'Status',
    width: '150px'
  },
  createdAt: {
    data: 'Created',
    width: '150px'
  },
  action: {
    data: 'Action',
    width: '150px'
  },
}
const MSDSISSUED = [
  { _id: 'Yes', name: 'Yes' },
  { _id: 'No', name: 'No' },
  { _id: 'NA', name: 'NA' },
]

export { TABLE_HEADER, MSDSISSUED };
