import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  marginX: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  marginY: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  marginRight:{
    marginRight: theme.spacing(2)
  },
  marginBottom01: {
    marginBottom: theme.spacing(1),
  },
  marginBottom02: {
    marginBottom: theme.spacing(2),
  },
  marginBottom03: {
    marginBottom: theme.spacing(3),
  },
  paddingX: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  paddingY: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paddingRight: {
    paddingRight: theme.spacing(5),
  },
  paddingLeft: {
    paddingLeft: theme.spacing(5),
  },
  paddingTop: {
    paddingTop: theme.spacing(2),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(2),
  },
  stretchHeight: {
    height: '100%'
  },
  height95: {
    height: '95%'
  },
  height90: {
    height: '90%'
  },
  height50:{
    height: '50%'
  },
  height40:{
    height: '40%'
  },
  height30:{
    height: '30%'
  },
  // height10:{
  //   height: '10%',
  // },
  height10:{
    height: '5%',
  },
  width1:{
    width: '1%',
  },
  width99: {
    width: '99%',
  },
  fullWidth: {
    flexGrow: 1,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexFitContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  flexFitContainerY: {
    display: 'flex',
    height: '100%',
  },
  flexFitContainerX: {
    display: 'flex',
    width: '100%',
  },
  verticalAlign: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  verticalAlignEnd: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  horizontalAlign: {
    display: 'flex',
    width:'100%',
    justifyContent: 'center',
  },
  div56:{
    height: '56px',
    width: '56px'
  },
  hideScrollbar: {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  disableSelection: {
    userSelect: 'none',
  },
  cursorPointer: {
    cursor: 'pointer !important',
  },
  overFlowTextEllipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  underline: {
    textDecoration: 'underline',
    textUnderlinePosition: 'under'
  },
  fixedTableLayout: {
    tableLayout: 'fixed',
  },
  res: {
    // width: '400px',
    display: 'none',
    "@media(maxWidth: 780px)": {
      // width: "80%",
      display: 'none'
    },
  },
}));

export default useStyles; 