import {
  SET_LOCAL_COMPANY,
} from '../action_types';
export const setLocalCompany = (company) => async dispatch => {
  dispatch({
    type: SET_LOCAL_COMPANY,
    payload: {
      data: company
    }
  });
}
