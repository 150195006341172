const TABLE_HEADER = {
  partName: {
    data: "Part Name",
    width: "150px",
  },
  partNumber: {
    data: "Part No.",
    width: '150px'
  },
  problemDefinition: {
    data: "Problem Definition",
    width: '250px',
  },
  type: {
    data: "Type",
    width: '150px',
  },
  commodity: {
    data: "Commodity",
    width: '150px'
  }
};

export {TABLE_HEADER}