import React from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core';
// import '../../styles/textfield.sass';
import './css/style.css';


const Index = ({
  text = 'Checkbox',
  name = Math.random(),
  onChange = null,
  color = 'default',
  checked = false,
  className = '',
  disabled = false,
  ...rest
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...rest}
          name={name}
          className={`${className}`}
          checked={checked}
          color={color}
          onChange={onChange}
          disabled={disabled}
        />
      }
      label={text}
    />
  )
}

export default Index;
