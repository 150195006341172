import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import useStyles from "./jss/style";
import { Fade, Grid, Icon, Paper, Popper, Typography } from "@material-ui/core";

import { AppBar, Toolbar, IconButton } from "@material-ui/core";

import {
  Menu as MenuIcon,
  NavigateBefore as CollapseIcon,
  ExitToApp as LogoutIcon,
  DateRange as CalendarIcon,
} from "@material-ui/icons";
import { setCurrentUser } from "../../redux/actions/auth";
import { listCompany } from "../../redux/actions/company";
import { setDateRange } from "../../redux/actions/dateRange";
import { useHistory, useLocation } from "react-router-dom";
import { DateRangePicker } from "../../commons/ui";
import { decrypt } from "n-krypta";
import axios from "axios";
import { setCompanyHeader } from "../../utils/setAuthToken";
import logo from "../../images/logo.svg";


const Index = ({ drawerState = true, handleDrawerOpen = null }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const localCompany = useSelector((state) => state.localCompany);
  const dateRange = useSelector((state) => state.dateRange);
  const company = useSelector((state) => state.company);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dates, setDates] = useState({});
  const [dateFlag, setDateFlag] = useState(false);
  const [companyDetail, setCompanyDetail] = useState({});

  const onLogout = () => {
    dispatch(setCurrentUser(null));
    localStorage.removeItem("user");
    history.push("/company-login/" + decrypt(localCompany.companyId.data, "secret"));
  };

  const toggleCalendar = (event) => {
    setCalendarOpen(!calendarOpen);
    if (event) setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (dateFlag) {
      dispatch(setDateRange(dates));
      setDateFlag(false);
    } else {
      setDates(dateRange);
    }
  }, [dateRange, dates, dateFlag, dispatch]);
  useEffect(() => {
    let companyId = localStorage.getItem("companyId");
    // console.log(companyId)
    if (companyId) {
      setCompanyHeader(companyId);
      dispatch(listCompany({ refCompanyId: decrypt(companyId, 'secret') }))
    }
  }, [])
  useEffect(() => {
    let companyId = localStorage.getItem("companyId");
    // console.log(company.list?.data?.data[0])
    if (companyId && company.list?.data?.data?.length) {
      setCompanyDetail(company.list.data.data[0])
    }
  }, [company])
  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: false,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <Icon style={{ width: "auto", height: "40px", fontWeight: "bold" }}>
            <img src={logo} height='40px' alt='logo' />
          </Icon>
          {auth &&
            auth.data &&
            auth.data.accessToken &&
            !auth.data.isTempPassword && window.location.pathname !== '/eversign-redirect' ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => handleDrawerOpen()}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: false,
              })}
            >
              {drawerState ? <CollapseIcon /> : <MenuIcon />}
            </IconButton>
          ) : null}
          {window.location.pathname !== '/eversign-redirect' ?
            <>
              <div className={classes.grow} />

              {/* //         <IconButton aria-label="show new notifications" color="inherit">
//           <Badge badgeContent={0} color="secondary">
//             <NotificationsIcon style={{ height:'40px', width:'40px' }}/>
//           </Badge>
//         </IconButton>

//         <IconButton
//           aria-label="account of current user"
//           aria-controls="primary-search-account-menu"
//           aria-haspopup="true"
//           color="inherit"
//         >
//           <AccountCircle style={{ height:'40px', width:'40px' }}/>
//         </IconButton>

//         <IconButton
//           aria-label="account setting"
//           aria-controls="primary-search-account-menu"
//           aria-haspopup="true"
//           color="inherit"
//           size='large'
//           onClick={handleProfileMenuOpen}
//         >
//           <SettingsIcon style={{ height:'40px', width:'40px' }} />
//         </IconButton>

//         <Icon style={{ width: 'auto', height: '40px' }}>
//           <img src={brandIcon} height='40px' />
// ======= */}
              {(location.pathname === "/dashboard" ||
                location.pathname === "/history/car") && (
                  <IconButton
                    color="inherit"
                    aria-label="Calendar"
                    onClick={(event) => toggleCalendar(event)}
                    edge="start"
                  >
                    <CalendarIcon />
                  </IconButton>
                )}
              {companyDetail._id && localStorage.getItem('companyId') && companyDetail.logo ?
                <img src={axios.defaults.baseURL + '/file/' + companyDetail.logo} style={{ width: 100, height: 50, marginRight: 50 }} />
                : null}
              <Grid className="xs-none" style={{ textAlign: "center", marginRight: "1rem" }}>
                <Typography>
                  {auth?.data?.firstName} {auth?.data?.lastName}
                </Typography>
                {auth?.data?.role?.name ? (
                  <Typography>({auth?.data?.role?.name})</Typography>
                ) : null}
              </Grid>
              {auth.data?.accessToken && (
                <IconButton
                  color="inherit"
                  aria-label="Logout"
                  onClick={() => onLogout()}
                  edge="start"

                >
                  <LogoutIcon />
                </IconButton>
              )}
              <Icon className="xs-none" style={{ width: "auto", height: "40px", fontWeight: "bold" }}>
                {/* <img src={brandIcon} height='40px' alt='brand-icon'/> */}
                iTechQ
              </Icon>
            </>
            : null}
        </Toolbar>
      </AppBar>

      <Popper
        className={classes.popper}
        open={calendarOpen}
        anchorEl={anchorEl}
        placement={"bottom-start"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={150}>
            <Paper>
              <DateRangePicker
                open={true}
                selectedRange={dates}
                setRange={(range) => {
                  setDates(range);
                  setDateFlag(true);
                  toggleCalendar();
                }}
              />
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default Index;
