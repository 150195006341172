import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Pagination, Table } from "../../../commons/mui";
import { deleteUser, listUsers } from "../../../redux/actions/user";
import { TABLE_HEADER } from "./CONSTANTS";
import { PDSLADMIN } from "../../Projects/AllProjects/CONSTANTS";
import { toast } from "react-toastify";
import { DIALOGS } from "../../../commons/CONSTANTS";
import useStyles from "../../../styles/jss/style";

export default function Index() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const [userList, setuserList] = useState([]);
  const [page, setPage] = useState(1);
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: "sample",
    text: "sample",
    open: false,
    btn1: {
      text: "No",
      onClick: () => {},
    },
    btn2: {
      text: "Yes",
      onClick: () => {},
    },
  });

  useEffect(() => {
    dispatch(listUsers({ skip: (page - 1) * 10 }));
  }, [page]);

  useEffect(() => {
    if (users.list.data) {
      users.list.data.map((itm) => {
        itm.name = itm.firstName + " " + itm.lastName;
        itm.role = itm.role ? itm.role.name : "";
        return itm;
      });
      setuserList(users.list.data);
    }
  }, [users]);

  const onDelete = (i, e, item) => {
    // console.log(item, i, item.name || item.projectNo)
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: "Please confirm to delete",
      text: `You are about to delete user. Are you sure ?`,
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => {
          if (item._id !== auth.data._id) {
            dispatch(deleteUser(item._id));
            setTimeout(() => {
              dispatch(listUsers({ skip: (page - 1) * 10 }));
            }, 500);
          } else {
            toast.error("You can't delete yourself");
          }
        },
      },
    }));
  };

  return (
    <Grid>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2.5rem",
          alignItems: "center",
        }}
      >
        <h2>User List</h2>
        <Button
          width="200px"
          text="Add User"
          variant="contained"
          color="primary"
          onClick={() =>
            history.push(
              auth.data?.role?.name === PDSLADMIN
                ? "/add/super-user"
                : "/add/user"
            )
          }
        />
      </Grid>
      {DIALOGS.CONFIRM_DIALOG({
        title: showConfirmDialog.title,
        text: showConfirmDialog.text,
        open: showConfirmDialog.open,
        setOpen: setShowConfirmDialog,
        btn1: showConfirmDialog.btn1,
        btn2: showConfirmDialog.btn2,
        container: `${classes.paddingX}`,
      })}
      <Grid style={{ marginTop: "1rem" }}>
        <Table
          header={TABLE_HEADER}
          data={userList}
          onDelete={onDelete}
          // onDelete={(i, e, row) => {
          //   if (row._id !== auth.data._id) {
          //     dispatch(deleteUser(row._id));
          //     setTimeout(() => {
          //       dispatch(listUsers({ skip: (page - 1) * 10 }));
          //     }, 500);
          //   } else {
          //     toast.error("You can't delete yourself")
          //   }
          // }}
          onEdit={(i, row) => {
            history.push(
              auth.data?.role?.name === PDSLADMIN
                ? `/edit/super-user/${row._id}`
                : `/edit/user/${row._id}`
            );
          }}
        />
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        <div className="ui-component-block">
          <div className="ui-components">
            <Pagination
              count={users.list.totalCount}
              page={page}
              setPage={setPage}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
