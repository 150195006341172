import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, IconButton, TextField } from "../../../commons/mui";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompany,
  clearCompanyData,
  getCompany,
  updateCompany,
} from "../../../redux/actions/company";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { uploadFile } from "../../../redux/actions/file";
import { setCompanyHeader } from "../../../utils/setAuthToken";
import axios from "axios";
import { BRM } from "../../Projects/AllProjects/CONSTANTS";
import { listUsers } from "../../../redux/actions/user";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 320,
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      width: 290,
      marginRight: "0rem",
    },
  },
  btn: {
    [theme.breakpoints.down("sm")]: {
      width: 290,
    },
  },
}));

export default function Index() {
  const [field, setField] = useState({
    name: "",
    address: "",
    userId: "",
    userEmail: "",
    userName: "",
    logo: "",
    customerEmail: '',
    customerName: ''
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const roles = useSelector((state) => state.role);
  const users = useSelector((state) => state.user);
  const file = useSelector((state) => state.file);
  const history = useHistory();
  const { companyId } = useParams();
  const [errors, seterrors] = useState({});
  const [brmList, setBrmList] = useState([]);
  useEffect(() => {
    // localStorage.removeItem("companyId");
    setCompanyHeader("");
    if (companyId) dispatch(getCompany(companyId));
  }, []);
  useEffect(() => {
    if (roles.list?.data?.length) {
      let brm = roles.list?.data.find(a => a.name === BRM);
      if (brm?._id)
        dispatch(listUsers({ role: brm._id }));
    }
  }, [roles]);
  useEffect(() => {
    if (users.list?.data?.length) {
      setBrmList(users.list?.data);
    }
  }, [users]);
  useEffect(() => {
    // console.log(company, companyId)
    if (
      company.detail &&
      company.detail.data &&
      company.detail.data &&
      company.detail.data.data &&
      company.detail.data.data._id
    ) {
      setField({ ...field, ...company.detail.data.data });
    }
    console.log(company)
    if (company.add?.data?.data?._id) {
      dispatch(clearCompanyData())
      history.push("/company");
    }
  }, [company]);

  useEffect(() => {
    if (
      file.detail &&
      file.detail.data &&
      file.detail.data &&
      file.detail.data.data &&
      file.detail.data.data.key
    ) {
      setFields("logo", file.detail.data.data.key);
    }
  }, [file]);

  const setFields = (type, val) => {
    Object.keys(field).map((itm) => {
      if (itm == type) {
        setField({ ...field, [itm]: val });
      }
    });
  };
  const handleImage = (event) => {
    console.log(event.target.files[0]);
    let formData = new FormData();
    if (event.target.files[0]) {
      formData.append("file", event.target.files[0]);
      dispatch(uploadFile(formData));
    }
  };

  const validate = () => {
    let temp = {};
    let error = false;
    if (!field.name) {
      error = true;
      temp.name = "Name is required";
    }
    if (!field.customerEmail) {
      error = true;
      temp.customerEmail = "Email is required";
    } else if (!((/$^|.+@.+..+/).test(field.customerEmail))) {
      error = true;
      temp.customerEmail = "Email is wrong";
    }
    if (!field.address) {
      error = true;
      temp.address = "Address is required";
    }
    if (!field.logo) {
      error = true;
      temp.logo = "Logo is required";
    }
    if (!field.customerName) {
      error = true;
      temp.customerName = "Customer Name is required";
    }

    if (!field.userId) {
      error = true;
      temp.userId = BRM + " is required";
    }

    seterrors(temp);

    return !error;
  };

  const onSubmit = () => {
    if (validate()) {
      let comp = field;
      let selectBRM = brmList.find(a => a._id === comp.userId);
      console.log(selectBRM)
      comp.userEmail = selectBRM.emailId;
      comp.userName = (selectBRM.firstName + (selectBRM.lastName ? " " + selectBRM.lastName : '')).trim();
      if (companyId) {
        delete comp.createdAt;
        dispatch(updateCompany(comp, companyId));
      } else dispatch(addCompany(comp));
    }
  };

  return (
    <Grid>
      <Grid>
        <Grid>
        <h2>
            <IconButton
              color="primary"
              onClick={() => history.push('/company')}
              icon=<ArrowBack />
            >
            </IconButton>
            Add Company</h2>
        </Grid>
        <Grid spacing={2} container>
          <Grid item sm={6}>
            <TextField
              variant="filled"
              label="Company Name"
              value={field.name}
              onChange={(e) => {
                setFields("name", e.target.value);
              }}
              isError={errors.name}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="filled"
              label="Customer Name"
              value={field.customerName}
              onChange={(e) => {
                setFields("customerName", e.target.value);
              }}
              isError={errors.customerName}
              fullWidth
            />
          </Grid>

          <Grid item sm={6}>
            <TextField
              variant="filled"
              label="Customer Email"
              value={field.customerEmail}
              onChange={(e) => {
                setFields("customerEmail", e.target.value);
              }}
              isError={errors.customerEmail}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="filled"
              label="Address"
              value={field.address}
              onChange={(e) => {
                setFields("address", e.target.value);
              }}
              isError={errors.address}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="filled"
              label={BRM}
              value={field.userId}
              onChange={(e) => {
                setFields("userId", e.target.value);
              }}
              select={true}
              options={brmList.map(a => ({ label: a.firstName ? a.firstName + (a.lastName ? " " + a.lastName : '') : a.emailId, value: a._id }))}
              isError={errors.userId}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            {!field.logo ? (
              <TextField
                type="file"
                // label="Logo"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => handleImage(e)}
                fullWidth
                isError={errors.logo}
              ></TextField>
            ) : (
              <div>
                <img
                  src={axios.defaults.baseURL + "/file/" + field.logo}
                  style={{ height: 100, width: 100 }}
                ></img>
                <TextField
                  style={{ paddingLeft: "1.5rem" }}
                  type="file"
                  // label="Logo"
                  onChange={(e) => handleImage(e)}
                  accept="image/png, image/gif, image/jpeg"
                  fullWidth
                ></TextField>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid className={`${classes.btn}`} style={{ textAlign: "center" }}>
        <Button
          style={{ marginTop: "1.7rem" }}
          width="150px"
          text={companyId ? "Update" : "Submit"}
          variant="contained"
          color="primary"
          onClick={() => { onSubmit() }}
        />
      </Grid>
    </Grid>
  );
}
