import React, { useState } from 'react'
import { InputAdornment, Paper, Typography } from '@material-ui/core';
import useGlobalStyles from '../../../../styles/jss/style';
import useComponentStyles from './jss/style';
import { IconButton, TextField } from '../../../../commons/mui';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

const Index = ({name='Category', value='', onChange=null, onBlur=null, onFocus=null, ...rest}) => {
  const globalStyles = useGlobalStyles();
  const classes = useComponentStyles();
  const [disabled, setDisabled] = useState(true);
  // const [tfValue, setTFValue] = useState(value);
  let timer;
  let clickCount = 0;
  
  const handleClick = (e) => {
    clearTimeout(timer);
    clickCount++;
    timer = setTimeout(() => {
      if(clickCount === 2) {
        setDisabled(false);
      }
      clickCount=0;
    }, 200);
  }

  const onTFChange = (e) => {
    onChange(e.target.value);
  }
  
  return (
    <>
      <Paper className={`${classes.cdbPaper}`} elevation={3}>
        {/* <Typography align='center'>{`${value}`}</Typography> */}
        <TextField
          {...rest}
          name='description' 
          label={`${name.charAt(0).toUpperCase()}${name.slice(1)} description`}
          variant='outlined'
          InputLabelProps={{
            shrink: true,
          }}
          disabled={disabled}
          value={value}
          // defaultValue={value}
          onClick={handleClick}
          onChange={onChange}
          onBlur={()=> {
            // if(!onBlur(tfValue)) setTFValue(value);
            onBlur(value);
            setDisabled(true)
          }}
          onFocus={() => {
            // onFocus()
          }}
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position='end'>
                
          //     </InputAdornment>
          //   )
          // }}
        />
      </Paper>
    </>
  )
}

export default Index
