import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import useStyles from "./jss/style";
import { Fade, Grid, Icon, Paper, Popper, Typography } from "@material-ui/core";

import { AppBar, Toolbar, IconButton } from "@material-ui/core";

import {
  Menu as MenuIcon,
  NavigateBefore as CollapseIcon,
  ExitToApp as LogoutIcon,
  DateRange as CalendarIcon,
} from "@material-ui/icons";
import { setCurrentUser } from "../../redux/actions/auth";
import { setDateRange } from "../../redux/actions/dateRange";
import { useHistory, useLocation } from "react-router-dom";
import { DateRangePicker } from "../../commons/ui";
import logo from "../../images/logo.svg";

const Index = ({ drawerState = true, handleDrawerOpen = null }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const dateRange = useSelector((state) => state.dateRange);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dates, setDates] = useState({});
  const [dateFlag, setDateFlag] = useState(false);

  const onLogout = () => {
    dispatch(setCurrentUser(null));
    localStorage.removeItem("user");
    history.push("/login");
  };

  const toggleCalendar = (event) => {
    setCalendarOpen(!calendarOpen);
    if (event) setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (dateFlag) {
      dispatch(setDateRange(dates));
      setDateFlag(false);
    } else {
      setDates(dateRange);
    }
  }, [dateRange, dates, dateFlag, dispatch]);
  useEffect(() => {

  }, [auth])

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: false,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <Icon style={{ width: "auto", height: "40px", fontWeight: "bold" }}>
            <img src={logo} height='40px' alt='logo' />
          </Icon>
          {auth &&
            auth.data &&
            auth.data.accessToken &&
            !auth.data.isTempPassword && window.location.pathname !== '/eversign-redirect' ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => handleDrawerOpen()}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: false,
              })}
            >
              {drawerState ? <CollapseIcon /> : <MenuIcon />}
            </IconButton>
          ) : null}
          {window.location.pathname !== '/eversign-redirect' ?
            <>
              <div className={classes.grow} />
              {(location.pathname === "/dashboard" ||
                location.pathname === "/history/car") && (
                  <IconButton
                    color="inherit"
                    aria-label="Calendar"
                    onClick={(event) => toggleCalendar(event)}
                    edge="start"
                  >
                    <CalendarIcon />
                  </IconButton>
                )}
              <Grid className="xs-none" style={{ textAlign: "center", marginRight: "1rem" }}>
                <Typography>
                  {auth?.data?.firstName} {auth?.data?.lastName}
                </Typography>
                {auth?.data?.role?.name ? (
                  <Typography>({auth?.data?.role?.name})</Typography>
                ) : null}
              </Grid>
              {auth.data?.accessToken && (
                <IconButton
                  color="inherit"
                  aria-label="Logout"
                  onClick={() => onLogout()}
                  edge="start"

                >
                  <LogoutIcon />
                </IconButton>
              )}
              <Icon className="xs-none" style={{ width: "auto", height: "40px", fontWeight: "bold" }}>
                {/* <img src={brandIcon} height='40px' alt='brand-icon'/> */}
                iTechQ
              </Icon>
            </> : null}
        </Toolbar>
      </AppBar>

      <Popper
        className={classes.popper}
        open={calendarOpen}
        anchorEl={anchorEl}
        placement={"bottom-start"}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={150}>
            <Paper>
              <DateRangePicker
                open={true}
                selectedRange={dates}
                setRange={(range) => {
                  setDates(range);
                  setDateFlag(true);
                  toggleCalendar();
                }}
              />
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default Index;
