import theme from '../../../theme/theme';

const firstHeader = [
  {
    id: 0,
    name: 'All',
    color: theme.palette.primary,
    required: true,
    link: '',
    selected: true,
  },
  {
    id: 1,
    name: 'Indoor Area',
    color: theme.palette.accent.main,
    textColor: theme.palette.secondary.main,
    required: true,
    link: '',
    selected: false,
  },
  {
    id: 2,
    name: 'Bay',
    color: theme.palette.accent.main,
    textColor: theme.palette.secondary.main,
    required: true,
    link: '',
    selected: false,
  },
  {
    id: 3,
    name: 'Yard',
    color: theme.palette.accent.main,
    textColor: theme.palette.secondary.main,
    required: true,
    link: '',
    selected: false,
  },
]

const secondHeader = [
  {
    id: 0,
    name: 'All',
    color: theme.palette.primary.main,
    required: true,
    link: ''
  },
  {
    id: 1,
    name: 'Assigned',
    color: theme.palette.accent.main,
    textColor: theme.palette.secondary.main,
    required: true,
    link: ''
  },
  {
    id: 2,
    name: 'Awaiting',
    color: theme.palette.accent.main,
    textColor: theme.palette.secondary.main,
    required: true,
    link: ''
  },
  {
    id: 3,
    name: 'Completed',
    color: theme.palette.accent.main,
    textColor: theme.palette.secondary.main,
    required: true,
    link: ''
  },
]

const cardDetails = [
  {
    id: 0,
    modelName: 'ABC 77L',
    name: 'Abc, XY',
    issues: '',
    jobcardNo: 'QWER1256',
    bayNo: '',
    status: 'Assigned for Rework',
    idealTime: 'N/A',
    remainingTime: 'N/A',
    progress: '45%',
  },
  {
    id: 1,
    modelName: '123 43L',
    name: 'Quentin, L',
    issues: '',
    jobcardNo: 'PQR1235789',
    bayNo: 'Bay 1',
    status: 'Rework Ongoing',
    idealTime: '3 hr 10 min',
    remainingTime: '1 hr 3 min',
    progress: '45%',
  },
  {
    id: 2,
    modelName: '523 12E',
    name: 'Quentin, L',
    issues: '',
    jobcardNo: 'ABC9865430',
    bayNo: 'Bay 2',
    status: 'Rework Ongoing',
    idealTime: '3 hr 10 min',
    remainingTime: '1 hr 3 min',
    progress: '23%',
  },
  {
    id: 3,
    modelName: '777 33S',
    name: 'Quentin, L',
    issues: '',
    jobcardNo: 'QPPS865444',
    bayNo: 'Bay 3',
    status: 'Hold',
    idealTime: '1 hr 20 min',
    remainingTime: '1 hr 3 min',
    progress: '85%',
  },

]

const CARLIST = [
  {
    id: 0,
    name: '3GMTT159',
    days: '45 Days | 6 Modules',
  },
  {
    id: 1,
    name: 'AB23JK22',
    days: 'N/A',
  },
  {
    id: 2,
    name: '12PJK111',
    days: '85 Days | 6 Modules',
  },
  {
    id: 3,
    name: '32AOL90',
    days: '25 Days | 0 Modules',
  },
  {
    id: 4,
    name: '45KLI22',
    days: '15 Days | 3 Modules',
  },
]

const ErList = [
  { id: 0, name: 'Dunphy, J', totalCar: 0, doneCar: 0 },
  { id: 1, name: 'Er. 1', totalCar: 0, doneCar: 0 },
  { id: 2, name: 'Er. 2', totalCar: 0, doneCar: 0 },
  { id: 3, name: 'Er. 3', totalCar: 0, doneCar: 0 },
  { id: 4, name: 'Er. 4', totalCar: 0, doneCar: 0 },
  { id: 5, name: 'Er. 5', totalCar: 0, doneCar: 0 },


];

const listBay = [
  { id: 0, name: 'Bay 1', type: '', lastCar: 'N/A', accupied: true, bayAssigned: false, time: 'N/A', totalCar: '0', status: 'available' },
  { id: 1, name: 'Bay 2', type: '', lastCar: 'N/A', accupied: true, bayAssigned: false, time: 'N/A', totalCar: '0', status: 'available' },
  { id: 2, name: 'Bay 3', type: '', lastCar: 'N/A', accupied: true, bayAssigned: false, time: 'N/A', totalCar: '0', status: 'available' },
  { id: 3, name: 'Bay 4', type: '', lastCar: 'N/A', accupied: false, bayAssigned: false, time: 'N/A', totalCar: '0', status: 'available' },
  { id: 4, name: 'Bay 5', type: '', lastCar: 'N/A', accupied: false, bayAssigned: false, time: 'N/A', totalCar: '0', status: 'available' },
  { id: 5, name: 'Bay 6', type: '', lastCar: 'N/A', accupied: false, bayAssigned: false, time: 'N/A', totalCar: '0', status: 'inuse', },
  { id: 6, name: 'Bay 7', type: '', lastCar: 'N/A', accupied: false, bayAssigned: false, time: 'N/A', totalCar: '0', status: 'inuse', },
  { id: 7, name: 'Bay 8', type: '', lastCar: 'N/A', accupied: false, bayAssigned: false, time: 'N/A', totalCar: '0', status: 'inuse', },
];
const dataEr = { id: 1, name: 'Er. 1', carName: 'ASD4567', totalCar: 0, doneCar: 0, status: 'busy' }

const TABLE_HEADER = {
  projectNoView: {
    data: 'Project #',
    width: '150px'
  },
  name: {
    data: 'Name',
    // width: '240px'
  },
  // description: {
  //   data: 'Description',
  //   // width: '240px'
  // },
  // revision: {
  //   data: 'Revision #',
  //   // width: '20px',
  // },
  customerName: {
    data: 'Customer',
    // width: '150px',
  },
  customerContact: {
    data: 'Customer Contact',
  },
  locationContact: {
    data: 'Location Contact',
  },
  createdAt: {
    data: 'Date Created'
  },
  status: {
    data: 'Status'
  },
  assessmentAssignTo: {
    data: 'Assessment Assigned'
  }
}
const ASSOCIATE = "Assessee"
const AUDITOR = "Auditor"
const CUSTOMER = "Customer"
const ADMIN = "Business Relationship Manager"

export { firstHeader, secondHeader, cardDetails, CARLIST, ErList, listBay, dataEr, TABLE_HEADER, ASSOCIATE, CUSTOMER, AUDITOR, ADMIN }
