import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";

export default function Index({data = {}}) {
  console.log(data)
  return (
    <div>
      <Grid style={{ margin: "0rem 3.5rem" }}>
        <h2>Process Audit</h2>
      </Grid>
      <Grid container spacing={3} style={{ margin: "1rem 3rem" }}>
        <Grid item sm={4} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            PDSL Project No. :
          </Typography>
          <Typography>{data?.projectNo}</Typography>
        </Grid>
        <Grid item sm={2} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Rev :
          </Typography>
          <Typography>0</Typography>
        </Grid>
        <Grid item sm={6} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            SAP/Customer Ref :
          </Typography>
          <Typography>{data?.sapNo}</Typography>
        </Grid>

        <Grid container spacing={3} style={{ margin: "1rem 0rem" }}>
          <Grid item sm={6} style={{ display: "flex" }}>
            <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
              Shift :
            </Typography>
            <Typography>Morning</Typography>
          </Grid>
          <Grid item sm={6} style={{ display: "flex" }}>
            <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
              Date :
            </Typography>
            <Typography>03.02.2022</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginLeft: "0rem" }}>
          <Grid item sm={6} style={{ display: "flex" }}>
            <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
              Auditor :
            </Typography>
            <Typography>A Auditor</Typography>
          </Grid>
          <Grid item sm={6} style={{ display: "flex" }}>
            <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
              Time :
            </Typography>
            <Typography>09:00</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ margin: "3rem 0rem" }}>
          <Grid container item sm={4} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Audit Record :
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>
                1) Work element Plan E-Signed?
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>
                2) Performance to Process?
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>
                3) Safe Working
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>
                4) Correct PPE
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>
                5) Attire Presentation
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>
                6) Work Area Tidy?
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>
                7) Work Area Configured?
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>
                8) SP Lesson(s) Adherence?
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>
                9) Product Correctly Stored?
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>
                10) Product Correctly Packed?
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>
                11) All Product Correctly Labelled?
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>
                12) Results Collection Latest?
              </Typography>
            </Grid>
          </Grid>
          <Grid container item sm={1} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", backgroundColor: "green", width: "2rem" }}>
                Yes
              </Typography>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item sm={1} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", backgroundColor: "red", width: "2rem" }}>
                No
              </Typography>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
            </Grid>
          </Grid>


          <Grid container item sm={6} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Comments/Corrective Actions*Closure :
              </Typography>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography style={{ fontWeight: "bold", marginRight: ".5rem", marginTop: "1rem" }}>

                </Typography>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

        <Grid container spacing={3} style={{ margin: "0rem 0rem" }}>
          <Grid container item sm={3} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Sample OK Product Audit :
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ marginRight: ".5rem", marginTop: "1rem" }}>

              </Typography>
            </Grid>
          </Grid>

          <Grid container item sm={3} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Number Audited
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ marginRight: ".5rem", marginTop: "1rem" }}>
                0
              </Typography>
            </Grid>
          </Grid>
          <Grid container item sm={6} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", backgroundColor: "red", width: "3rem" }}>
                NOK
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ marginRight: ".5rem", marginTop: "1rem" }}>
                5
              </Typography>
            </Grid>
          </Grid>
        </Grid>


        <Grid container spacing={3} style={{ margin: "1rem 0rem" }}>
          <Grid container item sm={3} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Sample NOK Product Audit :
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ marginRight: ".5rem", marginTop: "1rem" }}>

              </Typography>
            </Grid>
          </Grid>

          <Grid container item sm={3} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Number Audited
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ marginRight: ".5rem", marginTop: "1rem" }}>
                0
              </Typography>
            </Grid>
          </Grid>
          <Grid container item sm={6} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem", backgroundColor: "red", width: "3rem" }}>
                NOK
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ marginRight: ".5rem", marginTop: "1rem" }}>
                5
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginLeft: "0rem" }}>
          <Grid item sm={12} style={{ display: "flex" }}>
            <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
              Corrective Actions/Reaction Plan-Close*
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ marginLeft: "0rem", marginTop: "1rem", height: "6rem" }}>
          <Grid item sm={10} style={{ display: "flex", border: "1px solid black" }}>
            <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>

            </Typography>
          </Grid>
        </Grid>







        <Grid container spacing={3} style={{ margin: "1rem 0rem" }}>
          <Grid container item sm={6} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                PDSL Associates Audited :
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ marginRight: ".5rem", marginTop: "1rem" }}>
                A Quality Inspector
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ marginRight: ".5rem", marginTop: "1rem" }}>
                Another Q Inspector
              </Typography>
            </Grid>
          </Grid>

          <Grid container item sm={6} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Acceptance E-Signature
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ marginRight: ".5rem", marginTop: "1rem" }}>
                <i>INSPECTOR</i>
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ marginRight: ".5rem", marginTop: "1rem" }}>
                <i>INSPECTOR</i>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3} style={{ margin: "1rem 0rem" }}>
          <Grid container item sm={3} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Auditor :
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ marginRight: ".5rem", marginTop: "1rem" }}>
                A Auditor
              </Typography>
            </Grid>
          </Grid>
          <Grid container item sm={3} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Date :
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ marginRight: ".5rem", marginTop: "1rem" }}>
                03.02.2023
              </Typography>
            </Grid>
          </Grid>
          <Grid container item sm={3} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Time :
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ marginRight: ".5rem", marginTop: "1rem" }}>
                09:55
              </Typography>
            </Grid>
          </Grid>

          <Grid container item sm={3} style={{ display: "flex" }}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                E-Signature :
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography style={{ marginRight: ".5rem", marginTop: "1rem" }}>
                <i>SIGNATURE</i>
              </Typography>
            </Grid>

          </Grid>
        </Grid>







      </Grid>
    </div>
  );
}
