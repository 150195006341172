import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid, useTheme, Typography, Link, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, ListItem, ListItemIcon, ListItemText, makeStyles, CardHeader, Avatar, CardMedia, CardActions, Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';



import Bay from '../../../Cards/Bay';
import { clearProjectData, listProjectReports, projectReportHistory } from '../../../../redux/actions/project';
import { LIST_PROJECT_REPORT, PROJECT_REPORT_HISTORY } from '../../../../redux/action_types';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { CHART_OPTIONS_PROGRESS, TABLE_HEADER } from './CONSTANTS';
import { PieChart } from '../../../../commons/ui';
import ReactApexChart from "react-apexcharts";
import { Button, IconButton, Pagination, Table } from '../../../../commons/mui';
import { ArrowBack, ExpandMore, Inbox, MoreVert } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import moment from 'moment/moment';
import axios from 'axios';
import Carousel from 'react-material-ui-carousel'
import { Skeleton } from '@material-ui/lab';
import { CUSTOMER } from '../../../Projects/AllProjects/CONSTANTS';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    marginBottom: 20
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const Index = () => {
  const classes = useStyles();

  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.project);
  const localCompany = useSelector((state) => state.localCompany);
  const auth = useSelector((state) => state.auth);
  const [page, setPage] = useState(null);
  const { type } = useParams();
  const [nokReview, setNokReview] = useState(false);
  const [tipReview, setTIPReview] = useState(false);
  const [loader, setLoader] = useState(false);
  const [noOkList, setNokList] = useState([]);
  const [tipLevelList, setTIPLevelList] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [total, setTotal] = useState([]);
  const [selectedHistory, setSelectedHistory] = useState({ history: [] });

  const pieChartColors = [
    theme.palette.green.main,
    theme.palette.amber.main,
    theme.palette.cyan.main,
    theme.palette.red.main,
    theme.palette.purple.main,
  ]
  useEffect(() => {
    if (page) {
      let query = { skip: 10 * (page - 1) };
      if (type == 'open') {
        query.open = true;
      }
      if (type == 'closed') {
        query.closed = true;
      }
      if (type == 'tipLevel1') {
        query.tipLevel1 = true;
      }
      if (type == 'tipLevel2') {
        query.tipLevel2 = true;
      }
      if (type == 'tipLevel3') {
        query.tipLevel3 = true;
      }
      if (type == 'tipLevel4') {
        query.tipLevel4 = true;
      }
      if (type == 'tipLevel5') {
        query.tipLevel5 = true;
      }
      if (type == 'tipLevel5+') {
        query.tipLevel6 = true;
      }
      setReportList([]);
      setLoader(true)
      dispatch(listProjectReports(query));
    }
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [localCompany]);


  useEffect(() => {
    if (projects.reports?.data?.length) {
      dispatch(clearProjectData(LIST_PROJECT_REPORT))
      setLoader(false);
      setTotal(projects.reports.totalCount);
      setReportList(projects.reports?.data.map(a => {
        a.completedPercent = ((a.completedQuantity * 100) / a.totalQuantity).toFixed(2)
        return a;
      }))
      if (projects.reports.totalCount == 1) {
        setExpanded(projects.reports?.data[0]._id);
      }
    }
    if (projects.reportHistory?.data?.history) {
      dispatch(clearProjectData(PROJECT_REPORT_HISTORY))
      setLoader(false);
      setSelectedHistory({ ...selectedHistory, ...projects.reportHistory?.data })
    }
  }, [projects]);
  const goToDetail = (item, type) => {
    if (type == 'plan')
      history.push((auth.data?.role?.name === CUSTOMER ? '/customer' : '/brm') + '-plan-report/' + item._id)
    else
      history.push((auth.data?.role?.name === CUSTOMER ? '/customer' : '/brm') + '-inspector-report-by-project/' + item._id)
  }
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel._id : false);
    if (isExpanded) {
      setLoader(true)
      dispatch(projectReportHistory({ _id: panel._id }))
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item container >
        <Typography item variant='h6' align='left' gutterBottom>
          <IconButton
            color="primary"
            onClick={() => history.goBack()}
            icon=<ArrowBack />
          >
          </IconButton>
          List of Projects</Typography>
      </Grid>
      {
        !loader ? reportList.map((item, i) => (
          <Grid item container sm={12} direction='row'>
            <Accordion expanded={expanded === item._id} onChange={handleChange(item)}>
              <AccordionSummary
                expandIcon={<ExpandMore style={{ color: '#fff' }} />}
                aria-controls="panel1bh-content"
                id={"panel1bh-header-" + i}
                className="ui-component-headings"

              >
                <Typography item variant='h6' key={'heading'} style={{ width: '100%' }} >{item.name} ( {item.projectNo} ) <span style={{ paddingLeft: 50, display: 'block', position: 'absolute', top: 12, right: 220 }}><span style={{ paddingLeft: 20 }}>Inspectors: {item.totalTechnician}</span> <span style={{ paddingLeft: 20 }}>TIP Level: {item.totalNok > 5 ? '5+' : item.totalNok}</span></span></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item container spacing={2} sm={12} style={{ justifyContent: 'center' }}>
                  <Grid item sm={4} >
                    <Grid item className="ui-component-block">
                      <Grid item className="ui-components">
                        <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                          <Bay key={'test-1-1-1-1'} title='Work Plans' color={theme.palette.primary} titleAlign='center' width='250px' height='80px' content={<Link style={{ cursor: 'pointer' }} onClick={() => goToDetail(item, 'plan')}>{item.totalPlan}</Link>} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={4} >
                    <Grid item className="ui-component-block">
                      <Grid item className="ui-components">
                        <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                          <Bay key={'test-2-1-1-1'} title='Inspectors' color={theme.palette.primary} titleAlign='center' width='250px' height='80px' content={<Link style={{ cursor: 'pointer' }} onClick={() => goToDetail(item, 'technician')}>{item.totalTechnician}</Link>} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={4} >
                    <Grid item className="ui-component-block">
                      <Grid item className="ui-components">
                        <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                          <Bay key={'test-3-1-1-1'} title='Completed' color={item.completedPercent < 25 ? theme.palette.red : item.completedPercent < 75 ? theme.palette.amber : theme.palette.green} titleAlign='center' width='250px' height='80px' content={<Grid item container style={{ justifyContent: 'center' }}>

                            <Typography item>{item.completedQuantity + '/' + item.totalQuantity}</Typography></Grid>} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={4} >
                    <Grid item className="ui-component-block">
                      <Grid item className="ui-components">
                        <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                          <Bay key={'test-4-1-1-1'} title='Ok' color={theme.palette.green} titleAlign='center' width='250px' height='80px' content={<Grid item>{item.totalOk}</Grid>} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={4} >
                    <Grid item className="ui-component-block">
                      <Grid item className="ui-components">
                        <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                          <Bay key={'test-5-1-1-1'} title='Not OK' color={theme.palette.error} titleAlign='center' width='250px' height='80px' content={<Grid item><Link style={{ cursor: 'pointer' }} onClick={() => { setNokList(selectedHistory.nokHistory); setNokReview(true) }}>{item.totalNok}</Link></Grid>} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={4} >
                    <Grid item className="ui-component-block">
                      <Grid item className="ui-components">
                        <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                          <Bay key={'test-5-1-1-1'} title='TIP Level' color={theme.palette.error} titleAlign='center' width='250px' height='80px' content={<Grid item><Link style={{ cursor: 'pointer' }} onClick={() => { if (selectedHistory.technicianPlanLogs.length) { setTIPLevelList(selectedHistory.technicianPlanLogs.filter(a => a.nok)); setTIPReview(true) } }}>{item.totalNok > 5 ? '5+' : item.totalNok}</Link></Grid>} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {item.totalQuantity && expanded === item._id ?
                    <>
                      <Grid item sm={6} >
                        <Card>
                          <CardContent style={{ minHeight: '32rem' }}>
                            <ReactApexChart options={{
                              chart: {
                                width: 300,
                                type: 'donut',
                              },
                              labels: ['Pending', 'Ok', 'Nok'],
                              colors: ['#FFC300', '#00E396', '#F44336'],
                              fill: {
                                type: 'gradient',
                              },
                              title: {
                                text: 'Performance',
                                align: 'center'
                              },
                              plotOptions: {
                                pie: {
                                  startAngle: -90,
                                  endAngle: 270
                                }
                              },
                              legend: {
                                position: 'bottom',
                                show: true
                              }
                            }} series={[item.totalQuantity - (item.totalOk + item.totalNok), item.totalOk, item.totalNok]} type="donut" />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item sm={6} >
                        <Card>
                          <CardContent style={{ minHeight: '32rem' }}>
                            <ReactApexChart options={{
                              chart: {
                                type: 'bar',
                                height: 400,
                                // width: (selectedHistory.history.length * 100)
                                zoom: {
                                  enabled: true,
                                  type: 'x',
                                  resetIcon: {
                                    offsetX: -10,
                                    offsetY: 0,
                                    fillColor: '#fff',
                                    strokeColor: '#37474F'
                                  },
                                  selection: {
                                    background: '#90CAF9',
                                    border: '#0D47A1'
                                  }
                                }
                              },
                              title: {
                                text: 'Performance History',
                                align: 'center'
                              },
                              plotOptions: {
                                bar: {
                                  horizontal: false,
                                  columnWidth: '60%',
                                  endingShape: 'rounded',
                                  dataLabels: {
                                    orientation: 'vertical',
                                    position: 'center' // bottom/center/top
                                  }
                                },
                              },
                              dataLabels: {
                                enabled: false
                              },
                              stroke: {
                                show: true,
                                width: 2,
                                colors: ['transparent']
                              },
                              xaxis: {
                                categories: selectedHistory.history.map(a => [a.date, a.day]),
                              },
                              yaxis: {
                                title: {
                                  text: 'Pieces'
                                }
                              },
                              colors: ['#008FFB', '#00E396', '#F44336'],
                              fill: {
                                opacity: 1
                              },
                              tooltip: {
                                y: {
                                  formatter: function (val) {
                                    return val + " pieces"
                                  }
                                }
                              }
                            }} series={[
                              { name: 'Target', data: selectedHistory.history.map(a => a.target) },
                              { name: 'Ok', data: selectedHistory.history.map(a => a.ok) },
                              { name: 'Not Ok', data: selectedHistory.history.map(a => a.nok) }
                            ]} type="bar" />
                          </CardContent>
                        </Card>
                      </Grid>
                    </>
                    : null}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid >
        )) :

          [1, 2, 3, 4, 5].map(a => (
            <Grid item container sm={12} direction='row'>
              <Skeleton variant="rect" width={'100%'} height={66} style={{ marginBottom: 30 }} />
              {/*<Grid item container spacing={2} sm={12} style={{ justifyContent: 'center' }}>
              <Grid item sm={4} >
                <Skeleton variant="rect" width={'100%'} height={144} />
              </Grid>
              <Grid item sm={4} >
                <Skeleton variant="rect" width={'100%'} height={144} />
              </Grid>
              <Grid item sm={4} >
                <Skeleton variant="rect" width={'100%'} height={144} />
              </Grid>
              <Grid item sm={4} >
                <Skeleton variant="rect" width={'100%'} height={144} />
              </Grid>
              <Grid item sm={4} >
                <Skeleton variant="rect" width={'100%'} height={144} />
              </Grid>
              <Grid item sm={4} >
                <Skeleton variant="rect" width={'100%'} height={144} />
              </Grid>
              <Grid item sm={6} >
                <Skeleton variant="rect" width={'100%'} height={300} />
              </Grid>
              <Grid item sm={6} >
                <Skeleton variant="rect" width={'100%'} height={300} />
              </Grid>
        </Grid>*/}
            </Grid>
          )
          )
      }
      <Grid
        item
        container
        style={{ justifyContent: "center" }}
      >
        <div className="ui-components">
          <Pagination
            count={total}
            page={page}
            setPage={setPage}
          />
        </div>
      </Grid>
      <Dialog
        open={nokReview}
        onClose={() => setNokReview(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Not Ok Comments Review"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table
              header={TABLE_HEADER}
              data={noOkList}
              actions={false}
              color={true}
              onStart={() => {
                // history.push("/action");
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setNokReview(false)
            }}
            text="Ok"
            variant="contained"
            color="primary"
          />
        </DialogActions>
      </Dialog>
      <Dialog
        open={tipReview}
        onClose={() => setTIPReview(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >
        <DialogTitle id="alert-dialog-title">{"TIP Levels"}</DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <DialogContentText id="alert-dialog-description" >
            <List component="nav" aria-label="main mailbox folders">
              {tipLevelList.map((level, i) => (
                level?.technicianPlan?.workPlanId?.tipLevel && i >= level.technicianPlan?.workPlanId?.tipLevel - 1 ?
                  <Card key={'card-' + i} className={classes.root}>
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                          T
                        </Avatar>
                      }
                      action={
                        <IconButton aria-label="settings">
                          <MoreVert />
                        </IconButton>
                      }
                      title={"TIP Level " + (level.technicianPlan?.workPlanId?.tipLevel + (i - level.technicianPlan?.workPlanId?.tipLevel + 1))}
                      subheader={moment(level.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                    />
                    {level.images?.length ?
                      <Carousel key={'Carousel-' + i}>
                        {
                          level.images?.map((item, i) => (
                            <img src={axios.defaults.baseURL + '/file/' + item} style={{ width: '100%', height: 300 }} />
                          ))
                        }
                      </Carousel>
                      : null}
                    <CardContent>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {level.comment}
                      </Typography>
                    </CardContent>
                  </Card>
                  : null
              ))}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setTIPReview(false)
            }}
            text="Ok"
            variant="contained"
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </Grid >
  )
}

export default Index