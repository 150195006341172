const TABLE_HEADER = {
  sno: {
    data: "Reference",
    width: "50px",
  },
  // question: {
  //   data: "Assessment",
  //   // width: '240px',
  //   align: 'left'
  // },
  // assessmentAnswer: {
  //   data: 'Result'
  // },
  hazard: {
    data: 'Hazard',
  },
  controlMeasure: {
    data: 'Control Measeure',
  },
  riskAcceptableView: {
    data: 'Risk Accepted',
  }
};
const TABLE_HEADER_REVIEW = {
  sno: {
    data: "Reference",
    width: "50px",
  },
  question: {
    data: "Assessment",
    // width: '240px'
    align: 'left'
  },
  // assessmentAnswer: {
  //   data: 'Result'
  // },
  // hazard: {
  //   data: 'Hazard',
  // },
  // controlMeasure: {
  //   data: 'Control Measeure',
  // },
  riskAcceptable: {
    data: 'Risk Accepted',
  },
};

const TABLE_HEADER_1 = {
  sno: {
    data: "Reference",
    width: "50px",
  },
  question: {
    data: "Assessment",
    // width: '240px'
    align: 'left'
  },
  assessmentAnswer: {
    data: 'Action',
  },
  hazard: {
    data: 'Action',
  },
  controlMeasure: {
    data: 'Control Measure'
  },
  riskAcceptable: {
    data: 'Risk Accepted',
  }
}

export { TABLE_HEADER, TABLE_HEADER_1, TABLE_HEADER_REVIEW };
