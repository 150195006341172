import {
  LIST_USER,
  GET_USER,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
} from "../action_types";
import axios from "axios";
// import { QS } from '../../utils/urlHelper';

export const listUsers = (params) => async (dispatch) => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: 'GET',
      url: `/user`,
      params
    });
    dispatch({
      type: LIST_USER,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: LIST_USER,
      payload: {
        data: [],
        meta: err?.data?.meta,
        isLoading: false,
        hasError: true,
      },
    });
  }
};

export const getUser = (id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "GET",
      url: `/user/${id}`,
    });
    dispatch({
      type: GET_USER,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: GET_USER,
      payload: {
        data: {},
        meta: err?.data?.meta,
        isLoading: false,
        hasError: true,
      },
    });
  }
};

export const addUser = (data) => async (dispatch) => {
  try {
    let res = await axios({
      method: "POST",
      url: `/user`,
      data,
    });
    dispatch({
      type: ADD_USER,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: ADD_USER,
      payload: {
        data: [],
        meta: err?.data?.meta,
        hasError: true,
      },
    });
  }
};
export const inviteUser = (data) => async (dispatch) => {
  try {
    let res = await axios({
      method: "POST",
      url: `/user/invite_user`,
      data,
    });
    dispatch({
      type: ADD_USER,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: ADD_USER,
      payload: {
        data: [],
        meta: err?.data?.meta,
        hasError: true,
      },
    });
  }
};

export const updateUser = (data, id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "PUT",
      url: `/user/${id}`,
      data,
    });
    dispatch({
      type: UPDATE_USER,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: UPDATE_USER,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      },
    });
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "DELETE",
      url: `/user/${id}`,
    });
    dispatch({
      type: DELETE_USER,
      payload: {
        data: res.data.data,
        meta: res.data.meta,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: DELETE_USER,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      },
    });
  }
};
export const clearData = (type) => async (dispatch) => {
  dispatch({
    type: type,
    payload: {
      data: {},
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
}