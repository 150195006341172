const TABLE_HEADER = {
  question: {
    data: "Assessment",
    // width: '240px'
  },
  // assessmentAnswer: {
  //   data: 'Result'
  // },
  hazard: {
    data: "Hazard",
  },
  controlMeasure: {
    data: "Control Measeure",
  },
  riskAcceptableView: {
    data: "Risk Accepted",
  },
};

export { TABLE_HEADER };
