import {
  LIST_GRID,
  GET_GRID,
  ADD_GRID,
  UPDATE_GRID,
  DELETE_GRID
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listGrids = (params) => async dispatch => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: 'GET',
      url: `/grid`,
      params
    });
    dispatch({
      type: LIST_GRID,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: LIST_GRID,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const getGrid = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/grid/${id}`,
    });
    dispatch({
      type: GET_GRID,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: GET_GRID,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addGrid = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/grid`,
      data
    });
    dispatch({
      type: ADD_GRID,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: ADD_GRID,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const updateGrid = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/grid/${id}`,
      data
    });
    dispatch({
      type: UPDATE_GRID,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_GRID,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const deleteGrid = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/grid/${id}`,
    });
    console.log(res.data.data)
    dispatch({
      type: DELETE_GRID,
      payload: {
        data: res.data.data || { _id: id },
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: DELETE_GRID,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}
export const clearGridData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_GRID,
    payload: {
      data: {},
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
};