const TABLE_HEADER = {
  name: {
    data: "Name",
    width: '150px'
  },
  description: {
    data: "Description",
    width: '150px'
  },
  image: {
    data: "Image",
    width: "150px",
    component: 'img'
  },
};

export { TABLE_HEADER };