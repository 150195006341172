import { 
    LIST_COMPANY,
    GET_COMPANY,
    ADD_COMPANY,
    UPDATE_COMPANY,
    DELETE_COMPANY  
  } from '../action_types';
  
  const initialState = {
    list: {
      data: [],
      isLoading: true,
      hasError: false
    },
    detail: {
      data: {},
      isLoading: true,
      hasError: false
    },
    add: {
      data: {},
      isLoading: true,
      hasError: false
    },
    update: {
      data: {},
      isLoading: true,
      hasError: false
    },
    delete: {
      data: {},
      isLoading: true,
      hasError: false
    }
  }
  
  const company = (state=initialState, action)  => {
    const { type, payload } = action;
  
    switch(type) {
      case LIST_COMPANY: 
        return {
          ...state,
          list: {
            ...state.list,
            ...payload
          }
        };
      case GET_COMPANY:
        return {
          ...state,
          detail: {
            ...state.detail,
            ...payload
          }
        };
      case ADD_COMPANY:
        return {
          ...state,
          add: {
            ...state.add,
            ...payload,
            isLoading: false,
            hasError: payload.hasError,
          }
        }
      case UPDATE_COMPANY:
        return {
          ...state,
          update: {
            ...state.update,
            ...payload,
            isLoading: false,
            hasError: payload.hasError,
          }
        }
      case DELETE_COMPANY:
        return {
          ...state,
          delete: {
            ...state.delete,
            ...payload,
            isLoading: false,
            hasError: payload.hasError,
          }
        }
      default: return state;
    }
  }
  
  export default company;