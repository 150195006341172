

const TABLE_HEADER_CHECKLIST = {
  // checkPointView: {
  //   data: "Check Point",
  // },
  partView: {
    data: "Part",
    width: 300
  }
};

export {
  TABLE_HEADER_CHECKLIST
}
