const TABLE_HEADER = {
  name: {
    data: "Name",
    width: "150px",
  },
  description: {
    data: "Description",
    width: "150px",
  },
};

export { TABLE_HEADER };
const ROLES = [
  {
    label: "Customer",
    value: "Customer",
  },
  {
    label: "Inspector",
    value: "Inspector",
  },
  {
    label: "Assessee",
    value: "Assessee",
  },
];

export { ROLES };
const SUPERROLES = [
  {
    label: "Business Relationship Manager",
    value: "Business Relationship Manager",
  },
];

export { SUPERROLES };
