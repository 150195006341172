import React from 'react';
import {
  Typography, Grid, 
} from '@material-ui/core';
import {
  SingleHeaderTable,
} from '../../../commons/mui';
import useStyles from '../../../styles/jss/style';

const Index = ({
  data=null
}) => {
  const classes = useStyles();
  
  return (
    <Grid item container xs={12}>
      <Grid item sm={12}>
        <Typography className={`${classes.fullWidth}`} 
          variant='overline' align='left' gutterBottom>
          {data?.jobcardId}
        </Typography>
      </Grid>
      <Grid item container sm={12} className={`${classes.paddingBottom}`}>
        <Grid item container sm={6} className={`${classes.paddingRight}`} >
          <SingleHeaderTable header='Vehicle/Other Information' rows={[
            [{label: 'Tag', value: data?.vin?.tag || ''},],
            [{label: 'Reg No', value: data?.vin?.regNo || ''},],
            [{label: 'VIN', value: data?.vin?.vin || ''},],
            [{label: 'Model / Year', value: data?.vin?.modelYear || '' },],
            [{label: 'Engine Type', value: data?.vin?.engine || '' },],
            [{label: 'Body Color', value: data?.vin?.color || ''},],
            [{label: 'Transmission', value: data?.vin?.transmission || '' },],
            [{label: 'ODO (Start Value)', value:data?.startValue || ''},],
            [{label: 'ODO (End Value)', value:data?.endValue || ''},],
            [{label: 'Key Location', value: data?.keyLocation?.title || data?.keyLocation ||  '' },],
            [{label: 'Hand In', value: data?.location? typeof data?.location === 'string'? data?.location: data?.location.name : data?.vehicleHandInLocation || ''},],
          ]}/>
        </Grid>
        <Grid item container sm={6}>
          <Grid item container sm={12} className={`${classes.paddingBottom}`}>
            <SingleHeaderTable header='Requestor Details' noOfCols={1} rows={[
              [{label: 'Name', value: data?.requestorName?.firstName || data?.requestorName?.emailId || ''},],
              [{label: 'Cost Centre', value: data?.costCentre?.name || ''},],
              [{label: 'Department', value: ''},],
              [{label: 'Telephone', value: ''},],
            ]}
            />
          </Grid>
          <Grid item container sm={12} className={`${classes.paddingTop}`}>
            <SingleHeaderTable header='Job Details' noOfCols={1} rows={[
              [{label: 'Workshop', value: data?.workshop?.name || ''},],
              [{label: 'Supervisor', value: data?.jobSupervisor?.firstName || data?.jobSupervisor?.emailId || ''},],
              [{label: 'Team', value: data?.jobTeam?.name || ''},],
              [{label: 'Engineer', value: data?.jobEngineer?.firstName || data?.jobEngineer?.emailId || ''},],
            ]}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container sm={12} className={`${classes.paddingY}`}>
        <SingleHeaderTable noOfCols={1} rows={[
          [{label: 'Reason For Request', value: data?.reason || ''},],
          [{label: 'Summary', value: data?.summary || ''},],
        ]}
        />
      </Grid>
      <Grid item container sm={12}>
        <Typography className={`${classes.fullWidth}`} 
          variant='h5' align='left' gutterBottom>
          Instructions
        </Typography>
      </Grid>
      <Grid item container sm={12} className={`${classes.paddingY}`}>
        <SingleHeaderTable rows={
              [
                [{label: '', value: data?.instructions || ''},],
              ]
            }
            secondHeader={[
              'Instructions',
              'Initials'
            ]}
          />
      </Grid>
      <Grid item container sm={12} className={`${classes.paddingY}`}>
        <SingleHeaderTable header='Part Change Details' noOfCols={4} alignHeader={'center'} rows={[
            [
              {label: 'Part Type', value: data?.partType?.name || '' },
              {label: 'Component', value:  data?.partComponent?.name || '' },
              {label: 'Description', value: data?.partType?.description || '' },
              {label: 'New Part', value:  data?.newPart?.name || '' },
            ],
          ]}
          secondHeader={[
            'Part Type',
            'Component',
            'Description',
            'New Part',
          ]}
        />
      </Grid>
      <Grid item container sm={12} className={`${classes.paddingY}`}>
        <Grid item container sm={7} className={`${classes.fullWidth} ${classes.paddingRight}`} >
          <SingleHeaderTable noOfCols={2} rows={[
                [
                  {label: 'Tools/Machines Used', value: data?.toolsUsed || ''},
                  {label: 'ID Description', value: data?.idNoOrDescription || ''},
                ],
              ]}
              secondHeader={[
                'Tools/Machines Used',
                'ID Description',
              ]}
            />
        </Grid>
        <Grid item container sm={5} justifyContent='flex-end' className={``} >
          <SingleHeaderTable noOfCols={1} rows={
                [
                  [{label: 'Signature', value: `Signature: ${data?.signature || ''}`},],
                  [{label: 'Name', value: `Name: ${data?.name || ''}`},],
                  [{label: 'Date', value: `Date: ${data?.date ? (new Date(data?.date)).toLocaleString(): ''}`},],
                ]
              }
              secondHeader={[
                'Sign Off & Date',
              ]}
              secondHeaderAlign={'center'}
            />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Index;
