import { createSelector } from 'reselect';

export const createSelectorWithArgs = (...args) => {
  const selectors = args.slice(0, -1);
  const fn = args[args.length - 1];

  const argGetters = [];
  for (let idx = 0; idx < fn.length - selectors.length; idx++) {
    argGetters.push((...args) => args[idx + selectors.length]);
  }

  return createSelector(...selectors, ...argGetters, fn);
};