const Joi = require('joi');

const schema = Joi.object({
  jobcardId: Joi.string().length(13).required().messages({
    "string.empty": `"Jobcard ID" is required`,
    "string.length": `"Jobcard ID" must be 13 characters long`
  }),
  workshop: Joi.object().required().messages({
    "object.base": `"Workshop" is required`
  }),
  costCentre: Joi.object().required().messages({
    "object.base": `"Cost Centre" is required`
  }),
  location: Joi.object().required().messages({
    "object.base": `"Vehicle Hand-in Location" is required`
  }),
  vin: Joi.object().messages({
    "object.base": `"VIN" is required`
  }),
  tag: Joi.object().messages({
    "object.base": `"Tag" is required`
  }),
  regNo: Joi.object().messages({
    "object.base": `"Reg No" is required`
  }),
  jobType: Joi.object().messages({
    "object.base": `"Job Type" is required`
  }),
  jobParameter: Joi.object().messages({
    "object.base": `"Job Parameter" is required`
  }),
  ipWeek: Joi.any().when('jobType', {
    is: Joi.object().keys({
      name: Joi.string().valid("Software Update").required()
    }).unknown(),
    then: Joi.object().required().messages({
      "object.base": `"IP Week" is required`
    })
  }),
  keyLocation: Joi.object().messages({
    "object.base": `"Key Location" is required`
  }),
  mileageRequired: Joi.object().messages({
    "object.base": `Please select if "Mileage" is required`
  }),
  startValue: Joi.any().when('mileageRequired', {
    is: Joi.object().keys({
      title: Joi.string().valid("Yes").required()
    }).unknown(),
    then: Joi.number().min(0).max(999999).messages({
      "number.base": `"Start ODO" is required`,
      "number.min": `Invalid "Start ODO"`,
      "number.max": `Invalid "Start ODO"`
    })
  }),
  risJobcard: Joi.object().required().messages({
    "object.base": `Please select if it is a "RIS Jobcard"`
  }),
  partType: Joi.any().when('risJobcard', {
    is: Joi.object().keys({
      title: Joi.string().valid("Yes").required()
    }).unknown(),
    then: Joi.object().messages({
      "object.base": `"Part Type" is required`
    }),
  }),
  partComponent: Joi.any().when('risJobcard', {
    is: Joi.object().keys({
      title: Joi.string().valid("Yes").required()
    }).unknown(),
    then: Joi.object().messages({
      "object.base": `"Part Component" is required`
    }),
  }),
  newPart: Joi.any().when('risJobcard', {
    is: Joi.object().keys({
      title: Joi.string().valid("Yes").required()
    }).unknown(),
    then: Joi.object().messages({
      "object.base": `"New Part" is required`
    }),
  }),
  preferredStartDate: Joi.date().required().messages({
    "date.base": `"Preferred Start Date" is required`
  }),
  reason: Joi.string().required().messages({
    "string.empty": `"Reason" is required`
  }),
  summary: Joi.string().required().messages({
    "string.empty": `"Summary" is required`
  }),
  instructions: Joi.string().required().messages({
    "string.empty": `"Instructions" is required`
  }),
}).unknown();

export {
  schema
}