const TABLE_HEADER = {
  question: {
    data: "Question",
    width: "150px",
  },
  description: {
    data: "Description",
    width: "150px",
  },
};
const QUESTION_TYPE = [
  { label: "Quantitative", value: "Quantitative" },
  { label: "Yes/No", value: "Option" },
];

export { TABLE_HEADER, QUESTION_TYPE };
