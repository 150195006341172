import React, { useEffect } from "react";
import { TABLE_HEADER, TABLE_HEADER_1, TABLE_HEADER_PLAN } from "./CONSTANTS";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  ActionTable,
  Button,
  Checkbox,
  IconButton,
  Pagination,
  Table,
} from "../../../commons/mui";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  listProjectAssessment,
  updateProjectAssessment,
} from "../../../redux/actions/projectAssessment";
import {
  clearPlanAssessmentData,
  listProjectPlanAssessment,
  updateProjectPlanAssessment,
} from "../../../redux/actions/projectPlanAssessment";
import { EQUIPMENTS } from "../../Associate/ActionScreen/CONSTANTS";
import { TECHNICIAN } from "../../Projects/AllProjects/CONSTANTS";
import axios from "axios";
import {
  getPlan,
  clearPlanData,
  verifyPlanSign,
  updatePlan,
} from "../../../redux/actions/projectPlan";
import {
  GET_PROJECT,
  GET_WORK_PLAN,
  SIGN_WORK_PLAN,
  UPDATE_WORK_PLAN,
} from "../../../redux/action_types";
import { clearProjectData, getProject } from "../../../redux/actions/project";
import Iframe from "react-iframe";
import { ArrowBack, Done } from "@material-ui/icons";
import { ProjectDetailComponent } from "../../Projects";
import ForwardIcon from '@material-ui/icons/Forward';
import { REFERENCE_CRITERIA } from "../../WorkPlan/AddWorkPlan/CONSTANTS";
import { toast } from "react-toastify";

export default function Index() {
  const history = useHistory();
  const [data, setdata] = useState([]);
  const [list, setList] = useState([]);
  const [dataKey, setDataKey] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openReview, setOpenReview] = React.useState(false);
  const [comment, setComment] = useState("");
  const [planData, setPlanData] = useState({});
  const [selectedActivity, setSelectedActivity] = useState({});
  const auth = useSelector((state) => state.auth);
  const [index, setindex] = useState(null);
  const { planId } = useParams();
  const dispatch = useDispatch();
  const projectPlan = useSelector((state) => state.projectPlan);
  const projectAssessments = useSelector((state) => state.projectAssessments);
  const projectPlanAssessments = useSelector(
    (state) => state.projectPlanAssessment
  );
  const project = useSelector(state => state.project)
  const [page, setPage] = useState(1);
  const [equipments, setEquipments] = useState(EQUIPMENTS);
  const [isValid, setIsValid] = useState(false);
  const [eversignDetail, setEversignDetail] = useState({});
  const [signLoader, setSignLoader] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [projectDetails, setprojectDetails] = useState({})
  const [availableEquipments, setAvailableEquipments] = useState([]);
  const [references, setReferences] = useState(REFERENCE_CRITERIA);

  useEffect(() => {
    if (project.detail.data && project.detail.data._id) {
      dispatch(clearProjectData(GET_PROJECT));
      setprojectDetails(project.detail.data);
      if (project.detail.data._id) {
        // console.log(EQUIPMENTS, EQUIPMENTS.filter(a => project.detail.data.safetyEquipments.indexOf(a.name) !== -1))
        setAvailableEquipments(EQUIPMENTS.map(a => { a.checked = project.detail.data.safetyEquipments.indexOf(a.name) !== -1; return a; }))
      }
    }
  }, [project])

  useEffect(() => {
    getProjectPlan();
  }, []);
  useEffect(() => {
    if (planData._id) {
      if (planData.activity?.length) {
        setSelectedActivity({ ...selectedActivity, ...planData.activity[0], index: 0 });
      }
      getProjectAssessment(planData.project?._id);
      getProjectPlanAssessment();
      let everSign =
        planData.eversignList && planData.eversignList?.length
          ? planData.eversignList?.find((aa) => aa._id)
          : {};
      // console.log(everSign, planData);
      let signers = Object.keys(everSign?.meta || []).filter((a) =>
        a.includes("signer-")
      );
      // console.log("signers---", signers);
      let signerHead = everSign.meta ? everSign.meta["signer-0"] : {};
      signerHead.key = "signer-0";
      signerHead.position = "0";
      signers.map((it) => {
        if (everSign.meta[it].userId === auth.data._id) {
          signerHead = everSign.meta ? everSign.meta[it] : {};
          signerHead.key = it;
          signerHead.position = Number(it.replace("signer-", ""));
        }
        return it;
      });
      // console.log("signerHead---", signerHead);
      signerHead.document_hash = everSign.meta?.document_hash;
      // signers = signers.splice(1);
      if (
        (planData.status.includes("to Review Assessment") &
          (auth.data?.role?.name === TECHNICIAN) &&
          planData.status.includes(
            "Awaiting Inspector " + signerHead.position
          )) ||
        (planData.status === "Awaiting Inspector to Review Assessment" &&
          signerHead.userId === auth.data?._id)
      ) {

        if (signerHead.status === "signed" && openTemplate) {
          handleEverSignUpdate(signers);
        } else if (signerHead.status && openTemplate) {
          toast.error("Signature not done yet!");
        }
      } else {
        history.push("/inspector-workplan-list");
      }
    }
  }, [planData]);
  useEffect(() => {
    console.log(projectPlan);
    if (projectPlan.detail?.data?._id) {
      dispatch(clearPlanData(GET_WORK_PLAN));
      setPlanData(projectPlan.detail?.data);
      setEquipments(
        EQUIPMENTS.map((a) => {
          a.checked =
            projectPlan.detail?.data.project.safetyEquipments.indexOf(a.name) ==
              -1
              ? false
              : true;
          return a;
        })
      );
    }
    if (projectPlan?.update?.data && projectPlan?.update?.data._id) {
      // console.log(project)
      dispatch(clearPlanData(UPDATE_WORK_PLAN));
      getProjectPlan();
    }
    if (
      projectPlan.sign &&
      projectPlan.sign.data &&
      projectPlan.sign.data._id
    ) {
      dispatch(clearPlanData(SIGN_WORK_PLAN));
      getProjectPlan();
    }
  }, [projectPlan]);

  useEffect(() => {
    console.log(projectAssessments);
    if (projectAssessments.list?.data) {
      let list = projectAssessments.list?.data
        .map((a) => {
          a.sort = a.assessmentAnswer === "Yes" ? 1 : 0;
          return a;
        })
        .sort((a, b) => a.sort - b.sort);
      // console.log(list);
      setdata(
        list.map((a, i) => {
          a.question = a.assessmentQuestion.question;

          return a;
        })
      );
    }
  }, [projectAssessments]);
  useEffect(() => {
    if (projectPlanAssessments.list?.data) {
      let list = projectPlanAssessments.list?.data.map((a) => {
        a.sort = a.assessmentAnswer === "Yes" ? 1 : 0;
        return a;
      }); //.sort((a, b) => a.sort - b.sort);
      if (list.filter((a) => a.assessmentAnswer).length === list.length)
        setIsValid(true);
      else {
        setIsValid(false);
      }
      // console.log(list);
      setList(
        list.map((a, i) => {
          a.question = a.assessmentQuestion.question;
          a.assessmentAnswerView = (
            <div>
              {a.assessmentAnswer ? (
                <>
                  <Typography style={{ fontWeight: "bold" }}>
                    {a.assessmentAnswer}
                  </Typography>
                  <br />
                </>
              ) : null}

              <Button
                onClick={(e) => {
                  let upData = { assessmentAnswer: "Yes" };
                  dispatch(updateProjectPlanAssessment(upData, a._id));
                }}
                text="Yes"
                variant="contained"
                disabled={a.assessmentAnswer == "Yes"}
                color="primary"
              />
              <Button
                onClick={(e) => {
                  let upData = { assessmentAnswer: "No" };
                  dispatch(updateProjectPlanAssessment(upData, a._id));
                }}
                text="No"
                variant="contained"
                color="primary"
                disabled={a.assessmentAnswer == "No"}
              />
            </div>
          );
          a.commentView = (
            <div>
              {a.comment ? (
                <>
                  <Typography style={{ fontWeight: "bold" }}>
                    {a.comment}
                  </Typography>
                  <br />
                </>
              ) : null}
              <Button
                onClick={(e) => {
                  setDataKey("comment");
                  setindex(i);
                  setOpen(true);
                }}
                text={data[i]?.comment ? "Edit" : "Add"}
                variant="contained"
                color="primary"
                disabled={a.assessmentAnswer === "Yes"}
              />
            </div>
          );
          return a;
        })
      );
    }
    if (projectPlanAssessments?.update?.data) {
      dispatch(clearPlanAssessmentData());
      getProjectPlanAssessment();
    }
  }, [projectPlanAssessments]);
  useEffect(() => {
    getProjectPlanAssessment();
  }, [page]);
  useEffect(() => {
    console.log(data);
  }, [data]);
  const handleEverSignUpdate = (signer) => {
    let status = "Awaiting BRM to E-Sign Work Plan";
    signer = signer.splice(1);
    if (planData.status === "Awaiting BRM to E-Sign Work Plan")
      status = "Awaiting Customer to E-Sign";

    console.log(signer, signer.length, signer.length > 1);
    if (planData.status === "Awaiting Customer to E-Sign")
      status = signer.length - 1 > 1
        ? (planData.questionTemplate ? "Awaiting Inspector 1 to Review Assessment" : "Awaiting Inspector 1 to E-Sign Work Plan")
        : (planData.questionTemplate ? "Awaiting Inspector to Review Assessment" : "Awaiting Inspector to E-Sign Work Plan");
    //"Work Plan Started";
    if (planData.status === "Awaiting Inspector to E-Sign Work Plan" || planData.status === 'Awaiting Inspector to Review Assessment')
      status = "Work Plan Started";//"Awaiting Customer to E-Sign";
    signer.pop();
    console.log(signer);
    for (let i = 0; i < signer.length; i++) {
      console.log("Awaiting Inspector " + (i + 1) + " to E-Sign Work Plan");
      if (
        planData.status ===
        "Awaiting Inspector " + (i + 1) + " to E-Sign Work Plan"
      )
        status = signer.length > i + 1 ? "Awaiting Inspector " + (i + 2) + " to E-Sign Work Plan" : "Work Plan Started";//"Awaiting Customer to E-Sign";
      if (
        planData.status ===
        "Awaiting Inspector to E-Sign Work Plan"
      )
        status = "Work Plan Started"//"Awaiting Customer to E-Sign";
    }
    console.log(status)
    let upData = { status: status };
    dispatch(updatePlan(upData, planData._id));
    setOpenTemplate(false);
    setIframeLoaded(false);
  };
  const getProjectPlan = () => {
    if (planId) {
      dispatch(getPlan(planId));
    }
  };
  const getProjectAssessment = (projectId) => {
    if (projectId) {
      dispatch(listProjectAssessment({ project: projectId, limit: 0 }));
      dispatch(getProject(projectId))
    }
  };

  const getProjectPlanAssessment = () => {
    if (planId)
      dispatch(
        listProjectPlanAssessment({
          workPlan: planId,
          assessmentBy: auth.data?._id,
          skip: (page - 1) * 10,
        })
      );
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenReview = () => {
    setOpenReview(true);
  };

  const handleCloseReview = () => {
    setOpenReview(false);
  };
  const handleCheckList = (itm, i, j) => {
    // setSelectedGridRow(i);
    // setSelectedGridColumn(j);
    // setCheckList(itm.checkList || []);
    // setOpenCheckList(true);
  }
  return (
    <Grid container>
      <Grid index style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 key={"h3-1"}>
          <IconButton
            color="primary"
            onClick={() => history.push("/inspector-workplan-list")}
            icon=<ArrowBack />
          ></IconButton>
          {planData.name} ({auth.data.firstName
            ? auth.data.firstName +
            (auth.data.lastName ? " " + auth.data.lastName : "")
            : auth.data.emailId})
        </h3>

      </Grid>

      <ProjectDetailComponent data={projectDetails} show={['projectNo', 'rev', 'sapNo', 'typeOfWorkRequired', 'expectedEndDate']} additional={(
        <>
          <Grid container spacing={2}>
            <Grid item sm={10}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                Safety First
                <Typography variant="span" style={{ fontWeight: "normal", marginRight: ".5rem" }}>
                  &nbsp; Has The Dynamic Risk Assessment been completed?
                  <br />
                  Area / Location Quality Confirmation Center
                </Typography>
              </Typography>

            </Grid>
            <Grid item sm={1}>
              <Typography>
                Yes
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
                The PPE(Personal Protective Equipment) Available
              </Typography>
            </Grid>
            <Grid item sm={12} container>
              {availableEquipments.map((item, i) => (
                <Grid item sm={1} className="safety-checkboxes add-plan">
                  <Checkbox
                    color="primary"
                    checked={item.checked}
                    onChange={(e) => {
                      // let list = [...equipments]
                      // list[i].checked = e.target.checked;
                      // setEquipments([...list]);
                    }}
                    style={{ height: "36px", }}
                    value={item.name}
                    name={'safety-checkboxes'}
                    text={(
                      <Grid className={item.checked ? "safety-checkboxes-label safety-checkboxes-active" : "safety-checkboxes-label"} >
                        <img className="checkbox-img" src={item.image} width={70} style={{ width: '50px', height: '50px' }} />
                        <Typography>{item.name}</Typography>
                        <Done />
                      </Grid>
                    )}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item sm={4} style={{ display: "flex" }}>
            <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
              Tooling/Equipment Required :
            </Typography>
            <Typography>{planData?.equipmentRequired?.join()}</Typography>
          </Grid>
          <Grid item sm={4} style={{ display: "flex" }}>
            <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
              MSDS Issued :
            </Typography>
            <Typography>{planData?.msdsIssued}</Typography>
          </Grid>
          <Grid item sm={4} style={{ display: "flex" }}>
            <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
              TIP Level :
            </Typography>
            <Typography>{planData?.tipLevel}</Typography>
          </Grid>
          <Grid item sm={4} style={{ display: "flex" }}>
            <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
              Safe VIN/Serial No:
            </Typography>
            <Typography>{planData?.vin}</Typography>
          </Grid>
          <Grid item sm={4} style={{ display: "flex" }}>
            <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
              Criteria:
            </Typography>
            <Typography>{planData?.qantity}</Typography>
          </Grid>
        </>
      )} ></ProjectDetailComponent>
      <Grid container spacing={2} style={{ marginBottom: '2rem' }} className="what-how-why">
        <Grid item md={4} sm={12} style={{ display: 'flex', flexDirection: "column", textAlign: 'center', justifyContent: 'center' }}>
          <h3 item style={{ display: 'flex', justifyContent: 'center' }}>
            <ForwardIcon />
            What
          </h3>
          <Grid style={{ border: '1px solid #000', display: 'flex', justifyContent: 'center', width: '15rem', margin: '0 auto', height: '15rem' }}>
            <List dense={false} style={{ width: '100%' }}>
              {planData.activity?.map((a, i) => (
                <>
                  <ListItem
                    button
                    selected={i === selectedActivity.index}
                    onClick={(e) => setSelectedActivity({ ...selectedActivity, ...a, index: i })}
                  >
                    <ListItemText
                      primary={(i + 1) + '.' + a.what}
                      secondary={null}
                    />
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          </Grid>
        </Grid>
        <Grid item md={4} sm={12} style={{ display: 'flex', flexDirection: "column", textAlign: 'center', justifyContent: 'center' }}>
          <h3 item style={{ display: 'flex', justifyContent: 'center' }}><ForwardIcon />How</h3>
          <Grid style={{ border: '1px solid #000', display: 'flex', justifyContent: 'center', width: '15rem', margin: '0 auto', height: '15rem' }}>{selectedActivity.how}</Grid>
        </Grid>
        <Grid item md={4} sm={12} style={{ display: 'flex', flexDirection: "column", textAlign: 'center', justifyContent: 'center' }}>
          <h3 item style={{ display: 'flex', justifyContent: 'center' }}><ForwardIcon />Why</h3>
          <Grid item style={{ border: '1px solid #000', display: 'flex', justifyContent: 'center', width: '15rem', margin: '0 auto', height: '15rem' }}>{selectedActivity.why} </Grid>
        </Grid>
      </Grid>
      {planData.grid && planData.grid.images ?
        planData.grid.images.map((a, i) => (
          <Grid item container>
            <Grid item sm={3} style={{ textAlign: 'center' }}>
              <Typography>{a.side}</Typography>
            </Grid>
            <Grid item sm={7}>
              <Grid item
                // ref={gridRef}
                style={{
                  display: 'grid',
                  gridTemplateColumns: `repeat(${a.column}, 0fr)`,
                  gridTemplateRows: `repeat(${a.row}, 1fr)`,
                  gridGap: '5px',
                }}
              >

                {a.devideList.map((itm, j) => (
                  <Grid item style={{ position: 'relative' }}>
                    <img src={itm.imageBase64.indexOf('base64') != -1 ? itm.imageBase64 : axios.defaults.baseURL + '/file/' + itm.imageBase64} style={{ cursor: 'pointer', width: 100, height: 100 }} onClick={() => { handleCheckList(itm, i, j) }} />
                    <label style={{ position: 'absolute', left: '40%', top: '30%', fontWeight: 'bold', fontSize: 'xx-large', color: 'green' }}>{itm.tag}</label>
                  </Grid>
                ))}

              </Grid>

            </Grid>

            <Divider style={{ margin: '25px 0', width: '100%' }} />
          </Grid>
        ))
        :
        <Grid container spacing={2}>
          <Grid item container style={{ justifyContent: "center" }}>
            <img
              src={axios.defaults.baseURL + "/file/" + planData.part?.image}
              width={120}
              height={120}
            />
          </Grid>
          <Grid container item sm={12} style={{ justifyContent: 'center' }}>
            <Grid item md={6} sm={12} style={{}}>
              <Grid item
                style={{ background: "red", fontWeight: "bold", fontSize: "1.5rem", padding: '8px 15px', color: '#fff', width: '22rem', margin: '0 auto' }}
              >
                Not Ok Image (Example)
              </Grid>
              <Grid container spacing={2} style={{ margin: "1rem 0" }}>
                {planData.part?.illustration?.map((itm, i) => (
                  <Grid item sm={12} style={{ justifyContent: 'center', display: 'flex' }}>
                    <img
                      src={axios.defaults.baseURL + "/file/" + itm.notOkImage}
                      width={100}
                      height={100}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item sm={6} md={12} style={{}}>
              <Grid item
                style={{
                  background: "green",
                  fontWeight: "bold",
                  fontSize: "1.5rem", padding: '8px 15px', color: '#fff', width: '22rem', margin: '0 auto'
                }}
              >
                Ok Image (Example)
              </Grid>
              <Grid container spacing={2} style={{ margin: "1rem 0" }}>
                {planData.part?.illustration?.map((itm, i) => (
                  <Grid item sm={12} style={{ justifyContent: 'center', display: 'flex' }}>
                    <img
                      src={axios.defaults.baseURL + "/file/" + itm.okImage}
                      width={100}
                      height={100}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      <Grid index container style={{ marginTop: "1rem", justifyContent: 'center' }}>
        <ActionTable
          actions={false}
          header={TABLE_HEADER_PLAN}
          data={list}
          hazardColumn={false}
          controlMeasureColumn={false}
          color={true}
          onControlMeasure={(i) => {
            setDataKey("controlMeasure");
            setindex(i);
            handleClickOpen();
          }}
          onDeleteControlMeasure={(i) => {
            let upData = { controlMeasure: "" };
            dispatch(updateProjectAssessment(upData, data[i]._id));
          }}
        />
      </Grid>
      <Grid container index style={{ marginTop: "1rem", justifyContent: 'center' }}>
        <Grid index style={{ margin: "1rem 1rem" }}>
          <b> Assessment review details</b>
        </Grid>
        <ActionTable
          actions={false}
          header={TABLE_HEADER}
          data={data}
          hazardColumn={false}
          controlMeasureColumn={false}
          color={true}
          onControlMeasure={(i) => {
            setDataKey("controlMeasure");
            setindex(i);
            handleClickOpen();
          }}
          onDeleteControlMeasure={(i) => {
            let upData = { controlMeasure: "" };
            dispatch(updateProjectAssessment(upData, data[i]._id));
          }}
        />
      </Grid>
      <Grid container index style={{ marginTop: "1rem", justifyContent: 'center' }}>
        <div className="ui-component-block">
          <div className="ui-components">
            <Pagination
              count={projectAssessments.list.totalCount}
              page={page}
              setPage={setPage}
            />
          </div>
        </div>
      </Grid>
      <Grid index container>
        <Grid index style={{ margin: "1rem 1rem" }}>
          <b> Reference Criteria Example </b>
        </Grid>
        <Grid index container style={{ justifyContent: 'center' }}>
          {references.map((item, i) => (
            <Grid item sm={1} className="safety-checkboxes add-plan">
              <Checkbox
                color="primary"
                checked={planData.referenceCriteria === item.name}
                onChange={(e) => {
                  // let list = [...equipments]
                  // list[i].checked = e.target.checked;
                  // setEquipments([...list]);
                }}
                style={{ height: "36px", }}
                value={item.name}
                name={'safety-checkboxes'}
                text={(
                  <Grid className={planData.referenceCriteria === item.name ? "safety-checkboxes-label safety-checkboxes-active" : "safety-checkboxes-label"} >
                    <img className="checkbox-img" src={item.image} width={70} style={{ width: '50px', height: '50px' }} />
                    <Typography>{item.name}</Typography>
                    <Done />
                  </Grid>
                )}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid index container style={{ marginTop: "2rem", justifyContent: "center" }}>
        <Button
          onClick={(e) => {
            //   onEdit(i, row);
            // settoggle(true);
            handleClickOpenReview();
          }}
          text="Review & Submit"
          variant="contained"
          color="primary"
          style={{ marginBottom: ".5rem" }}
          disabled={!isValid}
        />
      </Grid>
      <div key={"div-2"}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Comment"}</DialogTitle>
          <DialogContent>
            <TextField
              variant="filled"
              label={"Comment"}
              value={comment}
              onChange={(e) => {
                // setField("partName", e.target.value);
                // setpartName(e.target.value);
                // console.log(e.target.value)
                setComment(e.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                console.log(comment, index, dataKey);
                let upData = { [dataKey]: comment };
                dispatch(updateProjectPlanAssessment(upData, list[index]._id));
                setComment("");
                handleClose();
              }}
              text="Submit"
              variant="contained"
              color="primary"
            />
          </DialogActions>
        </Dialog>
      </div>
      <div key={"div-3"}>
        <Dialog
          open={openReview}
          onClose={handleCloseReview}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Review"}</DialogTitle>
          <DialogContent>
            <Table
              header={TABLE_HEADER_1}
              data={list}
              actions={false}
              onStart={() => {
                // history.push("/action");
              }}
            />
            {equipments.filter((a) => a.checked).length ? (
              <Typography>
                <Typography style={{ display: "inline" }}>
                  Safety Equipments Available:{" "}
                </Typography>
                <Typography style={{ fontWeight: "bold", display: "inline" }}>
                  {equipments
                    .filter((a) => a.checked)
                    .map((a) => a.name)
                    .join()}
                </Typography>
              </Typography>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                setOpenReview(false);
                let everSign = planData.eversignList?.length
                  ? (planData.eversignList || []).find((a) => a._id)
                  : {};
                let signes = Object.keys(everSign.meta || []).filter((a) =>
                  a.includes("signer-")
                );
                console.log("signes---", signes);
                let signer = everSign.meta ? everSign.meta["signer-0"] : {};
                signes.map((it) => {
                  if (everSign.meta[it].userId === auth.data._id) {
                    signer = everSign.meta ? everSign.meta[it] : {};
                  }
                  return it;
                });
                console.log(signer);
                signer.document_hash = everSign.meta.document_hash;
                setEversignDetail(signer);
                setOpenTemplate(true);
              }}
              text="Submit and E-Sign"
              variant="contained"
              color="primary"
            />
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={openTemplate}
          // onClose={() => setOpenTemplate(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen
        >
          <DialogTitle id="alert-dialog-title">{"E-Sign Workplan Review"}</DialogTitle>
          <DialogContent style={{ padding: 0, overflow: "hidden" }}>
            {!iframeLoaded ? (
              <Grid
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "105vh",
                  margin: '0 auto',
                  background: "#ccc",
                  zIndex: 9,
                  overflow: "hidden",
                }}
              >
                <CircularProgress disableShrink style={{ margin: "30% 50%" }} />
              </Grid>
            ) : null}
            <Grid style={{ padding: '0 20px' }}>
              <Grid style={{ width: '50%', float: 'left' }}>
                <DialogContentText id="alert-dialog-description">
                  PIN: <strong>{eversignDetail.pin}</strong>{" "}
                  {/* <label style={{ color: "red", float: "right" }}>
                    {signError}
                  </label> */}
                </DialogContentText>
              </Grid>
              <Grid style={{ width: '50%', float: 'left' }}>
                <Button
                  onClick={(e) => {
                    setSignLoader(true);
                    let upData = {
                      meta: { related_document_hash: eversignDetail.document_hash },
                    };
                    dispatch(verifyPlanSign(upData));
                  }}
                  text="Verify E-Sign"
                  variant="contained"
                  color="primary"
                  customClass="float-right green-button"
                  startIcon={signLoader ? <CircularProgress size={30} style={{ color: '#fff' }} /> : null}
                />
              </Grid>
              <Iframe
                url={eversignDetail.embedded_signing_url}
                // width="650px"
                // height="650px"
                styles={{ width: "100%", height: "100vh", margin: "0 auto" }}
                id=""
                frameBorder={0}
                className=""
                onLoad={(e) => {
                  setIframeLoaded(true);
                }}
                display="block"
                position="relative"
              />
            </Grid>
          </DialogContent>
          {/* <DialogActions>
            <Button
              onClick={(e) => {
                setSignError("");
                let upData = {
                  meta: { related_document_hash: eversignDetail.document_hash },
                };
                dispatch(verifyPlanSign(upData));
              }}
              text="Verify E-Sign"
              variant="contained"
              color="primary"
            />
          </DialogActions> */}
        </Dialog>
      </div>
    </Grid>
  );
}
