import { 
  LIST_JOB_TYPE,
  GET_JOB_TYPE,
  ADD_JOB_TYPE,
  UPDATE_JOB_TYPE,
  DELETE_JOB_TYPE
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listJobTypes = (params) => async dispatch => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: 'GET',
      url: `/jobcard_type`,
      params
    });
    dispatch({
      type: LIST_JOB_TYPE,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: LIST_JOB_TYPE,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const getJobType = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/jobcard_type/${id}`,
    });
    dispatch({
      type: GET_JOB_TYPE,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: GET_JOB_TYPE,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addJobType = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/jobcard_type`,
      data
    });
    dispatch({
      type: ADD_JOB_TYPE,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: ADD_JOB_TYPE,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const updateJobType = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/jobcard_type/${id}`,
      data
    });
    dispatch({
      type: UPDATE_JOB_TYPE,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: UPDATE_JOB_TYPE,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const deleteJobType = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/jobcard_type/${id}`,
    });
    dispatch({
      type: DELETE_JOB_TYPE,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: DELETE_JOB_TYPE,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}