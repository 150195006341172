import {
  LIST_PROJECT,
  GET_PROJECT,
  ADD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  SIGN_PROJECT,
  LIST_PROJECT_REPORT,
  LIST_PROJECT_ACTIVITY,
  CLONE_PROJECT,
  RECOVER_PROJECT,
  PROJECT_REPORT_HISTORY
} from '../action_types';

const initialState = {
  list: {
    pagination: {},
    totalCount: 0,
    data: [],
    isLoading: true,
    hasError: false
  },
  detail: {
    data: {},
    isLoading: true,
    hasError: false
  }
}

const project = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LIST_PROJECT:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload
        }
      };
    case LIST_PROJECT_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          ...payload
        }
      };
    case PROJECT_REPORT_HISTORY:
      return {
        ...state,
        reportHistory: {
          ...state.reportHistory,
          ...payload
        }
      };
    case LIST_PROJECT_ACTIVITY:
      return {
        ...state,
        activity: {
          ...state.activity,
          ...payload
        }
      };
    case GET_PROJECT:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload
        }
      };
    case ADD_PROJECT:
      return {
        ...state,
        add: {
          ...state.add,
          ...payload,
          isLoading: false,
          hasError: payload.hasError,
        }
      }
    case CLONE_PROJECT:
      return {
        ...state,
        clone: {
          ...state.clone,
          ...payload,
          isLoading: false,
          hasError: payload.hasError,
        }
      }
    case UPDATE_PROJECT:
      return {
        ...state,
        update: {
          ...state.update,
          ...payload
        }
      }
    case RECOVER_PROJECT:
      return {
        ...state,
        recover: {
          ...state.recover,
          ...payload
        }
      }
    case SIGN_PROJECT:
      return {
        ...state,
        sign: {
          ...state.sign,
          ...payload
        }
      }
    case DELETE_PROJECT:
      return {
        ...state,
        delete: {
          ...state.delete,
          ...payload
        }
      }
    default: return state;
  }
}

export default project;