import {
  LIST_TEMPLATE,
  GET_TEMPLATE,
  ADD_TEMPLATE,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listTemplates = (params) => async dispatch => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: 'GET',
      url: `/assessment_templates`,
      params
    });
    dispatch({
      type: LIST_TEMPLATE,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: LIST_TEMPLATE,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const getTemplate = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/assessment_templates/${id}`,
    });
    dispatch({
      type: GET_TEMPLATE,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: GET_TEMPLATE,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addTemplate = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/assessment_templates`,
      data
    });
    dispatch({
      type: ADD_TEMPLATE,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: ADD_TEMPLATE,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const updateTemplate = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/assessment_templates/${id}`,
      data
    });
    dispatch({
      type: UPDATE_TEMPLATE,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_TEMPLATE,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const deleteTemplate = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/assessment_templates/${id}`,
    });
    dispatch({
      type: DELETE_TEMPLATE,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: DELETE_TEMPLATE,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}