import {
  LIST_PROJECT,
  GET_PROJECT,
  ADD_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  SIGN_PROJECT,
  LIST_PROJECT_REPORT,
  LIST_PROJECT_ACTIVITY,
  CLONE_PROJECT,
  RECOVER_PROJECT,
  PROJECT_REPORT_HISTORY
} from '../action_types';
import { callAPI } from '../../utils/apiHelper';

export const listProjects = (params) => async dispatch => {
  callAPI(dispatch, `/project`, 'GET', LIST_PROJECT, params, true);
}
export const listProjectReports = (params) => async dispatch => {
  callAPI(dispatch, `/project/report`, 'GET', LIST_PROJECT_REPORT, params, true);
}
export const projectReportHistory = ( params) => async dispatch => {
  callAPI(dispatch, `/project/reportHistory/`, 'GET', PROJECT_REPORT_HISTORY, params, true);
}
export const projectActivityReports = (params) => async dispatch => {
  callAPI(dispatch, `/project/activityReport`, 'GET', LIST_PROJECT_ACTIVITY, params, true);
}
export const getProject = (id) => async dispatch => {
  callAPI(dispatch, `/project/${id}`, 'GET', GET_PROJECT, {}, true);
}
export const cloneProject = (data) => async dispatch => {
  callAPI(dispatch, `/project/clone`, 'POST', CLONE_PROJECT, {}, false, data);
}

export const addProject = (data) => async dispatch => {
  callAPI(dispatch, `/project`, 'POST', ADD_PROJECT, {}, false, data);
}

export const updateProject = (data, id) => async dispatch => {
  callAPI(dispatch, `/project/${id}`, 'PUT', UPDATE_PROJECT, {}, false, data);
}
export const recoverProject = (data, id) => async dispatch => {
  callAPI(dispatch, `/project/${id}/recover`, 'PUT', RECOVER_PROJECT, {}, false, data);
}
export const verifyProjectSign = (data) => async dispatch => {
  callAPI(dispatch, `/project/verifyAgreement`, 'POST', SIGN_PROJECT, {}, false, data);
}

export const deleteProject = (id) => async dispatch => {
  callAPI(dispatch, `/project/${id}`, 'DELETE', DELETE_PROJECT, {}, false);
}
export const clearProjectData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_PROJECT,
    payload: {
      data: {},
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
};