const TABLE_HEADER = {
    name: {
        data: "Status",
        // width: "150px",
        align: 'left',
        style: { fontWeight: 'bold', fontSize: '16px' }
    },
    value: {
        data: "Value",
        // width: "150px",
        style: { fontWeight: 'bold',fontSize: '16px' }
    }
};
const TABLE_HEADER_ACTIVITY = {
    what: {
        data: "What",
        // width: "150px",
        // align: 'left',
        style: { fontWeight: 'bold', fontSize: '16px' }
    },
    how: {
        data: "How",
        // width: "150px",
        style: { fontWeight: 'bold',fontSize: '16px' }
    },
    why: {
        data: "Why",
        // width: "150px",
        style: { fontWeight: 'bold',fontSize: '16px' }
    }
};
const CHECKLIST_TABLE_HEADER = {
    // checkPoint: {
    //     data: "Check Point",
    //     // width: "150px",
    //     align: 'left',
    //     style: { fontWeight: 'bold', fontSize: '16px' }
    // },
    partView: {
        data: "Part",
        // width: "150px",
        align: 'left',
        style: { fontWeight: 'bold', fontSize: '16px' }
    },
    status: {
        data: "Status",
        // width: "150px",
        style: { fontWeight: 'bold',fontSize: '16px' }
    }
};
export { TABLE_HEADER,CHECKLIST_TABLE_HEADER,TABLE_HEADER_ACTIVITY }