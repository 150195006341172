import { Card, CardActionArea, CardContent, CardHeader, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import Tooltip from '../Tooltip';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  header: props => ({
    backgroundColor: props.header.background,
    color: props.header.color,
    width: props.header.width,
    textAlign: props.header.textAlign,
    height: props.header.height,
  }),
  content: props => ({
    textAlign: 'center',
    height: props.content.height,
    backgroundColor: props.content.backgroundColor,
  }),
  cardHeaderContent: {
    overflow: "hidden"
  }
});

const Index = ({
  styleObj = {},
  content='',
  title='Card Title',
  subheader='',
  Action=null,
  tooltipTitle='Card Action Button',
  header=true,
  to=null,
  ...rest
}) => {
  const classes = useStyles(styleObj);
  
  const cardContent = () =>  {
    let cc = <CardContent
      className={classes.content}
    >
      {content}
    </CardContent>

    if(to) {
      return <CardActionArea component={Link} to={to}>
        {cc}
      </CardActionArea>
    } else return cc;
  }

  return (
    <Card {...rest}>
      {
        header && <CardHeader 
          className={classes.header}
          classes={{
            content: classes.cardHeaderContent
          }}
          title={<Typography variant='h6' noWrap>{title}</Typography>}
          subheader={subheader}
          action={
            Action 
            ? <Tooltip
                arrow
                title={tooltipTitle}
                Component={Action} 
              />
            : null
          }
        >
          
        </CardHeader>
      }
      {
        cardContent()
      }
    </Card>
  )
}

export default Index;
