import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Divider,
  Grid, Hidden, useTheme,
  withStyles, Typography
} from '@material-ui/core';
import {
  TextField as CustomTextField,
  Button,
  AutoComplete as CustomAutoComplete,
  IconButton,
  DynamicForm, Table
} from '../../../commons/mui';
import useStyles from '../../../styles/jss/style';
import {
  TABLE_HEADER,
} from './CONSTANTS';
import {
  DIALOGS,
} from '../../../commons/CONSTANTS';
import { deleteVehicle, listVehicles } from '../../../redux/actions/vehicle';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const Index = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const vehicles = useSelector(state => state.vehicle);
  const [data, setData] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: 'sample',
    text: 'sample',
    open: false,
    btn1: {
      text: 'No',
      onClick: () => { }
    },
    btn2: {
      text: 'Yes',
      onClick: () => { }
    }
  });

  useEffect(() => {
    dispatch(listVehicles())
  }, []);

  useEffect(() => {
    const construct = async () => {
      if (vehicles.list.data && vehicles.list.data.length > 0) {
        let temp = await constructTableData(vehicles.list.data);
        setData(temp);
      }
    }
    construct();
  }, [vehicles]);

  const constructTableData = async (raw) => {
    let result = [];
    for (let i = 0; i < raw.length; i++) {
      let vehicle = raw[i];
      let row = {
        _id: vehicle._id,
        vin: vehicle.vin,
        tag: vehicle.tag || '',
        regNo: vehicle.regNo || '',
        platform: vehicle.platform?.name || '',
        make: vehicle.make?.name || '',
        model: vehicle.model?.name || '',
        variant: vehicle.variant?.name || '',
        year: vehicle.modelYear || '',
      }
      result.push(row);
    }
    return result;
  }

  const onEdit = (e, item, i) => {
    // alert(`Edit clicked on ${item.vin}`)
    history.push('/vehicle/edit/' + item._id)
  }

  const onDelete = (e, item, i) => {
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: 'Please confirm to delete',
      text: `You are about to delete vehicle "${i.vin}".  Are you sure ?`,
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => {

          dispatch(deleteVehicle(i._id))
          setTimeout(() => {
            dispatch(listVehicles())
          }, 500)
        },
      }
    }));
  }

  return (
    <Grid container className={`${classes.paddingX} ${classes.stretchHeight}`}>
      <Grid item container className={`${classes.height5}`}>
        <Typography variant='h6' align='left' gutterBottom>List of Vehicles</Typography>
      </Grid>
      <Grid item container className={`${classes.height95}`} direction='column'>
        <Grid item container direction='row' className={`${classes.fullWidth} ${classes.stretchHeight}`} >
          <Grid item container xs={12} direction='row' className={`${classes.stretchHeight}`}>
            <div className={`${classes.fullWidth} ${classes.stretchHeight}`}>
              <Grid item container xs={12} justifyContent='space-between' className={`${classes.height90} ${classes.hideScrollbar}`} style={{ overflowY: 'scroll', whiteSpace: 'nowrap' }}>
                <Table
                  header={TABLE_HEADER}
                  data={data}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  editPrefix='vehicle/edit/'
                />
              </Grid>
              <Grid item container xs={12} className={`${classes.height10}`} direction='column' alignItems='center' justifyContent='center'>
                <Grid item container xs={6} justifyContent='flex-end'>
                  {/* <Button text='Cancel' variant='contained'/> */}
                </Grid>
                <Grid item container xs={6} justifyContent='flex-start'>
                  {/* <Button type='submit' text='Submit' variant='contained' color='primary'/> */}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
      {
        DIALOGS.CONFIRM_DIALOG({
          title: showConfirmDialog.title,
          text: showConfirmDialog.text,
          open: showConfirmDialog.open,
          setOpen: setShowConfirmDialog,
          btn1: showConfirmDialog.btn1,
          btn2: showConfirmDialog.btn2,
          container: `${classes.paddingX}`,
        })
      }
    </Grid>
  )
}

export default Index