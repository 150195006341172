import {
  LIST_TECHNICIAN_WORK_PLAN,
  GET_TECHNICIAN_WORK_PLAN,
  ADD_TECHNICIAN_WORK_PLAN,
  UPDATE_TECHNICIAN_WORK_PLAN,
  DELETE_TECHNICIAN_WORK_PLAN,
  LIST_TECHNICIAN_WORK_PLAN_REPORT,
} from "../action_types";
import axios from "axios";
// import { QS } from '../../utils/urlHelper';

export const listTechnicianPlans = (params) => async (dispatch) => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: "GET",
      url: `/technician_workplan`,
      params,
    });
    dispatch({
      type: LIST_TECHNICIAN_WORK_PLAN,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        meta: res.data.meta,
        isLoading: false,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: LIST_TECHNICIAN_WORK_PLAN,
      payload: {
        data: [],
        meta: err?.data?.meta,
        isLoading: false,
        hasError: true,
      },
    });
  }
};
export const listTechnicianPlanReports = (params) => async (dispatch) => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: "GET",
      url: `/technician_workplan/report`,
      params,
    });
    dispatch({
      type: LIST_TECHNICIAN_WORK_PLAN_REPORT,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        meta: res.data.meta,
        isLoading: false,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: LIST_TECHNICIAN_WORK_PLAN_REPORT,
      payload: {
        data: [],
        meta: err?.data?.meta,
        isLoading: false,
        hasError: true,
      },
    });
  }
};

export const getTechnicianPlan = (id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "GET",
      url: `/technician_workplan/${id}`,
    });
    dispatch({
      type: GET_TECHNICIAN_WORK_PLAN,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        meta: res.data.meta,
        isLoading: false,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: GET_TECHNICIAN_WORK_PLAN,
      payload: {
        data: {},
        meta: err?.data?.meta,
        isLoading: false,
        hasError: true,
      },
    });
  }
};

export const addTechnicianPlan = (data) => async (dispatch) => {
  try {
    let res = await axios({
      method: "POST",
      url: `/technician_workplan`,
      data,
    });
    dispatch({
      type: ADD_TECHNICIAN_WORK_PLAN,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        meta: res.data.meta,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: ADD_TECHNICIAN_WORK_PLAN,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      },
    });
  }
};

export const updateTechnicianPlan = (data, id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "PUT",
      url: `/technician_workplan/${id}`,
      data,
    });
    dispatch({
      type: UPDATE_TECHNICIAN_WORK_PLAN,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        meta: res.data.meta,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: UPDATE_TECHNICIAN_WORK_PLAN,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      },
    });
  }
};


export const deleteTechnicianPlan = (id) => async (dispatch) => {
  try {
    let res = await axios({
      method: "DELETE",
      url: `/technician_workplan/${id}`,
    });
    dispatch({
      type: DELETE_TECHNICIAN_WORK_PLAN,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        meta: res.data.meta,
        hasError: false,
      },
    });
  } catch (err) {
    dispatch({
      type: DELETE_TECHNICIAN_WORK_PLAN,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      },
    });
  }
};
export const clearTechnicianPlanData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_TECHNICIAN_WORK_PLAN,
    payload: {
      data: null,
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
}; 
