const FORMCONFIG = [
  {
    name: 'platform',
    component: 'Autocomplete',
    source: 'platforms',
    props: {
      name:'platform',
      label:'Platform',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      required: true,
      customOptionLabel: (option) => option.name,
    },
    dialog:{
      title: 'Add new platform',
      model: [
        {
          name: 'name',
          component: 'Textfield',
          props: {
            name: 'name',
            label: 'Name',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            required: true,
          },
          dialog: false,
        },
        {
          name: 'description',
          component: 'Textfield',
          props: {
            name: 'description',
            label: 'Description',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
          },
          dialog: false,
        },
      ]
    }
  },
  {
    name: 'make',
    component: 'Autocomplete',
    source: 'makes',
    props: {
      name:'make',
      label:'Make',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      required: true,
      customOptionLabel: (option) => option.name
    },
    dialog:{
      title: 'Add new make',
      model: [
        {
          name: 'name',
          component: 'Textfield',
          props: {
            name: 'name',
            label: 'Name',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            required: true,
          },
          dialog: false,
        },
        {
          name: 'description',
          component: 'Textfield',
          props: {
            name: 'description',
            label: 'Description',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
          },
          dialog: false,
        },
        // {
        //   name: 'platform',
        //   component: 'Textfield',
        //   props: {
        //     name: 'platform',
        //     label: 'Platform',
        //     variant: 'filled',
        //     color: 'primary',
        //     fullWidth: true,
        //     disabled: true,
        //   },
        //   dialog: false,
        // },
      ]
    }
  },
  {
    name: 'model',
    component: 'Autocomplete',
    source: 'models',
    props: {
      name:'model',
      label:'Model',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      required: true,
      customOptionLabel: (option) => option.name
    },
    dialog:{
      title: 'Add new model',
      model: [
        {
          name: 'name',
          component: 'Textfield',
          props: {
            name: 'name',
            label: 'Name',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            required: true,
          },
          dialog: false,
        },
        {
          name: 'description',
          component: 'Textfield',
          props: {
            name: 'description',
            label: 'Description',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
          },
          dialog: false,
        },
        // {
        //   name: 'make',
        //   component: 'Textfield',
        //   props: {
        //     name: 'Make',
        //     label: 'Make',
        //     variant: 'filled',
        //     color: 'primary',
        //     fullWidth: true,
        //     disabled: true,
        //   },
        //   dialog: false,
        // },
      ]
    }
  },
  {
    name: 'variant',
    component: 'Autocomplete',
    source: 'variants',
    props: {
      name:'variant',
      label:'Variant',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      required: true,
      customOptionLabel: (option) => option.name
    },
    dialog:{
      title: 'Add new variant',
      model: [
        {
          name: 'name',
          component: 'Textfield',
          props: {
            name: 'name',
            label: 'Name',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            required: true,
          },
          dialog: false,
        },
        {
          name: 'description',
          component: 'Textfield',
          props: {
            name: 'description',
            label: 'Description',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
          },
          dialog: false,
        },
        // {
        //   name: 'model',
        //   component: 'Textfield',
        //   props: {
        //     name: 'model',
        //     label: 'Model',
        //     variant: 'filled',
        //     color: 'primary',
        //     fullWidth: true,
        //     disabled: true,
        //   },
        //   dialog: false,
        // },
      ]
    }
  },
  {
    name: 'vin',
    component: 'Textfield',
    props: {
      name: 'vin',
      label: 'VIN',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      required: true,
      inputProps: {style:{"text-transform": "uppercase"}}
    },
    dialog: false,
  },
  {
    name: 'regNo',
    component: 'Textfield',
    props: {
      name: 'regNo',
      label: 'Registration No',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      inputProps: {style:{"text-transform": "uppercase"}}
    },
    dialog: false,
  },
  {
    name: 'tag',
    component: 'Textfield',
    props: {
      name: 'tag',
      label: 'TAG',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      inputProps: {style:{"text-transform": "uppercase"}}
    },
    dialog: false,
  },
  {
    name: 'chipId',
    component: 'Textfield',
    props: {
      name: 'chipId',
      label: 'Chip ID',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
    },
    dialog: false,
  },
  {
    name: 'modelYear',
    component: 'Textfield',
    props: {
      name: 'modelYear',
      label: 'Model Year',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      InputProps:{
        inputProps: {
          min:1800,
          max:2200,
        }
      },
      type:'number'
    },
    dialog: false,
  },
  {
    name: 'bodyStyle',
    component: 'Textfield',
    props: {
      name: 'bodyStyle',
      label: 'Body Style',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
    },
    dialog: false,
  },
  {
    name: 'engine',
    component: 'Textfield',
    props: {
      name: 'engine',
      label: 'Engine',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
    },
    dialog: false,
  },
  {
    name: 'transmission',
    component: 'Textfield',
    props: {
      name: 'transmission',
      label: 'Transmission',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
    },
    dialog: false,
  },
  {
    name: 'slNo',
    component: 'Textfield',
    props: {
      name: 'slNo',
      label: 'Serial No',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      type:'number',
      InputProps:{
        inputProps: {
          min:0,
        }
      },
    },
    dialog: false,
  },
  {
    name: 'fuelType',
    component: 'Textfield',
    props: {
      name: 'fuelType',
      label: 'Fuel Type',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
    },
    dialog: false,
  },
  {
    name: 'color',
    component: 'Textfield',
    props: {
      name: 'color',
      label: 'Color',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
    },
    dialog: false,
  },
  {
    name: 'market',
    component: 'Textfield',
    props: {
      name: 'market',
      label: 'Market',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
    },
    dialog: false,
  },
];

const createVehicle = (title, id, platform, make, model, variant, vin, regNo, tag, chipId,
   modelYear, bodyStyle, engine, transmission, slNo, fuelType, color, market ) =>
   ({title, id, platform, make, model, variant, vin, regNo, tag, chipId, 
    modelYear, bodyStyle, engine, transmission, slNo, fuelType, color, market, type:'vehicle'});

const TYPE_COLORS = {
  vehicle: '#513535',
  platform: '#BD845B',
  make: '#9AAEBF',
  model: '#40465C',
  variant: '#C5AD7E',
}

const HIERARCHY_TABLE = [
  'platform',
  'make',
  'model',
  'variant'
]
const SAMPLE_DATA = {
  VEHICLES: [
    createVehicle('Vehicle 1', 0, 3, 2, 1, 4, 'VIN 1', 'HAT 419', 'WAIODN', 'chipId 1', 2019, 'Sedan', 'Hybrid','Automatic', 1920392892, 'Hybrid', 'red', 'market 1'),
    createVehicle('Vehicle 2', 1, 5, 1, 6, 6, 'VIN 2', 'RES 856', 'DOWIAHD', 'chipId 2', 2020, 'Sedan', 'Hybrid', 'Manual', 1920354878, 'Hybrid', 'black', 'market 2'),
    createVehicle('Vehicle 3', 2, 5, 1, 2, 2, 'VIN 3', 'HML 168', 'IDUIWAUBD', 'chipId 3', 2020, 'Hatchback', 'Electric', 'Manual', 65158489647, 'Electric', 'grey', 'market 3'),
  ],
  PLATFORMS: [
    {title: 'Platform 1', type:'platform', id: 0, name: 'Platform 1', description: 'Description for Platform 1'},
    {title: 'Platform 2', type:'platform', id: 1, name: 'Platform 2', description: 'Description for Platform 2'},
    {title: 'Platform 3', type:'platform', id: 2, name: 'Platform 3', description: 'Description for Platform 3'},
    {title: 'Platform 4', type:'platform', id: 3, name: 'Platform 4', description: 'Description for Platform 4'},
    {title: 'Platform 5', type:'platform', id: 4, name: 'Platform 5', description: 'Description for Platform 5'},
    {title: 'Platform 6', type:'platform', id: 5, name: 'Platform 6', description: 'Description for Platform 6'},
    {title: 'Platform 7', type:'platform', id: 6, name: 'Platform 7', description: 'Description for Platform 7'}
  ],
  MAKES: [
    {title: 'Make 1', platformId: 0, type:'make', id: 0, name: 'Make 1', description: 'Description for Make 1'},
    {title: 'Make 2', platformId: 5, type:'make', id: 1, name: 'Make 2', description: 'Description for Make 2'},
    {title: 'Make 3', platformId: 3, type:'make', id: 2, name: 'Make 3', description: 'Description for Make 3'},
    {title: 'Make 4', platformId: 1, type:'make', id: 3, name: 'Make 4', description: 'Description for Make 4'},
    {title: 'Make 5', platformId: 3, type:'make', id: 4, name: 'Make 5', description: 'Description for Make 5'},
    {title: 'Make 6', platformId: 5, type:'make', id: 5, name: 'Make 6', description: 'Description for Make 6'},
    {title: 'Make 7', platformId: 0, type:'make', id: 6, name: 'Make 7', description: 'Description for Make 7'}
  ],
  MODELS: [
    {title: 'Model 1', makeId: 3, type:'model', id: 0, name: 'Model 1', description: 'Description for Model 1'},
    {title: 'Model 2', makeId: 2, type:'model', id: 1, name: 'Model 2', description: 'Description for Model 2'},
    {title: 'Model 3', makeId: 1, type:'model', id: 2, name: 'Model 3', description: 'Description for Model 3'},
    {title: 'Model 4', makeId: 1, type:'model', id: 3, name: 'Model 4', description: 'Description for Model 4'},
    {title: 'Model 5', makeId: 6, type:'model', id: 4, name: 'Model 5', description: 'Description for Model 5'},
    {title: 'Model 6', makeId: 0, type:'model', id: 5, name: 'Model 6', description: 'Description for Model 6'},
    {title: 'Model 7', makeId: 1, type:'model', id: 6, name: 'Model 7', description: 'Description for Model 7'}
  ],
  VARIANTS: [
    {title: 'Variant 1', modelId: 6, type:'variant', id: 0, name: 'Variant 1', description: 'Description for Variant 1'},
    {title: 'Variant 2', modelId: 3, type:'variant', id: 1, name: 'Variant 2', description: 'Description for Variant 2'},
    {title: 'Variant 3', modelId: 2, type:'variant', id: 2, name: 'Variant 3', description: 'Description for Variant 3'},
    {title: 'Variant 4', modelId: 1, type:'variant', id: 3, name: 'Variant 4', description: 'Description for Variant 4'},
    {title: 'Variant 5', modelId: 1, type:'variant', id: 4, name: 'Variant 5', description: 'Description for Variant 5'},
    {title: 'Variant 6', modelId: 1, type:'variant', id: 5, name: 'Variant 6', description: 'Description for Variant 6'},
    {title: 'Variant 7', modelId: 6, type:'variant', id: 6, name: 'Variant 7', description: 'Description for Variant 7'}
  ]
}

const MAPPED_OBJECTS = {
  'platform': 0,
  'make': 1,
  'model': 2,
  'variant': 3,
};

export {
  FORMCONFIG,
  MAPPED_OBJECTS,
  SAMPLE_DATA,
  TYPE_COLORS,
  HIERARCHY_TABLE,
  createVehicle,
}