import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid, useTheme, Typography, Link, Card, CardContent, DialogActions, DialogContentText, DialogContent, DialogTitle, Dialog
} from '@material-ui/core';



import Bay from '../../../Cards/Bay';
import { clearTechnicianPlanData, listTechnicianPlanReports } from '../../../../redux/actions/technicianPlan';
import { GET_WORK_PLAN, LIST_WORK_PLAN_REPORT } from '../../../../redux/action_types';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { ArrowBack } from '@material-ui/icons';
import { Button, IconButton, Table } from '../../../../commons/mui';
import { clearPlanData, getPlan } from '../../../../redux/actions/projectPlan';
import ReactApexChart from "react-apexcharts";
import { TABLE_HEADER } from './CONSTANTS';
import { CUSTOMER } from '../../../Projects/AllProjects/CONSTANTS';

const Index = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const technicianPlan = useSelector((state) => state.technicianPlan);
  const projectPlans = useSelector((state) => state.projectPlan);
  const localCompany = useSelector((state) => state.localCompany);
  const auth = useSelector((state) => state.auth);
  const [page, setPage] = useState(null);
  const [nokReview, setNokReview] = useState(false);
  const [noOkList, setNokList] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [plan, setPlan] = useState({});
  const { projectId, planId } = useParams();

  useEffect(() => {
    let query = {};
    if (projectId) {
      query.project = projectId;
    }
    if (planId) {
      query.workPlanId = planId;
      dispatch(getPlan(planId));
    }
    if (page)
      query.skip = 10 * (page - 1)
    dispatch(listTechnicianPlanReports(query));
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [localCompany]);
  useEffect(() => {
    if (projectPlans.detail?.data?._id) {
      dispatch(clearPlanData(GET_WORK_PLAN))
      setPlan({ ...plan, ...projectPlans.detail?.data })
    }
  }, [projectPlans]);

  useEffect(() => {
    if (technicianPlan.reports?.data?.length) {
      dispatch(clearTechnicianPlanData(LIST_WORK_PLAN_REPORT))
      setReportList(technicianPlan.reports?.data.map(a => {
        a.completedPercent = ((a.completedQuantity * 100) / a.target).toFixed(2)
        return a;
      }))
    }
  }, [technicianPlan]);


  return (
    <Grid container spacing={2}>
      <Grid item container >
        <Typography variant='h6' align='left' gutterBottom>
          <IconButton
            color="primary"
            onClick={() => history.goBack()}
            icon=<ArrowBack />
          >
          </IconButton>
          List of Inspectors</Typography>
      </Grid>
      {reportList.map(item => (
        <Grid item container sm={12} direction='row'>
          <h4 style={{ width: '100%' }} className="ui-component-headings">{item.userId.firstName + (item.userId.lastName ? ' ' + item.userId.lastName : '')}</h4>
          <Grid item container spacing={2} sm={12} style={{ justifyContent: 'center' }}>
            <Grid item sm={4} >
              <Grid item className="ui-component-block">
                <Grid item className="ui-components">
                  <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                    <Bay title='Completed' color={item.completedPercent < 25 ? theme.palette.red : item.completedPercent < 75 ? theme.palette.amber : theme.palette.green} titleAlign='center' width='250px' height='80px' content={<Grid item>{item.completedQuantity + '/' + item.target}</Grid>} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4} >
              <Grid item className="ui-component-block">
                <Grid item className="ui-components">
                  <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                    <Bay title='Ok' color={theme.palette.green} titleAlign='center' width='250px' height='80px' content={<Grid item>{item.totalOk}</Grid>} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4} >
              <Grid item className="ui-component-block">
                <Grid item className="ui-components">
                  <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                    <Bay title='Not OK' color={theme.palette.error} titleAlign='center' width='250px' height='80px' content={<Grid item><Link style={{ cursor: 'pointer' }} onClick={() => { setNokList(item.nokHistory); setNokReview(true) }}>{item.totalNok}</Link></Grid>} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {item.totalQuantity ?
              <>
                <Grid item sm={6} >
                  <Card>
                    <CardContent style={{ minHeight: '32rem' }}>
                      <ReactApexChart options={{
                        chart: {
                          width: 300,
                          type: 'donut',
                        },
                        labels: ['Pending', 'Ok', 'Nok'],
                        colors: ['#FFC300', '#00E396', '#F44336'],
                        fill: {
                          type: 'gradient',
                        },
                        title: {
                          text: 'Performance',
                          align: 'center'
                        },
                        plotOptions: {
                          pie: {
                            startAngle: -90,
                            endAngle: 270
                          }
                        },
                        legend: {
                          position: 'bottom',
                          show: true
                        }
                      }} series={[item.target - (item.totalOk + item.totalNok), item.totalOk, item.totalNok]} type="donut" />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item sm={6} >
                  <Card>
                    <CardContent style={{ minHeight: '32rem' }}>
                      <ReactApexChart
                        options={{
                          chart: {
                            type: 'bar',
                            height: 400
                          },
                          title: {
                            text: 'Performance History',
                            align: 'center'
                          },
                          plotOptions: {
                            bar: {
                              horizontal: false,
                              columnWidth: '60%',
                              endingShape: 'rounded'
                            },
                          },
                          dataLabels: {
                            enabled: false
                          },
                          stroke: {
                            show: true,
                            width: 2,
                            colors: ['transparent']
                          },
                          xaxis: {
                            categories: item.history.map(a => a.date),
                          },
                          yaxis: {
                            title: {
                              text: 'Pieces'
                            }
                          },
                          colors: ['#008FFB', '#00E396', '#F44336'],
                          fill: {
                            opacity: 1
                          },
                          tooltip: {
                            y: {
                              formatter: function (val) {
                                return val + " pieces"
                              }
                            }
                          }
                        }}
                        series={[
                          { name: 'Target', data: item.history.map(a => a.target) },
                          { name: 'Ok', data: item.history.map(a => a.ok) },
                          { name: 'Not Ok', data: item.history.map(a => a.nok) }
                        ]}
                        type="bar"
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </>
              : null}
          </Grid>
        </Grid>
      ))
      }
      <Dialog
        open={nokReview}
        onClose={() => setNokReview(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Not Ok Comments Review"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table
              header={TABLE_HEADER}
              data={noOkList}
              actions={false}
              color={true}
              onStart={() => {
                // history.push("/action");
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setNokReview(false)
            }}
            text="Ok"
            variant="contained"
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </Grid >
  )
}

export default Index