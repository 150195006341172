import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid, useTheme, Typography, Link, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';



import Bay from '../../../Cards/Bay';
import { clearProjectData, projectActivityReports } from '../../../../redux/actions/project';
import { LIST_PROJECT_ACTIVITY } from '../../../../redux/action_types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { TABLE_HEADER } from './CONSTANTS';
import ReactApexChart from "react-apexcharts";
import { Button, Table } from '../../../../commons/mui';
import { CUSTOMER } from '../../../Projects/AllProjects/CONSTANTS';


const Index = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.project);
  const localCompany = useSelector((state) => state.localCompany);
  const auth = useSelector((state) => state.auth);
  const [nokReview, setNokReview] = useState(false);
  const [noOkList, setNokList] = useState([]);
  const [reportList, setReportList] = useState({ total: 0, open: 0, closed: 0 });

  useEffect(() => {
    console.log(localCompany)
    if (localCompany.companyId?.data)
      setTimeout(() => {
        dispatch(projectActivityReports({}));
      }, 500);
  }, [localCompany]);



  useEffect(() => {
    if (projects.activity?.data?.total) {
      dispatch(clearProjectData(LIST_PROJECT_ACTIVITY))
      setReportList({ ...reportList, ...projects.activity?.data })
    }
  }, [projects]);
  const goToDetail = (item) => {
    history.push((auth.data?.role?.name===CUSTOMER?'/customer':'/brm')+'-projection/' + item)
  }

  return (
    <Grid container spacing={2}>
      <Grid item container >
        <Typography item variant='h6' align='left' gutterBottom>Activity</Typography>
      </Grid>
      <Grid item container sm={12} direction='row'>
        <Typography item variant='h6' key={'heading'} style={{ width: '100%' }} className="ui-component-headings">Project Activity </Typography>
        <Grid item container spacing={2} sm={12} style={{ justifyContent: 'center' }}>
          <Grid item sm={4} >
            <Grid item className="ui-component-block">
              <Grid item className="ui-components">
                <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                  <Bay key={'test-1-1-1-1'} title='Total Projects' color={theme.palette.primary} titleAlign='center' width='250px' height='80px' content={<Link style={{ cursor: 'pointer' }} onClick={() => goToDetail('all')}>{reportList.total}</Link>} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} >
            <Grid item className="ui-component-block">
              <Grid item className="ui-components">
                <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                  <Bay key={'test-2-1-1-1'} title='Open' color={theme.palette.amber} titleAlign='center' width='250px' height='80px' content={<Link style={{ cursor: 'pointer' }} onClick={() => goToDetail('open')}>{reportList.open}</Link>} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} >
            <Grid item className="ui-component-block">
              <Grid item className="ui-components">
                <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                  <Bay key={'test-2-1-1-1'} title='Closed' color={theme.palette.green} titleAlign='center' width='250px' height='80px' content={<Link style={{ cursor: 'pointer' }} onClick={() => goToDetail('closed')}>{reportList.closed}</Link>} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} >
            <Grid item className="ui-component-block">
              <Grid item className="ui-components">
                <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                  <Bay key={'test-1-1-1-1'} title='TIP Level 1' color={theme.palette.primary} titleAlign='center' width='250px' height='80px' content={<Link style={{ cursor: 'pointer' }} onClick={() => goToDetail('tipLevel1')}>{reportList.tipLevel1}</Link>} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} >
            <Grid item className="ui-component-block">
              <Grid item className="ui-components">
                <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                  <Bay key={'test-1-1-1-1'} title='TIP Level 2' color={theme.palette.primary} titleAlign='center' width='250px' height='80px' content={<Link style={{ cursor: 'pointer' }} onClick={() => goToDetail('tipLevel2')}>{reportList.tipLevel2}</Link>} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} >
            <Grid item className="ui-component-block">
              <Grid item className="ui-components">
                <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                  <Bay key={'test-1-1-1-1'} title='TIP Level 3' color={theme.palette.primary} titleAlign='center' width='250px' height='80px' content={<Link style={{ cursor: 'pointer' }} onClick={() => goToDetail('tipLevel3')}>{reportList.tipLevel3}</Link>} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} >
            <Grid item className="ui-component-block">
              <Grid item className="ui-components">
                <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                  <Bay key={'test-2-1-1-1'} title='TIP Level 4' color={theme.palette.primary} titleAlign='center' width='250px' height='80px' content={<Link style={{ cursor: 'pointer' }} onClick={() => goToDetail('tipLevel4')}>{reportList.tipLevel4}</Link>} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} >
            <Grid item className="ui-component-block">
              <Grid item className="ui-components">
                <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                  <Bay key={'test-2-1-1-1'} title='TIP Level 5' color={theme.palette.red} titleAlign='center' width='250px' height='80px' content={<Link style={{ cursor: 'pointer' }} onClick={() => goToDetail('tipLevel5')}>{reportList.tipLevel5}</Link>} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} >
            <Grid item className="ui-component-block">
              <Grid item className="ui-components">
                <Grid item style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                  <Bay key={'test-2-1-1-1'} title='TIP Level 5+' color={theme.palette.red} titleAlign='center' width='250px' height='80px' content={<Link style={{ cursor: 'pointer' }} onClick={() => goToDetail('tipLevel5+')}>{reportList.tipLevel6}</Link>} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} >
            <Card>
              <CardContent style={{ minHeight: '32rem' }}>
                <ReactApexChart options={{
                  chart: {
                    width: 300,
                    type: 'donut',
                  },
                  labels: ['Open Projects', 'Closed Projects', 'Draft Projects'],
                  colors: ['#FFC300', '#00E396', '#F44336'],
                  fill: {
                    type: 'gradient',
                  },
                  title: {
                    text: 'Activity',
                    align: 'center'
                  },
                  plotOptions: {
                    pie: {
                      startAngle: -90,
                      endAngle: 270
                    }
                  },
                  legend: {
                    position: 'bottom',
                    show: true
                  }
                }} series={[reportList.open, reportList.closed, reportList.total - (reportList.open + reportList.closed)]} type="donut" />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={nokReview}
        onClose={() => setNokReview(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Not Ok Comments Review"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table
              header={TABLE_HEADER}
              data={noOkList}
              actions={false}
              color={true}
              onStart={() => {
                // history.push("/action");
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setNokReview(false)
            }}
            text="Ok"
            variant="contained"
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </Grid >
  )
}

export default Index