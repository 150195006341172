const TABLE_HEADER = {
    name: {
        data: "Company Name",
        width: "150px",
    },
    userName: {
        data: "BRM",
        width: "150px",
    },
    customerName: {
        data: "Customer",
        width: "150px",
    },
    address: {
        data: "Address",
        width: "150px",
    },
    // logo: {
    //     data: 'Logo',
    //     width: '150px',
    //     component: 'img'
    // },
    companyLogin: {
        data: 'Company Login',
        width: '150px',
        // component: 'link'
    },
    users: {
        data: 'Users',
        width: '100px',
        component: 'link',
        label:'Users'
    },
    // userRoles: {
    //     data: 'User Roles',
    //     width: '100px',
    //     component: 'link',
    //     label:'Roles'
    // }
};

export { TABLE_HEADER };
