import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  searchACLeftRender: {
    flexGrow: 1,
  },
  searchACRightRender: {
    minWidth: '60px',
    textAlign: 'right',
    color: theme.palette.secondary.light,
  }  
}));

export default useStyles;