import { Input, InputAdornment, InputLabel } from "@material-ui/core";
import React from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export default function Index({
  id = Math.random(),
  variant = "standard",
  customClass = "",
  label = "",
  options = [],
  optionInfo = { value: "value", label: "label" },
  ...rest
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <InputLabel htmlFor="standard-adornment-password" style={{fontSize: '.9rem'}}>{label}</InputLabel>
      <Input
        {...rest}
        id="standard-adornment-password"
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <div
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              style={{cursor: 'pointer'}}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </div>
          </InputAdornment>
        }
      />
    </div>
  );
}
