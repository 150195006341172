import {
  LIST_ACCIDENT,
  GET_ACCIDENT,
  ADD_ACCIDENT,
  UPDATE_ACCIDENT,
  DELETE_ACCIDENT,
  REPORT_ACCIDENT
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listAccidents = (params) => async dispatch => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: 'GET',
      url: `/accident`,
      params
    });
    dispatch({
      type: LIST_ACCIDENT,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: LIST_ACCIDENT,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      }
    });
  }
}
export const reportAccident = (params) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/accident/report`,
      params
    });
    dispatch({
      type: REPORT_ACCIDENT,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: REPORT_ACCIDENT,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}
export const getAccident = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/accident/${id}`,
    });
    dispatch({
      type: GET_ACCIDENT,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: GET_ACCIDENT,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addAccident = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/accident`,
      data
    });
    dispatch({
      type: ADD_ACCIDENT,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: ADD_ACCIDENT,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const updateAccident = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/accident/${id}`,
      data
    });
    dispatch({
      type: UPDATE_ACCIDENT,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_ACCIDENT,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const deleteAccident = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/accident/${id}`,
    });
    dispatch({
      type: DELETE_ACCIDENT,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: DELETE_ACCIDENT,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}