import {
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Pagination, Table } from "../../../commons/mui";
import { TABLE_HEADER } from "./CONSTANTS";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { clearPartData, deletePart, listParts } from "../../../redux/actions/part";
import axios from "axios";
import { DIALOGS } from "../../../commons/CONSTANTS";
import useStyles from "../../../styles/jss/style";
import { DELETE_PART, LIST_PART } from "../../../redux/action_types";


export default function Index() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const parts = useSelector((state) => state.parts);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: "sample",
    text: "sample",
    open: false,
    btn1: {
      text: "No",
      onClick: () => { },
    },
    btn2: {
      text: "Yes",
      onClick: () => { },
    },
  });

  // useEffect(() => {
  //   dispatch(listParts());
  // }, [])
  useEffect(() => {
    if (parts?.list?.data?.length) {
      dispatch(clearPartData(LIST_PART));
      setData(parts.list?.data.map(a => { a.image = axios.defaults.baseURL + "/file/" + a.image; return a; }));
    }
    if (parts?.delete?.data._id) {
      dispatch(clearPartData(DELETE_PART));
      dispatch(listParts({ skip: 10 * (page - 1) }));
    }
  }, [parts]);
  useEffect(() => {
    dispatch(listParts({ skip: 10 * (page - 1) }));
  }, [page]);
  const onEdit = (i, row) => { history.push('/edit/part/' + row._id) }
  const onDelete = (i, e, item) => {
    // console.log(item, i, item.name || item.projectNo)
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: "Please confirm to delete",
      text: `You are about to delete part.  Are you sure ?`,
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => {
          dispatch(deletePart(item._id));
          setTimeout(() => {
            listParts({ skip: (page - 1) * 10 })
          }, 500);
        },

      },
    }));
  };

  return (
    <Grid container>
      <Grid item sm={12}>
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "3.5rem",
            alignItems: "center",
            marginBottom: 20
          }}
          item
        >
          <h2>Part List</h2>
          <Button
            width="200px"
            text="Add Part"
            variant="contained"
            color="primary"
            onClick={() => history.push("/part/create")}
          />
        </Grid>
      </Grid>
      {DIALOGS.CONFIRM_DIALOG({
        title: showConfirmDialog.title,
        text: showConfirmDialog.text,
        open: showConfirmDialog.open,
        setOpen: setShowConfirmDialog,
        btn1: showConfirmDialog.btn1,
        btn2: showConfirmDialog.btn2,
        container: `${classes.paddingX}`,
      })}
      <Grid
        item sm={12}
      >
        <Table
          header={TABLE_HEADER}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          // onDelete={(i, e, row) => {
          //   dispatch(deletePart(row._id));
          //   setTimeout(() => {
          //     listParts({ skip: (page - 1) * 10 })
          //   }, 500);
          // }}
          editPrefix="project/edit/"
        />
        <Grid
          item
          container
          style={{ justifyContent: "center" }}
        >
          <div className="ui-components">
            <Pagination
              count={parts.list.totalCount}
              page={page}
              setPage={setPage}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
