import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CustomButton from '../../Button/index'
import TextField from '../../TextField';
import useStyles from '../../../../styles/jss/style';
import './css/style.css';

const styles = (theme) => ({
  root: {
    margin: 0,
    width:500,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  }
}))(MuiDialogContent);

const Index = ({
  Component= CustomButton,
  title='', 
  body='', 
  tfLabel1='Sample Label 1',
  tfLabel2='Sample Label 2',
  firstActionbutton='', 
  secondActionbutton='',
  onSecondActionClick=null
}) => {
  const classes = useStyles();
  const id = Math.random();
  const [open, setOpen] = useState(false);
  const [tfValue, setTfValue] = useState(['','']);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTfValue(['','']);
  };
  
  const onTFChange = (e, index) => {
    let temp = [...tfValue];
    temp[index] = e.target.value;
    setTfValue(temp);
  }

  return (
    <div>
      {<Component onClick={handleClickOpen} />}
      <Dialog onClose={handleClose} aria-labelledby={`${id}`} open={open}>
        <DialogTitle id={`${id}`} onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent>
          <div className='dialog-tf-div'>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField 
                label={tfLabel1}
                variant='standard'
                color='primary'
                autoFocus
                fullWidth
                value={tfValue[0]}
                onChange={(e) => onTFChange(e,0)}
              />
            </div>
            <div className={`${classes.fullWidth} ${classes.marginX}`}>
              <TextField 
                label={tfLabel2}
                variant='standard'
                color='primary'
                autoFocus
                fullWidth
                value={tfValue[1]}
                onChange={(e) => onTFChange(e,1)}
              />
            </div>
          </div>
          <div className="actions">
            <CustomButton text={firstActionbutton} variant='contained' onClick={handleClose} />
            <div className="actions-child" >
              <CustomButton text={secondActionbutton} variant='contained' color='primary' onClick={() => {
                  onSecondActionClick(tfValue);
                  if(tfValue) handleClose();
                }
              }/>
            </div>
        </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Index;
