import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { 
  Divider,
  Grid, Hidden, useTheme, 
  withStyles, Typography,
} from '@material-ui/core';
import { 
  TextField as CustomTextField,
  Button,
  Tooltip,
  AutoComplete
} from '../../../commons/mui';
import {
  DIALOGS,
} from '../../../commons/CONSTANTS';
import useStyles from '../../../styles/jss/style';
import { TFPART1, TYPE_COLORS, SAMPLE_DATA as DATA, createData,  } from './CONSTANTS';
import { AddCircleOutline  } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Search from '../../Search';
import { addPart, updatePart, listParts, getPart, deletePart } from '../../../redux/actions/part';

const TextField = withStyles((theme) => ({
    root: {
      marginBottom:theme.spacing(3),
    }
  }))(CustomTextField);


const Index = () => {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const parts = useSelector(state => state.parts);
    const [partObj, setPartObj] = useState({
      name: '',
      description: '',
      resolutionTime: 0,
    });
    const [selectedPart, setSelectedPart] = useState(null);

    const [listMode, setListMode] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState({
      title:'sample',
      text:'sample',
      open: false,
      btn1: {
        text: 'No',
        onClick: () => {}
      },
      btn2: {
        text: 'Yes',
        onClick: ()=>{}
      }
    });
    const [addPartObj, setAddPartObj] = useState({
      name:'',
      description: '',
      resolutionTime: '',
    });

    useEffect(() => {
      dispatch(listParts());
    }, []);

    useEffect(() => {
      if(selectedPart) {
        setPartObj({
          name: selectedPart.name,
          description: selectedPart.description,
          resolutionTime: selectedPart.resolutionTime,
        });
      }
    }, [selectedPart]);

    const onTFChange = (e) => {
      setPartObj(old => ({
        ...old,
        [e.target.name]: e.target.value
      }))
    }
    
    const listPart = () => {
      setListMode(!listMode)
    }

    const headCells = [
      { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
      { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
      { id: 'resolutionTime', numeric: true, disablePadding: false, label: 'Resolution Time' },
      { id: 'actions', numeric: true, disablePadding: false, label: 'Actions' },
    ];
    
    //  Delete item from parts list
    const onDeleteParts = (e, id) => {
      dispatch(deletePart(id))
      resetPartObj();
      alert(`Deleted Workshop with id ${id}`);
    }

    //   Edit parts from list and search
    const onEditParts = (item) => {
      setListMode(false);
      setSelectedPart(item)
    }

    const resetPartObj = () => setPartObj({name: '', description: '', resolutionTime: ''});

    //   On Cancel Button
    const cancelForm = () =>{
      resetPartObj();
      setSelectedPart(null);
    }

    //   Autocomplete change
    const onACChange = (e, value) => {
      setSelectedPart(value)
    }
    
    //  OnSubmitData
    const onSubmit = (e) => {
      e.preventDefault();
      if(selectedPart) {
        setShowConfirmDialog((old) => ({
          ...old,
          open: true,
          title: `Edit part`,
          text: 'Are you sure ?',
          btn1: {
            ...old.btn1,
          },
          btn2: {
            ...old.btn2,
            onClick: () => {
              dispatch(updatePart(partObj, selectedPart._id))
              setSelectedPart(null);
              resetPartObj();
            }
          }
        }));
      } else {
        setShowConfirmDialog((old) => ({
          ...old,
          open: true,
          title: `Add new part`,
          text: 'Are you sure ?',
          btn1: {
            ...old.btn1,
          },
          btn2: {
            ...old.btn2,
            onClick: () => {
              dispatch(addPart(partObj));
              setSelectedPart(null);
              resetPartObj();
            }
          }
        }));
      }
    }


  return (
    <Grid container className={`${classes.paddingX} ${classes.stretchHeight}`}>
    <Grid item container className={`${classes.height5}`}>
      <Typography variant='h6' align='left' gutterBottom>Add/Edit Parts</Typography>
    </Grid>
    <Grid item container className={`${classes.height95}`} direction='column'>
      <Grid item container direction='row' className={`${classes.fullWidth} ${classes.stretchHeight}`} >
        <Grid item container xs={12} sm={6} md={6} direction='row' className={`${classes.stretchHeight}`}>
          <form onSubmit={onSubmit} className={`${classes.fullWidth} ${classes.stretchHeight}`}>
            <Grid item container xs={12} justifyContent='space-between' className={`${classes.height90} ${classes.hideScrollbar}`} style={{overflowY: 'scroll', whiteSpace:'nowrap'}}>
                    <Grid item xs={12} sm={6} direction='row' className={`${classes.paddingRight}`}>
                    {
                    TFPART1.map((item, i) => {
                      return <div key={i} className={`${classes.fullWidth}`} style={{display: 'flex'}}>
                        <TextField 
                          {...item}
                          value={partObj[item.name]}
                          fullWidth
                          onChange={onTFChange}
                        />
                        <IconButton disabled color="primary" icon={<AddCircleOutline style={{visibility:'hidden', height:'32', width:'32' }} />} />
                      </div>
                    })
                  }
                    </Grid>
            </Grid>
            <Grid item container xs={12} className={`${classes.height10}`} direction='column' alignItems='center' justifyContent='center' style={{height:'10%'}}>
                <Grid item container xs={6} justifyContent='flex-end'>
                  <Button onClick={cancelForm} text='Cancel' variant='contained'/>
                </Grid>
                <Grid item container xs={6} justifyContent='flex-start'>
                  <Button type='submit' text='Submit' variant='contained' color='primary'/>
                </Grid>
              </Grid>
          </form>
        </Grid>
        <Hidden xsDown>
          <Grid item container sm={6} direction='column'>
            <Divider orientation='vertical'/>
           
            <Grid container direction='column' className={`${classes.fullWidth} ${classes.paddingLeft} `}>
              <Grid item container  justifyContent='space-between'  alignItems='center'>
                <Grid item container sm={6}>
                  <AutoComplete
                    label='Search Part'
                    variant='filled'
                    color='primary'
                    onChange={(event, newValue) => {
                      onACChange(event, newValue);
                    }}
                    options={parts.list.data}
                    customOptionLabel={(option) => option.name}
                    fullWidth
                  />
                </Grid> 
                <Grid item container sm={6} justifyContent='flex-end'>
                  {
                  !listMode ? <Button  text='Show All Parts' variant='contained' color='primary' onClick={listPart}/>
                           : <Button  text='Back' variant='contained' color='primary' onClick={listPart}/>
                }
                  </Grid>
              </Grid>
                {/* <Grid item xs={2} ></Grid> */}
                
              {/* {  !listMode ?
                  <Grid item container justifyContent='center' alignItems='center'>
                    
                    <AutoComplete
                  label='Search Part'
                  variant='filled'
                  color='primary'
                  value={addPartObj.name}
                  onChange={(event, newValue) => {
                    onACChange(event, newValue);
                  }}
                  options={[...DATA.MODULES]}
                  fullWidth
                />
                  </Grid>
                  
                  : <Grid item></Grid>
                } */}
                
                {
                !listMode ?
                (
                    addPartObj.name !== ''  ?
                     <Grid item container direction='column' >
                     {
                       [
                         {title: 'Name*', value:addPartObj.name},
                         {title: 'Description*', value: addPartObj.description},
                         {title: 'Resolution Time*', value: addPartObj.resolutionTime},
                       ].map((item, i) => (
                         <Grid item sm={12} key={i} className={`${classes.paddingX} ${classes.marginY}`} >
                           <Typography variant='caption'>{i===0 ? (item.value? item.title: item.value): (item.value!=null ? item.title: ' ')}</Typography>
                           <Typography variant='h6' className={`${classes.capitalize}`} >
                             {i===0 ? item.value: item.value }
                           </Typography>
                         </Grid>
                       ))
                     }
                   </Grid>
                   : <Grid item></Grid>
                )
                :
                <Grid item container direction='column' >
                    {/* <Grid item></Grid> */}

                    {/* <Grid item></Grid> */}
                    {/* <Table 
                        rows={DATA.partRows}
                        headCells={headCells}
                        // link='/addeditparts'
                        title='Part List'
                        // minHeight='calc(10vh - 150px)'
                        onDelete = {onDeleteParts}
                        onEdit = {onEditParts}
                    />  */}
                    
                    <TableContainer component={Paper}>
                        <Table  aria-label="simple table">
                            <TableHead>
                            <TableRow>

                                <TableCell>Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell align="center">Resolution Time</TableCell>
                                <TableCell align="center">Action</TableCell>

                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {parts.list.data.length > 0 && parts.list.data.map((row, i) => (
                               
                                <TableRow key={i}>
                                
                                <TableCell component="th" scope="row">
                                  <div style={{width:'80px'}}>
                                    <Tooltip 
                                      arrow 
                                      title={row.name} 
                                      placement='bottom'
                                      Component={() => <Typography noWrap={true}>{row.name}</Typography>} 
                                    />
                                  </div>
                                </TableCell>
                                <TableCell align="left">
                                  <div style={{width:'160px'}}>
                                    <Tooltip 
                                      arrow 
                                      title={row.description} 
                                      placement='bottom'
                                      Component={() => <Typography noWrap={true}>{row.description}</Typography>} 
                                    />
                                  </div>
                                </TableCell>
                                <TableCell align="center">{row.resolutionTime}</TableCell>
                                <TableCell>
                                    <IconButton aria-label="edit" onClick={((e) => { e.cancelBubble = true;
                                                                                    if(e.stopPropagation) e.stopPropagation();
                                                                                           onEditParts(row);
                                                                                        })}><EditIcon /></IconButton>
                                    <IconButton aria-label="delete" onClick={((e) => { e.cancelBubble = true;
                                                                                    if(e.stopPropagation) e.stopPropagation();
                                                                                            onDeleteParts(e, row._id);
                                                                                        })}><DeleteIcon /></IconButton>
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>                   
                </Grid>
                //  <Grid item container justifyContent='space-between'>
                //         <Grid item sm={4}><Typography variant='caption'>Name</Typography></Grid>
                //         <Grid item sm={4}><Typography variant='caption'>Description</Typography></Grid>
                //         <Grid item sm={4}><Typography variant='caption'>Resolution Time (in Min)</Typography></Grid>
                //         <Divider />
                //         {DATA.MODULES.map(item=>{
                //             return(<>
                //             <Grid item sm={4}><Typography variant='caption'>{item.name}</Typography></Grid>
                //             <Grid item sm={4}  style={{overflowX: 'hidden', whiteSpace: 'nowrap'}}><Typography variant='caption'>{item.description}</Typography></Grid>
                //             <Grid item sm={4}><Typography variant='caption'>{item.resolutionTime}</Typography></Grid>
                //         <Divider />

                //         </>)})}
                // </Grid> 
                
                }
                
                
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
    {
      DIALOGS.CONFIRM_DIALOG({
        title: showConfirmDialog.title,
        text: showConfirmDialog.text,
        open: showConfirmDialog.open,
        setOpen: setShowConfirmDialog,
        btn1:showConfirmDialog.btn1,
        btn2:showConfirmDialog.btn2,
        container: `${classes.paddingX}`,
      })
    }  
  </Grid>
  )
}

export default Index;
