import {
  SET_LOCAL_COMPANY
} from '../action_types';

const initialState = {
  companyId: {
    data: null
  },
}

const localCompany = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOCAL_COMPANY:
      return {
        ...state,
        companyId: {
          ...state.companyId,
          ...payload
        }
      };
    default: return state;
  }
}

export default localCompany;