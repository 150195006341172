const TABLE_HEADER = {
    title: {
      data: "Title",
      width: "150px",
    },
    type: {
      data: "Type",
      width: "150px",
    },
    comment:{
      data: "Comment",
    }
  };
  
  export { TABLE_HEADER };