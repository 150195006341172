import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Pagination, Table } from "../../../commons/mui";
import { TABLE_HEADER } from "./CONSTANTS";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { deleteRole, listRoles } from "../../../redux/actions/companyRole";
import { Grid } from "@material-ui/core";
import { setCompanyHeader } from "../../../utils/setAuthToken";
import { encrypt } from "n-krypta";
import { ArrowBack } from "@material-ui/icons";
import { BRM } from "../../Projects/AllProjects/CONSTANTS";
import { DIALOGS } from "../../../commons/CONSTANTS";
import useStyles from "../../../styles/jss/style";

export default function Index() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const companyRole = useSelector((state) => state.companyRole);
  const auth = useSelector((state) => state.auth);
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const { companyId } = useParams();
  const [companyLId, setCompanyLId] = useState("");
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: "sample",
    text: "sample",
    open: false,
    btn1: {
      text: "No",
      onClick: () => {},
    },
    btn2: {
      text: "Yes",
      onClick: () => {},
    },
  });

  useEffect(() => {
    if (localStorage.getItem("comapnyId"))
      setCompanyLId(localStorage.getItem("comapnyId"));
    if (companyId) setCompanyHeader(encrypt(companyId, "secret"));
    // dispatch(listRoles());
  }, []);
  useEffect(() => {
    dispatch(listRoles({ skip: 10 * (page - 1) }));
  }, [page]);

  useEffect(() => {
    if (companyRole.list.data) {
      setdata(companyRole.list.data);
    }
  }, [companyRole]);

  const onEdit = (e, item) => {
    console.log(e, item);
    history.push("/company-edit-role/" + item._id);
  };

  // const onDelete = (i, e, row) => {
  //     dispatch(deleteRole(row._id))
  //     setTimeout(() => {
  //         dispatch(listRoles())
  //     }, 500);
  // }

  const onLogin = (row, column) => {
    // localStorage.setItem('companyId', bcrypt.hashSync(row._id, 10))
    console.log(row, column);

    // dispatch(clearData())
    history.push("/company-project-list/" + row._id);
    // let id = bcrypt.hashSync(row._id, 10);
    // localStorage.setItem('id', id)
  };
  const onDelete = (i, e, item) => {
    // console.log(item, i, item.name || item.projectNo)
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: "Please confirm to delete",
      text: `You are about to delete role.  Are you sure ?`,
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => {
          dispatch(deleteRole(item._id));
          setTimeout(() => {
            dispatch(listRoles());
          }, 500);
        },
      },
    }));
  };

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2.5rem",
          alignItems: "center",
        }}
      >
        <h2>
          <IconButton
            color="primary"
            onClick={() =>
              history.push(
                (auth.data?.role?.name === BRM ? "/brm-" : "/") +
                  "company-user-list/" +
                  companyId
              )
            }
            icon=<ArrowBack />
          ></IconButton>
          Role List
        </h2>
        <Button
          width="200px"
          text="Add Role"
          variant="contained"
          color="primary"
          onClick={() =>
            history.push(
              (auth.data?.role?.name === BRM ? "/brm-" : "/") +
                "company-add-role"
            )
          }
        />
      </Grid>
      {DIALOGS.CONFIRM_DIALOG({
        title: showConfirmDialog.title,
        text: showConfirmDialog.text,
        open: showConfirmDialog.open,
        setOpen: setShowConfirmDialog,
        btn1: showConfirmDialog.btn1,
        btn2: showConfirmDialog.btn2,
        container: `${classes.paddingX}`,
      })}
      <Grid style={{ marginTop: "1rem" }}>
        <Table
          header={TABLE_HEADER}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          onLogin={onLogin}
        />
        <Grid item container style={{ justifyContent: "center" }}>
          <div className="ui-components">
            <Pagination
              count={companyRole.list.data.totalCount}
              page={page}
              setPage={setPage}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
