import React from "react";
import {
  Grid,
  Typography,
} from "@material-ui/core";

import moment from "moment";



export default function Index({ data = {}, show = [
  'name', 'projectNo', 'sapNo', 'rev', 'companyName', 'poNo', 'customer', 'customerContact', 'phoneNo', 'emailId', 'mobileNo', 'workLocation', 'location', 'partName', 'partNumber', 'commodity', 'problemDescription', 'typeOfWorkRequired', 'projectRequirement', 'startDate', 'expectedEndDate', 'status', 'createdAt', 'reportingFrequency'
], additional = '', margin='1rem 3rem' }) {

  return (
    <Grid container xs={11} spacing={3} style={{ margin: margin||'0' }}>
      {show.indexOf('name') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Project Name :
          </Typography>
          <Typography>{data?.name}</Typography>
        </Grid>
        : null}
      {show.indexOf('projectNo') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            PDSL Project No. :
          </Typography>
          <Typography>{data?.projectNo}</Typography>
        </Grid>
        : null}
      {show.indexOf('rev') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Rev :
          </Typography>
          <Typography>{data?.rev}</Typography>
        </Grid>
        : null}
      {show.indexOf('sapNo') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            SAP/Customer Ref :
          </Typography>
          <Typography>{data?.sapNo}</Typography>
        </Grid>
        : null}
      {show.indexOf('companyName') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Company Name :
          </Typography>
          <Typography>{data?.companyName}</Typography>
        </Grid>
        : null}
      {show.indexOf('poNo') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            PO No. :
          </Typography>
          <Typography>{data?.poNo}</Typography>
        </Grid>
        : null}
      {show.indexOf('customerContact') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Client Contact Name :
          </Typography>
          <Typography>
            {data?.customerContact}
          </Typography>
        </Grid>
        : null}
      {show.indexOf('customer') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Customer :
          </Typography>
          <Typography>
            {data?.customer?.firstName} {data?.customer?.lastName}
          </Typography>
        </Grid>
        : null}
      {show.indexOf('phoneNo') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Telephone No. :
          </Typography>
          <Typography>{data?.phoneNo}</Typography>
        </Grid>
        : null}
      {show.indexOf('emailId') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Email Address :
          </Typography>
          <Typography>{data?.emailId}</Typography>
        </Grid>
        : null}
      {show.indexOf('mobileNo') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Mobile No. :
          </Typography>
          <Typography>{data?.mobileNo}</Typography>
        </Grid>
        : null}
      {show.indexOf('workLocation') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Location of work :
          </Typography>
          <Typography>
            {data?.workLocation?.map((a) => a.address).join()}
          </Typography>
        </Grid>
        : null}
      {show.indexOf('location') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Location Contact :
          </Typography>
          <Typography style={{wordBreak:'break-all'}}>{data?.location?.map((a) => a.contactName + '(' + a.contactNumber + ')').join()}</Typography>
        </Grid>
        : null}
      {show.indexOf('partName') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Part Name :
          </Typography>
          <Typography>{data?.partName}</Typography>
        </Grid>
        : null}
      {show.indexOf('partNumber') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Part Number :
          </Typography>
          <Typography>{data?.partNumber}</Typography>
        </Grid>
        : null}
      {show.indexOf('problemDescription') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Problem Description :
          </Typography>
          <Typography>{data?.description}</Typography>
        </Grid>
        : null}
      {show.indexOf('typeOfWorkRequired') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Type of Work Required :
          </Typography>
          <Typography>{data?.typeOfWorkRequired}</Typography>
        </Grid>
        : null}
      {show.indexOf('commodity') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Commodity :
          </Typography>
          <Typography>{data?.commodity}</Typography>
        </Grid>
        : null}
      {show.indexOf('projectRequirement') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Project Requirement :
          </Typography>
          <Typography>{data?.projectRequirement}</Typography>
        </Grid>
        : null}
      {show.indexOf('startDate') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Start Date :
          </Typography>
          <Typography>
            {data?.startDate
              ? moment(data?.startDate).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD")}
          </Typography>
        </Grid>
        : null}
      {show.indexOf('expectedEndDate') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Exit Date :
          </Typography>
          <Typography>
            {data?.expectedEndDate
              ? moment(data?.expectedEndDate).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD")}
          </Typography>
        </Grid>
        : null}
      {show.indexOf('createdAt') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Created Date :
          </Typography>
          <Typography>
            {data?.createdAt
              ? moment(data?.createdAt).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD")}
          </Typography>
        </Grid>
        : null}
      {show.indexOf('reportingFrequency') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Reporting Frequency :
          </Typography>
          <Typography>{data?.reportingFrequency}</Typography>
        </Grid>
        : null}
      {show.indexOf('status') !== -1 ?
        <Grid item sm={6} md={4} xs={12} style={{ display: "flex" }}>
          <Typography style={{ fontWeight: "bold", marginRight: ".5rem" }}>
            Status :
          </Typography>
          <Typography>{data?.status}</Typography>
        </Grid>
        : null}
      {additional || ''}
    </Grid>

  );
}
