const TABLE_HEADER = {
    address: {
      data: "Address",
      width: "150px",
    },
    city: {
      data: "City",
      width: "150px",
    },
    country: {
      data: "Country",
      width: "150px",
    },
  };
  
  export { TABLE_HEADER };