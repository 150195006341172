const TABLE_HEADER = {
  projectNo: {
    data: 'Project #',
    width: '150px'
  },
  name: {
    data: 'Name',
    // width: '240px'
  },
  description: {
    data: 'Description',
    // width: '240px'
  },
  // revision: {
  //   data: 'Revision #',
  //   // width: '20px',
  // },
  customer: {
    data: 'Customer',
    // width: '150px',
  },
  customerContact: {
    data: 'Customer Contact',
  },
  locationContact: {
    data: 'Location Contact',
  },
  dateCreated: {
    data: 'Date Created'
  },
  status: {
    data: 'Status'
  },
  
}

export { TABLE_HEADER };
