import React from 'react';
import './css/toggle_switch.css';

const ToggleSwitch = ({checked=false, onChange, toggleState}) => {
  return (
    <label className="switch">
      <input key={Math.random()} type="checkbox" defaultChecked={checked} onChange={e => onChange(e.target.checked)}/>
      <span className="switch-slider switch-round"></span>
    </label>
  )
}

export default ToggleSwitch;
