import {
  LIST_TICKET,
  GET_TICKET,
  ADD_TICKET,
  UPDATE_TICKET,
  DELETE_TICKET,
  REPORT_TICKET
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listTickets = (params) => async dispatch => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: 'GET',
      url: `/tickets`,
      params
    });
    dispatch({
      type: LIST_TICKET,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: LIST_TICKET,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      }
    });
  }
}
export const reportTicket = (params) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/tickets/report`,
      params
    });
    dispatch({
      type: REPORT_TICKET,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: REPORT_TICKET,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}
export const getTicket = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/tickets/${id}`,
    });
    dispatch({
      type: GET_TICKET,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: GET_TICKET,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addTicket = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/tickets`,
      data
    });
    dispatch({
      type: ADD_TICKET,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: ADD_TICKET,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const updateTicket = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/tickets/${id}`,
      data
    });
    dispatch({
      type: UPDATE_TICKET,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_TICKET,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const deleteTicket = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/tickets/${id}`,
    });
    dispatch({
      type: DELETE_TICKET,
      payload: {
        data: res.data.data,
        pagination: res.data.pagination || {},
        totalCount: res.data.totalCount || 0,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: DELETE_TICKET,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}
export const clearTicketData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_TICKET,
    payload: {
      data: {},
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
};