import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Button, Pagination, Table, Tooltip } from "../../../commons/mui";
import useStyles from "../../../styles/jss/style";
import {
  BRM,
  ASSOCIATE,
  AUDITOR,
  TABLE_HEADER,
  EVERSIGNPROJECTCREATION,
  EVERSIGNPROJECTREVIEW,
  RECOVER_TABLE_HEADER,
} from "./CONSTANTS";

import {
  deleteProject,
  listProjects,
  updateProject,
  clearProjectData,
  verifyProjectSign,
  cloneProject,
  recoverProject,
} from "../../../redux/actions/project";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DIALOGS } from "../../../commons/CONSTANTS";
import { listRoles } from "../../../redux/actions/companyRole";
import { listUsers } from "../../../redux/actions/user";
import moment from "moment";
import Iframe from "react-iframe";
import { CLONE_PROJECT, DELETE_PROJECT, RECOVER_PROJECT, SIGN_PROJECT, UPDATE_PROJECT } from "../../../redux/action_types";
import { toast } from "react-toastify";

const Index = ({ styleObj = {} }) => {
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.project);
  const users = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const comapnyRoles = useSelector((state) => state.companyRole);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [associates, setAssociates] = useState([]);
  const [auditors, setAuditors] = useState([]);
  const [showProject, setShowProject] = useState(false);
  const [selectedEsignProject, setSelectedEsignProject] = useState({});
  const [openTemplate, setOpenTemplate] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [eversignDetail, setEversignDetail] = useState({});
  const [signLoader, setSignLoader] = useState(false);
  const [showCloneBtn, setshowCloneBtn] = useState(false);
  const [isRecover, setIsRecover] = useState(false);
  const [projectId, setprojectId] = useState(null);

  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: "sample",
    text: "sample",
    open: false,
    btn1: {
      text: "No",
      onClick: () => { },
    },
    btn2: {
      text: "Yes",
      onClick: () => { },
    },
  });

  const [showAssignDialog, setShowAssignDialog] = useState({
    open: false,
    title: "Assign Project",
    tfProps: {
      variant: "filled",
      color: "primary",
      select: true,
      options: [],
      label: "Assign To",
      value: "",
      fullWidth: true,
    },
    btn1: {
      text: "Cancel",
      onClick: () => { },
    },
    btn2: {
      text: "Submit",
      onClick: () => { },
    },
  });
  const [showInfoDialog, setShowInfoDialog] = useState({
    title: "sample",
    open: false,
    children: <div></div>,
    btn1: {
      text: "Close",
      onClick: () => { },
    },
  });

  // useEffect(() => {
  //   dispatch(listRoles());
  // }, []);

  useEffect(() => {
    dispatch(listRoles());
  }, [page]);
  useEffect(() => {
    // console.log(AUDITOR, ASSOCIATE, comapnyRoles.list.data)
    if (comapnyRoles.list.data && comapnyRoles.list.data.length) {
      let customerRole =
        comapnyRoles.list.data.filter(
          (a) => a.name === ASSOCIATE || a.name === AUDITOR || a.name === BRM
        ) || [];
      if (customerRole.length) {
        dispatch(
          listUsers({ limit: 0, role: customerRole.map((a) => a._id).join() })
        );
      }
    }
  }, [comapnyRoles]);
  useEffect(() => {
    // console.log(users, users.list.data.filter(
    //   (a) => a.role?.name === ASSOCIATE || a.role?.name === BRM
    // ))
    if (users.list.data && users.list.data.length) {
      setAssociates(
        users.list.data.filter(
          (a) => a.role?.name === ASSOCIATE || a.role?.name === BRM
        )
      );
      setAuditors(
        users.list.data.filter(
          (a) => a.role?.name === AUDITOR || a.role?.name === BRM
        )
      );
    }
  }, [users]);
  useEffect(() => {
    // console.log(associates)
    let query = { skip: 10 * (page - 1) };
    if (window.location.href.indexOf('recover') != -1) {
      query.isDeleted = true;
      setIsRecover(true)
    }
    dispatch(listProjects(query));
  }, [associates]);

  useEffect(() => {
    if (projects.list.data && projects.list.data.length) {
      let temp = constructTableData(
        projects.list.data.map((a) => {
          a.customerName = a.customer
            ? a.customer.firstName
              ? a.customer.firstName +
              (a.customer.lastName ? " " + a.customer.lastName : "")
              : a.customer.emailId
            : "";
          a.customerName = a.customerName + (a.customerName ? '( ' + a.phoneNo + ' )' : '')
          if (a._id === selectedEsignProject._id) {
            let everSign =
              a.status === "Awaiting BRM to E-Sign" ||
                a.status === "Awaiting Review E-Sign BRM"
                ? a.eversignList && a.eversignList.length
                  ? a.eversignList.find(
                    (aa) =>
                      aa.type ===
                      (a.status === "Awaiting BRM to E-Sign"
                        ? EVERSIGNPROJECTCREATION
                        : EVERSIGNPROJECTREVIEW)
                  )
                  : {}
                : {};
            // console.log(everSign);
            let signer = everSign.meta ? everSign.meta["signer-0"] : {};
            if (signer.userId !== auth.data._id) {
              signer = everSign.meta ? everSign.meta["signer-1"] : {};
            }
            signer.document_hash = everSign.meta?.document_hash;
            if (signer.status === "signed" && openTemplate) {
              handleEverSignUpdate();
            } else if (signer.status && openTemplate) {
              // toast.error("Signature not done yet!");
            }
          }
          return a;
        })
      );
      setData(temp);
    } else {
      setData([])
    }
    // console.log(projects)
    if (projects.update && projects.update.data && projects.update.data._id) {
      dispatch(clearProjectData(UPDATE_PROJECT));
      toast.success("Project updated sucessfully");
      let query = { skip: 10 * (page - 1) };
      if (window.location.href.indexOf('recover') != -1) {
        query.isDeleted = true;
        setIsRecover(true)
      }
      dispatch(listProjects(query));
    }
    if (projects.recover && projects.recover.data && projects.recover.data._id) {
      dispatch(clearProjectData(RECOVER_PROJECT));
      toast.success("Project recovered sucessfully");
      let query = { skip: 10 * (page - 1) };
      if (window.location.href.indexOf('recover') != -1) {
        query.isDeleted = true;
        setIsRecover(true)
      }
      dispatch(listProjects(query));
    }
    if (projects.sign && projects.sign.data && projects.sign.data._id) {
      dispatch(clearProjectData(SIGN_PROJECT));
      setSignLoader(false);
      let signer = projects.sign.data.meta ? projects.sign.data.meta["signer-0"] : {};
      if (signer.userId !== auth.data._id) {
        signer = projects.sign.data.meta ? projects.sign.data.meta["signer-1"] : {};
      }
      signer.document_hash = projects.sign.data.meta?.document_hash;
      if (signer.status && signer.status !== "signed" && openTemplate) {
        toast.error("Signature not done yet!");
      }else if(signer.status && signer.status === "signed" && openTemplate){
        toast.success("Signature verified sucessfully")
      }
      let query = { skip: 10 * (page - 1) };
      if (window.location.href.indexOf('recover') != -1) {
        query.isDeleted = true;
        setIsRecover(true)
      }
      dispatch(listProjects(query));
    }
    if (projects.delete && projects.delete.data && projects.delete.data._id) {
      dispatch(clearProjectData(DELETE_PROJECT));
      toast.success("Project deleted sucessfully");
      let query = { skip: 10 * (page - 1) };
      if (window.location.href.indexOf('recover') != -1) {
        query.isDeleted = true;
        setIsRecover(true)
      }
      dispatch(listProjects(query));
    }
    if (projects.clone && projects.clone.data && projects.clone.data._id) {
      dispatch(clearProjectData(CLONE_PROJECT));
      let query = { skip: 10 * (page - 1) };
      if (window.location.href.indexOf('recover') != -1) {
        query.isDeleted = true;
        setIsRecover(true)
      }
      dispatch(listProjects(query));
    }
  }, [projects]);

  // useEffect(() => {
  //   if (showProject && projects.detail.data) {
  //     setShowInfoDialog((old) => ({
  //       ...old,
  //       title: "Project",
  //       open: true,
  //       children: <ProjectTemplate data={projects.detail.data} />,
  //     }));
  //   }
  // }, [showProject, projects.detail.data]);
  const handleEverSignUpdate = () => {
    let upData = {
      status:
        selectedEsignProject.status === "Awaiting Review E-Sign BRM"
          ? "Awaiting Work plan"
          : "Awaiting Risk Assessment",
    };
    dispatch(updateProject(upData, selectedEsignProject._id));
    setOpenTemplate(false);
    setIframeLoaded(false);
  };
  const showAssementAssingDialog = (e, project) => {
    // console.log(associates, auditors, project.assessmentDone, (project.assessmentDone ? auditors : associates))
    setShowAssignDialog((old) => ({
      ...old,
      open: true,
      title:
        "Assign assessment of project Assessee",
      tfProps: {
        ...old.tfProps,
        options: (associates).map((a) => {
          // console.log(a);
          return {
            label: a.firstName
              ? a.firstName + (a.lastName ? " " + a.lastName : "")
              : a.emailId,
            value: a._id,
          };
        }),
      },
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        required: true,
        onClick: (data) => {
          if (project._id && data) {
            let udata = {};
            udata = {
              assessmentAssignTo: data,
              status: "Awaiting to Start Assessment",
              assessmentAssignOn: new Date(),
            };
            dispatch(updateProject(udata, project._id));
          }
        },
      },
    }));
  };
  const constructTableData = (raw) => {
    let result = [];
    for (let i = 0; i < raw.length; i++) {
      let project = raw[i];
      let row = {};
      Object.keys(isRecover ? RECOVER_TABLE_HEADER : TABLE_HEADER).map((a) => {
        if (project.status === 'Awaiting BRM to E-Sign') {
          row.edit = true;
        } else {
          row.edit = false;
        }
        if (a == "createdAt")
          row[a] = moment(project[a]).format("YYYY-MM-DD hh:mm A");
        else if (a == "assessmentAssignTo") {
          // console.log(project.status, project.status !== 'Awaiting BRM to E-Sign' && project.status !== 'Awaiting Risk Assessment' && project.status !== 'Awaiting to Assign Auditor')
          // if ((project[a] && !project.assessmentDone) || (project.auditBy)) {
          if (
            project.status === "Awaiting BRM to E-Sign" ||
            project.status === "Awaiting Customer to E-Sign" ||
            project.status === "Awaiting Risk Assessment" ||
            project.status === "Awaiting to Assign Auditor" ||
            project.status === "Awaiting Review E-Sign BRM" ||
            project.status === "Awaiting Work plan"
          ) {
            row[a] =
              project.status === "Awaiting BRM to E-Sign" ||
                project.status === "Awaiting Review E-Sign BRM" ? (
                <Tooltip
                  title={`E-Sign`}
                  placement="bottom"
                  Component={() => (
                    <Button
                      onClick={(e) => {
                        if (project.eversignList.length) {
                          setSelectedEsignProject(project);
                          let everSign =
                            project.status === "Awaiting BRM to E-Sign" ||
                              project.status === "Awaiting Review E-Sign BRM"
                              ? project.eversignList &&
                                project.eversignList.length
                                ? project.eversignList.find(
                                  (a) =>
                                    a.type ===
                                    (project.status === "Awaiting BRM to E-Sign"
                                      ? EVERSIGNPROJECTCREATION
                                      : EVERSIGNPROJECTREVIEW)
                                )
                                : {}
                              : {};
                          let signer = everSign.meta
                            ? everSign.meta["signer-0"]
                            : {};
                          if (signer.userId !== auth.data._id) {
                            signer = everSign.meta
                              ? everSign.meta["signer-1"]
                              : {};
                          }
                          signer.document_hash = everSign.meta.document_hash;
                          setEversignDetail(signer);
                          setOpenTemplate(true);
                        } else {
                          dispatch(updateProject({ status: 'Awaiting Review E-Sign BRM' }, project._id));
                          toast.warn('Some technicial error try again.');
                        }
                      }}
                      text="E-Sign"
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: ".5rem" }}
                    />
                  )}
                />
              ) : project.status === "Awaiting Risk Assessment" ? (
                <Tooltip
                  title={
                    `Assign to Assessee`
                  }
                  placement="bottom"
                  Component={() => (
                    <Button
                      onClick={(e) => showAssementAssingDialog(e, project)}
                      text="Assign"
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: ".5rem" }}
                    />
                  )}
                />
              ) : project.status === "Awaiting Work plan" ? (
                <Tooltip
                  title={`Work Plans`}
                  placement="bottom"
                  Component={() => (
                    <Button
                      onClick={(e) => {
                        history.push("/workplan-list/" + project._id);
                      }}
                      text="Work Plans"
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: ".5rem" }}
                    />
                  )}
                />
              ) : null;
          } else {
            row[a] =
              project[a] && !project.assessmentDone
                ? project[a]?.firstName
                  ? project[a]?.firstName +
                  (project[a]?.lastName ? " " + project[a]?.lastName : "")
                  : project[a]?.emailId
                : project?.auditBy?.firstName
                  ? project?.auditBy?.firstName +
                  (project?.auditBy?.lastName
                    ? " " + project?.auditBy?.lastName
                    : "")
                  : project?.auditBy?.emailId;
            if (
              project.auditBy &&
              project.auditBy._id === auth.data?._id &&
              project.assessmentDone &&
              !project.auditDone
            ) {
              row.startButtonTitle = "Start Audit";
              row.assessmentDone = project.assessmentDone;
              row.auditByDetail = project.auditBy;
            } else if (
              project.assessmentAssignTo &&
              project.assessmentAssignTo._id === auth.data._id &&
              !project.assessmentDone
            ) {
              row.startButtonTitle = "Start Assessment";
              row.assessmentDone = project.assessmentDone;
              row.assessmentAssignToDetail = project.assessmentAssignTo;
            }
          }
        } else if (a == "locationContact") {
          let str = "";
          if (Array.isArray(project.location))
            (project?.location || []).map((itm, i) => {
              if (i == project?.location?.length - 1) {
                str += itm.name + " " + (itm?.location?.address || "");
              } else {
                str += itm.name + "," + (itm?.location?.address || "") + ",";
              }
            });
          // console.log('->',project.location)
          row[a] = str;
        } else if (a === "projectNoView") {
          row[a] = (
            <Grid>
              <Link
                onClick={() => {
                  history.push(`/project/${project._id}`);
                }}
                style={{ cursor: "pointer" }}
              >
                {project.projectNo}
              </Link>
            </Grid>
          );
        } else row[a] = project[a];
        row['actionView'] = (<Tooltip
          title={`Recover`}
          placement="bottom"
          Component={() => (
            <Button
              onClick={(e) => {
                dispatch(recoverProject({ isDeleted: false }, project._id))
              }}
              text="Recover"
              variant="contained"
              color="primary"
              style={{ marginBottom: ".5rem" }}
            />
          )}
        />)
        return a;
      });
      row._id = project._id;
      row.revProject = project.revProject;
      result.push(row);
    }
    return result;
  };

  const onEdit = (e, item) => {
    // alert(`Edit clicked on ${item.projectId}`)
    history.push("/edit/project/" + item._id);
  };
  const onStart = (e, i, row) => {
    // console.log(row, auth.data, row.assessmentDone)
    if (
      row.auditByDetail &&
      row.auditByDetail._id === auth.data._id &&
      row.assessmentDone
    ) {
      history.push("/admin-audit/" + row._id);
    } else if (
      row.assessmentAssignToDetail &&
      row.assessmentAssignToDetail._id === auth.data._id &&
      !row.assessmentDone
    ) {
      history.push("/admin-action/" + row._id);
    }
  };

  const onDelete = (i, e, item) => {
    // console.log(item, i, item.name || item.projectNo)
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: "Please confirm to delete",
      text: `You are about to delete project "${item.name || item.projectNo
        }".  Are you sure ?`,
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => {
          dispatch(deleteProject(item._id));
        },
      },
    }));
  };
  const handleClone = () => {
    dispatch(cloneProject({ _id: projectId }))
  }
  return (
    <>
      <Grid
        container
        style={{
          // display: "flex",
          // justifyContent: "space-between",
          // height: "2.5rem",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Grid item xs={12} sm={6}>
          <h2>{isRecover ? 'Deleted ' : ''}Project List</h2>
        </Grid>
        {!isRecover ?
          <Grid item xs={12} sm={6} style={{ textAlign: 'end' }}>
            <Button
              width="200px"
              text="Clone Project"
              variant="contained"
              color="primary"
              disabled={!showCloneBtn}
              onClick={() => {
                // handleClone()
                history.push(`/clone/project/${projectId}`)
              }}
            />
            <Button
              width="200px"
              text="Create Project"
              variant="contained"
              color="primary"
              onClick={() => history.push("/create/project")}
            />
          </Grid>
          : null}
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        className={`${classes.height100} ${classes.hideScrollbar}`}
        style={{ overflowX: "scroll", whiteSpace: "nowrap" }}
      >
        <Table
          header={isRecover ? RECOVER_TABLE_HEADER : TABLE_HEADER}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          onStart={onStart}
          projectTable={true}
          selectedRowFunc={(val) => {
            if (val != null) {
              setprojectId(val._id);
              setshowCloneBtn(true);
            } else {
              setshowCloneBtn(false);
            }
          }}
          editPrefix="project/edit/"
          actions={isRecover ? false : true}
        />
        <Grid item container style={{ justifyContent: "center" }}>
          <div className="ui-components">
            <Pagination
              count={projects.list.totalCount}
              page={page}
              setPage={setPage}
            />
          </div>
        </Grid>
      </Grid>
      {DIALOGS.CONFIRM_DIALOG({
        title: showConfirmDialog.title,
        text: showConfirmDialog.text,
        open: showConfirmDialog.open,
        setOpen: setShowConfirmDialog,
        btn1: showConfirmDialog.btn1,
        btn2: showConfirmDialog.btn2,
        container: `${classes.paddingX}`,
      })}
      {DIALOGS.DIALOG_WITH_INPUT({
        ...showAssignDialog,
        setOpen: setShowAssignDialog,
        container: `${classes.paddingX}`,
      })}
      {DIALOGS.INFO_DIALOG({
        ...showInfoDialog,
        setOpen: setShowInfoDialog,
        container: `${classes.paddingX}`,
      })}
      <div>
        <Dialog
          open={openTemplate}
          // onClose={() => setOpenTemplate(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen
        >
          <DialogTitle id="alert-dialog-title">
            {selectedEsignProject.status === "Awaiting BRM to E-Sign"
              ? "Contract"
              : "Assessment"}
          </DialogTitle>
          <DialogContent style={{ padding: 0, overflow: "hidden" }}>
            {!iframeLoaded ? (
              <Grid
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100vh",
                  margin: '0 auto',
                  background: "#ccc",
                  zIndex: 9,
                  overflow: "hidden",
                }}
              >
                <CircularProgress disableShrink style={{ margin: "25% 50%" }} />
              </Grid>
            ) : null}
            <Grid style={{ padding: 20 }}>
              <Grid style={{ width: '50%', float: 'left' }}>
                <DialogContentText id="alert-dialog-description">
                  PIN: <strong>{eversignDetail.pin}</strong>{" "}
                  {/* <label style={{ color: "red", float: "right" }}>
                    {signError}
                  </label> */}
                </DialogContentText>
              </Grid>
              <Grid style={{ width: '50%', float: 'left' }}>
                <Button
                  onClick={(e) => {
                    // let upData = {
                    //   status:
                    //     selectedEsignProject.status === "Awaiting Review E-Sign BRM"
                    //       ? "Awaiting Work plan"
                    //       : "Awaiting Customer to E-Sign",
                    // };
                    // dispatch(updateProject(upData, selectedEsignProject._id));
                    // setOpenTemplate(false);
                    // setSignError("");
                    let upData = {
                      meta: { related_document_hash: eversignDetail.document_hash },
                    };
                    setSignLoader(true)
                    dispatch(verifyProjectSign(upData));
                  }}
                  text="Verify E-Sign"
                  variant="contained"
                  color={'green'}
                  customClass="float-right green-button"
                  startIcon={signLoader ? <CircularProgress size={30} style={{ color: '#fff' }} /> : null}
                />
              </Grid>

              <Iframe
                url={eversignDetail.embedded_signing_url}
                // width="650px"
                // height="650px"
                styles={{ width: '100%', height: '95vh', margin: '0 auto' }}
                id=""
                frameBorder={0}
                className=""
                onLoad={(e) => {
                  // console.log(e)
                  // if(e.target.bad projects)
                  setIframeLoaded(true);
                }}
                display="block"
                position="relative"
              />
              {/* {selectedEsignProject.status === 'Awaiting BRM to E-Sign' ?
                <ConfirmationTemplate data={selectedEsignProject}></ConfirmationTemplate>
                : <ProcessAudit data={selectedEsignProject} />} */}
            </Grid>
          </DialogContent>
          {/* <DialogActions>
            <Button
              onClick={(e) => {
                // let upData = {
                //   status:
                //     selectedEsignProject.status === "Awaiting Review E-Sign BRM"
                //       ? "Awaiting Work plan"
                //       : "Awaiting Customer to E-Sign",
                // };
                // dispatch(updateProject(upData, selectedEsignProject._id));
                // setOpenTemplate(false);
                setSignError("");
                let upData = {
                  meta: { related_document_hash: eversignDetail.document_hash },
                };
                dispatch(verifyProjectSign(upData));
              }}
              text="Verify E-Sign"
              variant="contained"
              color="primary"
            />
          </DialogActions> */}
        </Dialog>
      </div>
    </>
  );
};

export default Index;
