const TABLE_HEADER = {
    name: {
        data: "Name",
        width: "150px",
    },
    description: {
        data: "Description",
        width: "150px",
    }
};

export { TABLE_HEADER };
