import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid, useTheme,
  withStyles, Typography, Link
} from '@material-ui/core';
import {
  TextField as CustomTextField,
  Button,
  PasswordField,
} from '../../../commons/mui';
import useStyles from '../../../styles/jss/style';
import {
  DIALOGS,
} from '../../../commons/CONSTANTS';
import { changePassword, clearData, forgot, verify } from '../../../redux/actions/auth';
import { useHistory } from 'react-router-dom';
import OTPInput from 'react-otp-input';
import { CHANGE_PASSWORD, FORGOT, VERIFY } from '../../../redux/action_types';
import { toast } from 'react-toastify';
import { BRM, PDSLADMIN } from '../../Projects/AllProjects/CONSTANTS';
import { decrypt } from 'n-krypta';

const TextField = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  }
}))(CustomTextField);



const Index = () => {
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const localCompany = useSelector(state => state.localCompany);
  const roles = useSelector(state => state.role.list.data);
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: 'sample',
    text: 'sample',
    open: false,
    btn1: {
      text: 'No',
      onClick: () => { }
    },
    btn2: {
      text: 'Yes',
      onClick: () => { }
    }
  });
  const [resetForm, setResetForm] = useState(false);
  const [verifyForm, setVerifyForm] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  // const [body, setbody] = useState({})

  useEffect(() => {
    if (auth?.data?.accessToken) {
      let temp = roles.find((item) => item._id === (auth?.data?.role?._id || auth?.role)) || null;
      switch (temp?.name) {
        case BRM:
          history.push("/jobcard");
          break;
        case "Supervisor":
          history.push("/s_cars");
          break;
        case "Rework Engineer":
          history.push("/myjobs");
          break;
        case "Rework Manager":
          history.push("/cars");
          break;
        case PDSLADMIN:
          history.push("/company");
          break;
        default:
          break;
      }
    }
    if (!auth.hasError) {
      if (auth.type == FORGOT) {
        setVerifyForm(true)
      }
      if (auth.type == VERIFY) {
        setVerifyForm(false)
        setResetForm(true)
      }
      if (auth.type == CHANGE_PASSWORD) {
        setVerifyForm(false)
        setResetForm(false)
        toast.success('Password change successfull');
        dispatch(clearData())
        if (localCompany.companyId.data)
          history.push('/company-login/' + decrypt(localCompany.companyId.data, 'secret'))
        else
          history.push('/login')
      }
    }
  }, [auth, roles]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!resetForm && !verifyForm) {
      let body = {
        emailId: e.target[0].value,
      };
      setEmail(body.emailId);
      if (body.emailId)
        dispatch(forgot(body));
      else
        toast.error('Email is required!')
    } else if (verifyForm) {
      let body = {
        emailId: email,
        otp: otp,
      };
      dispatch(verify(body));
    } else if (resetForm) {
      if (e.target[0].value == e.target[1].value) {
        let body = {
          emailId: email,
          password: e.target[0].value,
        };
        dispatch(changePassword(body));
      } else {
        toast.error("Password and confirm not matched!")
      }
    }
  }
  const handleOtp = (newVal) => {
    setOtp(newVal)
  }
  const resend = () => {
    let body = {
      emailId: email,
    };
    setEmail(body.emailId);
    dispatch(forgot(body));
  }
  return (
    <Grid container className={`${classes.paddingX} ${classes.stretchHeight}`}>
      {/* <Grid item container className={`${classes.height5}`}>
        <Typography variant='h6' align='left' gutterBottom>Login</Typography>
      </Grid> */}
      <Grid item container direction='column'>
        <Grid item container direction='row' className={`${classes.fullWidth} ${classes.stretchHeight}`} >
          <Grid item container xs={12} direction='row' className={`${classes.stretchHeight}`}>
            {resetForm ?
              <form onSubmit={(e) => onSubmit(e)} className={`${classes.fullWidth} ${classes.stretchHeight} ${classes.verticalAlign}`} style={{ display: 'flex' }}>

                <Grid item container direction='column'
                  justifyContent='center' alignContent='center'
                  style={{ background: theme.palette.accent.main, width: '400px' }}
                  className={`${classes.paddingX} ${classes.paddingY}`}
                >
                  <Grid key={"g-1"} item className={`${classes.marginX} ${classes.paddingY}`}>
                    <Typography variant='h5' align='center'>Reset Password</Typography>
                  </Grid>
                  <Grid key={"g-2"} item className={`${classes.marginX} ${classes.paddingY}`}>
                    {/* <TextField name='password' type='password' fullWidth variant='standard' label='Enter New Password' /> */}
                    <PasswordField label="Enter New Password" fullWidth variant='standard' />
                  </Grid>
                  <Grid key={"g-4"} item className={`${classes.marginX} ${classes.paddingY}`}>
                    {/* <TextField name='confirmPassword' type='password' fullWidth variant='standard' label='Enter New Confirm Password' /> */}
                    <PasswordField label="Enter New Confirm Password" fullWidth variant='standard' />
                  </Grid>
                  <Grid key={"g-3"} item className={`${classes.marginX} ${classes.paddingBottom}`}>
                    <Button type='submit' marginLeft='0px' marginRight='0px' width='300px' variant='contained' color='primary' text='Reset' />
                  </Grid>
                </Grid>

              </form> : verifyForm ?
                <form onSubmit={(e) => onSubmit(e)} className={`${classes.fullWidth} ${classes.stretchHeight} ${classes.verticalAlign}`} style={{ display: 'flex' }}>

                  <Grid item container direction='column'
                    justifyContent='center' alignContent='center'
                    style={{ background: theme.palette.accent.main, width: '400px' }}
                    className={`${classes.paddingX} ${classes.paddingY}`}
                  >
                    <Grid key={"g-1"} item className={`${classes.marginX} ${classes.paddingY}`}>
                      <Typography variant='h5' align='center'>Verify</Typography>
                    </Grid>
                    <Grid key={"g-2"} item className={`${classes.marginX} ${classes.paddingY}`}>
                      <OTPInput
                        value={otp}
                        onChange={handleOtp}
                        numInputs={6}
                        inputStyle={{ width: '3rem', height: '3rem', margin: 0, fontSize: '2rem', borderRadius: 4, border: '1px solid rgba(0,0,0,.3)' }}
                        containerStyle={{ justifyContent: 'center' }}
                        shouldAutoFocus={true}
                        // inputType='number'
                        renderSeparator={<span style={{ margin: '0 5px 0 5px' }}>-</span>}
                        renderInput={(props) => <input {...props} />}
                      />
                      <Link style={{ float: 'right', cursor: 'pointer' }} onClick={resend}>Resend</Link>

                    </Grid>
                    <Grid key={"g-3"} item className={`${classes.marginX} ${classes.paddingBottom}`}>
                      <Button type='submit' marginLeft='0px' marginRight='0px' width='100%' variant='contained' color='primary' text='Verify' />
                    </Grid>
                  </Grid>
                </form> :
                <form onSubmit={(e) => onSubmit(e)} className={`${classes.fullWidth} ${classes.stretchHeight} ${classes.verticalAlign}`} style={{ display: 'flex' }}>

                  <Grid item container direction='column'
                    justifyContent='center' alignContent='center'
                    style={{ background: theme.palette.accent.main, width: '400px' }}
                    className={`${classes.paddingX} ${classes.paddingY}`}
                  >
                    <Grid key={"g-1"} item className={`${classes.marginX} ${classes.paddingY}`}>
                      <Typography variant='h5' align='center'>Forgot Password</Typography>
                    </Grid>
                    <Grid key={"g-2"} item className={`${classes.marginX} ${classes.paddingY}`}>
                      <TextField name='emailId' type='email' fullWidth variant='standard' label='Enter Email' value={email} onChange={(e) => { setEmail(e.target.value) }} />
                    </Grid>
                    <Grid key={"g-3"} item className={`${classes.marginX} ${classes.paddingBottom}`}>
                      <Button type='submit' marginLeft='0px' marginRight='0px' width='300px' variant='contained' color='primary' text='Submit' />
                    </Grid>
                  </Grid>
                </form>
            }
          </Grid>
        </Grid>
      </Grid>
      {
        DIALOGS.CONFIRM_DIALOG({
          title: showConfirmDialog.title,
          text: showConfirmDialog.text,
          open: showConfirmDialog.open,
          setOpen: setShowConfirmDialog,
          btn1: showConfirmDialog.btn1,
          btn2: showConfirmDialog.btn2,
          container: `${classes.paddingX}`,
        })
      }
    </Grid>
  )
}

export default Index;