const TEXTFIELDS=[
    {
      name:'name',
      label:'Name',
      variant:'filled',
      color:'primary',
      required:true,
    },
    
    {
      name:'description',
      label:'Description',
      variant:'filled',
      color:'primary',
      multiline: true,
      required:true,
      rowsMax:"4"
    },
    {
        name:'resolutionTime',
        label:'Resolution Time (in min)',
        variant:'filled',
        color:'primary',
        type:'number',
        required:true,
        InputProps:{
          inputProps: {
            min:0,
            max:999,
          }
        },
      },
  ];
  const TFPART1=TEXTFIELDS.slice(0,3);

  const TYPE_COLORS = {
    vehicle: '#513535',
    platform: '#BD845B',
    make: '#9AAEBF',
    model: '#40465C',
    variant: '#C5AD7E',
  }

 
  
  const createData = (id, name, description, resolutiontime) => ({id, name, description, resolutiontime});  

  
  const SAMPLE_DATA = {
  
    partRows:[ 
        createData(0, 'Part 1', 'Description for Part 1',1),
        createData(1, 'Part 2', 'Description for Part 2',2),
        createData(2, 'Part 3', 'Description for Part 3',3),
        createData(3, 'Part 4', 'Description for Part 4',4),
        createData(4, 'Part 5', 'Description for Part 5',5),
        createData(5, 'Part 6', 'Description for Part 6',6),
        createData(6, 'Part 7', 'Description for Part 7',7),

    ],
    MODULES: [
      {title: 'Part 1', type:'part', id: 0, name: 'Part 1', description: 'Description for Part 1', resolutiontime:1},
      {title: 'Part 2', type:'part', id: 1, name: 'Part 2', description: 'Description for Part 2', resolutiontime:2},
      {title: 'Part 3', type:'part', id: 2, name: 'Part 3', description: 'Description for Part 3', resolutiontime:3},
      {title: 'Part 4', type:'part', id: 3, name: 'Part 4', description: 'Description for Part 4', resolutiontime:4},
      {title: 'Part 5', type:'part', id: 4, name: 'Part 5', description: 'Description for Part 5', resolutiontime:5},
      {title: 'Part 6', type:'part', id: 5, name: 'Part 6', description: 'Description for Part 6', resolutiontime:6},
      {title: 'Part 7', type:'part', id: 6, name: 'Part 7', description: 'Description for Part 7', resolutiontime:7}
    ],
    
  }

export {
    TFPART1,
    TYPE_COLORS,
    SAMPLE_DATA,
    createData
  }
  