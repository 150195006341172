import { 
  LIST_PART_COMPONENT,
  GET_PART_COMPONENT,
  ADD_PART_COMPONENT,
  UPDATE_PART_COMPONENT,
  DELETE_PART_COMPONENT
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listPartComponents = (params) => async dispatch => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: 'GET',
      url: `/part_component`,
      params
    });
    dispatch({
      type: LIST_PART_COMPONENT,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: LIST_PART_COMPONENT,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const getPartComponent = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/part_component/${id}`,
    });
    dispatch({
      type: GET_PART_COMPONENT,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: GET_PART_COMPONENT,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addPartComponent = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/part_component`,
      data
    });
    dispatch({
      type: ADD_PART_COMPONENT,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: ADD_PART_COMPONENT,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const updatePartComponent = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/part_component/${id}`,
      data
    });
    dispatch({
      type: UPDATE_PART_COMPONENT,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: UPDATE_PART_COMPONENT,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const deletePartComponent = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/part_component/${id}`,
    });
    dispatch({
      type: DELETE_PART_COMPONENT,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: DELETE_PART_COMPONENT,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}