const Joi = require('joi');

const schema = Joi.object({
  platform: Joi.object().messages({
    "object.base": `"Platform" is required`
  }),
  make: Joi.object().messages({
    "object.base": `"Make" is required`
  }),
  model: Joi.object().messages({
    "object.base": `"Model" is required`
  }),
  variant: Joi.object().messages({
    "object.base": `"Variant" is required`
  }),
  vin: Joi.string().alphanum().length(17).required().messages({
    "string.empty": `"VIN" is required`,
    "string.length": `"VIN" must be 17 characters long`,
    "string.alphanum": `"VIN" can only consist of Alpha-numeric characters`
  }),
  regNo: Joi.string().alphanum().length(7).allow("").messages({
    "string.length": `"Reg No" must be 7 characters long`,
    "string.alphanum": `"Reg No" can only consist of Alpha-numeric characters`,
  }),
  tag: Joi.string().alphanum().length(8).allow("").messages({
    "string.length": `"Tag" must be 8 characters long`,
    "string.alphanum": `"Tag" can only consist of Alpha-numeric characters`,
  }),
  chipId: Joi.string().alphanum().length(10).allow("").messages({
    "string.length": `"Chip ID" must be 10 characters long`,
    "string.alphanum": `"Chip ID" can only consist of Alpha-numeric characters`,
  }),
  modelYear: Joi.number().min(1800).max(2200).allow("").messages({
    "number.min": `Invalid "Model year"`,
    "number.max": `Invalid "Model year"`,
  }),
  bodyStyle: Joi.string().allow(""),
  engine: Joi.string().allow(""),
  transmission: Joi.string().allow(""),
  slNo: Joi.string().length(12).regex(/^\d+$/).allow("").messages({
    "string.length": `"Serial No" must be 12 characters long`,
    "string.pattern.base": `Invalid "Serial No"`
  }),
  fuelType: Joi.string().allow(""),
  color: Joi.string().allow(""),
  market: Joi.string().allow(""),
});

export {
  schema
}