import React, { useState, useEffect } from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core';
import useStyles from './jss/style';

const Index = ({
  open=true,
  discardable=true,
  contained=false,
  ...rest
}) => {
  const classes = useStyles();
  const [openKey, setOpenKey] = useState(false);

  useEffect(() => {
    setOpenKey(open);
  }, [open])

  const handleClose = () => {
    if(discardable) setOpenKey(false);
  }
  
  return (
    <Backdrop
      {...rest}
      className={ contained ? classes.contained: classes.backdrop }
      open={ openKey }
      // onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default Index;