import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontWeight: "bold",
    fontSize: 12,
  },
  body: {
    fontSize: 13,
  },
}))(TableCell);

const SecondHeaderStyledTableCell = withStyles((theme) => ({
  head: {
    fontWeight: "bold",
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// const useStyles = makeStyles({
//   table: {
//     minWidth: 700,
//   },
// });
const Index = ({
  header,
  rows,
  noOfCols = 1,
  alignHeader = "left",
  secondHeaderAlign = "left",
  secondHeader = false,
  size = "small",
}) => {
  // const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={""} aria-label="customized table" size={size}>
        {
          <TableHead>
            {header && (
              <TableRow>
                <StyledTableCell align={alignHeader} colSpan={noOfCols}>
                  {header}
                </StyledTableCell>
              </TableRow>
            )}
            {header && secondHeader && (
              <TableRow>
                {secondHeader.map((item, i) => (
                  <SecondHeaderStyledTableCell
                    key={i}
                    align={secondHeaderAlign}
                  >
                    {item}
                  </SecondHeaderStyledTableCell>
                ))}
              </TableRow>
            )}
            {!header && secondHeader && (
              <TableRow>
                {secondHeader.map((item, i) => (
                  <SecondHeaderStyledTableCell
                    key={i}
                    align={secondHeaderAlign}
                  >
                    {item}
                  </SecondHeaderStyledTableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
        }
        <TableBody>
          {rows.map((row, i) => (
            <StyledTableRow key={i}>
              {Array.from(Array(noOfCols)).map((temp, j) => (
                <StyledTableCell key={j}>
                  {/* {`${!secondHeader ? row[j].label + ":" : ""}`} {row[j].value} */}
                  {row[j].label ?( (!secondHeader ? row[j].label + ":" : "") + (row[j].value)):row[j].value}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Index;
