import { 
  GET_MODELS_PERFORMANCE, RESET_MODELS_REPORT
} from '../action_types';

const initialState = {
  data: {},
  isLoading: true,
  hasError: false
}

const index = (state=initialState, action)  => {
  const { type, payload } = action;

  switch(type) {
    case GET_MODELS_PERFORMANCE: 
      return {
        ...state,
        ...payload
      };
    case RESET_MODELS_REPORT: {
        return initialState;
      }
    default: return state;
  }
}

export default index;