import { callAPI } from "../../utils/apiHelper";
import {
  LIST_OBJECT_CHECKPOINT_LOG,
  GET_OBJECT_CHECKPOINT_LOG,
  ADD_OBJECT_CHECKPOINT_LOG,
  UPDATE_OBJECT_CHECKPOINT_LOG,
  DELETE_OBJECT_CHECKPOINT_LOG
} from "../action_types";

export const listCheckpointLogs = (params) => async (dispatch) => {
  callAPI(dispatch,`/object_checkpoint_logs`, 'GET',LIST_OBJECT_CHECKPOINT_LOG,params,true);
};


export const getCheckpointLog = (id) => async (dispatch) => {
  callAPI(dispatch,`/object_checkpoint_logs/${id}`, 'GET',GET_OBJECT_CHECKPOINT_LOG,{},true);
};

export const addCheckpointLog = (data) => async (dispatch) => {
  callAPI(dispatch,`/object_checkpoint_logs`, 'POST',ADD_OBJECT_CHECKPOINT_LOG,{},false,data);
};


export const updateCheckpointLog = (data, id) => async (dispatch) => {
  callAPI(dispatch,`/object_checkpoint_logs/${id}`, 'PUT',UPDATE_OBJECT_CHECKPOINT_LOG,{},false,data);
};


export const deleteCheckpointLog = (id) => async (dispatch) => {
  callAPI(dispatch,`/object_checkpoint_logs/${id}`, 'DELETE',DELETE_OBJECT_CHECKPOINT_LOG,{},false);
};
export const clearCheckpointLogData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_OBJECT_CHECKPOINT_LOG,
    payload: {
      data: null,
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
}; 
