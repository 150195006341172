import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf';
import jsPDF from 'jspdf';
import {
  Divider,
  Grid, Hidden, useTheme,
  withStyles, Typography, Box
} from '@material-ui/core';
import {
  DatePicker as CustomDatePicker
} from '@material-ui/pickers';
import { 
  TextField as CustomTextField,
  Button,
  AutoComplete as CustomAutoComplete,
  IconButton, DynamicForm,
  SingleHeaderTable
} from '../../../commons/mui';
import useStyles from '../../../styles/jss/style';
import { 
  FORMCONFIG,
  SAMPLE_DATA as DATA,
} from './CONSTANTS';
import {
  DIALOGS,
  FORMS,
  ROLES,
} from '../../../commons/CONSTANTS';
import { 
  AddCircleOutline,
  CloudDownload as CloudDownloadIcon,
  Print as PrintIcon,
} from '@material-ui/icons';
import {
  JobcardTemplate
} from '../';
import { listWorkshops } from '../../../redux/actions/workshop';
import { listCostCentres, addCostCentre } from '../../../redux/actions/costCentre';
import { listLocations, addLocation } from '../../../redux/actions/location';
import { listJobTypes, addJobType } from '../../../redux/actions/jobType';
import { listJobParameters, addJobParameter } from '../../../redux/actions/jobParameter';
import { listPartTypes, addPartType } from '../../../redux/actions/partType';
import { listPartComponents, addPartComponent } from '../../../redux/actions/partComponent';
import { listNewParts, addNewPart } from '../../../redux/actions/newPart';
import { listVehicles } from '../../../redux/actions/vehicle';
import { addJobcard } from '../../../redux/actions/jobcard';
import { supervisorsByWorkshop } from '../../../redux/selectors/user';
import { listUsers } from '../../../redux/actions/user';
import { Link, useHistory } from 'react-router-dom';
import { schema } from '../CONSTANTS';
import convertToUTC from '../../../utils/convertToUTC';

const TextField = withStyles((theme) => ({
  root: {
    marginBottom:theme.spacing(3),
  }
}))(CustomTextField);

const AutoComplete = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  }
}))(CustomAutoComplete);

const DatePicker = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  }
}))(CustomDatePicker);

const Index = () => {
  const history = useHistory();
  const theme=useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [jobcardObj, setJobcardObj] = useState({
    requestorName: '',
    jobcardId: `JOBC-${Math.floor((Math.random()*10**7)+ (10**7))}`,
    workshop: null,
    costCentre: '',
    location: '',
    vin: null,
    tag: '',
    regNo: '',
    jobType: null,
    jobParameter: null,
    ipWeek: null,
    keyLocation: null,
    mileageRequired: null,
    startValue:'',
    partType: null,
    partComponent: null,
    partDescription: '',
    newPart: null,
    toolsUsed: '',
    idNoOrDescription: '',
    risJobcard: null,
    preferredStartDate: null,
    reason: '',
    summary: '',
    instructions: ''
  });
  const auth = useSelector(state => state.auth.data);
  const jobcard = useSelector(state => state.jobcard.list);
  const workshops = useSelector(state =>  state.workshop);
  const costCentres = useSelector(state =>  state.costCentre);
  const locations = useSelector(state => state.location);
  const jobcardTypes = useSelector(state => state.jobType);
  const jobcardParameters = useSelector(state => state.jobParameter);
  const partComponents = useSelector(state => state.partComponent);
  const newParts = useSelector(state => state.newPart);
  const partTypes = useSelector(state => state.partType);
  const vehicles = useSelector(state => state.vehicle);
  const wipStatuses = useSelector(state=>state.wipStatus.list.data);
  const [jobCreatedSts, setJobCreatedSts] = useState(null);
  const [printing, setPrinting] = useState(false);  
  const [errors, setErrors] = useState([]);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [hiddenElements, setHiddenElements] = useState({
    jobcardId: false,
    workshop: false,
    costCentre: false,
    location: false,
    vin: false,
    tag: false,
    regNo: false,
    jobType: false,
    jobParameter: false,
    ipWeek: true,
    keyLocation: false,
    mileageRequired: false,
    startValue: true,
    partType: true,
    partComponent: true,
    partDescription: true,
    newPart: true,
    toolsUsed: true,
    idNoOrDescription: true,
    risJobcard: false,
    preferredStartDate: false,
    reason: false,
    summary: false,
    instructions: false,
  });
  const [disabledElements, setDisabledElements] = useState({
    jobcardId: false,
    workshop: false,
    costCentre: false,
    location: false,
    vin: false,
    tag: false,
    regNo: false,
    jobType: false,
    jobParameter: false,
    ipWeek: false,
    keyLocation: false,
    mileageRequired: false,
    startValue: false,
    partType: false,
    partComponent: false,
    partDescription: true,
    newPart: false,
    toolsUsed: false,
    idNoOrDescription: false,
    risJobcard: false,
    preferredStartDate: false,
    reason: false,
    summary: false,
    instructions: false,
  });
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title:'sample',
    text:'sample',
    open: false,
    btn1: {
      text: 'No',
      onClick: () => {}
    },
    btn2: {
      text: 'Yes',
      onClick: ()=>{}
    }
  });
  const [showInfoDialog, setShowInfoDialog] = useState({
    title:'sample',
    open: false,
    children: <div></div>,
    btn1: {
      text: 'Okay',
      onClick: () => {}
    },
  });
  
  useEffect(() => {
    dispatch(listWorkshops());
    dispatch(listUsers());
    dispatch(listCostCentres());
    dispatch(listLocations());
    dispatch(listVehicles());
    dispatch(listJobTypes());
    dispatch(listJobParameters());
    dispatch(listNewParts());
    dispatch(listPartTypes());
    dispatch(listPartComponents());
  }, [])

  useEffect(() => {
    if(submitFlag && jobcard.data?.length > 0) {
      history.push('/jobcard');
    }
  }, [jobcard]);

  useEffect(() => {
    if(auth?.accessToken) {
      setJobcardObj( old => ({
        ...old,
        requestorName: auth || null
      }));
    }
  }, [auth])

  useEffect(() => {
    if(wipStatuses?.length > 0) {
      setJobCreatedSts(wipStatuses.find(item => item.name === 'Jobcard Created')||null);
    }
  },[wipStatuses])

  const onSubmit = (e) => {
    e.preventDefault();
    const { error, value } = schema.validate(jobcardObj, {abortEarly: false});
    if( !error ) {
      setErrors([]);
      setSubmitFlag(true);
      confirmToSubmit();
    } else {
      setErrors([]);
      setFoundErrors(error?.details);
      console.debug(error?.details, value)
    }
  }
  
  const confirmToSubmit = () => {
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: `Create Jobcard`,
      text: 'Are you sure ?',
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => {
          let temp = {...jobcardObj};
          temp = {
            ...temp,
            // requestorName: auth?._id || null,
            vehicleHandInLocation: typeof temp.location === 'string'? temp.location: temp.location?.name|| '',
            // supervisor: temp.supervisor?.title,
            ipWeek: temp.ipWeek?.title,
            keyLocation: temp.keyLocation?.title,
            mileageRequired: temp.mileageRequired?.title === 'Yes',
            risJobcard: temp.risJobcard?.title === 'Yes',
            preferredStartDate: formatDate(temp.preferredStartDate),
            wipStatus: jobCreatedSts
          }
          dispatch(addJobcard(temp));
        }
      }
    }));
  }

  const setFoundErrors = (errArr = []) => {
    errArr.forEach(item => {
      setErrors( old => ({
        ...old,
        [item.path[0]]: {
          error: true,
          message: item?.message
        }
      }));
    });
  }

  const submitNewObject = (e, item) => {
    switch(item.name){
      case 'costCentre': return dispatch(addCostCentre({name: e.target[0].value, description: e.target[1].value}));
      // case 'location':  return dispatch(addLocation({city: e.target[1].value, country: e.target[0].value}));
      case 'jobType': return dispatch(addJobType({name: e.target[0].value, description: e.target[1].value}));
      case 'jobParameter': return dispatch(addJobParameter({jobcardType: jobcardObj.jobType?._id, name: e.target[0].value, description: e.target[1].value}));
      case 'partType': return dispatch(addPartType({name: e.target[0].value, description: e.target[1].value}));
      case 'partComponent': return dispatch(addPartComponent({name: e.target[0].value, description: e.target[1].value}));
      case 'newPart': return dispatch(addNewPart({name: e.target[0].value, description: e.target[1].value}));
      default: return;
    }
  }
  
  const onTFChange = (e) => {
    setJobcardObj(old => ({
      ...old,
      [e.target.name]: e.target.value
    }))
  }

  const onACChange = (e, name, value) => {
    setJobcardObj(old => ({
      ...old,
      [name]: value === null ? null : value
    }))
  }

  const onDateChange = (name, date) => {
    setJobcardObj(old => ({
      ...old,
      [name]: date
    }))
  }
  
  const addNewItem = (item) => {
    alert(`Added successfully!`);
  }

  const mapData = (item) => {
    let props = {
      value: jobcardObj[item.name],
      onChange:(event, newValue) => {
        onACChange(event, item.name, newValue);
      },
      options: item.source ? eval(item.source).list.data: DATA[item.local],
      matchSorterOptions: {keys: ['name']},
      customOptionLabel: (option) => option.name,
    }

    switch(item.name) {
      case 'workshop': props = {
          ...props,
          options: workshops.list.data,
          onInputChange:(event, inputValue) => {
            dispatch(listWorkshops({name: inputValue}));
          },
        }
      break;
      case 'costCentre': props = {
          ...props,
          options: costCentres.list.data,
        }
      break;
      case 'location': props =  {
          ...props,
          options: [{name: 'Keyroom'},...workshops.list.data],
          onInputChange:(event, inputValue) => {
            dispatch(listWorkshops({name: inputValue}));
          },
          matchSorterOptions: {keys: ['name']},
          // matchSorterOptions: {keys: ['city', 'country']},
          customOptionLabel: (option) => option.name
          // customOptionLabel: (option) => option._id ? `${option.city}, ${option.country}`: ''
        }
      break;
      case 'vin': props = {
          ...props,
          options: vehicles.list.data,
          customOptionLabel: (option) => option.vin,
          matchSorterOptions: {keys: ['vin']},
          onChange:(event, newValue) => {
            setJobcardObj(old => ({
              ...old,
              vin: newValue === null ? null : newValue,
              tag: newValue === null ? null : newValue,
              regNo: newValue === null ? null : newValue,
            }))
            setDisabledElements((old) => ({
              ...old,
              tag: newValue !== null,
              regNo: newValue !== null
            }))
          },
          onInputChange:(event, inputValue) => {
            dispatch(listVehicles({vin: inputValue}));
          },
        }
      break;
      case 'tag': props = {
          ...props,
          options: vehicles.list.data,
          customOptionLabel: (option) => option.tag,
          matchSorterOptions: {keys: ['tag']},
          onChange:(event, newValue) => {
            setJobcardObj(old => ({
              ...old,
              vin: newValue === null ? null : newValue,
              tag: newValue === null ? null : newValue,
              regNo: newValue === null ? null : newValue,
            }))
            setDisabledElements((old) => ({
              ...old,
              tag: newValue !== null,
              regNo: newValue !== null
            }))
          },
        }
      break;
      case 'regNo': props = {
          ...props,
          options: vehicles.list.data,
          customOptionLabel: (option) => option.regNo,
          matchSorterOptions: {keys: ['regNo']},
          onChange:(event, newValue) => {
            setJobcardObj(old => ({
              ...old,
              vin: newValue === null ? null : newValue,
              tag: newValue === null ? null : newValue,
              regNo: newValue === null ? null : newValue,
            }))
            setDisabledElements((old) => ({
              ...old,
              tag: newValue !== null,
              regNo: newValue !== null
            }))
          },
        }
      break;
      case 'jobType': props =  {
          ...props,
          options: jobcardTypes.list.data,
          onChange:(event, newValue) => {
            setJobcardObj(old => ({
              ...old, 
              jobType: newValue,
              ipWeek: null
            }));
            setHiddenElements(old => ({
              ...old,
              ipWeek: !(newValue?.name === 'Software Update'),
            }));
          },
        }
      break;
      case 'jobParameter': props =  {
          ...props,
          options: jobcardParameters.list.data,
        }
      break;
      case 'mileageRequired': props = {
          ...props,
          options: DATA.MILEAGE_REQURED,
          onChange:(event, newValue) => {
            setJobcardObj(old => ({
              ...old, 
              mileageRequired: newValue,
              startValue: ''
            }));
            setHiddenElements(old => ({
              ...old,
              startValue: !(newValue?.name === 'Yes'),
            }));
          },
        }
      break;
      case 'risJobcard': props = {
          ...props,
          options: DATA.RIS_JOBCARD,
          onChange:(event, newValue) => {
            let temp = !(newValue?.name === 'Yes');
            setJobcardObj(old => ({
              ...old, 
              risJobcard: newValue,
              partType: null,
              partComponent: null,
              newPart: null,
              partDescription: '',
              toolsUsed: '',
              idNoOrDescription: '',
            }));
            setHiddenElements(old => ({
              ...old,
              partType: temp,
              partComponent: temp,
              newPart: temp,
              partDescription: temp,
              toolsUsed: temp,
              idNoOrDescription: temp,
            }));
          },
        }
      break;
      case 'partType': props = {
          ...props,
          options: partTypes.list.data,
          onChange: (event, newValue) => {
            setJobcardObj(old => ({
              ...old,
              partType: newValue,
              partDescription: newValue?.description || ''
            }));
          }
        }
      break;
      case 'partComponent': props = {
          ...props,
          options: partComponents.list.data,
        }
      break;
      case 'newPart': props = {
          ...props,
          options: newParts.list.data,
        }
      break;
      default : break;
    }
    return props;
  }

  const mapTFData = (item) => {
    let props = {
      disabled: disabledElements[item.name],
      value: jobcardObj[item.name],
    }
    switch(item.name) {
      default: break;
    }
    return props;
  }

  const formatDate = (date) => convertToUTC(date, "MM-dd-yyyy kk:mm:ss xxx", "UTC");
  
  const selectFormElement = (item) => {
    switch(item.component) {
      case 'Textfield':     return <TextField 
                              {...item.props}
                              {...mapTFData(item)}
                              error={errors[item.name]?.error || false}
                              helperText={errors[item.name]?.message || ""}
                              FormHelperTextProps={{style:{whiteSpace: 'pre-wrap'}}}
                              onChange={onTFChange}
                            />
      case 'Autocomplete':  return <AutoComplete
                              {...item.props}
                              disabled={disabledElements[item.name]}
                              textFieldParams = {{
                                error: errors[item.name]?.error || false,
                                helperText: errors[item.name]?.message || "",
                                FormHelperTextProps:{style:{whiteSpace: 'pre-wrap'}}
                              }}
                              {...mapData(item)}
                            />
      case 'Datepicker':    return <DatePicker 
                              {...item.props}
                              value={jobcardObj[item.name]}
                              error={errors[item.name]?.error || false}
                              helperText={errors[item.name]?.message || ""}
                              FormHelperTextProps={{style:{whiteSpace: 'pre-wrap'}}}
                              onChange={(value) => onDateChange(item.name, value)}
                            />
    }
  }

  const addIconButton = (item) => {
    if(item.dialog) {
      return <DynamicForm
          Component={(props) => 
            <IconButton {...props} color="primary" icon={<AddCircleOutline style={{ height:'32', width:'32' }} />} />}
          title={item.dialog.title}
          content={ (closeDialog) =>
          FORMS.GENERAL_TF_FORM({
          onSubmit: (e)=> { 
            e.preventDefault();
            e.stopPropagation();
            setShowConfirmDialog((old) => ({
              ...old,
              open: true,
              title: `Please confirm to add new ${item.dialog.name}`,
              text: 'Are you sure ?',
              btn1: {
                ...old.btn1,
              },
              btn2: {
                ...old.btn2,
                onClick: () => submitNewObject(e, item),
              }
            }));
            closeDialog();
          },
          onReset:  (e) => {
            e.preventDefault();
            e.stopPropagation();
            closeDialog();
          },
          container: `${classes.paddingX}`,
          ...item.dialog,
        },
        )}
      />
    }
    return <IconButton disabled color="primary" icon={<AddCircleOutline style={{visibility:'hidden', height:'32', width:'32' }} />} />
  }

  const renderForm = (model) => {
    return model.map((item, i) => <div key={i} className={`${classes.fullWidth}`} style={{display:`${hiddenElements[item.name]? 'none': 'flex'}`, visibility: `${hiddenElements[item.name]? 'hidden': 'visible'}`}}>
        {selectFormElement(item)}
        {addIconButton(item)}
      </div>
    )
  }

  // const convertToPdf = (action) => {
  //   const block = document.getElementById('printable-block');
  //   // block.css({
  //   //   height: "",
  //   // });
  //   console.log(block);
  //   // html2canvas(block).then(canvas => {
  //   //   const convertToPng = canvas.toDataURL('image/jpeg');
  //   //   console.log(convertToPng);
  //   //   const pdf = new jsPDF();
  //   //   pdf.addImage(convertToPng, 'JPEG', 0,0);
  //     const pdf = html2pdf(block)
  //     if(action === 'print') {
  //       pdf.autoPrint();
  //       window.open(pdf.output('bloburl'), '_blank')
  //       // pdf.output("dataurilnewwindow")
  //     } else {
  //       pdf.save("Jobcard.pdf");
  //     }
  //   // });
  // }

  const convertToPdf = action => {
    setPrinting(true);
    const block = document.getElementById('printable-block');
    html2canvas(block).then((canvas) => {  
      var imgWidth = 200;  
      var pageHeight = '290';  
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  
      const imgData = canvas.toDataURL('image/png');  
      const pdf = new jsPDF('p', 'mm', 'a4')  
      var position = 0;  
      var heightLeft = imgHeight;  
      pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);  
      if(action === 'print') {
        pdf.autoPrint();
        window.open(pdf.output('bloburl'), '_blank')
        // pdf.output("dataurilnewwindow")
      } else {
        pdf.save(`Jobcard-${jobcardObj.jobcardId}.pdf`);
      }
      setPrinting(false);
    }); 
  }

  return (
    <Grid container className={`${classes.paddingX} ${classes.stretchHeight}`}>
      <Grid item container className={`${classes.height5}`}>
        <Typography variant='h6' align='left' gutterBottom>Manage Job Card</Typography>
      </Grid>
      <Grid item container className={`${classes.height95}`} direction='column'>
        <Grid item container direction='row' className={`${classes.fullWidth} ${classes.stretchHeight}`} >
          <Grid item container xs={12} sm={6} md={6} direction='row' className={`${classes.stretchHeight}`}>
            <form onSubmit={onSubmit} className={`${classes.fullWidth} ${classes.stretchHeight}`} noValidate>
              <Grid item container xs={12} justifyContent='space-between' className={`${classes.height90}`} style={{overflowY: 'auto', whiteSpace:'nowrap'}}>
                <Grid item xs={12} sm={6} direction='row' className={`${classes.paddingRight}`}>
                  {renderForm(FORMCONFIG.slice(0,10))}
                </Grid>
                <Grid item xs={12} sm={6} direction='row' className={`${classes.paddingRight}`}>
                  {renderForm(FORMCONFIG.slice(10))}
                </Grid>
              </Grid>
              <Grid item container xs={12} className={`${classes.height10}`} direction='column' alignItems='center' justifyContent='center'>
                <Grid item container xs={6} justifyContent='flex-end'>
                  <Button text='Cancel' variant='contained' component={Link} to='/jobcard' />
                </Grid>
                <Grid item container xs={6} justifyContent='flex-start'>
                  <Button type='submit' text='Submit' variant='contained' color='primary'/>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Hidden xsDown>
            <Grid item container sm={6} direction='column' className={`${classes.stretchHeight}`}>
              <Divider orientation='vertical'/>
              <Grid id="printable-block" container direction='column' className={`${classes.fullWidth} ${classes.paddingLeft} ${classes.stretchHeight}`}>                
                <Grid item container className={`${classes.stretchHeight}`}>
                  <Grid item container sm={12} className={`${classes.height10}`}>
                    <Grid item sm={8}>
                      <Typography className={`${classes.fullWidth}`} 
                        variant='h5' align='left' gutterBottom>
                        Vehicle Engineering Job Card
                      </Typography>
                    </Grid>
                    <Grid item container sm={4} justifyContent='flex-end' className={`${classes.paddingX}`}>
                      <Grid item className='hidden-from-print'>
                        <IconButton color="primary" icon={<CloudDownloadIcon/>} onClick={(e) => convertToPdf('download')}></IconButton>
                      </Grid>
                      <Grid item className='hidden-from-print'>
                        <IconButton color="primary" icon={<PrintIcon/>} onClick={(e) => convertToPdf('print')}></IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container sm={12} className={`${classes.height90} ${classes.hideScrollbar}`} style={{overflowY: 'scroll', whiteSpace:'nowrap'}}>
                    <JobcardTemplate data={jobcardObj}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      {
        DIALOGS.CONFIRM_DIALOG({
          title: showConfirmDialog.title,
          text: showConfirmDialog.text,
          open: showConfirmDialog.open,
          setOpen: setShowConfirmDialog,
          btn1:showConfirmDialog.btn1,
          btn2:showConfirmDialog.btn2,
          container: `${classes.paddingX}`,
        })
      }  
      {
        DIALOGS.INFO_DIALOG({
          ...showInfoDialog,
          setOpen: setShowInfoDialog,
          container: `${classes.paddingX}`,
        })
      } 
    </Grid>
  )
}

export default Index