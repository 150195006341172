import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Pagination, Table } from "../../../commons/mui";
import { TABLE_HEADER } from "./CONSTANTS";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { deleteRole, listRoles } from "../../../redux/actions/role";
import { useDispatch, useSelector } from "react-redux";
import { PDSLADMIN } from "../../Projects/AllProjects/CONSTANTS";
import { DIALOGS } from "../../../commons/CONSTANTS";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: 290,
    },
  },
  input: {
    marginTop: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "1rem",
    },
  },
}));

export default function Index() {
  const classes = useStyles();
  const history = useHistory();
  const [partList, setpartList] = useState([]);
  const dispatch = useDispatch();
  const [totalCount, settotalCount] = useState(null);
  const roles = useSelector((state) => state.role);
  const auth = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: "sample",
    text: "sample",
    open: false,
    btn1: {
      text: "No",
      onClick: () => {},
    },
    btn2: {
      text: "Yes",
      onClick: () => {},
    },
  });

  useEffect(() => {
    dispatch(listRoles({ skip: 10 * (page - 1) }));
  }, [page]);

  useEffect(() => {
    if (roles.list.data) {
      settotalCount(roles.list.totalCount);
      setpartList(roles.list.data);
    }
  }, [roles]);

  const onDelete = (i, e, item) => {
    // console.log(item, i, item.name || item.projectNo)
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: "Please confirm to delete",
      text: `You are about to delete role. Are you sure ?`,
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => {  dispatch(deleteRole(item._id));
             setTimeout(() => {
              dispatch(listRoles({ skip: 10 * (page - 1) }));
            }, 500);},

      },
    }));
  };

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2.5rem",
          alignItems: "center",
        }}
      >
        <h2>Role List</h2>
        <Button
          width="200px"
          text="Add Roles"
          variant="contained"
          color="primary"
          onClick={() => history.push(auth.data?.role?.name === PDSLADMIN ? "/add/super-roles" : "/add/roles")}
        />
      </Grid>
      {DIALOGS.CONFIRM_DIALOG({
        title: showConfirmDialog.title,
        text: showConfirmDialog.text,
        open: showConfirmDialog.open,
        setOpen: setShowConfirmDialog,
        btn1: showConfirmDialog.btn1,
        btn2: showConfirmDialog.btn2,
        container: `${classes.paddingX}`,
      })}
      <Grid style={{ marginTop: "1rem" }}>
        <Table
          // showDelete={false}
          header={TABLE_HEADER}
          data={partList}
          onDelete={onDelete}
          // onDelete={(i, e, row) => {
          //   dispatch(deleteRole(row._id));
          //   setTimeout(() => {
          //     dispatch(listRoles({ skip: 10 * (page - 1) }));
          //   }, 500);
          // }}
          onEdit={(i, row) => {
            history.push(auth.data?.role?.name === PDSLADMIN ? `/edit/super-roles/${row._id}` : `/edit/roles/${row._id}`);
          }}
        />
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        <div className="ui-component-block">
          <div className="ui-components">
            <Pagination count={totalCount} page={page} setPage={setPage} />
          </div>
        </div>
      </Grid>
    </div>
  );
}
