import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf';
import jsPDF from 'jspdf';
import {
  Divider,
  Grid, Hidden, useTheme,
  withStyles, Typography, Box
} from '@material-ui/core';
import {
  DatePicker as CustomDatePicker
} from '@material-ui/pickers';
import { 
  TextField as CustomTextField,
  Button,
  AutoComplete as CustomAutoComplete,
  IconButton, DynamicForm,
  SingleHeaderTable
} from '../../../commons/mui';
import useStyles from '../../../styles/jss/style';
import { 
  FORMCONFIG,
  SAMPLE_DATA as DATA,
} from './CONSTANTS';
import {
  DIALOGS,
  FORMS,
  ROLES,
} from '../../../commons/CONSTANTS';
import { 
  AddCircleOutline,
  CloudDownload as CloudDownloadIcon,
  Print as PrintIcon,
} from '@material-ui/icons';
import { listWorkshops } from '../../../redux/actions/workshop';
import { listCostCentres, addCostCentre } from '../../../redux/actions/costCentre';
import { listLocations, addLocation } from '../../../redux/actions/location';
import { listJobTypes, addJobType } from '../../../redux/actions/jobType';
import { listJobParameters, addJobParameter } from '../../../redux/actions/jobParameter';
import { listPartTypes, addPartType } from '../../../redux/actions/partType';
import { listPartComponents, addPartComponent } from '../../../redux/actions/partComponent';
import { listNewParts, addNewPart } from '../../../redux/actions/newPart';
import { listVehicles } from '../../../redux/actions/vehicle';
import { addJobcard, getJobcard, updateJobcard } from '../../../redux/actions/jobcard';
import { supervisorsByWorkshop } from '../../../redux/selectors/user';
import { listUsers } from '../../../redux/actions/user';
import { Link, useHistory } from 'react-router-dom';
import { schema } from '../CONSTANTS';
import convertToUTC from '../../../utils/convertToUTC';

const TextField = withStyles((theme) => ({
  root: {
    marginBottom:theme.spacing(3),
  }
}))(CustomTextField);

const AutoComplete = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  }
}))(CustomAutoComplete);

const DatePicker = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  }
}))(CustomDatePicker);

const Index = (props) => {
  const theme=useTheme();
  const _id = props?.match?.params?.id || localStorage.getItem('_id') || null;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [jobcardObj, setJobcardObj] = useState({
    jobcardId: `JOBC-${Math.floor((Math.random()*10**7)+ (10**7))}`,
    workshop: null,
    costCentre: '',
    location: '',
    vin: null,
    tag: '',
    regNo: '',
    jobType: null,
    jobParameter: null,
    ipWeek: null,
    keyLocation: null,
    mileageRequired: null,
    startValue:'',
    partType: null,
    partComponent: null,
    partDescription: '',
    newPart: null,
    toolsUsed: '',
    idNoOrDescription: '',
    risJobcard: null,
    preferredStartDate: null,
    reason: '',
    summary: '',
    instructions: ''
  });
  const jobcard = useSelector(state => state.jobcard);
  const workshops = useSelector(state =>  state.workshop);
  const costCentres = useSelector(state =>  state.costCentre);
  const locations = useSelector(state => state.location);
  const jobcardTypes = useSelector(state => state.jobType);
  const jobcardParameters = useSelector(state => state.jobParameter);
  const partComponents = useSelector(state => state.partComponent);
  const newParts = useSelector(state => state.newPart);
  const partTypes = useSelector(state => state.partType);
  const vehicles = useSelector(state => state.vehicle);
  const [printing, setPrinting] = useState(false);  
  const [errors, setErrors] = useState([]);
  const [submitFlag, setSubmitFlag] = useState(false);
  const supervisors = useSelector(state => supervisorsByWorkshop(state, [ROLES.supervisor], jobcardObj.workshop?._id));
  const [hiddenElements, setHiddenElements] = useState({
    jobcardId: false,
    workshop: false,
    costCentre: false,
    location: false,
    vin: false,
    tag: false,
    regNo: false,
    jobType: false,
    jobParameter: false,
    ipWeek: true,
    keyLocation: false,
    mileageRequired: false,
    startValue: true,
    partType: true,
    partComponent: true,
    partDescription: true,
    newPart: true,
    toolsUsed: true,
    idNoOrDescription: true,
    risJobcard: false,
    preferredStartDate: false,
    reason: false,
    summary: false,
    instructions: false,
  });
  const [disabledElements, setDisabledElements] = useState({
    jobcardId: false,
    workshop: false,
    costCentre: false,
    location: false,
    vin: false,
    tag: false,
    regNo: false,
    jobType: false,
    jobParameter: false,
    ipWeek: false,
    keyLocation: false,
    mileageRequired: false,
    startValue: false,
    partType: false,
    partComponent: false,
    partDescription: true,
    newPart: false,
    toolsUsed: false,
    idNoOrDescription: false,
    risJobcard: false,
    preferredStartDate: false,
    reason: false,
    summary: false,
    instructions: false,
  });
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title:'sample',
    text:'sample',
    open: false,
    btn1: {
      text: 'No',
      onClick: () => {}
    },
    btn2: {
      text: 'Yes',
      onClick: ()=>{}
    }
  });
  
  useEffect(() => {
    if(_id) {
      dispatch(getJobcard(_id));
    }
    dispatch(listWorkshops());
    dispatch(listUsers());
    dispatch(listCostCentres());
    dispatch(listLocations());
    dispatch(listVehicles());
    dispatch(listJobTypes());
    dispatch(listJobParameters());
    dispatch(listNewParts());
    dispatch(listPartTypes());
    dispatch(listPartComponents());
  }, []);

  useEffect(() => {
    if(_id) {
      dispatch(getJobcard(_id))
    }
  }, [_id]);

  useEffect(() => {
    if(submitFlag && jobcard.data?.length > 0) {
      history.push('/jobcard');
      return;
    }
    if(jobcard.detail.data) {
      let data = jobcard.detail.data;
      let vin = vehicles.list.data.find(item => item?._id === ( data?.vin?._id || data?.vin ));
      let ipWeek = data?.ipWeek || '';
      let risJobcard = data?.risJobcard;
      let partType = partTypes.list.data.find(item => item._id === (data?.partType?._id || data?.partType)) || null;
      setJobcardObj(old => ({
        ...old,
        jobcardId: data.jobcardId,
        workshop: workshops.list.data.find(item => item._id === ( data?.workshop?._id || data?.workshop )) || null,
        costCentre: costCentres.list.data.find(item => item._id === ( data?.costCentre?._id || data?.costCentre )) || null,
        location: data?.vehicleHandInLocation === 'Keyroom' ? { name: 'Keyroom' } : workshops.list.data.find(item => item.name === data?.vehicleHandInLocation) || null,
        vin: vin || null,
        tag: vin || null,
        regNo: vin || null,
        jobType: jobcardTypes.list.data.find(item => item._id === (data?.jobType?._id || data?.jobType)) || null,
        jobParameter: jobcardParameters.list.data.find(item => item._id === (data?.jobParameter?._id || data?.jobParameter)) || null,
        ipWeek: DATA['IP_WEEKS'].find(item => item.name === data?.ipWeek) || null,
        keyLocation: data?.keyLocation === 'Keyroom' ? DATA['KEY_LOCATIONS'][0] : DATA['KEY_LOCATIONS'][1],
        mileageRequired: DATA['MILEAGE_REQURED'][data?.mileageRequired ? 1: 0],
        startValue: data?.mileageRequired && data?.startValue || '',
        risJobcard: DATA['RIS_JOBCARD'][data?.risJobcard ? 1: 0],
        preferredStartDate: data?.preferredStartDate || '',
        reason: data?.reason || '',
        summary: data?.summary || '',
        instructions: data?.instructions || '',
        partType: partType,
        partComponent: partComponents.list.data.find(item => item._id === (data?.partComponent?._id || data?.partComponent)) || null,
        partDescription: partType?.description || '',
        newPart: newParts.list.data.find(item => item._id === (data?.newPart?._id || data?.newPart)) || null,
        toolsUsed: data?.toolsUsed || '',
        idNoOrDescription: data?.idNoOrDescription || '',
      }));

      setHiddenElements(old => ({
        ...old,
        ipWeek: data?.jobType?.name === 'Software Update' ? false : true,
        startValue: !data?.mileageRequired,
        partType: !risJobcard,
        partComponent: !risJobcard,
        partDescription: !risJobcard,
        newPart: !risJobcard,
        toolsUsed: !risJobcard,
        idNoOrDescription: !risJobcard,
      }))
      setDisabledElements(old => ({
        ...old,
        tag: true,
        regNo: true,
      }));
    }
  }, [jobcard])

  const onSubmit = (e) => {
    e.preventDefault();
    const { error, value } = schema.validate(jobcardObj, {abortEarly: false});
    if( !error ) {
      setErrors([]);
      setSubmitFlag(true);
      confirmToSubmit();
    } else {
      setErrors([]);
      setFoundErrors(error?.details);
      console.debug(error?.details, value)
    }
    
    // alert('Data Added');
  }

  const confirmToSubmit = () => {
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: `Update job card`,
      text: 'Are you sure ?',
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => {
          let temp = {...jobcardObj};
          temp = {
            ...temp,
            vehicleHandInLocation: typeof temp.location === 'string'? temp.location: temp.location?.name|| '',
            ipWeek: temp.ipWeek?.title,
            keyLocation: temp.keyLocation?.title,
            mileageRequired: temp.mileageRequired?.title === 'Yes',
            risJobcard: temp.risJobcard?.title === 'Yes',
          }
          dispatch(updateJobcard(temp, _id));
        }
      }
    }));
  }

  const setFoundErrors = (errArr = []) => {
    errArr.forEach(item => {
      setErrors( old => ({
        ...old,
        [item.path[0]]: {
          error: true,
          message: item?.message
        }
      }));
    });
  }

  const submitNewObject = (e, item) => {
    switch(item.name){
      case 'costCentre': return dispatch(addCostCentre({name: e.target[0].value, description: e.target[1].value}));
      // case 'location':  return dispatch(addLocation({city: e.target[1].value, country: e.target[0].value}));
      case 'jobType': return dispatch(addJobType({name: e.target[0].value, description: e.target[1].value}));
      case 'jobParameter': return dispatch(addJobParameter({jobcardType: jobcardObj.jobType?._id, name: e.target[0].value, description: e.target[1].value}));
      case 'partType': return dispatch(addPartType({name: e.target[0].value, description: e.target[1].value}));
      case 'partComponent': return dispatch(addPartComponent({name: e.target[0].value, description: e.target[1].value}));
      case 'newPart': return dispatch(addNewPart({name: e.target[0].value, description: e.target[1].value}));
      default: return;
    }
  }
  
  const onTFChange = (e) => {
    setJobcardObj(old => ({
      ...old,
      [e.target.name]: e.target.value
    }))
  }

  const onACChange = (e, name, value) => {
    setJobcardObj(old => ({
      ...old,
      [name]: value === null ? null : value
    }))
  }

  const onDateChange = (name, date) => {
    setJobcardObj(old => ({
      ...old,
      [name]: date
    }))
  }
  
  const addNewItem = (item) => {
    alert(`Added successfully!`);
  }

  const mapData = (item) => {
    let props = {
      value: jobcardObj[item.name],
      onChange:(event, newValue) => {
        onACChange(event, item.name, newValue);
      },
      options: item.source ? eval(item.source).list.data: DATA[item.local],
      matchSorterOptions: {keys: ['name']},
      customOptionLabel: (option) => option.name,
    }

    switch(item.name) {
      case 'workshop': props = {
          ...props,
          options: workshops.list.data,
        }
      break;
      case 'costCentre': props = {
          ...props,
          options: costCentres.list.data,
        }
      break;
      case 'location': props =  {
          ...props,
          options: [{name: 'Keyroom'}, ...workshops.list.data],
          matchSorterOptions: {keys: ['name']},
          // matchSorterOptions: {keys: ['city', 'country']},
          customOptionLabel: (option) => option.name
          // customOptionLabel: (option) => option._id ? `${option.city}, ${option.country}`: ''
        }
      break;
      case 'supervisor': props = {
          ...props,
          options: supervisors,
          matchSorterOptions: {keys:['firstName', 'emailId', 'lastName']},
          customOptionLabel: (option) => option.firstName || option.emailId,
        }
      break;
      case 'vin': props = {
          ...props,
          options: vehicles.list.data,
          customOptionLabel: (option) => option.vin,
          matchSorterOptions: {keys: ['vin']},
          onChange:(event, newValue) => {
            setJobcardObj(old => ({
              ...old,
              vin: newValue === null ? null : newValue,
              tag: newValue === null ? null : newValue,
              regNo: newValue === null ? null : newValue,
            }))
            setDisabledElements((old) => ({
              ...old,
              tag: newValue !== null,
              regNo: newValue !== null
            }))
          },
        }
      break;
      case 'tag': props = {
          ...props,
          options: vehicles.list.data,
          customOptionLabel: (option) => option.tag,
          matchSorterOptions: {keys: ['tag']},
          onChange:(event, newValue) => {
            setJobcardObj(old => ({
              ...old,
              vin: newValue === null ? null : newValue,
              tag: newValue === null ? null : newValue,
              regNo: newValue === null ? null : newValue,
            }))
            setDisabledElements((old) => ({
              ...old,
              tag: newValue !== null,
              regNo: newValue !== null
            }))
          },
        }
      break;
      case 'regNo': props = {
          ...props,
          options: vehicles.list.data,
          customOptionLabel: (option) => option.regNo,
          matchSorterOptions: {keys: ['regNo']},
          onChange:(event, newValue) => {
            setJobcardObj(old => ({
              ...old,
              vin: newValue === null ? null : newValue,
              tag: newValue === null ? null : newValue,
              regNo: newValue === null ? null : newValue,
            }))
            setDisabledElements((old) => ({
              ...old,
              tag: newValue !== null,
              regNo: newValue !== null
            }))
          },
        }
      break;
      case 'jobType': props =  {
          ...props,
          options: jobcardTypes.list.data,
          onChange:(event, newValue) => {
            setJobcardObj(old => ({
              ...old, 
              jobType: newValue,
              ipWeek: null
            }));
            setHiddenElements(old => ({
              ...old,
              ipWeek: !(newValue?.name === 'Software Update'),
            }));
          },
        }
      break;
      case 'jobParameter': props =  {
          ...props,
          options: jobcardParameters.list.data,
        }
      break;
      case 'mileageRequired': props = {
          ...props,
          options: DATA.MILEAGE_REQURED,
          onChange:(event, newValue) => {
            setJobcardObj(old => ({
              ...old, 
              mileageRequired: newValue,
              startValue: ''
            }));
            setHiddenElements(old => ({
              ...old,
              startValue: !(newValue?.name === 'Yes'),
            }));
          },
        }
      break;
      case 'risJobcard': props = {
          ...props,
          options: DATA.RIS_JOBCARD,
          onChange:(event, newValue) => {
            let temp = !(newValue?.name === 'Yes');
            setJobcardObj(old => ({
              ...old, 
              risJobcard: newValue,
              partType: null,
              partComponent: null,
              newPart: null,
              partDescription: '',
              toolsUsed: '',
              idNoOrDescription: '',
            }));
            setHiddenElements(old => ({
              ...old,
              partType: temp,
              partComponent: temp,
              newPart: temp,
              partDescription: temp,
              toolsUsed: temp,
              idNoOrDescription: temp,
            }));
          },
        }
      break;
      case 'partType': props = {
          ...props,
          options: partTypes.list.data,
          onChange: (event, newValue) => {
            setJobcardObj(old => ({
              ...old,
              partType: newValue,
              partDescription: newValue?.description || ''
            }));
          }
        }
      break;
      case 'partComponent': props = {
          ...props,
          options: partComponents.list.data,
        }
      break;
      case 'newPart': props = {
          ...props,
          options: newParts.list.data,
        }
      break;
      default : break;
    }
    return props;
  }

  const mapTFData = (item) => {
    let props = {
      disabled: disabledElements[item.name],
      value: jobcardObj[item.name],
    }
    switch(item.name) {
      default: break;
    }
    return props;
  }

  const formatDate = (date) => convertToUTC(date, "MM-dd-yyyy kk:mm:ss xxx", "UTC");

  const selectFormElement = (item) => {
    switch(item.component) {
      case 'Textfield':     return <TextField 
                              {...item.props}
                              {...mapTFData(item)}
                              error={errors[item.name]?.error || false}
                              helperText={errors[item.name]?.message || ""}
                              FormHelperTextProps={{style:{whiteSpace: 'pre-wrap'}}}
                              onChange={onTFChange} 
                            />
      case 'Autocomplete':  return <AutoComplete
                              {...item.props}
                              disabled={disabledElements[item.name]}
                              textFieldParams = {{
                                error: errors[item.name]?.error || false,
                                helperText: errors[item.name]?.message || "",
                                FormHelperTextProps:{style:{whiteSpace: 'pre-wrap'}}
                              }}
                              {...mapData(item)} 
                            />
      case 'Datepicker':    return <DatePicker 
                              {...item.props}
                              value={jobcardObj[item.name]}
                              error={errors[item.name]?.error || false}
                              helperText={errors[item.name]?.message || ""}
                              FormHelperTextProps={{style:{whiteSpace: 'pre-wrap'}}}
                              onChange={(value) => onDateChange(item.name, value)}
                            />
    }
  }

  const addIconButton = (item) => {
    if(item.dialog) {
      return <DynamicForm
          Component={(props) => 
            <IconButton {...props} color="primary" icon={<AddCircleOutline style={{ height:'32', width:'32' }} />} />}
          title={item.dialog.title}
          content={ (closeDialog) =>
          FORMS.GENERAL_TF_FORM({
          onSubmit: (e)=> { 
            e.preventDefault();
            e.stopPropagation();
            setShowConfirmDialog((old) => ({
              ...old,
              open: true,
              title: `Please confirm to add new ${item.dialog.name}`,
              text: 'Are you sure ?',
              btn1: {
                ...old.btn1,
              },
              btn2: {
                ...old.btn2,
                onClick: () => submitNewObject(e, item),
              }
            }));
            closeDialog();
          },
          onReset:  (e) => {
            e.preventDefault();
            e.stopPropagation();
            closeDialog();
          },
          container: `${classes.paddingX}`,
          ...item.dialog,
        },
        )}
      />
    }
    return <IconButton disabled color="primary" icon={<AddCircleOutline style={{visibility:'hidden', height:'32', width:'32' }} />} />
  }

  const renderForm = (model) => {
    return model.map((item, i) => <div key={i} className={`${classes.fullWidth}`} style={{display:`${hiddenElements[item.name]? 'none': 'flex'}`, visibility: `${hiddenElements[item.name]? 'hidden': 'visible'}`}}>
        {selectFormElement(item)}
        {addIconButton(item)}
      </div>
    )
  }

  // const convertToPdf = (action) => {
  //   const block = document.getElementById('printable-block');
  //   // block.css({
  //   //   height: "",
  //   // });
  //   console.log(block);
  //   // html2canvas(block).then(canvas => {
  //   //   const convertToPng = canvas.toDataURL('image/jpeg');
  //   //   console.log(convertToPng);
  //   //   const pdf = new jsPDF();
  //   //   pdf.addImage(convertToPng, 'JPEG', 0,0);
  //     const pdf = html2pdf(block)
  //     if(action === 'print') {
  //       pdf.autoPrint();
  //       window.open(pdf.output('bloburl'), '_blank')
  //       // pdf.output("dataurilnewwindow")
  //     } else {
  //       pdf.save("Jobcard.pdf");
  //     }
  //   // });
  // }

  const convertToPdf = action => {
    setPrinting(true);
    const block = document.getElementById('printable-block');
    html2canvas(block).then((canvas) => {  
      var imgWidth = 200;  
      var pageHeight = '290';  
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  
      const imgData = canvas.toDataURL('image/png');  
      const pdf = new jsPDF('p', 'mm', 'a4')  
      var position = 0;  
      var heightLeft = imgHeight;  
      pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);  
      if(action === 'print') {
        pdf.autoPrint();
        window.open(pdf.output('bloburl'), '_blank')
        // pdf.output("dataurilnewwindow")
      } else {
        pdf.save(`Jobcard-${jobcardObj.jobcardId}.pdf`);
      }
      setPrinting(false);
    }); 
  }

  return (
    <Grid container className={`${classes.paddingX} ${classes.stretchHeight}`}>
      <Grid item container className={`${classes.height5}`}>
        <Typography variant='h6' align='left' gutterBottom>Manage Job Card</Typography>
      </Grid>
      <Grid item container className={`${classes.height95}`} direction='column'>
        <Grid item container direction='row' className={`${classes.fullWidth} ${classes.stretchHeight}`} >
          <Grid item container xs={12} sm={6} md={6} direction='row' className={`${classes.stretchHeight}`}>
            <form onSubmit={onSubmit} className={`${classes.fullWidth} ${classes.stretchHeight}`} noValidate>
              <Grid item container xs={12} justifyContent='space-between' className={`${classes.height90}`} style={{overflowY: 'auto', whiteSpace:'nowrap'}}>
                <Grid item xs={12} sm={6} direction='row' className={`${classes.paddingRight}`}>
                  {renderForm(FORMCONFIG.slice(0,10))}
                </Grid>
                <Grid item xs={12} sm={6} direction='row' className={`${classes.paddingRight}`}>
                  {renderForm(FORMCONFIG.slice(10))}
                </Grid>
              </Grid>
              <Grid item container xs={12} className={`${classes.height10}`} direction='column' alignItems='center' justifyContent='center'>
                <Grid item container xs={6} justifyContent='flex-end'>
                  <Button text='Cancel' variant='contained' component={Link} to='/jobcard' />
                </Grid>
                <Grid item container xs={6} justifyContent='flex-start'>
                  <Button type='submit' text='Update' variant='contained' color='primary'/>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Hidden xsDown>
            <Grid item container sm={6} direction='column' className={`${classes.stretchHeight}`}>
              <Divider orientation='vertical'/>
              <Grid id="printable-block" container direction='column' className={`${classes.fullWidth} ${classes.paddingLeft} ${classes.stretchHeight}`}>                
                <Grid item container className={`${classes.stretchHeight}`}>
                  <Grid item container sm={12} className={`${classes.height10}`}>
                    <Grid item sm={8}>
                      <Typography className={`${classes.fullWidth}`} 
                        variant='h5' align='left' gutterBottom>
                        Vehicle Engineering Job Card
                      </Typography>
                    </Grid>
                    <Grid item container sm={4} justifyContent='flex-end' className={`${classes.paddingX}`}>
                      <Grid item className='hidden-from-print'>
                        <IconButton color="primary" icon={<CloudDownloadIcon/>} onClick={(e) => convertToPdf('download')}></IconButton>
                      </Grid>
                      <Grid item className='hidden-from-print'>
                        <IconButton color="primary" icon={<PrintIcon/>} onClick={(e) => convertToPdf('print')}></IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container sm={12} className={`${classes.height90} ${classes.hideScrollbar}`} style={{overflowY: 'scroll', whiteSpace:'nowrap'}}>
                    <Grid item sm={12}>
                      <Typography className={`${classes.fullWidth}`} 
                        variant='overline' align='left' gutterBottom>
                        {jobcardObj.jobcardId}
                      </Typography>
                    </Grid>
                    <Grid item container sm={12} className={`${classes.paddingBottom}`}>
                      <Grid item container sm={6} className={`${classes.paddingRight}`} >
                        <SingleHeaderTable header='Vehicle/Other Information' rows={[
                          [{label: 'Tag', value: jobcardObj.vin?.tag || ''},],
                          [{label: 'Reg No', value: jobcardObj.vin?.regNo || ''},],
                          [{label: 'VIN', value: jobcardObj.vin?.vin || ''},],
                          [{label: 'Model / Year', value: jobcardObj.vin?.modelYear || '' },],
                          [{label: 'Engine Type', value: jobcardObj.vin?.engine || '' },],
                          [{label: 'Body Color', value: jobcardObj.vin?.color || ''},],
                          [{label: 'Transmission', value: jobcardObj.vin?.transmission || '' },],
                          [{label: 'ODO', value: jobcardObj.startValue },],
                          [{label: 'Key Location', value: jobcardObj.keyLocation?.title || '' },],
                          [{label: 'Hand In', value: jobcardObj.location? typeof jobcardObj.location === 'string'? jobcardObj.location: jobcardObj.location.name : ''},],
                          // [{label: 'Hand In', value: jobcardObj.location? `${jobcardObj.location.city}, ${jobcardObj.location.country}`: ''},],
                        ]}/>
                      </Grid>
                      <Grid item container sm={6}>
                        <Grid item container sm={12} className={`${classes.paddingBottom}`}>
                          <SingleHeaderTable header='Requestor Details' noOfCols={1} rows={[
                            [{label: 'Name', value: ''},],
                            [{label: 'Cost Centre', value: jobcardObj.costCentre?.name || ''},],
                            [{label: 'Department', value: ''},],
                            [{label: 'Telephone', value: ''},],
                          ]}
                          />
                        </Grid>
                        <Grid item container sm={12} className={`${classes.paddingTop}`}>
                          <SingleHeaderTable header='Job Details' noOfCols={1} rows={[
                            [{label: 'Workshop', value: jobcardObj.workshop?.name || ''},],
                            [{label: 'Supervisor', value: jobcardObj.supervisor?.firstName || jobcardObj.supervisor?.emailId || ''},],
                            [{label: 'Team', value: ''},],
                            [{label: 'Engineer', value: ''},],
                          ]}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container sm={12} className={`${classes.paddingY}`}>
                      <SingleHeaderTable noOfCols={1} rows={[
                        [{label: 'Reason For Request', value: jobcardObj.reason},],
                        [{label: 'Summary', value: jobcardObj.summary},],
                      ]}
                      />
                    </Grid>
                    <Grid item container sm={12}>
                      <Typography className={`${classes.fullWidth}`} 
                        variant='h5' align='left' gutterBottom>
                        Instructions
                      </Typography>
                    </Grid>
                    <Grid item container sm={12} className={`${classes.paddingY}`}>
                      <SingleHeaderTable rows={
                            [
                              [{label: '', value: jobcardObj.instructions},],
                            ]
                          }
                          secondHeader={[
                            'Instructions',
                            'Initials'
                          ]}
                        />
                    </Grid>
                    <Grid item container sm={12} className={`${classes.paddingY}`}>
                      <SingleHeaderTable header='Requestor Details' noOfCols={4} alignHeader={'center'} rows={[
                          [
                            {label: 'Part Type', value: jobcardObj.partType?.name || '' },
                            {label: 'Component', value:  jobcardObj.partComponent?.name || '' },
                            {label: 'Description', value: jobcardObj.partType?.description || '' },
                            {label: 'New Part', value:  jobcardObj.newPart?.name || '' },
                          ],
                        ]}
                        secondHeader={[
                          'Part Type',
                          'Component',
                          'Description',
                          'New Part',
                        ]}
                      />
                    </Grid>
                    <Grid item container sm={12} className={`${classes.paddingY}`}>
                      <Grid item container sm={7} className={`${classes.fullWidth} ${classes.paddingRight}`} >
                        <SingleHeaderTable noOfCols={2} rows={[
                              [
                                {label: 'Tools/Machines Used', value: jobcardObj.toolsUsed || ''},
                                {label: 'ID Description', value: jobcardObj.idNoOrDescription || ''},
                              ],
                            ]}
                            secondHeader={[
                              'Tools/Machines Used',
                              'ID Description',
                            ]}
                          />
                      </Grid>
                      <Grid item container sm={5} justifyContent='flex-end' className={``} >
                        <SingleHeaderTable noOfCols={1} rows={
                              [
                                [{label: 'Signature', value: `Signature:${''}`},],
                                [{label: 'Name', value: `Name: ${''}`},],
                                [{label: 'Date', value: `Date: ${''}`},],
                              ]
                            }
                            secondHeader={[
                              'Sign Off & Date',
                            ]}
                            secondHeaderAlign={'center'}
                          />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      {
        DIALOGS.CONFIRM_DIALOG({
          title: showConfirmDialog.title,
          text: showConfirmDialog.text,
          open: showConfirmDialog.open,
          setOpen: setShowConfirmDialog,
          btn1:showConfirmDialog.btn1,
          btn2:showConfirmDialog.btn2,
          container: `${classes.paddingX}`,
        })
      }  
    </Grid>
  )
}

export default Index