import React from 'react';
import { Pagination } from '@material-ui/lab';

const Index = ({
  count = 0,
  page = 1,
  setPage = () =>{},
  ...rest
}) => {

  const handleChange = (event, value) => {
    if(page !== value) setPage(value);
  }

  return (
    <Pagination 
      {...rest}
      count={count <= 10 ? 1 : Math.ceil(count/10) } 
      page={page}
      color="primary" 
      size="medium"
      disabled={count <= 10 ? true : false}
      siblingCount={ 0 }
      boundaryCount={ 1 }
      // showFirstButton={true}
      // showLastButton={true}
      // className={`${devClasses.marginB1}`}
      onChange={handleChange} 
    />
  )
}

export default Index;
