const TABLE_HEADER = {
    name: {
        data: "Status",
        // width: "150px",
        align: 'left',
        style: { fontWeight: 'bold', fontSize: '16px' }
    },
    value: {
        data: "Value",
        // width: "150px",
        style: { fontWeight: 'bold',fontSize: '16px' }
    }
};
const TABLE_HEADER_ACTIVITY = {
    what: {
        data: "What",
        // width: "150px",
        // align: 'left',
        style: { fontWeight: 'bold', fontSize: '16px' }
    },
    how: {
        data: "How",
        // width: "150px",
        style: { fontWeight: 'bold',fontSize: '16px' }
    },
    why: {
        data: "Why",
        // width: "150px",
        style: { fontWeight: 'bold',fontSize: '16px' }
    },
    action:{
        data:'Action'
    }
};
const TABLE_HEADER_HISTORY = {
    commentView: {
        data: "Comment",
        // width: "150px",
        // align: 'left',
        style: { fontWeight: 'bold', fontSize: '16px' }
    },
    rectifyCommentView: {
        data: "Rectify Comment",
        // width: "150px",
        style: { fontWeight: 'bold',fontSize: '16px' }
    },
    image: {
        data: "Image",
        // width: "150px",
        style: { fontWeight: 'bold',fontSize: '16px' }
    },
    dateView:{
        data:'Date'
    }
};
const CHECKLIST_TABLE_HEADER = {
    // checkPoint: {
    //     data: "Check Point",
    //     // width: "150px",
    //     align: 'left',
    //     style: { fontWeight: 'bold', fontSize: '16px' }
    // },
    partView: {
        data: "Part",
        // width: "150px",
        align: 'left',
        style: { fontWeight: 'bold', fontSize: '16px' }
    },
    status: {
        data: "Status",
        // width: "150px",
        style: { fontWeight: 'bold',fontSize: '16px' }
    }
};
export { TABLE_HEADER,CHECKLIST_TABLE_HEADER,TABLE_HEADER_ACTIVITY,TABLE_HEADER_HISTORY }