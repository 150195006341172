import { makeStyles } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  grow:{
    flexGrow: 1,
  },
  appBar: {
    zIndex: `${theme.zIndex.drawer + 1} !important`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    border: '2px solid #fff'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    border: '2px solid #fff'
  },
  menuButton: {
    marginLeft: 16,
  },
  hide: {
    display: 'none',
  },
  toolbar:{
  },
  popper: {
    zIndex: theme.zIndex.modal + 1
  }
}));

export default useStyles;
