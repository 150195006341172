const TABLE_HEADER = {
    question: {
      data: "Question",
      width: "150px",
    },
    type: {
      data: "Type",
      width: "150px",
    },
    reference: {
      data: "Reference",
      width: "150px",
    },
    controlMeasure: {
      data: 'Control Measure',
      width: '150px'
    }
  };
  
  export { TABLE_HEADER };