import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  contained: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    position: 'relative',
    width: '100%',
    height: '100%'
  }
}));

export default useStyles;