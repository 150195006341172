import {
  LIST_PROJECT_ASSESMENTS,
  GET_PROJECT_ASSESMENTS,
  ADD_PROJECT_ASSESMENTS,
  UPDATE_PROJECT_ASSESMENTS,
  DELETE_PROJECT_ASSESMENTS,
} from "../action_types";

const initialState = {
  list: {
    pagination: {},
    totalCount: 0,
    data: [],
    isLoading: true,
    hasError: false,
  },
  detail: {
    data: {},

    isLoading: true,
    hasError: false,
  },
};

const projectAssessments = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LIST_PROJECT_ASSESMENTS:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
        },
      };
    case GET_PROJECT_ASSESMENTS:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload,
        },
      };
    case ADD_PROJECT_ASSESMENTS:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: [...state.list.data, payload.data],
          hasError: payload.hasError,
        },
      };
    case UPDATE_PROJECT_ASSESMENTS:
      return {
        ...state,
        update: {
          ...state.update,
          ...payload
        },
      };
    case DELETE_PROJECT_ASSESMENTS:
      let deletedItem = payload.data;
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data.filter((item) => item._id !== deletedItem._id),
          isLoading: false,
          hasError: payload.hasError,
        },
      };
    default:
      return state;
  }
};

export default projectAssessments;
