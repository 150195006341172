import { callAPI } from "../../utils/apiHelper";
import {
  LIST_PLAN_ACTIVITY,
  GET_PLAN_ACTIVITY,
  ADD_PLAN_ACTIVITY,
  UPDATE_PLAN_ACTIVITY,
  DELETE_PLAN_ACTIVITY
} from "../action_types";

export const listPlanActivities = (params) => async (dispatch) => {
  callAPI(dispatch,`/plan_activities`, 'GET',LIST_PLAN_ACTIVITY,params,true);
};


export const getPlanActivity = (id) => async (dispatch) => {
  callAPI(dispatch,`/plan_activities/${id}`, 'GET',GET_PLAN_ACTIVITY,{},true);
};

export const addPlanActivity = (data) => async (dispatch) => {
  callAPI(dispatch,`/plan_activities`, 'POST',ADD_PLAN_ACTIVITY,{},false,data);
};


export const updatePlanActivity = (data, id) => async (dispatch) => {
  callAPI(dispatch,`/plan_activities/${id}`, 'PUT',UPDATE_PLAN_ACTIVITY,{},false,data);
};


export const deletePlanActivity = (id) => async (dispatch) => {
  callAPI(dispatch,`/plan_activities/${id}`, 'DELETE',DELETE_PLAN_ACTIVITY,{},false);
};
export const clearPlanActivityData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_PLAN_ACTIVITY,
    payload: {
      data: null,
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
}; 
