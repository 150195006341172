import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Pagination, Table } from "../../../commons/mui";
import { TABLE_HEADER } from "./CONSTANTS";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { deleteTemplate, listTemplates } from "../../../redux/actions/assessmentTemplate";
import { DIALOGS } from "../../../commons/CONSTANTS";
import useStyles from "../../../styles/jss/style";


export default function Index() {

  const history = useHistory();
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.assessmentTemplate);
  const [page, setPage] = useState(1);
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    title: "sample",
    text: "sample",
    open: false,
    btn1: {
      text: "No",
      onClick: () => {},
    },
    btn2: {
      text: "Yes",
      onClick: () => {},
    },
  });
  const classes = useStyles();

  useEffect(() => {
    dispatch(listTemplates({ type:['P','T'],skip: 10 * (page - 1) }));
  }, [page]);

  const onDelete = (i, e, item) => {
    // console.log(item, i, item.name || item.projectNo)
    setShowConfirmDialog((old) => ({
      ...old,
      open: true,
      title: "Please confirm to delete",
      text: `You are about to delete template.  Are you sure ?`,
      btn1: {
        ...old.btn1,
      },
      btn2: {
        ...old.btn2,
        onClick: () => { dispatch(deleteTemplate(item._id));
          setTimeout(() => {
            dispatch(listTemplates({ skip: 10 * (page - 1) }));
          }, 500);},

      },
    }));
  };

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2.5rem",
          alignItems: "center",
        }}
      >
        <h2>Template List</h2>
        <Button
          width="200px"
          text="Add Template"
          variant="contained"
          color="primary"
          onClick={() => history.push("/add/assessment-template")}
        />
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        <Table
          header={TABLE_HEADER}
          data={templates.list.data}
          onDelete={onDelete}
          onEdit={(i, row) => {
            // console.log(row)
            history.push(`/edit/assessment-template/${row._id}`)
          }}
        />
        <Grid
          item
          container
          style={{ justifyContent: "center" }}
        >
          <div className="ui-components">
            <Pagination
              count={templates.list.totalCount}
              page={page}
              setPage={setPage}
            />
          </div>
        </Grid>
      </Grid>
      {DIALOGS.CONFIRM_DIALOG({
        title: showConfirmDialog.title,
        text: showConfirmDialog.text,
        open: showConfirmDialog.open,
        setOpen: setShowConfirmDialog,
        btn1: showConfirmDialog.btn1,
        btn2: showConfirmDialog.btn2,
        container: `${classes.paddingX}`,
      })}
    </div>
  );
}
