import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import setApiUrl from '../utils/setBaseUrl';

const initialState = {};
const middleware = [thunk];

// if (window.location.port == 3000) {
// setApiUrl('http://localhost:8080/api/v1');
// setApiUrl('https://iteq-app-staging.ccwm.co.uk/api/v1');
// setApiUrl('https://itechq-erework.ccwm.co.uk/api/v1');
setApiUrl('/api/v1');
// } else
// setApiUrl('https://iteq-api.ccwm.co.uk/api/v1');
// setApiUrl('https://iteq-staging.ccwm.co.uk/api/v1');

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
