import React from 'react';
import { Card } from '../../../commons/mui';
import './css/style.css'

const Index = ({
  color,
  width='auto',
  height='auto',
  content,
  title='Card Title',
  titleAlign='left',
  headerHeight='auto',
  backgroundColor='',
  onAction=null,
  Action=null,
  ...rest
}) => {
  const styleObj = {
    header: {
      background: color.main,
      color: color.contrastText,
      width: width,
      textAlign: titleAlign,
      height: headerHeight,
    },
    content: {
      height: height,
      backgroundColor: backgroundColor.main,
    }
  }

  // const onConfirm = (e) => {
  //   onAction(e);
  // }
  
  return (
    <Card 
      {...rest}
      styleObj={styleObj} 
      content={content} 
      title={title} 
      Action={Action}
      // Action={
      //   Action 
      //   ? ((props)=> <FormDialog {...props}
      //             Component={(props) => <IconButton {...props} color={'inherit'} icon={<Action />} />}
      //             title="Rename Bay"
      //             firstActionbutton="No"
      //             secondActionbutton="Yes"
      //             contentText='Are you sure?'
      //             onSecondActionClick={onConfirm}
      //           />)
      //   : null
      // } 
    />
  )
}

export default Index;