const cardDetails = [
  {
    modelName: "123 43L",
    name: "Quentin, L",
    issues: "",
    jobcardNo: "PQR1235789",
    bayNo: "Bay 1",
    status: "Rework Ongoing",
    idealTime: "3 hr 10 min",
    remainingTime: "1 hr 3 min",
    progress: "45%",
  },
  {
    modelName: "523 12E",
    name: "Quentin, L",
    issues: "",
    jobcardNo: "ABC9865430",
    bayNo: "Bay 1",
    status: "Rework Ongoing",
    idealTime: "3 hr 10 min",
    remainingTime: "1 hr 3 min",
    progress: "23%",
  },
  {
    modelName: "777 33S",
    name: "Quentin, L",
    issues: "",
    jobcardNo: "QPPS865444",
    bayNo: "Bay 2",
    status: "Rework Ongoing",
    idealTime: "1 hr 20 min",
    remainingTime: "1 hr 3 min",
    progress: "85%",
  },
  {
    modelName: "889 01P",
    name: "Quentin, L",
    issues: "",
    jobcardNo: "ASQ121358",
    bayNo: "Bay 5",
    status: "Rework Ongoing",
    idealTime: "4 hr 1 min",
    remainingTime: "1 hr 3 min",
    progress: "5%",
  },
];

const TABLE_HEADER = {
  id: {
    data: "Serial No.",
    width: "150px",
  },
  name: {
    data: "Name",
    width: "150px",
  },
  date: {
    data: "Date",
    width: "150px",
  },
  documents: {
    data: "Documents",
    width: "150px",
  },
};

export { cardDetails, TABLE_HEADER };
