import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogContentText from '@material-ui/core/DialogContentText';
import { Grid, Typography, withStyles, IconButton, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


const styles = (theme) => ({
  root: {
    margin: 0,
    width:500,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  }
}))(MuiDialogContent);

// const DialogContentText = withStyles((theme) => ({
//   root: {
//     marginBottom: theme.spacing(4),
//     marginLeft: theme.spacing(3),
//     marginRight: theme.spacing(3)
//   }
// }))(MuiDialogContentText);

const Index = ({title, text, btn, open, setOpen, container, children}) => {
  // const [openL, setOpen] = useState(open);
  // const handleClickOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(old => ({...old, open: false}))}
      aria-labelledby="error-dialog"
    >
      <DialogTitle id="error-dialog" onClose={()=>setOpen(old => ({...old, open:false}))}>{title}</DialogTitle>
      <DialogContent>
        <Grid container justifyContent='center' spacing={3} className={container}>
          {children}
          <Grid item container xs={12} justifyContent='center'>
            <Grid item>
              <Button variant='contained' color='primary' onClick={() => {
                setOpen(old => ({...old, open:false}));
                btn.onClick();
              }}>{btn.text}</Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default Index;
