import React from "react";
import {
  makeStyles,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  withStyles,
  useTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "100%",
    // marginTop: theme.spacing(3)
  },
  head: {
    backgroundColor: "#fff",
    minWidth: "50px"
  },
  tableContainer: {
    maxHeight: props => props?.tableContainer?.maxHeight || "100%",
    maxWidth: props => props?.tableContainer?.maxWidth || "100%"
  },
  cell: {
    minWidth: "100px"
  },
  firstRow: {
    minHeight: "50px",
    top: "57px !important",
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2,
  },
  secondRow: {
    minHeight: "50px",
    top: "114px !important",
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2,
  },
  firstCol: {
    minWidth: "100px",
    left: "100px !important",
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  },
  SecondCol: {
    minWidth: "100px",
    left: "100px !important",
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  },
}));


const StickyCornerCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 4,
  },
  body: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  }
}))(TableCell);

const StickyHorizontalCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 3,
  },
  body: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  }
}))(TableCell);

const StickyVerticalCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2,
  },
  body: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1,
  }
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: "break-word",
  },
  body: {
    fontSize: 14,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: "break-word",
    whiteSpace: "nowrap",
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white
  }
}))(TableRow);

const Index = ({
  keys = [],
  headers = [],
  body = [],
  styleObj = {},
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles(styleObj);

  const renderHeader = () => {
    let rows = headers.map((row, i) => {
      let dataCells = keys.map((item, j) => {
        let cell = row[item];
        if(cell) {
          let WrapperComponent = getCellWrapper(cell?.sticky?.type);
          return <WrapperComponent key={j} align={cell.align || 'left'} style={{borderRight: '1px solid', ...cell?.style}} colSpan={cell.colSpan || 1}>
            {cell.data}
          </WrapperComponent>
        } else return null;
      });
      return <StyledTableRow key={i}>
        {dataCells}
      </StyledTableRow>
    })
    return rows;
  }

  const renderBody = () => {
    let rows = body.map((row, i) => {
      let dataCells = keys.map((item, j) => {
        let cell = row[item];
        if(cell) {
          let WrapperComponent = getCellWrapper(cell?.sticky?.type);
          return <WrapperComponent key={j} align={cell.align || 'left'} style={{borderRight: `1px solid ${theme.palette.accent.dark}`, ...cell?.style}} colSpan={cell.colSpan || 1}>
            {cell.data}
          </WrapperComponent>
        } else return null;
      });
      return <StyledTableRow key={i}>
        {dataCells}
      </StyledTableRow>
    })
    return rows;
  }

  const getCellWrapper = (type) => {
    switch(type){
      case "CORNER_CELL": return StickyCornerCell;
      case "COLUMN_CELL": return StickyHorizontalCell;
      case "ROW_CELL": return StickyVerticalCell;
      default: return StyledTableCell;
    }
  }

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader aria-label="sticky table" {...rest}>
        <TableHead>
          {renderHeader()}
        </TableHead>
        <TableBody>
          {renderBody()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Index;
