const FORMCONFIG = [
  {
    name: 'jobcardId',
    component: 'Textfield',
    props: {
      name: 'jobcardId',
      label: 'Job Card ID',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      disabled: true,
    },
    dialog: false,
  },
  {
    name: 'workshop',
    source: 'workshops',
    component: 'Autocomplete',
    props: {
      name: 'workshop',
      label: 'Workshop',
      variant: 'filled',
      color: 'primary',
      required: true,
      fullWidth: true,
      customOptionLabel: (option) => option.name
    },
    dialog: false,
  },
  {
    name: 'costCentre',
    source: 'costCentres',
    component: 'Autocomplete',
    props: {
      name: 'costCentre',
      label: 'Cost-Centre',
      variant: 'filled',
      color: 'primary',
      required: true,
      fullWidth: true,
      customOptionLabel: (option) => option.name
    },
    dialog: {
      title: 'Add new cost centre',
      name: 'cost centre',
      model: [
        {
          component: 'Textfield',
          props: {
            name: 'costCentre',
            label: 'Cost Centre',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            required: true,
          },
          dialog: false
        },
        {
          name: 'description',
          component: 'Textfield',
          props: {
            name: 'description',
            label: 'Description',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            multiline: true,
            rows: 4,
          },
          dialog: false,
        },
      ],
    },
  },
  {
    name: 'location',
    source: 'locations',
    component: 'Autocomplete',
    props: {
      name: 'location',
      label: 'Vehicle Hand-In Location',
      variant: 'filled',
      color: 'primary',
      required: true,
      fullWidth: true,
      customOptionLabel: (option) => option._id ? `${option.city}, ${option.country}`: ''
    },
    dialog: false,
    // dialog: {
    //   title: 'Add new location',
    //   name: 'location',
    //   model: [
    //     {
    //       component: 'Textfield',
    //       props: {
    //         name: 'country',
    //         label: 'Country',
    //         variant: 'filled',
    //         color: 'primary',
    //         fullWidth: true,
    //         required: true,
    //       },
    //       dialog: false
    //     },
    //     {
    //       component: 'Textfield',
    //       props: {
    //           name: 'city',
    //           label: 'City',
    //           variant: 'filled',
    //           color: 'primary',
    //           fullWidth: true,
    //           required: true,
    //         },
    //       dialog: false
    //     },
    //   ],
    // },
  },
  // {
  //   name: 'supervisor',
  //   local: 'SUPERVISORS',
  //   component: 'Autocomplete',
  //   props: {
  //     name: 'supervisor',
  //     label: 'Supervisor',
  //     variant: 'filled',
  //     color: 'primary',
  //     // required: true,
  //     fullWidth: true,
  //   },
  //   dialog: false,
  // },
  {
    name: 'vin',
    source: 'vehicles',
    component: 'Autocomplete',
    props: {
      name: 'vin',
      label: 'VIN',
      variant: 'filled',
      color: 'primary',
      required: true,
      fullWidth: true,
      customOptionLabel: (option) => option.vin
    },
    dialog: false,
  },
  {
    name: 'tag',
    source: 'vehicles',
    component: 'Autocomplete',
    props: {
      name: 'tag',
      label: 'TAG',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      customOptionLabel: (option) => { 
        if(typeof option === 'string') return option;
        if(option.tag) return option.tag;
        return "Undefined"
      }
      // disabled: true,
    },
    dialog: false,
  },
  {
    name: 'regNo',
    source: 'vehicles',
    component: 'Autocomplete',
    props: {
      name: 'regNo',
      label: 'Registration No',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      customOptionLabel: (option) => { 
        if(typeof option === 'string') return option;
        if(option.regNo) return option.regNo;
        return "Undefined"
      }
    },
    dialog: false,
  },
  {
    name: 'jobType',
    component: 'Autocomplete',
    source: 'jobcardTypes',
    props: {
      name: 'jobType',
      label: 'Job Type',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      required: true,
      customOptionLabel: (option) => option.name
    },
    dialog: {
      title: 'Add new job type',
      name: 'job type',
      model: [
        {
          name: 'name',
          component: 'Textfield',
          props: {
            name: 'name',
            label: 'Job Type',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            required: true,
          },
          dialog: false
        },
        {
          name: 'description',
          component: 'Textfield',
          props: {
            name: 'description',
            label: 'Job Type Description',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            multiline: true,
            rows: 4,
          },
          dialog: false,
        },
      ],
    },
  },
  {
    name: 'jobParameter',
    component: 'Autocomplete',
    source: 'jobcardParameters',
    props: {
      name: 'jobParameter',
      label: 'Job Parameter',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      customOptionLabel: (option) => option.name
    },
    dialog: {
      title: 'Add new job parameter',
      name: 'job parameter',
      model: [
        {
          name: 'name',
          component: 'Textfield',
          props: {
            name: 'name',
            label: 'Job Parameter',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            required: true,
          },
          dialog: false
        },
        {
          name: 'description',
          component: 'Textfield',
          props: {
            name: 'description',
            label: 'Job Parameter Description',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            multiline: true,
            rows: 4,
          },
          dialog: false,
        },
      ],
    },
  },
  {
    name: 'ipWeek',
    local: 'IP_WEEKS',
    component: 'Autocomplete',
    props: {
      name: 'ipWeek',
      label: 'IP Week',
      variant: 'filled',
      color: 'primary',
      required: true,
      fullWidth: true,
    },
    dialog: false,
  },
  {
    name: 'keyLocation',
    local: 'KEY_LOCATIONS',
    component: 'Autocomplete',
    props: {
      name: 'keyLocation',
      label: 'Key Location',
      variant: 'filled',
      color: 'primary',
      required: true,
      fullWidth: true
    },
    dialog: false,
  },
  {
    name: 'mileageRequired',
    component: 'Autocomplete',
    local: 'MILEAGE_REQURED',
    props: {
      name: 'mileageRequired',
      label: 'Mileage Required',
      variant: 'filled',
      color: 'primary',
      required: true,
      fullWidth: true,
    },
    dialog: false,
  },
  {
    name: 'startValue',
    component: 'Textfield',
    props: {
      name: 'startValue',
      label: 'Start Value',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      required: true,
      InputProps:{
        inputProps: {
          min:0,
          max:999999,
        }
      },
      type:'number'
    },
    dialog: false,
  },
  {
    name: 'risJobcard',
    local:'RIS_JOBCARD',
    component: 'Autocomplete',
    props: {
      name: 'risJobcard',
      label: 'RIS Job Card',
      variant: 'filled',
      color: 'primary',
      required: true,
      fullWidth: true,
    },
    dialog: false,
  },
  // {
  //   name: 'partsOrSoftwareChanges',
  //   local: 'PARTS_OR_SOFTWARE_CHANGES',
  //   component: 'Autocomplete',
  //   props: {
  //     name: 'partsOrSoftwareChanges',
  //     label: 'Parts/Software Changes',
  //     variant: 'filled',
  //     color: 'primary',
  //     required: true,
  //     fullWidth: true,
  //   },
  //   dialog: false,
  // },
  {
    name: 'partType',
    source: 'partTypes',
    component: 'Autocomplete',
    props: {
      name: 'partType',
      label: 'Part Type',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      required: true,
      customOptionLabel: (option) => option.name
    },
    dialog: {
      title: 'Add new part type',
      name: 'part type',
      model: [
        {
          name: 'name',
          component: 'Textfield',
          props: {
            name: 'name',
            label: 'Part Type',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            required: true,
          },
          dialog: false
        },
        {
          name: 'description',
          component: 'Textfield',
          props: {
            name: 'description',
            label: 'Description',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            multiline: true,
            rows: 4,
          },
          dialog: false,
        },
      ],
    },
  },
  {
    name: 'partComponent',
    source: 'partComponents',
    component: 'Autocomplete',
    props: {
      name: 'partComponent',
      label: 'Component',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      required: true,
      customOptionLabel: (option) => option.name
    },
    dialog: {
      title: 'Add new component',
      name: 'component',
      model: [
        {
          name: 'name',
          component: 'Textfield',
          props: {
            name: 'name',
            label: 'Component',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            required: true,
          },
          dialog: false
        },
        {
          name: 'description',
          component: 'Textfield',
          props: {
            name: 'description',
            label: 'Description',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            multiline: true,
            rows: 4,
          },
          dialog: false,
        },
      ],
    },
  },
  {
    name: 'partDescription',
    component: 'Textfield',
    props: {
      name: 'partDescription',
      label: 'Part Description',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      disabled: true,
    },
    dialog: false
  },
  {
    name: 'newPart',
    source: 'newParts',
    component: 'Autocomplete',
    props: {
      name: 'newPart',
      label: 'New Part',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      required: true,
      customOptionLabel: (option) => option.name
    },
    dialog: {
      title: 'Add new part',
      name: 'part',
      model: [
        {
          name: 'name',
          component: 'Textfield',
          props: {
            name: 'name',
            label: 'Part',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            required: true,
          },
          dialog: false
        },
        {
          name: 'description',
          component: 'Textfield',
          props: {
            name: 'description',
            label: 'Description',
            variant: 'filled',
            color: 'primary',
            fullWidth: true,
            multiline: true,
            rows: 4,
          },
          dialog: false,
        },
      ],
    },
  },
  {
    name: 'toolsUsed',
    component: 'Textfield',
    props: {
      name: 'toolsUsed',
      label: 'Tools/Machines Used',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      multiline: true,
    },
    dialog: false,
  },
  {
    name: 'idNoOrDescription',
    component: 'Textfield',
    props: {
      name: 'idNoOrDescription',
      label: 'ID No/Description',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      multiline: true,
    },
    dialog: false,
  },
  {
    name: 'preferredStartDate',
    component: 'Datepicker',
    props: {
      name: 'preferredStartDate',
      label: 'Preferred Start Date',
      autoOk: true,
      format:'MM/dd/yyyy',
      disablePast: true,
      inputVariant: 'filled',
      disableToolbar: true,
      variant: 'inline',
    },
    dialog: false,
  },
  {
    name: 'reason',
    component: 'Textfield',
    props: {
      name: 'reason',
      label: 'Reason for Request',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      required: true,
      multiline: true
    },
    dialog: false,
  },
  {
    name: 'summary',
    component: 'Textfield',
    props: {
      name: 'summary',
      label: 'Summary',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      required: true,
      multiline: true
    },
    dialog: false,
  },
  {
    name: 'instructions',
    component: 'Textfield',
    props: {
      name: 'instructions',
      label: 'Instructions',
      variant: 'filled',
      color: 'primary',
      fullWidth: true,
      required: true,
      multiline: true
    },
    dialog: false,
  }
];

const SAMPLE_DATA = {
  WORKSHOPS:[
    {title: 'Workshop 01', name: 'Workshop 01', id: 0, dataType:'workshop'},
    {title: 'Workshop 02', name: 'Workshop 02', id: 1, dataType:'workshop'},
    {title: 'Workshop 03', name: 'Workshop 03', id: 2, dataType:'workshop'},
    {title: 'Workshop 04', name: 'Workshop 04', id: 3, dataType:'workshop'},
  ],
  COST_CENTERS: [
    {title: 'Cost Center 01', name: 'Cost Center 01', id: 0, dataType: 'costCenter'},
    {title: 'Cost Center 02', name: 'Cost Center 02', id: 1, dataType: 'costCenter'},
    {title: 'Cost Center 03', name: 'Cost Center 03', id: 2, dataType: 'costCenter'},
    {title: 'Cost Center 04', name: 'Cost Center 04', id: 3, dataType: 'costCenter'},
  ],
  VEHICLE_HAND_INS: [
    {title: 'Location 01', name: 'Location 01', id: 0, dataType: 'vehicleHandInLocation'},
    {title: 'Location 02', name: 'Location 02', id: 1, dataType: 'vehicleHandInLocation'},
    {title: 'Location 03', name: 'Location 03', id: 2, dataType: 'vehicleHandInLocation'},
    {title: 'Location 04', name: 'Location 04', id: 3, dataType: 'vehicleHandInLocation'},
  ],
  SUPERVISORS: [
    {title: 'Supervisor 01', name: 'Supervisor 01', id: 0, dataType: 'supervisor'},
    {title: 'Supervisor 02', name: 'Supervisor 02', id: 1, dataType: 'supervisor'},
    {title: 'Supervisor 03', name: 'Supervisor 03', id: 2, dataType: 'supervisor'},
    {title: 'Supervisor 04', name: 'Supervisor 04', id: 3, dataType: 'supervisor'},
  ],
  VEHICLES: [
    {title: 'Vehicle 01', name:'Vehicle 01', id: 0, dataType: 'vehicle', tag: 'TAG 01', regNo: 'ACC 012',},
    {title: 'Vehicle 02', name:'Vehicle 02', id: 1, dataType: 'vehicle', tag: 'TAG 02', regNo: 'AED 112',},
    {title: 'Vehicle 03', name:'Vehicle 03', id: 2, dataType: 'vehicle', tag: 'TAG 03', regNo: 'AAW 658',},
    {title: 'Vehicle 04', name:'Vehicle 04', id: 3, dataType: 'vehicle', tag: 'TAG 04', regNo: 'AWA 598',}, 
  ],
  JOB_TYPES: [
    {title: 'Job Type 01', name: 'Job Type 01', id: 0, dataType: 'jobType'},
    {title: 'Job Type 02', name: 'Job Type 02', id: 1, dataType: 'jobType'},
    {title: 'Job Type 03', name: 'Job Type 03', id: 2, dataType: 'jobType'},
    {title: 'Job Type 04', name: 'Job Type 04', id: 3, dataType: 'jobType'},
  ],
  JOB_PARAMETERS: [
    {title: 'Job Parameter 01', name: 'Job Parameter 01', id: 0, dataType: 'jobParameter'},
    {title: 'Job Parameter 02', name: 'Job Parameter 02', id: 1, dataType: 'jobParameter'},
    {title: 'Job Parameter 03', name: 'Job Parameter 03', id: 2, dataType: 'jobParameter'},
    {title: 'Job Parameter 04', name: 'Job Parameter 04', id: 3, dataType: 'jobParameter'},
  ],
  IP_WEEKS: [
    {title: 'IP21W01', name:'IP21W01', id: 0, dataType:'ipWeek'},
    {title: 'IP21W02', name:'IP21W02', id: 1, dataType:'ipWeek'},
    {title: 'IP21W03', name:'IP21W03', id: 2, dataType:'ipWeek'},
    {title: 'IP21W04', name:'IP21W04', id: 3, dataType:'ipWeek'},
    {title: 'IP21W05', name:'IP21W05', id: 4, dataType:'ipWeek'},
    {title: 'IP21W06', name:'IP21W06', id: 5, dataType:'ipWeek'},
    {title: 'IP21W07', name:'IP21W07', id: 6, dataType:'ipWeek'},
    {title: 'IP21W08', name:'IP21W08', id: 7, dataType:'ipWeek'},
    {title: 'IP21W09', name:'IP21W09', id: 8, dataType:'ipWeek'},
    {title: 'IP21W10', name:'IP21W10', id: 9, dataType:'ipWeek'},
    {title: 'IP21W11', name:'IP21W11', id: 10, dataType:'ipWeek'},
    {title: 'IP21W12', name:'IP21W12', id: 11, dataType:'ipWeek'},
    {title: 'IP21W13', name:'IP21W13', id: 12, dataType:'ipWeek'},
    {title: 'IP21W14', name:'IP21W14', id: 13, dataType:'ipWeek'},
    {title: 'IP21W15', name:'IP21W15', id: 14, dataType:'ipWeek'},
    {title: 'IP21W16', name:'IP21W16', id: 15, dataType:'ipWeek'},
    {title: 'IP21W17', name:'IP21W17', id: 16, dataType:'ipWeek'},
    {title: 'IP21W18', name:'IP21W18', id: 17, dataType:'ipWeek'},
    {title: 'IP21W19', name:'IP21W19', id: 18, dataType:'ipWeek'},
    {title: 'IP21W20', name:'IP21W20', id: 19, dataType:'ipWeek'},
    {title: 'IP21W21', name:'IP21W21', id: 20, dataType:'ipWeek'},
    {title: 'IP21W22', name:'IP21W22', id: 21, dataType:'ipWeek'},
    {title: 'IP21W23', name:'IP21W23', id: 22, dataType:'ipWeek'},
    {title: 'IP21W24', name:'IP21W24', id: 23, dataType:'ipWeek'},
    {title: 'IP21W25', name:'IP21W25', id: 24, dataType:'ipWeek'},
    {title: 'IP21W26', name:'IP21W26', id: 25, dataType:'ipWeek'},
    {title: 'IP21W27', name:'IP21W27', id: 26, dataType:'ipWeek'},
    {title: 'IP21W28', name:'IP21W28', id: 27, dataType:'ipWeek'},
    {title: 'IP21W29', name:'IP21W29', id: 28, dataType:'ipWeek'},
    {title: 'IP21W30', name:'IP21W30', id: 29, dataType:'ipWeek'},
    {title: 'IP21W31', name:'IP21W31', id: 30, dataType:'ipWeek'},
    {title: 'IP21W32', name:'IP21W32', id: 31, dataType:'ipWeek'},
    {title: 'IP21W33', name:'IP21W33', id: 32, dataType:'ipWeek'},
    {title: 'IP21W34', name:'IP21W34', id: 33, dataType:'ipWeek'},
    {title: 'IP21W35', name:'IP21W35', id: 34, dataType:'ipWeek'},
    {title: 'IP21W36', name:'IP21W36', id: 35, dataType:'ipWeek'},
    {title: 'IP21W37', name:'IP21W37', id: 36, dataType:'ipWeek'},
    {title: 'IP21W38', name:'IP21W38', id: 37, dataType:'ipWeek'},
    {title: 'IP21W39', name:'IP21W39', id: 38, dataType:'ipWeek'},
    {title: 'IP21W40', name:'IP21W40', id: 39, dataType:'ipWeek'},
    {title: 'IP21W41', name:'IP21W41', id: 40, dataType:'ipWeek'},
    {title: 'IP21W42', name:'IP21W42', id: 41, dataType:'ipWeek'},
    {title: 'IP21W43', name:'IP21W43', id: 42, dataType:'ipWeek'},
    {title: 'IP21W44', name:'IP21W44', id: 43, dataType:'ipWeek'},
    {title: 'IP21W45', name:'IP21W45', id: 44, dataType:'ipWeek'},
    {title: 'IP21W46', name:'IP21W46', id: 45, dataType:'ipWeek'},
    {title: 'IP21W47', name:'IP21W47', id: 46, dataType:'ipWeek'},
    {title: 'IP21W48', name:'IP21W48', id: 47, dataType:'ipWeek'},
    {title: 'IP21W49', name:'IP21W49', id: 48, dataType:'ipWeek'},
    {title: 'IP21W50', name:'IP21W50', id: 49, dataType:'ipWeek'},
    {title: 'IP21W51', name:'IP21W51', id: 50, dataType:'ipWeek'},
    {title: 'IP21W52', name:'IP21W52', id: 51, dataType:'ipWeek'},
    {title: 'IP21W53', name:'IP21W53', id: 52, dataType:'ipWeek'},
    {title: 'SPECIAL', name:'SPECIAL', id: 53, dataType:'ipWeek'},
  ],
  KEY_LOCATIONS: [
    {title: 'Keyroom', name:'Keyroom', id: 0, dataType:'keyLocation'},
    {title: 'With Vehicle', name:'With Vehicle', id: 1, dataType:'keyLocation'},
  ],
  MILEAGE_REQURED: [
    {title: 'No', name:'No', id:0, dataType: 'mileageRequired'},
    {title: 'Yes', name:'Yes', id:1, dataType: 'mileageRequired'},
  ],
  PARTS_OR_SOFTWARE_CHANGES: [
    {title: 'No', name:'No', id:0, dataType: 'partsOrSoftwareChanges'},
    {title: 'Yes', name:'Yes', id:1, dataType: 'partsOrSoftwareChanges'},
  ],
  PART_TYPES: [
    {title: 'Part Type 01', name: 'Part Type 01', id: 0, dataTYpe: 'partType'},
    {title: 'Part Type 02', name: 'Part Type 02', id: 1, dataTYpe: 'partType'},
    {title: 'Part Type 03', name: 'Part Type 03', id: 2, dataTYpe: 'partType'},
    {title: 'Part Type 04', name: 'Part Type 04', id: 3, dataTYpe: 'partType'},
  ],
  COMPONENTS: [
    {title: 'Component 01', name: 'Component 01', id: 0, dataType: 'component'},
    {title: 'Component 02', name: 'Component 02', id: 1, dataType: 'component'},
    {title: 'Component 03', name: 'Component 03', id: 2, dataType: 'component'},
    {title: 'Component 04', name: 'Component 04', id: 3, dataType: 'component'},
  ],
  PARTS: [
    {title: 'Part 01', name: 'Part 01', id: 0, dataType: 'part'},
    {title: 'Part 02', name: 'Part 02', id: 1, dataType: 'part'},
    {title: 'Part 03', name: 'Part 03', id: 2, dataType: 'part'},
    {title: 'Part 04', name: 'Part 04', id: 3, dataType: 'part'},
  ],
  RIS_JOBCARD:[
    {title: 'No', name:'No', id:0, dataType: 'risJobcard'},
    {title: 'Yes', name:'Yes', id:1, dataType: 'risJobcard'},
  ]
}

export {
  FORMCONFIG,
  SAMPLE_DATA,
}