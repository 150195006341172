import { createSelectorWithArgs } from './createSelectorWithArgs';

const users = (state) => state.user.list.data;
const filterByRole = (users, role) => users.filter((item) => role.includes(item.role));
const filterByTeam = (users, team) => users.filter((item) => {
    // console.debug(item, team, item.team === team)
    return team === item.team
});
const filterSupervisorsByWorkshop = (users, role, workshop) => users.filter((item) => workshop === item.workshop && role.includes(item.role));
const filterByTeamSelectable = (users, role, team) => users.filter((item) => {
  if(role.includes(item.role)) {
    if(team === item.team) return true; 
    else if(!item.team) return true;
  }
  return false;
});
const filterByWorkshopSelectableSupervisors = (users, role, workshop) => (users.filter((item) => {
    if(role.includes(item.role)){
      if(workshop === item.workshop) return true;
      else if(!item.workshop) return true;
    }
    return false;
  }
));
export const usersByRole = createSelectorWithArgs(
  users,
  filterByRole,
)

export const usersByTeam = createSelectorWithArgs(
  users,
  filterByTeam,
)

export const supervisorsByWorkshop = createSelectorWithArgs(
  users,
  filterSupervisorsByWorkshop,
)

export const usersTeamSelectable = createSelectorWithArgs(
  users,
  filterByTeamSelectable,  
)

export const usersWorkshopSelectableSupervisors = createSelectorWithArgs(
  users,
  filterByWorkshopSelectableSupervisors,  
)