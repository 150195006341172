import { Grid, Typography, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Button, TextField, Checkbox, Table } from "../../../commons/mui";
import { TABLE_HEADER } from "./CONSTANTS";

const useStyles = makeStyles({
  root: {
    width: 420,
  },
});

export default function Index() {
  const classes = useStyles();
  const [partList, setpartList] = useState([]);
  const [type, settype] = useState({
    inspection: false,
    rework: false,
    retrofit: false,
    audit: false,
    lab: false,
    engineer: false,
    cs: false,
    other: false,
  });
  const [fields, setfields] = useState({
    partName: "",
    partNo: "",
    problemDefinition: "",
    type: "",
    commodity: "",
  });
  const [editBtn, setEditBtn] = useState(false)
  const [index, setIndex] = useState(null)

  const markType = (val, e) => {
    let obj = {};
    Object.keys(type).map((itm) => {
      if (itm === val) {
        obj[itm] = e.target.checked;
        if (e.target.checked) {
          fields.type = itm;
          setfields(fields);
        }
      } else {
        obj[itm] = false;
      }
    });
    settype(obj);
  };

  const setField = (field, val) => {
    Object.keys(fields).map((itm) => {
      if (itm === field) {
        setfields({ ...fields, [itm]: val });
      }
    });
  };

  return (
    <Grid container>
      <Grid sm={12} md={5}>
        <Grid>
          <h2>Add Part</h2>
        </Grid>
        <Grid style={{ marginTop: "1rem" }}>
          <TextField
            variant="filled"
            label="Part Name"
            value={fields.partName}
            onChange={(e) => {
              setField("partName", e.target.value);
            }}
            customClass={classes.root}
          />
        </Grid>
        <Grid style={{ marginRight: "3rem", marginTop: "1rem" }}>
          <TextField
            variant="filled"
            label="Part Number"
            value={fields.partNo}
            onChange={(e) => {
              setField("partNo", e.target.value);
            }}
            customClass={classes.root}
          />
        </Grid>
        <Grid style={{ marginRight: "3rem", marginTop: "1rem" }}>
          <TextField
            variant="filled"
            label="Problem Definition"
            value={fields.problemDefinition}
            onChange={(e) => {
              setField("problemDefinition", e.target.value);
            }}
            customClass={classes.root}
          />
        </Grid>
        <Grid>
          <Typography style={{ marginTop: ".5rem", marginRight: ".5rem" }}>
            Type of work required:
          </Typography>
        </Grid>
        <Grid style={{ display: "flex" }}>
          <Checkbox
            text="Inspection"
            checked={type.inspection}
            color="primary"
            onChange={(e) => {
              markType("inspection", e);
            }}
          />
          <Checkbox
            text="Rework"
            checked={type.rework}
            color="primary"
            onChange={(e) => {
              markType("rework", e);
            }}
          />
          <Checkbox
            text="Retrofit"
            checked={type.retrofit}
            color="primary"
            onChange={(e) => {
              markType("retrofit", e);
            }}
          />
          <Checkbox
            text="Audit"
            checked={type.audit}
            color="primary"
            onChange={(e) => {
              markType("audit", e);
            }}
          />
        </Grid>
        <Grid style={{ marginTop: "1rem", display: "flex" }}>
          <Checkbox
            text="Lab/CMM"
            checked={type.lab}
            color="primary"
            onChange={(e) => {
              markType("lab", e);
            }}
          />
          <Checkbox
            text="Engineer"
            checked={type.engineer}
            color="primary"
            onChange={(e) => {
              markType("engineer", e);
            }}
          />
          <Checkbox
            text="CS 1/2/3/4"
            checked={type.cs}
            color="primary"
            onChange={(e) => {
              markType("cs", e);
            }}
          />
          <Checkbox
            text="Other"
            checked={type.other}
            color="primary"
            onChange={(e) => {
              markType("other", e);
            }}
          />
        </Grid>
        <Grid style={{ marginTop: "1rem" }}>
          <TextField
            variant="filled"
            label="Commodity"
            value={fields.commodity}
            onChange={(e) => {
              setField("commodity", e.target.value);
            }}
            customClass={classes.root}
          />
        </Grid>
        <Grid style={{ textAlign: "start" }}>
          {
            !editBtn ? <Button
            style={{ marginTop: "1.7rem", marginBottom: "10px" }}
            width="150px"
            text="Add"
            variant="contained"
            color="primary"
            onClick={() => {
              setfields({
                partName: "",
                partNo: "",
                problemDefinition: "",
                type: "",
                commodity: "",
              });
              let obj = {};
              Object.keys(type).map((itm) => {
                obj[itm] = false;
              });
              settype(obj);
              setpartList([...partList, fields]);
            }}
          /> : <Button
          style={{ marginTop: "1.7rem", marginBottom: "10px" }}
          width="150px"
          text="Edit"
          variant="contained"
          color="primary"
          onClick={() => {
            setfields({
              partName: "",
              partNo: "",
              problemDefinition: "",
              type: "",
              commodity: "",
            });
            let obj = {};
            Object.keys(type).map((itm) => {
              obj[itm] = false;
            });
            settype(obj);
            partList[index] = fields;
            setpartList([...partList]);
          }}
        />}
        </Grid>
      </Grid>
      <Grid
        style={{
          paddingLeft: "1rem",
          borderLeft: "1px solid gray"
        }}
        sm={12} 
        md={7}
      >
        <Grid>
          <h2>Parts List</h2>
        </Grid>
        <Grid>
          <Table
            header={TABLE_HEADER}
            data={partList}
            onDelete={(i) => {
              let arr = [];
              partList.map((itm, index) => {
                if (index !== i) {
                  arr.push(itm);
                }
              });
              setpartList(arr);
            }}
            onEdit={(i) => {
              setEditBtn(true)
              setIndex(i);
              let obj = {};
              Object.keys(type).map((itm) => {
                if (itm === partList[i]?.type) {
                  obj[itm] = true;
                }
              });
              settype(obj);
              setfields(partList[i]);
            }}
          />
        </Grid>
        <Grid
          style={{
            textAlign: "center",
          }}
        >
          <Button
            style={{ marginTop: "20px", marginBottom: "10px" }}
            width="150px"
            text="Submit"
            variant="contained"
            color="primary"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
