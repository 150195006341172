import { 
  LIST_MODEL,
  GET_MODEL,
  ADD_MODEL,
  UPDATE_MODEL,
  DELETE_MODEL
} from '../action_types';
import axios from 'axios';

export const listModels = (params) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/model`,
      params
    });
    dispatch({
      type: LIST_MODEL,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: LIST_MODEL,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const getModel = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/model/${id}`,
    });
    dispatch({
      type: GET_MODEL,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: GET_MODEL,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addModel = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/model`,
      data
    });
    dispatch({
      type: ADD_MODEL,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: ADD_MODEL,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const updateModel = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/model/${id}`,
      data
    });
    dispatch({
      type: UPDATE_MODEL,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: UPDATE_MODEL,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const deleteModel = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/model/${id}`,
    });
    dispatch({
      type: DELETE_MODEL,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: DELETE_MODEL,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}