import Highcharts from 'highcharts';

var pieChartText = {};

const CHART_OPTIONS_PROGRESS = {
  chart: {
    type: 'pie',

    events: {
      redraw: function (event) {
        var total = 0;
        this.series[0].yData.forEach(item => total += item);
        if (pieChartText.destroy) pieChartText.destroy(); // To Avoid rerendering on top of existing text
        pieChartText = this.renderer.text(
          'Total: ' + total,
          this.plotLeft + 20,
          this.plotTop
        ).css({
          fontWeight: 'bold',
          fontSize: '10px',
          fontFamily: 'sans-serif',
        }
        ).attr({
          zIndex: 5,
        }
        ).add()
      }
    }
  },
  credits: {
    enabled: false
  },
  title: {
    text: `Project's Performance`,
    align: 'left'
  },
  tooltip: {
    // headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    // pointFormat: '<tr><td style="color:{series.color};padding:0"> </td>' +
    //   '<td style="padding:0"><b>{point.y}</b></td></tr>',
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    // footerFormat: '</table>',
  },
  plotOptions: {
    pie: {
      innerSize: '100%',
      dataLables: {
        enabled: true,
        format: '<b>{point.name}%</b>: {point.percentage:.1f} %',
        style: {
          color: (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
            'black'
        }
      },
      showInLegend: false,
      allowPointSelect: true,
      cursor: 'pointer',
    }
  },
  series: [{
    data: []
  }]
}
const TABLE_HEADER = {
  comment: {
    data: "Comment"
  }
}

export {
  CHART_OPTIONS_PROGRESS,
  TABLE_HEADER
}