import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, IconButton, TextField } from "../../../commons/mui";
import { ROLES, SUPERROLES } from "./CONSTANTS";
import { useDispatch, useSelector } from "react-redux";
import { addTicket, clearData, listTickets } from "../../../redux/actions/ticket";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ADD_ROLE } from "../../../redux/action_types";
import { PDSLADMIN } from "../../Projects/AllProjects/CONSTANTS";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: 290,
    },
  },
  input: {
    marginTop: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "1rem",
    },
  },
  res: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));

export default function Index() {
  const [field, setField] = useState({
    type: "",
    description: "",
  });
  const classes = useStyles();
  const ticket = useSelector((state) => state.ticket);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [errors, seterrors] = useState({});

  useEffect(() => {
    if (ticket.add?.data?._id) {
      history.push(
        auth.data?.role?.name === PDSLADMIN ? "/super-tickets" : "/tickets"
      );
    }
  }, [ticket])

  const validate = () => {
    let temp = {};
    let error = false;
    if (!field.type) {
      error = true;
      temp.type = "Type is required";
    }
    if (!field.description) {
      error = true;
      temp.description = "Description is required";
    }
    seterrors(temp);

    return !error;
  };

  const onSubmit = () => {
    if (validate()) {
      dispatch(addTicket(field));

    }
  };

  const setFields = (type, val) => {
    Object.keys(field).map((itm) => {
      if (itm == type) {
        setField({ ...field, [itm]: val });
      }
    });
  };

  return (
    <Grid>
      <Grid>
        <Grid>
          <h2>
            <IconButton
              color="primary"
              onClick={() => { history.push('/super-tickets') }}
              icon=<ArrowBack />
            ></IconButton>
            Add Ticket
          </h2>
        </Grid>
        <Grid
          spacing={3}
          className={`${classes.res}`}
          container
          component={"form"}
          noValidate
        >
          <Grid item sm={6}>
            <TextField
              variant="filled"
              label="Type"
              value={field.type}
              isError={errors.type}
              onChange={(e) => {
                setFields("type", e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              variant="filled"
              label="Description"
              value={field.description}
              multiline
              minrow={3}
              isError={errors.description}
              onChange={(e) => {
                setFields("description", e.target.value);
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid style={{ textAlign: "center" }}>
          <Button
            style={{ marginTop: "1.7rem" }}
            width="150px"
            text="Add"
            variant="contained"
            color="primary"
            disabled={!field.type || !field.description}
            onClick={() => {
              onSubmit();
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
