import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Button, IconButton, Table, TextField } from "../../../commons/mui";
import { useDispatch, useSelector } from "react-redux";
import {
  addLocation,
  getLocation,
  updateLocation,
} from "../../../redux/actions/location";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import { TABLE_HEADER } from "./CONSTANTS";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    width: '95%',
  },
});

export default function Index() {
  const [field, setField] = useState({
    city: "",
    country: "",
    address: "",
    contacts: [],
  });
  const classes = useStyles();
  const [partList, setpartList] = useState([]);
  const [editBtn, setEditBtn] = useState(false);
  const [index, setIndex] = useState(null);
  const [errors, seterrors] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { locationId } = useParams();
  const [contactList, setcontactList] = useState([]);
  const location = useSelector((state) => state.location);
  useEffect(() => {
    setField({ ...field, contacts: processContacts(contactList) });
  }, [contactList]);

  const setFields = (type, val) => {
    Object.keys(field).map((itm) => {
      if (itm === type) {
        setField({ ...field, [itm]: val });
      }
    });
  };

  useEffect(() => {
    if (locationId) {
      dispatch(getLocation(locationId));
    }
  }, []);

  useEffect(() => {
    if (locationId && location.detail.data._id) {
      setField({
        city: location.detail.data.city,
        country: location.detail.data.country,
        address: location.detail.data.address,
        contacts: processContacts(location?.detail?.data?.contacts),
      });
    }
  }, [location]);
  const validate = () => {
    let temp = {};
    let error = false;
    if (!field.address) {
      error = true;
      temp.address = "Address is required";
    }
    if (!field.country) {
      error = true;
      temp.country = "Country is required";
    }
    if (!field.city) {
      error = true;
      temp.city = "City is required";
    }
    seterrors(temp);

    return !error;
  };

  const onSubmit = () => {
    if (validate()) {
      let data = { ...field };
      data.contacts = data.contacts.map(a => ({ contactName: a.contactName, contactNumber: a.contactNumber }));
      // console.log(field);
      if (locationId) {
        dispatch(updateLocation(data, locationId));
      } else {
        dispatch(addLocation(data));
      }
      setTimeout(() => {
        history.push("/location");
      }, 500);
    }
  };

  const setContactFields = (type, val, i) => {
    let contacts = contactList;
    if (contacts[i]) {
      contacts[i][type] = val;
      setcontactList(contacts);
      setField({ ...field, contacts: processContacts(contacts) });
    }
  };

  const processContacts = (questions) => {
    return questions.map((a, i) => {
      a.errors = a.errors || {};
      a.contactNameView = (
        <TextField
          variant="filled"
          placeholder="Contact Name"
          value={a.contactName}
          // isError={a.errors?.reference}
          onChange={(e) => {
            setContactFields("contactName", e.target.value, i);
          }}
          fullWidth
        />
      );
      a.contactNumberView = (
        <TextField
          variant="filled"
          placeholder="Contact Number"
          value={a.contactNumber}
          // isError={a.errors?.question}
          onChange={(e) => {
            setContactFields("contactNumber", e.target.value, i);
          }}
          fullWidth
        />
      );
      return a;
    });
  };

  return (
    <Grid>
      <Grid>
        <Grid>
          <h2>
            <IconButton
              color="primary"
              onClick={() =>
                history.push(
                  '/location'
                )
              }
              icon=<ArrowBack />
            ></IconButton>
            {!locationId ? "Add" : "Edit"} Location
          </h2>
        </Grid>
        <Grid
          style={{ display: "flex", justifyContent: "space-around" }}
          container
        >
          <Grid sm={12} md={4} style={{ marginTop: "1rem" }}>
            <TextField
              variant="filled"
              label="Address"
              value={field.address}
              isError={errors.address}
              onChange={(e) => {
                setFields("address", e.target.value);
              }}
              customClass={classes.root}
            />
          </Grid>
          <Grid sm={12} md={4} style={{ marginTop: "1rem" }}>
            <TextField
              variant="filled"
              label="City"
              value={field.city}
              isError={errors.city}
              onChange={(e) => {
                setFields("city", e.target.value);
              }}
              customClass={classes.root}
            />
          </Grid>
          <Grid sm={12} md={4} style={{ marginTop: "1rem" }}>
            <TextField
              variant="filled"
              label="Country"
              value={field.country}
              isError={errors.country}
              onChange={(e) => {
                setFields("country", e.target.value);
              }}
              customClass={classes.root}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        <Table
          header={TABLE_HEADER}
          data={field.contacts}
          onDelete={(i, e, row) => {
            let contacts = field.contacts;
            contacts.splice(i, 1);
            setcontactList(contacts);
          }}
          onEdit={(i, row) => {
            // console.log(row)
          }}
          editBtn={false}
          addRow={true}
          onAddRow={(e) => {
            let contacts = field.contacts;
            contacts.push({ errors: {} });
            setcontactList(contacts);
          }}
        />
      </Grid>
      <Grid style={{ textAlign: "center" }}>
        <Button
          style={{ marginTop: "1.7rem" }}
          width="150px"
          text={locationId ? "Save" : "Add"}
          variant="contained"
          color="primary"
          onClick={() => {
            onSubmit();
          }}
        />
      </Grid>
    </Grid>
  );
}
