import {
  Commute as VehicleIcon,
  AddLocationTwoTone as LocationIcon,
  BusinessTwoTone as CompanyIcon,
  AccountBoxTwoTone as RoleIcon,
  PersonAddTwoTone as UserIcon,
  GroupWorkTwoTone as PartIcon,
  Dashboard as DashboardIcon,
  Pages as PagesIcon,
  ExitToApp,
  RestoreFromTrash as RestoreFromTrashIcon
} from "@material-ui/icons";
import { SvgIcon } from "../../commons/mui";
import {
  Modules,
  Part,
  Grid,
} from "../../assets/icons/custom";
import { ASSOCIATE, BRM, CUSTOMER, PDSLADMIN, TECHNICIAN } from "../Projects/AllProjects/CONSTANTS";

export default (companyId = '') => {

  const drawerItems =
    [
      ...(companyId ?
        [
          ...[
            {
              icon: (
                <DashboardIcon
                  style={{ height: "24px", color: '#000' }}
                />
              ),
              title: "Projection",
              description: "",
              link: "/brm-projection",
              access: BRM,
              children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
            },
            {
              icon: (
                <PagesIcon
                  style={{ height: "24px", color: '#000' }}
                />
              ),
              title: "Report Incidents",
              description: "",
              link: "/accident",
              access: BRM,
              children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
            },
            {
              icon: (

                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24" /></g><g><path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M19.46,9.12l-2.78,1.15 c-0.51-1.36-1.58-2.44-2.95-2.94l1.15-2.78C16.98,5.35,18.65,7.02,19.46,9.12z M12,15c-1.66,0-3-1.34-3-3s1.34-3,3-3s3,1.34,3,3 S13.66,15,12,15z M9.13,4.54l1.17,2.78c-1.38,0.5-2.47,1.59-2.98,2.97L4.54,9.13C5.35,7.02,7.02,5.35,9.13,4.54z M4.54,14.87 l2.78-1.15c0.51,1.38,1.59,2.46,2.97,2.96l-1.17,2.78C7.02,18.65,5.35,16.98,4.54,14.87z M14.88,19.46l-1.15-2.78 c1.37-0.51,2.45-1.59,2.95-2.97l2.78,1.17C18.65,16.98,16.98,18.65,14.88,19.46z" /></g></svg>
              ),
              title: "Ticket",
              description: "",
              link: "/tickets",
              access: BRM,
              children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
            },
            {
              icon: <VehicleIcon />,
              title: 'Vehicle',
              description: '',
              link: '/vehicle/create',
              access: BRM,
              isDivider: false,
              disabled: false,
              hidden: false,
              children: [
                {
                  icon: <VehicleIcon />,
                  title: 'List Vehicles',
                  description: '',
                  link: '/vehicle',
                  access: BRM,
                  isDivider: false,
                  disabled: false,
                  hidden: false,
                  children: false,
                },
                {
                  icon: <VehicleIcon />,
                  title: 'Add Vehicle',
                  description: '',
                  link: '/vehicle/create',
                  access: BRM,
                  isDivider: false,
                  disabled: false,
                  hidden: false,
                  children: false,
                }
              ],
            },
            {
              icon: (
                <SvgIcon
                  component={Part}
                  viewBox="9 0 6 24"
                  styleObj={{ height: "24px" }}
                />
              ),
              title: "Project",
              description: "",
              link: "/projects",
              access: BRM,
              // children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
              children: [
                {
                  icon: <SvgIcon
                    component={Part}
                    viewBox="9 0 6 24"
                    styleObj={{ height: "24px" }}
                  />,
                  title: 'List Projects',
                  description: '',
                  link: '/projects',
                  access: BRM,
                  isDivider: false,
                  disabled: false,
                  hidden: false,
                  children: false,
                },
                {
                  icon: <RestoreFromTrashIcon />,
                  title: 'Recover Project',
                  description: '',
                  link: '/recover-projects',
                  access: BRM,
                  isDivider: false,
                  disabled: false,
                  hidden: false,
                  children: false,
                }
              ],
            },
            {
              icon: <SvgIcon
                component={Modules}
                viewBox="9 0 6 24"
                styleObj={{ height: "24px" }}
              />,
              title: "Template",
              description: "",
              link: "/assessment-template",
              access: BRM,
              children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
            },
            {
              icon: <LocationIcon />,
              title: "Location",
              description: "",
              link: "/location",
              access: BRM,
              children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
            },
            {
              icon: <PartIcon />,
              title: "Part",
              description: "",
              link: "/part/list",
              access: BRM,
              children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
            },
            {
              icon: <RoleIcon />,
              title: "Roles",
              description: "",
              link: "/roles",
              access: BRM,
              children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
            },
            {
              icon: <UserIcon />,
              title: "User",
              description: "",
              link: "/user",
              access: BRM,
              children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
            },
            {
              icon: (
                <SvgIcon
                  component={Grid}
                  viewBox="9 0 6 24"
                  styleObj={{ height: "24px" }}
                />
              ),
              title: "Grid Builder",
              description: "",
              link: "/brm-grids",
              access: BRM,
              children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
            },
            {
              icon: <ExitToApp />,
              title: "Logout",
              description: "",
              link: "/logout",
              action: "logout",
              isDivider: false,
              disabled: false,
              hidden: false,
              access: BRM,
            },
          ],
          ...[
            {
              icon: (
                <DashboardIcon
                  style={{ height: "24px", color: '#000' }}
                />
              ),
              title: "Projection",
              description: "",
              link: "/customer-projection",
              access: CUSTOMER,
              children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
            },
            {
              icon: (
                <SvgIcon
                  component={Part}
                  viewBox="9 0 6 24"
                  styleObj={{ height: "24px" }}
                />
              ),
              title: "Project",
              description: "",
              link: "/customer-projects",
              access: CUSTOMER,
              children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
            },
            {
              icon: <ExitToApp />,
              title: "Logout",
              description: "",
              link: "/logout",
              action: "logout",
              isDivider: false,
              disabled: false,
              hidden: false,
              access: CUSTOMER,
            },
          ],
          ...[
            {
              icon: (
                <SvgIcon
                  component={Part}
                  viewBox="9 0 6 24"
                  styleObj={{ height: "24px" }}
                />
              ),
              title: "Project",
              description: "",
              link: "/associate",
              access: ASSOCIATE,
              children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
            },
            {
              icon: <ExitToApp />,
              title: "Logout",
              description: "",
              link: "/logout",
              action: "logout",
              isDivider: false,
              disabled: false,
              hidden: false,
              access: ASSOCIATE,
            },
          ],
          ...[
            {
              icon: (
                <SvgIcon
                  component={Grid}
                  viewBox="9 0 6 24"
                  styleObj={{ height: "24px" }}
                />
              ),
              title: "Work Plans",
              description: "",
              link: "/inspector-workplan-list",
              access: TECHNICIAN,
              children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
            },
            {
              icon: (

                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24" /></g><g><path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M19.46,9.12l-2.78,1.15 c-0.51-1.36-1.58-2.44-2.95-2.94l1.15-2.78C16.98,5.35,18.65,7.02,19.46,9.12z M12,15c-1.66,0-3-1.34-3-3s1.34-3,3-3s3,1.34,3,3 S13.66,15,12,15z M9.13,4.54l1.17,2.78c-1.38,0.5-2.47,1.59-2.98,2.97L4.54,9.13C5.35,7.02,7.02,5.35,9.13,4.54z M4.54,14.87 l2.78-1.15c0.51,1.38,1.59,2.46,2.97,2.96l-1.17,2.78C7.02,18.65,5.35,16.98,4.54,14.87z M14.88,19.46l-1.15-2.78 c1.37-0.51,2.45-1.59,2.95-2.97l2.78,1.17C18.65,16.98,16.98,18.65,14.88,19.46z" /></g></svg>
              ),
              title: "Ticket",
              description: "",
              link: "/tickets",
              access: TECHNICIAN,
              children: false,
              isDivider: false,
              disabled: false,
              hidden: false,
            },
            {
              icon: <ExitToApp />,
              title: "Logout",
              description: "",
              link: "/logout",
              action: "logout",
              isDivider: false,
              disabled: false,
              hidden: false,
              access: TECHNICIAN,
            },
          ]
        ]
        :
        []
      ),
      ...[
        {
          icon: <CompanyIcon />,
          title: "Company",
          description: "",
          link: "/company",
          isDivider: false,
          disabled: false,
          hidden: false,
          access: PDSLADMIN,
        },
        {
          icon: (
            <SvgIcon
              component={Grid}
              viewBox="9 0 6 24"
              styleObj={{ height: "24px" }}
            />
          ),
          title: "Grid Builder",
          description: "",
          link: "/grids",
          access: PDSLADMIN,
          children: false,
          isDivider: false,
          disabled: false,
          hidden: false,
        },
        {
          icon: <RoleIcon />,
          title: "Roles",
          description: "",
          link: "/super-roles",
          access: PDSLADMIN,
          children: false,
          isDivider: false,
          disabled: false,
          hidden: false,
        },
        {
          icon: <UserIcon />,
          title: "PDSL Users",
          description: "",
          link: "/super-user",
          access: PDSLADMIN,
          children: false,
          isDivider: false,
          disabled: false,
          hidden: false,
        },
        {
          icon: <ExitToApp />,
          title: "Logout",
          description: "",
          link: "/logout",
          action: "logout",
          isDivider: false,
          disabled: false,
          hidden: false,
          access: PDSLADMIN,
        },
      ],

    ];
  return drawerItems;
}

