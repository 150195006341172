import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CustomButton from '../../Button/index'

const styles = (theme) => ({
  root: {
    margin: 0,
    width:500,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  }
}))(MuiDialogContent);

// const DialogContentText = withStyles((theme) => ({
//   root: {
//     marginBottom: theme.spacing(4),
//     marginLeft: theme.spacing(3),
//     marginRight: theme.spacing(3)
//   }
// }))(MuiDialogContentText);

const Index = ({
  Component= CustomButton,
  title='Title',
  content=() => {},
  forceClose=false,
}) => {
  const id = Math.random();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // console.log(forceClose);
    if(!forceClose && open) handleClickOpen()
    else handleClose()
  }, [forceClose, open]);

  return (
    <div>
      {<Component onClick={handleClickOpen} />}
      <Dialog onClose={handleClose} aria-labelledby={`${id}`} open={open}>
        <DialogTitle id={`${id}`} onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent>
          {content(handleClose)}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Index;
