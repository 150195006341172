import {
  LIST_COMPANY,
  GET_COMPANY,
  ADD_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listCompany = (params) => async dispatch => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: 'GET',
      url: `/company`,
      params
    });
    dispatch({
      type: LIST_COMPANY,
      payload: {
        data: res.data,
        meta: res.data.meta,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: LIST_COMPANY,
      payload: {
        data: [],
        meta: err?.data?.meta || null,
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const getCompany = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/company/${id}`,
    });
    dispatch({
      type: GET_COMPANY,
      payload: {
        data: res.data,
        meta: res.data.meta,
        isLoading: false,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: GET_COMPANY,
      payload: {
        data: {},
        meta: err?.data?.meta,
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addCompany = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/company`,
      data
    });
    dispatch({
      type: ADD_COMPANY,
      payload: {
        data: res.data,
        meta: res.data.meta,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: ADD_COMPANY,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      }
    });
  }
}

export const updateCompany = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/company/${id}`,
      data
    });
    dispatch({
      type: UPDATE_COMPANY,
      payload: {
        data: res.data,
        meta: res.data.meta,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_COMPANY,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      }
    });
  }
}

export const deleteCompany = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/company/${id}`,
    });
    dispatch({
      type: DELETE_COMPANY,
      payload: {
        data: res.data,
        meta: res.data.meta,
        hasError: false,
      }
    });
  } catch (err) {
    dispatch({
      type: DELETE_COMPANY,
      payload: {
        data: {},
        meta: err?.data?.meta,
        hasError: true,
      }
    });
  }
}
export const clearCompanyData = (data) => async (dispatch) => {
  dispatch({
    type: DELETE_COMPANY,
    payload: {
      data: {},
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
};