import {
  Grid,
  makeStyles,
  withStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AutoComplete, Button, IconButton, TextField } from "../../../commons/mui";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../redux/actions/user";
import { updateUser } from "../../../redux/actions/user";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { BRM, PDSLADMIN, TECHNICIAN } from "../../Projects/AllProjects/CONSTANTS";
import { setCurrentUser } from "../../../redux/actions/auth";
import { ArrowBack } from "@material-ui/icons";
import { listLocations } from "../../../redux/actions/location";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 330,
    [theme.breakpoints.down("sm")]: {
      width: 290,
    },
  },
  input: {
    marginTop: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "1rem",
    },
  },
}));

export default function Index() {
  const [field, setField] = useState({
    name: "",
    role: {},
    emailId: "",
  });
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [roleList, setRoleList] = useState([]);
  const roles = useSelector((state) => state.role);
  const auth = useSelector((state) => state.auth);
  const [errors, seterrors] = useState({});
  const [isTechnician, seIsTechnician] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const locations = useSelector((state) => state.location);

  const setFields = (type, val) => {
    Object.keys(field).map((itm) => {
      if (itm == type) {
        setField({ ...field, [itm]: val });
      }
    });
  };

  useEffect(() => {
    dispatch(listLocations({ limit: 0 }));
  }, []);

  useEffect(() => {
    // console.log('->',locations)
    if (locations.list.data && locations.list.data.length) {
      setLocationList(locations.list.data);
    }
  }, [locations]);

  useEffect(() => {
    if (roles.list.data) {
      let rls = roles.list.data.map((itm) => {
        return { label: itm.name, value: itm._id };
      });
      // console.log(rls)
      setRoleList(rls);
    }
  }, [roles]);

  useEffect(() => {
    dispatch(getUser(id));
  }, []);

  useEffect(() => {
    if (user.detail.data._id) {
      if (user.detail?.data?.role?.name === TECHNICIAN) seIsTechnician(true);
      else seIsTechnician(false);
      setField({
        _id: user.detail.data._id,
        name: user.detail.data.firstName + " " + user.detail.data.lastName,
        emailId: user.detail.data.emailId,
        role: user.detail.data.role._id,
        location: user.detail.data.location.map((a) => {
          a.name = a.address;
          return a;
        }),
      });
    }
  }, [user]);

  const validate = () => {
    let temp = {};
    let error = false;
    if (!field.name) {
      error = true;
      temp.name = "Name is required";
    }
    if (!field.emailId) {
      error = true;
      temp.emailId = "Email is required";
    }
    if (!field.role) {
      error = true;
      temp.role = "Role is required";
    }

    seterrors(temp);

    return !error;
  };

  const onSubmit = () => {
    if (validate()) {
      let data = { ...field };
      // console.log(data.name, data.name.split(" "), data.name.split(" ")[0]);
      data.firstName = data.name.split(" ")[0];
      data.lastName = data.name.replace(data.firstName, "").trim();
      data.location = data.location.map((a) => a._id);
      dispatch(updateUser(data, id));
      setTimeout(() => {
        if (id === auth.data?._id) {
          let usr = { ...auth.data };
          usr.firstName = data.firstName;
          usr.lastName = data.lastName;
          dispatch(setCurrentUser(usr));
        }
        history.push(
          auth.data?.role?.name === PDSLADMIN ? "/super-user" : "/user"
        );
      }, 500);
    }
  };

  return (
    <Grid>
      <Grid>
        <Grid>
          <h2>
            <IconButton
              color="primary"
              onClick={() => {
                if (auth.data?.role?.name === BRM) {
                  history.push("/user");
                } else {
                  history.push("/super-user");
                }
              }}
              icon=<ArrowBack />
            ></IconButton>
            Edit User
          </h2>
        </Grid>
        <Grid spacing={2} container>
          <Grid item sm={4}>
            <TextField
              variant="filled"
              label="Name"
              value={field.name}
              onChange={(e) => {
                setFields("name", e.target.value);
              }}
              fullWidth
              isError={errors.name}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              variant="filled"
              label="Email"
              value={field.emailId}
              onChange={(e) => {
                setFields("emailId", e.target.value);
              }}
              fullWidth
              isError={errors.emailId}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label="Role"
              variant="filled"
              color="secondary"
              value={field.role}
              defaultValue={field.role}
              select={true}
              options={roleList}
              fullWidth
              isError={errors.role}
              onChange={(e) => {
                let selected = roleList.find((a) => a.value === e.target.value);
                if (selected.label === TECHNICIAN) seIsTechnician(true);
                else seIsTechnician(false);
                setField({ ...field, role: e.target.value });
              }}
            />
          </Grid>
          {isTechnician ? (
            <Grid item sm={4}>
              <AutoComplete
                label="Location of Work"
                variant="filled"
                value={field.location}
                name="location"
                multiple
                // select={true}
                options={(locationList || []).map((a) => {
                  a.name = a.address;
                  return a;
                })}
                onChange={(e, newValue) => {
                  setFields("location", newValue);
                }}
                fullWidth
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid style={{ textAlign: "center" }}>
          <Button
            style={{ marginTop: "1.7rem" }}
            width="150px"
            text="Save"
            variant="contained"
            color="primary"
            onClick={() => {
              onSubmit();
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
