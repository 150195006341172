import { callAPI } from "../../utils/apiHelper";
import {
  LIST_WORK_PLAN,
  GET_WORK_PLAN,
  ADD_WORK_PLAN,
  UPDATE_WORK_PLAN,
  DELETE_WORK_PLAN,
  SIGN_WORK_PLAN,
  LIST_WORK_PLAN_REPORT,
  ADD_WORK_PLAN_INSPECTOR,
  LIST_WORK_PLAN_VEHICLE_REPORT,
} from "../action_types";
// import { QS } from '../../utils/urlHelper';

export const listPlans = (params) => async (dispatch) => {
  callAPI(dispatch,`/project_workplans`, 'GET',LIST_WORK_PLAN,params,true);
};
export const listPlanReports = (params) => async (dispatch) => {
  callAPI(dispatch,`/project_workplans/report`, 'GET',LIST_WORK_PLAN_REPORT,params,true);
};
export const listPlanVehicleReports = (planId) => async (dispatch) => {
  callAPI(dispatch,`/project_workplans/report-by-vehicle/${planId}`, 'GET',LIST_WORK_PLAN_VEHICLE_REPORT,{},true);
};

export const getPlan = (id) => async (dispatch) => {
  callAPI(dispatch,`/project_workplans/${id}`, 'GET',GET_WORK_PLAN,{},true);
};

export const addPlan = (data) => async (dispatch) => {
  callAPI(dispatch,`/project_workplans`, 'POST',ADD_WORK_PLAN,{},false,data);
};
export const addInspactor= (data) => async (dispatch) => {
  callAPI(dispatch,`/project_workplans/addInspactor`, 'POST',ADD_WORK_PLAN_INSPECTOR,{},false,data);
};

export const updatePlan = (data, id) => async (dispatch) => {
  callAPI(dispatch,`/project_workplans/${id}`, 'PUT',UPDATE_WORK_PLAN,{},false,data);
};
export const verifyPlanSign = (data, id) => async (dispatch) => {
  callAPI(dispatch,`/project_workplans/verifyAgreement`, 'PUT',SIGN_WORK_PLAN,{},false,data);
};

export const deletePlan = (id) => async (dispatch) => {
  callAPI(dispatch,`/project_workplans/${id}`, 'DELETE',DELETE_WORK_PLAN,{},false);
};
export const clearPlanData = (type = '') => async (dispatch) => {
  dispatch({
    type: type || UPDATE_WORK_PLAN,
    payload: {
      data: null,
      meta: null,
      isLoading: false,
      hasError: false,
    }
  });
}; 
