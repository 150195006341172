import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Index = ({component, styleObj, viewBox='0 0 24 24', ...rest}) => {
  // Extracting required styles from remaining props
  let styleProps = Object.fromEntries(
    Object.entries(rest).filter(([key]) => ['height', 'width', 'transform'].includes(key))
  )
  let style = { ...styleObj, ...styleProps} || {};
  return (
    <SvgIcon {...rest} style={style} component={component} viewBox={viewBox}/>
  )
}

export default Index;