import { 
  GET_STATIC_REPORT, GET_RANGE_REPORT, 
  GET_CAR_PERFORMANCE_DATA, GET_ENG_PERFORMANCE_DATA,
  GET_BAY_PERFORMANCE_DATA, GET_MODULES_PERFORMANCE_DATA,
  GET_DAY_WISE_REPORT, RESET_DASHBOARD
} from '../action_types';

const initialState = {
  staticReport: {
    data: {},
    isLoading: true,
    hasError: false
  },
  reportR: {
    data: {},
    isLoading: true,
    hasError: false
  },
  carPerformance: {
    data: {},
    isLoading: true,
    hasError: true,
  },
  engPerformance: {
    data: [],
    isLoading: true,
    hasError: true,
  },
  bayPerformance: {
    data: {},
    isLoading: true,
    hasError: true,
  },
  modulesPerformance: {
    data: {},
    isLoading: true,
    hasError: true,
  },
  dayWiseReport: {
    data: {},
    isLoading: true,
    hasError: true,
  }
}

const dashboard = (state=initialState, action)  => {
  const { type, payload } = action;

  switch(type) {
    case GET_STATIC_REPORT: 
      return {
        ...state,
        staticReport: {
          ...payload
        }
      };
    case GET_RANGE_REPORT:
      return {
        ...state,
        reportR: {
          ...payload
        }
      };
    case GET_CAR_PERFORMANCE_DATA:
      return {
        ...state,
        carPerformance: {
          ...payload
        }
      }
    case GET_ENG_PERFORMANCE_DATA:
      return {
        ...state,
        engPerformance: {
          ...payload
        }
      }
    case GET_BAY_PERFORMANCE_DATA:
      return {
        ...state,
        bayPerformance: {
          ...payload
        }
      }
    case GET_MODULES_PERFORMANCE_DATA:
      return {
        ...state,
        modulesPerformance: {
          ...payload
        }
      }
    case GET_DAY_WISE_REPORT:
      return {
        ...state,
        dayWiseReport: {
          ...payload
        }
      }
    case RESET_DASHBOARD: {
        return initialState;
      }
    default: return state;
  }
}

export default dashboard;