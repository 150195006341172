import React from "react";

export default function Index({ data = {} }) {
  return (
    <div>
      <h3 style={{marginLeft: "1.5rem"}}>DATA COLLECTION - RESULTS</h3>
      <div style={{display: "flex"}}>
        <div style={{width: "50%"}}>
          <div style={{margin:"1.5rem"}}>
            <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
              PDSL Project No:
            </span>
            <span style={{marginRight: "0.7rem"}}>{data?.projectNo}</span>
            <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>Rev:</span>
            <span>{data?.rev}</span>
          </div>
          <div style={{margin:"1.5rem"}}>
            <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
              Customer Name:
            </span>
            <span>{data?.customer?.firstName+" "+data?.customer?.lastName}</span>
          </div>
          <div style={{margin:"1.5rem"}}>
            <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
              Contact Name:
            </span>
            <span>{data?.contactName}</span>
          </div>
        </div>
        <div style={{width: "50%"}}>
          <div style={{margin:"1.5rem"}}>
            <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
              SAP/Customer Ref:
            </span>
            <span>{data?.sapNo}</span>
          </div>
          <div style={{margin:"1.5rem"}}>
            <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
              Date of Activity:
            </span>
            <span>{data?.activityDate}</span>
          </div>
          <div style={{margin:"1.5rem"}}>
            <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
              Location of Work:
            </span>
            <span>{data?.workLocation?.address}</span>
          </div>
          <div style={{margin:"1.5rem"}}>
            <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
              Activity:
            </span>
            <span>{data?.activity}</span>
          </div>
        </div>
      </div>
      <div style={{display: "flex"}}>
        <div style={{width: "50%"}}>
          <div style={{margin: "1.5rem", display: "flex"}}>
            <span style={{fontWeight:" bold", marginRight: "1.5rem"}}>
              Project Status:
            </span>
            <div style={{marginRight: "1rem"}}>
              <span style={{fontWeight:" bold", marginRight: "0.1rem"}}>{data?.projectStatus}</span>
              <span>Yes</span>
            </div>
            <div>
              <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>Old</span>
              <span></span>
            </div>
          </div>
        </div>
        <div style={{width: "50%"}}>
          <div style={{margin: "1.5rem", display: "flex"}}>
            <div style={{display:" flex", marginRight: "1.5rem"}}>
              <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
                Continues:
              </span>
              <span>Yes</span>
            </div>
            <div style={{display: "flex"}}>
              <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>End:</span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div style={{display: "flex"}}>
        <div style={{width: "25%"}}>
          <div style={{margin:"1.5rem"}}>
            <span style={{fontWeight: "bold"}}>Associate Name/ID</span>
          </div>
          <div style={{margin:"1.5rem"}}>
            <span>{data?.associateName}</span>
          </div>
          <div style={{margin:"1.5rem"}}>
            <span>{data?.associateName}</span>
          </div>
        </div>
        <div style={{width: "25%"}}>
          <div style={{margin:"1.5rem"}}>
            <span style={{fontWeight: "bold"}}>Start Time-End Time of Day</span>
          </div>
          <div style={{margin: "1.5rem", display: "flex"}}>
            <span style={{width: "50%"}}>6:00</span>
            <span style={{width: "50%"}}>14:00</span>
          </div>
          <div style={{margin: "1.5rem", display: "flex"}}>
            <span style={{width: "50%"}}>6:00</span>
            <span style={{width: "50%"}}>14:00</span>
          </div>
        </div>
        <div style={{width: "25%"}}>
          <div style={{margin:"1.5rem"}}>
            <span style={{fontWeight: "bold"}}>Hours on This Project</span>
          </div>
          <div style={{margin: "1.5rem", display: "flex"}}>
            <span style={{width: "50%"}}>8:00</span>
            <span style={{width: "50%"}}>10:00</span>
          </div>
          <div style={{margin: "1.5rem", display: "flex"}}>
            <span style={{width: "50%"}}>8:00</span>
            <span style={{width: "50%"}}>10:00</span>
          </div>
        </div>
        <div style={{width: "25%"}}>
          <div style={{margin:"1.5rem"}}>
            <span style={{fontWeight: "bold"}}>Total</span>
          </div>
          <div style={{margin: "1.5rem", display: "flex"}}>
            <span style={{width: "50%"}}>2</span>
          </div>
          <div style={{margin: "1.5rem", display: "flex"}}>
            <span style={{width: "50%"}}>2</span>
          </div>
        </div>
      </div>
      <div>
        <div style={{margin:"1.5rem"}}>
          <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
            Part/Product Name:
          </span>
          <span style={{marginRight: "0.7rem"}}>{data?.partName}</span>
        </div>
        <div style={{margin:"1.5rem"}}>
          <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
            Part Number:
          </span>
          <span style={{marginRight: "0.7rem"}}>{data?.partNumber}</span>
        </div>
        <div style={{margin:"1.5rem"}}>
          <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
            Batch/Production Date(if applicable):
          </span>
          <span style={{marginRight: "0.7rem"}}>Supplier batch no 456</span>
        </div>
        <div style={{margin:"1.5rem"}}>
          <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
            Delivery/Advice No(if applicable):
          </span>
          <span style={{marginRight: "0.7rem"}}>01.02.2023</span>
        </div>
        <div style={{margin:"1.5rem"}}>
          <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
            Total Amount Checked:
          </span>
          <span style={{marginRight: "0.7rem"}}>2000</span>
        </div>
        <div style={{margin:"1.5rem"}}>
          <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
            NOK Total:
          </span>
          <span style={{marginRight: "0.7rem"}}>5</span>
        </div>
        <div style={{margin:"1.5rem"}}>
          <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
            NOK Reworked:
          </span>
          <span style={{marginRight: "0.7rem"}}>0</span>
        </div>
        <div style={{margin: "1.5rem", display: "flex"}}>
          <div style={{width: "50%"}}>
            <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
              NOK Stopped:
            </span>
            <span style={{marginRight: "0.7rem", backgroundColor: "red"}}>5</span>
          </div>
          <div style={{width: "50%"}}>
            <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>
              Defect Description:
            </span>
            <span style={{marginRight: "0.7rem"}}>Assembly bolt missing</span>
          </div>
        </div>
        <div style={{margin:"1.5rem"}}>
          <span style={{fontWeight: "bold", marginRight: "0.7rem"}}>Total OK:</span>
          <span style={{marginRight: "0.7rem", backgroundColor: "green"}}>
            1995
          </span>
        </div>
      </div>
      <div style={{margin:"1.5rem"}}>
        <div style={{border: "1px solid grey", width: "100%", height: "10rem"}}></div>
      </div>
      <div style={{textAlign: "center"}}>
        <span style={{fontWeight: "bold", fontStyle: "italic"}}>
          (All Linked to Customer Dashboard Visibility & Include Stats/Costs/PO
          Depletion etc)
        </span>
      </div>
      <div style={{textAlign:" center", marginTop: "1.5rem"}}>
        <div style={{display: "flex", paddingLeft: "25%"}}>
          <div style={{border: "1px solid black", padding: "5px", fontFamily: "Arial, Helvetica, sans-serif", color: "white",backgroundColor: "green", fontWeight: "bold"}}>
            SAFETY
          </div>
          <div style={{border: "1px solid black", padding: "5px", fontFamily: "Arial, Helvetica, sans-serif", color: "white",backgroundColor: "blue", fontWeight: "bold"}}>
            QUALITY
          </div>
          <div style={{border: "1px solid black", padding: "5px", fontFamily: "Arial, Helvetica, sans-serif", color: "white",backgroundColor: "red", fontWeight: "bold"}}>
            DELIVERY
          </div>
          <div style={{border: "1px solid black", padding: "5px", fontFamily: "Arial, Helvetica, sans-serif", color: "white",backgroundColor: "black", fontWeight: "bold"}}>
            COST
          </div>
          <div style={{border: "1px solid black", padding: "5px", fontFamily: "Arial, Helvetica, sans-serif", color: "white",backgroundColor: "grey", fontWeight: "bold"}}>
            ENVIRONMENT
          </div>
          <div style={{border: "1px solid black", padding: "5px", fontFamily: "Arial, Helvetica, sans-serif", color: "white",backgroundColor: "yellow", fontWeight: "bold"}}>
            PEOPLE
          </div>
          <div style={{border: "1px solid black", padding: "5px", fontFamily: "Arial, Helvetica, sans-serif", color: "white",backgroundColor: "white", fontWeight: "bold"}}>
            LEADERSHIP
          </div>
        </div>
      </div>
    </div>
  );
}
