import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function Index({ data = {}, eSign = false }) {
  const auth = useSelector(state => state.auth)
  data.location  = Array.isArray(data?.location)?data?.location:[]
  useEffect(() => {
    console.log(data);
  }, []);

  useEffect(() => {
    console.log(auth);
  }, [auth])

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h1>Project : iTechQ</h1>
      </div>
      <div style={{ marginLeft: "1rem" }}>
        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>PDSL Project No</b>
            </div>
            <div style={{ width: "40%" }}>{data?.projectNo}</div>
            <div>
              <b>Rev O</b>
            </div>
          </div>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>SAP/Customer Ref</b>
            </div>
            <div>{data?.sapNo}</div>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>Company Name</b>
            </div>
            <div>{data?.companyName}</div>
          </div>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>PO No</b>
            </div>
            <div>{data?.poNo}</div>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>Client Contact Name</b>
            </div>
            <div>
              {data?.customer?.firstName + " " + data?.customer?.lastName}
            </div>
          </div>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>Telephone No</b>
            </div>
            <div>{data?.phoneNo}</div>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>E-mail Address</b>
            </div>
            <div>{data?.customer?.emailId}</div>
          </div>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>Mobile No</b>
            </div>
            <div>{data?.customerContact}</div>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>Location of Work</b>
            </div>
            <div>
              {data?.workLocation.map((itm) => {
                return (
                  <>
                    <span>{itm.address},</span>
                  </>
                );
              })}
            </div>
          </div>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>Location Contact</b>
            </div>
            <div>
              {data?.location.map((itm) => {
                return (
                  <>
                    <span>
                      {itm.contactName + "(" + itm.contactNumber + "),"}
                    </span>
                  </>
                );
              })}
            </div>
          </div>
        </div>

        {/* <div style={{ display: "flex", marginTop: "1rem" }}>
          123 Customer Site,Customer Road,CA12 GRO
        </div> */}

        {/* <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "50%", display: "flex" }}>
            <div>
              <b>Additional Locations-Support Required</b>
            </div>
          </div>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>Yes Where?PDSL Project No(s)?</div>
            <div style={{ width: "10%" }}>
              <b>NO</b>
            </div>
            <div>NO</div>
          </div>
        </div> */}

        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>Part Name(s)</b>
            </div>
            <div>{data?.partName}</div>
          </div>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>Part Number(s)</b>
            </div>
            <div>{data?.partNumber}</div>
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ marginRight: "1rem" }}>
            <b>Problem Definition/Description</b>
          </div>
          <div>{data?.problemDescription}</div>
        </div>

        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>Type of Work Required</b>
            </div>
            <div style={{ width: "45%" }}>
              <span>{data?.typeOfWorkRequired} </span>
            </div>
            {/* <div>Yes</div> */}
          </div>
          {/* <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>Rework</b>
            </div>
            <div style={{ width: "45%" }}>
              <b>Retrofit</b>
            </div>
          </div> */}
        </div>
        {/* 
        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "50%", display: "flex" }}>
            <div>
              <b>Audit</b>
            </div>
          </div>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "45%" }}>
              <b>Lab/CMM</b>
            </div>
            <div style={{ width: "45%" }}>
              <b>Engineer</b>
            </div>
          </div>
        </div> */}

        {/* <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "50%", display: "flex" }}>
            <b>CS1/2/3/4</b>
          </div>
          <div style={{ width: "50%", display: "flex" }}>
            <b>Other-Describe</b>
          </div>
        </div> */}

        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "15%" }}>
            <b>Commodity</b>
          </div>
          <div style={{ width: "8%" }}>
            <span>{data?.commodity}</span>
          </div>
          {/* <div style={{ width: "8%" }}>
            <b>CHA</b>
          </div>
          <div style={{ width: "8%" }}>
            <b>ELEC</b>
          </div>
          <div style={{ width: "8%" }}>
            <b>EXT</b>
          </div>
          <div style={{ width: "8%" }}>
            <b>INT</b>
          </div>
          <div style={{ width: "8%" }}>
            <b>POW</b>
          </div>
          <div style={{ width: "8%" }}>
            <b>X</b>
          </div>
          <div style={{ width: "8%" }}>
            <b>STB</b>
          </div>
          <div style={{ width: "8%" }}>
            <b>SOF</b>
          </div> */}
        </div>

        <div style={{ width: "50%", display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "45%" }}>
            <b>Project Requirement</b>
          </div>
          <div style={{ width: "45%" }}>{data?.projectRequirement}</div>
        </div>

        <div style={{ width: "50%", display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "45%" }}>
            <b>Start Date</b>
          </div>
          <div style={{ width: "45%" }}>{data?.startDate?.split("T")[0]}</div>
        </div>

        <div style={{ width: "65%", display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "19%" }}>
            <b>Exit Date/Criteria</b>
          </div>
          <div style={{ width: "40%" }}>
            {data?.expectedEndDate?.split("T")[0]}
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "15%" }}>
            <b>Reporting Frequency</b>
          </div>
          <div style={{ width: "10%" }}>{data?.reportingFrequency}</div>
          {/* <div style={{ width: "10%" }}>Yes</div>
          <div style={{ width: "10%" }}>
            <b>EOS</b>
          </div>
          <div style={{ width: "10%" }}>
            <b>Daily</b>
          </div>
          <div style={{ width: "10%" }}>
            <b>Weekly</b>
          </div> */}
        </div>

        <div
          style={{
            marginTop: "1rem",
            border: "1px solid black",
            padding: "1rem",
            width: "80%",
          }}
        >
        </div>

        {eSign ? (
          <>
            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div style={{ display: "flex", width: "33%" }}>
                <div style={{ width: "32%" }}>
                  <b>Order Acceptance By</b>
                </div>
                <div>{auth?.data?.firstName + ' ' + auth?.data?.lastName}</div>
              </div>
              <div style={{ display: "flex", width: "33%" }}>
                <div style={{ width: "20%" }}>
                  <b>Date</b>
                </div>
                <div></div>
              </div>
              <div style={{ display: "flex", width: "33%" }}>
                <div style={{ width: "20%" }}>
                  <b>E-Signature</b>
                </div>
                <div>
                  <img
                    src="https://tse1.mm.bing.net/th?id=OIP.k4lC6_WStP_TZ-Kap0EkZgHaCz&pid=Api&P=0&h=180"
                    style={{ width: "4rem", height: "1.5rem" }}
                  />
                </div>
              </div>
            </div>

            <div style={{ display: "flex", marginTop: "1rem" }}>
              <div style={{ display: "flex", width: "33%" }}>
                <div style={{ width: "32%" }}>
                  <b>Order Approved By</b>
                </div>
                <div>{data?.customer?.firstName + ' ' + data?.customer?.lastName}</div>
              </div>
              <div style={{ display: "flex", width: "33%" }}>
                <div style={{ width: "20%" }}>
                  <b>Date</b>
                </div>
                <div>{data?.date}</div>
              </div>
              <div style={{ display: "flex", width: "33%" }}>
                <div style={{ width: "20%" }}>
                  <b>E-Signature</b>
                </div>
                <div>
                  <img
                    src="https://tse1.mm.bing.net/th?id=OIP.k4lC6_WStP_TZ-Kap0EkZgHaCz&pid=Api&P=0&h=180"
                    style={{ width: "4rem", height: "1.5rem" }}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}

        <div
          style={{
            marginTop: "1rem",
            border: "1px solid black",
            padding: "1rem",
            width: "80%",
          }}
        >
          <div>
            <b>Please Note:Any Changes/Additions Execution of the Order-</b>
          </div>
          <div>
            If any amendments are necessary,these must be given in writing to
          </div>
        </div>
      </div>
    </div>
  );
}
