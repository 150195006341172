import {
  Grid,
  makeStyles,
  withStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, IconButton, Pagination, Table, TextField } from "../../../commons/mui";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../redux/actions/user";
import {
  addRole,
  deleteRole,
  getRole,
  listRoles,
  updateRole,
} from "../../../redux/actions/role";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { BRM, PDSLADMIN } from "../../Projects/AllProjects/CONSTANTS";
import { ROLES, SUPERROLES } from "../AddRoles/CONSTANTS";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: 290,
    },
  },
  input: {
    marginTop: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "1rem",
    },
  },
}));

export default function Index() {
  const [field, setField] = useState({
    name: "",
    description: "",
  });
  const classes = useStyles();
  const roles = useSelector((state) => state.role);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [existRoleList, setExistRoleList] = useState([]);

  const [errors, seterrors] = useState({});
  const setFields = (type, val) => {
    Object.keys(field).map((itm) => {
      if (itm == type) {
        setField({ ...field, [itm]: val });
      }
    });
  };

  useEffect(() => {
    dispatch(getRole(id));
  }, []);

  useEffect(() => {
    if (roles.detail.data._id) {
      setField({
        _id: roles.detail.data._id,
        name: roles.detail.data.name,
        description: roles.detail.data.description,
      });
    }
    if (roles?.list?.data) setExistRoleList(roles.list.data.map((a) => a.name));
  }, [roles]);

  const validate = () => {
    let temp = {};
    let error = false;
    if (!field.name) {
      error = true;
      temp.name = "Name is required";
    }
    if (!field.description) {
      error = true;
      temp.description = "Description is required";
    }
    seterrors(temp);

    return !error;
  };

  const onSubmit = () => {
    if (validate()) {
      dispatch(updateRole(field, field._id));
      setField({
        description: "",
        name: "",
      });
      history.push(
        auth.data?.role?.name === PDSLADMIN ? "/super-roles" : "/roles"
      );
    }
  };

  return (
    <Grid>
      <Grid>
        <Grid>
          <h2>
            <IconButton
              color="primary"
              onClick={() => {
                if(auth.data?.role?.name === BRM){
                  history.push("/roles");
                } else {
                  history.push("/super-roles");
                }
                
              }}
              icon=<ArrowBack />
            ></IconButton>
            Edit Role
          </h2>
        </Grid>
        <Grid style={{ display: "flex", flexWrap: "wrap" }} container>
          <Grid className={`${classes.input}`}>
            <TextField
              variant="filled"
              label="Name"
              value={field.name}
              isError={errors.name}
              onChange={(e) => {
                setFields("name", e.target.value);
              }}
              select={true}
              options={(auth.data?.role?.name === PDSLADMIN
                ? SUPERROLES
                : ROLES
              ).filter(
                (a) =>
                  existRoleList.indexOf(a.value) == -1 || a.value === field.name
              )}
              customClass={classes.root}
            />
          </Grid>
          <Grid className={`${classes.input}`}>
            <TextField
              variant="filled"
              label="Description"
              value={field.description}
              isError={errors.description}
              onChange={(e) => {
                setFields("description", e.target.value);
              }}
              customClass={classes.root}
            />
          </Grid>
        </Grid>
        <Grid style={{ textAlign: "center" }}>
          <Button
            style={{ marginTop: "1.7rem" }}
            width="150px"
            text="Edit"
            variant="contained"
            color="primary"
            onClick={() => {
              onSubmit();
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
