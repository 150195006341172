import eyewear from "../../../images/eyewear.jpg";
import harness from "../../../images/harness.png";
import hearing from "../../../images/hearing.jpg";
import helmet from "../../../images/helmet.png";
import jacket from "../../../images/jacket.jpg";
import mask from "../../../images/mask.png";
import gloves from "../../../images/gloves.jpg";
import shoes2 from "../../../images/shoes2.jpg";
import suit from "../../../images/suit.jpg";
const TABLE_HEADER = {
  sno: {
    data: "Reference",
    width: "50px",
  },
  question: {
    data: "Assessment",
    // width: '240px'
    align: 'left'
  }
};

const TABLE_HEADER_1 = {
  sno: {
    data: "Reference",
    width: "50px",
  },
  // question: {
  //   data: "Assessment",
  //   // width: '240px'
  //   align: 'left'
  // },
  // assessmentAnswer: {
  //   data: 'Action',
  // },
  hazard: {
    data: 'Hazard'
  },
  controlMeasure: {
    data: 'Control Measure'
  },
}
const TABLE_HEADER_2 = {
  sno: {
    data: "Reference",
    width: "50px",
  },
  question: {
    data: "Assessment",
    // width: '240px'
    align: 'left'
  },
  assessmentAnswer: {
    data: 'Action',
  },
  hazard: {
    data: 'Hazard'
  },
  controlMeasure: {
    data: 'Control Measure'
  },
}
const EQUIPMENTS = [
  {
    name: "Safety Glasses",
    image: eyewear
  },
  // {
  //   name: "Safety Harness",
  //   image: harness
  // },
  {
    name: "Safety Headphone",
    image: hearing
  },
  {
    name: "Safety Helmet",
    image: helmet
  },
  {
    name: "High-Vis Vest",
    image: jacket
  },
  {
    name: "Safety Mask",
    image: mask
  },
  {
    name: "Safety Shoes",
    image: shoes2
  },
  {
    name: "Safety Suit",
    image: suit
  },
  {
    name: "Safety Gloves",
    image: gloves
  },
]
const TABLE_HEADER_HAZARDLIST = {
  referenceView: {
    data: "Reference",
    width: "50px",
  },
  hazardView: {
    data: "Hazard",
    width: "300px",
  },
  controlMeasureView: {
    data: "Control Measure",
    width: "300px",
  },
};
export { TABLE_HEADER, TABLE_HEADER_1, TABLE_HEADER_2, EQUIPMENTS, TABLE_HEADER_HAZARDLIST };
