import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core';
import {
  AutoComplete,
  Button, IconButton, Table, TextField,

} from '../../../commons/mui';


import './Grid.css'
// import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { TABLE_HEADER_CHECKLIST } from './CONSTANTS';
import { red } from '@material-ui/core/colors';
import { addGrid, clearGridData, getGrid, updateGrid } from '../../../redux/actions/grid';
import { clearPartData, listParts } from '../../../redux/actions/part';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { BRM } from '../../Projects/AllProjects/CONSTANTS';
import { ADD_GRID, GET_GRID, LIST_PART, UPDATE_GRID } from '../../../redux/action_types';
import axios from 'axios';
import { AddCircleOutline, ArrowBack, Close } from '@material-ui/icons';
import { AddPart } from '../../Part';


const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}))(Button);
const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const uploadFile = useRef(null);
  const cropperRef = useRef(null);
  const [cropedImage, setCropedImage] = useState(null);
  const [name, setName] = useState('');
  const [row, setRow] = useState(2);
  const [side, setSide] = useState("");
  const [column, setColumn] = useState(7);
  const [stream, setStream] = useState(null);
  const [image, setImage] = useState(null);
  const [width, setWidth] = useState(644);
  const [height, setHeight] = useState(484);
  const imageRef = useRef(null);
  const [isCameraOn, setCameraOn] = useState(false);
  const [openCrop, setOpenCrop] = useState(false);
  const [imageType, setImageType] = useState('');
  const [cropSource, setCropSource] = useState(null);
  const [cropedSource, setCropedSource] = useState(null);
  const [crop, setCrop] = useState(null);
  const [gridsList, setGridsList] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const [partList, setPartList] = useState([]);
  const [selectedGridRow, setSelectedGridRow] = useState(null);
  const [selectedGridcolumn, setSelectedGridColumn] = useState(null);
  const [selectedCheckPoint, setSelectedCheckPoint] = useState(null);
  const [addedPart, setAddedPart] = useState(null);
  const [openCheckList, setOpenCheckList] = useState(false);
  const [openAddPart, setOpenAddPart] = useState(false);
  const { gridId } = useParams();
  const grid = useSelector((state) => state.grid);
  const auth = useSelector((state) => state.auth);
  const parts = useSelector((state) => state.parts);


  const webcamRef = useRef(null);
  useEffect(() => {
    if (gridId) {
      dispatch(getGrid(gridId));
    }
    dispatch(listParts({limit:0}));
  }, []);
  useEffect(() => {
    console.log(parts)
    if (parts.list?.data?.length) {
      dispatch(clearPartData(LIST_PART));
      setPartList(parts.list?.data);
    }
  }, [parts]);
  useEffect(() => {
    if (checkList.length) {
      let list = checkList;
      list[selectedCheckPoint].part = addedPart;
      setCheckList(processCheckList(list))
      setSelectedCheckPoint(null);
      setAddedPart(null);
    }
  }, [partList])
  useEffect(() => {
    if (grid.add?.data?._id) {
      dispatch(clearGridData(ADD_GRID));
      history.push(auth.data?.role?.name === BRM ? '/brm-grids' : '/grids');
    }
    if (grid.update?.data?._id) {
      dispatch(clearGridData(UPDATE_GRID));
      history.push(auth.data?.role?.name === BRM ? '/brm-grids' : '/grids');
    }
    if (grid.detail?.data?._id) {
      dispatch(clearGridData(GET_GRID));
      setName(grid.detail?.data.name);
      setGridsList(grid.detail?.data?.images || [])
    }
  }, [grid]);
  useEffect(() => {
    if (isCameraOn)
      startWebcam()
    else {
      stopWebcam()
    }
  }, [isCameraOn]);
  useEffect(() => {
    // console.log(image)
    if (image) {
      handleCrop('upload')
    }
  }, [image]);
  const getCroped = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropedSource(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      let img = new Image();
      img.onload = function () {
        console.log(img.naturalWidth, img.naturalHeight, this.width, this.height);
        setWidth(this.width);
        setHeight(this.height);
      }
      img.src = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
    }
  };

  useEffect(() => {
    // console.log(gridsList);
    if (cropedImage)
      handleUploadedFile()
  }, [cropedImage]);

  const handleCrop = async (type = 'camera') => {
    setImageType(type);
    if (type === 'camera') {
      var canvasMain = document.createElement('canvas');
      canvasMain.width = width;
      canvasMain.height = height;
      canvasMain.getContext('2d').drawImage(webcamRef.current, 0, 0, canvasMain.width, canvasMain.height);
      setCameraOn(false);
      // console.log(canvasMain.toDataURL())
      setCropSource(canvasMain.toDataURL());
    } else {
      setCropSource(image)
    }
    setOpenCrop(true);
  }
  const handleUploadedFile = async () => {
    console.log('HI')
    // Clear any previous grid content
    let obj = { devideList: [], row: row || 1, column: column || 1 };
    obj.imageBase64 = cropedImage;

    // Calculate the width and height of each grid cell
    const cellWidth = width / (column || 1);
    const cellHeight = height / (row || 1);
    let alphas = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let rows = 0; rows < row; rows++) {
      let ref = alphas[rows];
      for (let col = 0; col < column; col++) {
        const canvas = document.createElement('canvas');
        canvas.width = cellWidth;
        canvas.height = cellHeight;

        const context = canvas.getContext('2d');
        context.drawImage(
          imageRef.current,
          col * cellWidth,
          rows * cellHeight,
          cellWidth,
          cellHeight,
          0,
          0,
          cellWidth,
          cellHeight
        );
        // console.log(canvas.toDataURL())
        const label = document.createElement('Grid');
        // gridRef.current.appendChild(canvas);
        // setCameraOn(false);
        obj.devideList.push({ tag: ref + (col + 1), imageBase64: canvas.toDataURL(), checkList: [] })
      }
    }
    let list = [...gridsList]
    list.push({ ...obj, side: side });
    console.log(list);
    setGridsList(list)
    setSide('');
    setCropedImage(null)
    setWidth(644);
    setHeight(484)
  };

  const handleCaptureClick = async () => {
    console.log('HI')
    // if (gridRef.current) {
    //   // Clear any previous grid content
    //   gridRef.current.innerHTML = '';
    let obj = { devideList: [], row: row || 1, column: column || 1 };
    var canvasMain = document.createElement('canvas');
    canvasMain.width = width;
    canvasMain.height = height;
    canvasMain.getContext('2d').drawImage(webcamRef.current, 0, 0, canvasMain.width, canvasMain.height);
    obj.imageBase64 = (canvasMain.toDataURL());
    // console.log(webcamRef.current.toDataURL())
    // Calculate the width and height of each grid cell
    const cellWidth = width / (column || 1);
    const cellHeight = height / (row || 1);
    for (let rows = 0; rows < row; rows++) {
      for (let col = 0; col < column; col++) {
        const canvas = document.createElement('canvas');
        canvas.width = cellWidth;
        canvas.height = cellHeight;

        const context = canvas.getContext('2d');
        context.drawImage(
          webcamRef.current,
          col * cellWidth,
          rows * cellHeight,
          cellWidth,
          cellHeight,
          0,
          0,
          cellWidth,
          cellHeight
        );
        // console.log(canvas.toDataURL())
        const label = document.createElement('Grid');
        // gridRef.current.appendChild(canvas);
        obj.devideList.push({ canvas, image: canvas.toDataURL() })

      }
    }
    setCameraOn(false);
    let list = [...gridsList]
    list.push(obj);
    // console.log(list);
    setGridsList(list)

    // }
  };
  const startWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment'
        }
      });
      if (webcamRef.current) {
        webcamRef.current.srcObject = stream;
      }
      setStream(stream)

    } catch (error) {
      console.error('Error accessing webcam:', error);
    }
  }
  const stopWebcam = async () => {
    try {
      // now get all tracks
      let tracks = stream?.getTracks() || [];
      // now close each track by having forEach loop
      tracks.forEach(function (track) {
        // stopping every track
        track.stop();
      });
      setStream(null)
      setCameraOn(false)
    } catch (error) {
      console.error('Error accessing webcam:', error);
    }
  }
  const handleUploadClick = event => {
    // console.log(uploadFile.current)
    uploadFile.current.click()
  }
  const handleFile = event => {
    // console.log(event);
    let file = event.target.files[0];
    let img = new Image();
    img.onload = function () {
      console.log(img.naturalWidth, img.naturalHeight, this.width, this.height);
      // setWidth(this.width);
      // setHeight(this.height);
    }
    img.src = window.URL.createObjectURL(file);
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);

    // file.url = URL.createObjectURL(file)
    // setImage(file)
  }
  const handleCheckList = (itm, i, j) => {
    setSelectedGridRow(i);
    setSelectedGridColumn(j);
    setCheckList(processCheckList(itm.checkList || []));
    setOpenCheckList(true);
  }
  const setCheckListFields = (type, val, i, chList = []) => {
    let list = checkList.length ? checkList : chList;
    // console.log('type', type, val, i, list);
    if (list[i]) {
      list[i][type] = val;
      setCheckList(processCheckList(list));
    }
  };
  const processCheckList = (activities, row) => {
    return activities.map((a, i) => {
      a.errors = a.errors || {};
      a.checkPointView = (
        <Grid container style={{ justifyContent: 'center' }}>
          <TextField
            variant="filled"
            placeholder="Check Point"
            value={a.checkPoint}
            isError={a.errors?.checkPoint}
            onChange={(e) => {
              setCheckListFields("checkPoint", e.target.value, i, activities);
            }}
            fullWidth
          // multiline
          // minRows={10}
          // maxRows={10}
          />
        </Grid>
      );
      a.partView = (
        <Grid container style={{ justifyContent: 'center' }}>
          <AutoComplete
            variant="filled"
            label="Select Part"
            color="secondary"
            value={a.part}
            key={a.part}
            name="part"
            options={partList}
            isError={a.errors.part}
            // helperText=""
            onChange={(e, newValue) => {
              setCheckListFields("part", newValue, i, activities);
            }}
            // fullWidth
            style={{ width: '80%' }}
          />
          <IconButton color='primary' onClick={() => { setSelectedCheckPoint(i); setOpenAddPart(true) }} icon={<AddCircleOutline />} />
        </Grid>
      );
      return a;
    });
  };
  const createGrid = () => {
    let data = {};
    data.name = name;
    data.images = gridsList.map(a => {
      a.devideList = (a.devideList || []).map(aa => {
        aa.checkList = (aa.checkList || []).map(itm => {
          return {
            // checkPoint: itm?.checkPoint,
            part: itm?.part
          }
        })
        return aa;
      })
      return a;
    });
    // console.log(data);
    if (gridId) {
      dispatch(updateGrid(data, gridId));
    } else
      dispatch(addGrid(data));
  }
  return (
    <Grid container >
      <Grid>
        <h2>
          <IconButton
            color="primary"
            onClick={() => { history.push('/brm-grids') }}
            icon=<ArrowBack />
          ></IconButton>
          Grid Builder
        </h2>
      </Grid>
      <Grid item container spacing={2} style={{ justifyContent: 'center' }}>
        <Grid item container spacing={2}>
          <Grid item sm={4}>
            <TextField
              variant="filled"
              label="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value || 1);
              }}
              // isError={}
              fullWidth
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item sm={4}>
              <TextField
                variant="filled"
                label="Object Side"
                value={side}
                onChange={(e) => {
                  setSide(e.target.value || 1);
                }}
                // isError={}
                fullWidth
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                variant="filled"
                label="Row"
                value={row}
                type={'number'}
                onChange={(e) => {
                  setRow(e.target.value);
                }}
                // isError={}
                fullWidth
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                variant="filled"
                label="Column"
                value={column}
                type={'number'}
                onChange={(e) => {
                  setColumn(e.target.value);
                }}
                // isError={errors.vin}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ position: 'relative', border: '2px solid black', width: width, display: 'block', padding: 0, height: height }}>
          {isCameraOn && !image ? <video ref={webcamRef} autoPlay style={{ width: width, height: height }} ></video> : cropedImage ? <img ref={imageRef} src={cropedImage} style={{ maxWidth: width, maxHeight: height }} /> : null}
          <Grid className="overlay" style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}>
            {(row > 0 ? [...Array(row - 1).keys()] : []).map(a => (
              <Grid className={"grid-line horizontal-line ch" + (a + 1)} style={{ top: 'calc(' + ((a + 1) * (100 / row)) + '% - 1px)', backgroundColor: '#000', position: 'absolute', height: 2, width: '100%' }}></Grid>
            ))}
            {(column > 0 ? [...Array(column - 1).keys()] : []).map(a => (
              <Grid className={"grid-line vertical-line ch" + (a + 1)} style={{ position: 'absolute', height: '100%', width: 2, marginLeft: ((100 / column) * (a + 1)) + '%', backgroundColor: '#000' }}></Grid>
            ))}

          </Grid>
        </Grid>
        <Grid item sm={12} container style={{ justifyContent: 'center' }}>
          {isCameraOn ?
            <Button color="primary" text='Capture' variant="contained" onClick={() => handleCrop()} />
            :
            <>
              <input accept="image/png, image/gif, image/jpeg" onChange={handleFile} type="file" ref={uploadFile} style={{ display: 'none' }} />
              <Button color="primary" text='Upload' variant="contained" onClick={() => handleUploadClick()} disabled={!side || row <= 0 || column <= 0} />
              <Button color="primary" text='Start Camera' variant="contained" onClick={() => { setCameraOn(true); setImage(null) }} disabled={!side || row <= 0 || column <= 0} />
            </>
          }
        </Grid>

        <Grid container spacing={2} style={{ justifyContent: 'center', margin: '1rem 0' }}>
          <Grid item container style={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            fontWeight: "bold",
            fontSize: 12,
            overflowX: "hidden",
            textOverflow: "ellipsis",
            wordWrap: "break-word",
          }}>
            <Grid item sm={3} style={{ textAlign: 'center' }}>
              <Typography style={{ fontWeight: 'bold' }}>Object Side</Typography>
            </Grid>
            <Grid item sm={7}>
              <Typography style={{ fontWeight: 'bold' }}>Grids</Typography>
            </Grid>
            <Grid item sm={2} style={{ textAlign: 'center' }}>
              <Typography style={{ fontWeight: 'bold' }}>Action</Typography>
            </Grid>
          </Grid>
          {gridsList.map((a, i) => (
            <Grid item container>
              <Grid item sm={3} style={{ textAlign: 'center' }}>
                <Typography>{a.side}</Typography>
              </Grid>
              <Grid item sm={7}>
                <Grid item
                  // ref={gridRef}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${a.column}, 0fr)`,
                    gridTemplateRows: `repeat(${a.row}, 1fr)`,
                    gridGap: '5px',
                  }}
                >

                  {a.devideList.map((itm, j) => (
                    <Grid item style={{ position: 'relative', width: 100, height: 100 }}>
                      <img src={itm.imageBase64.indexOf('base64') != -1 ? itm.imageBase64 : axios.defaults.baseURL + '/file/' + itm.imageBase64} style={{ cursor: 'pointer', width: 100, height: 100 }} onClick={() => { handleCheckList(itm, i, j) }} />
                      <label style={{ position: 'absolute', left: '40%', top: '30%', fontWeight: 'bold', fontSize: 'xx-large', color: itm.checkList?.length ? 'green' : 'red', cursor: 'pointer' }} onClick={() => { handleCheckList(itm, i, j) }}>{itm.tag}</label>
                    </Grid>
                  ))}

                </Grid>

              </Grid>
              <Grid item sm={2} style={{ textAlign: 'center' }}>
                <DeleteButton
                  onClick={(e) => {
                    let list = [...gridsList];
                    delete list[i];
                    setGridsList(list.filter(a => a));
                  }}
                  text="Delete"
                  variant="contained"
                  style={{ marginBottom: ".5rem" }}
                />
              </Grid>
              <Divider style={{ margin: '25px 0', width: '100%' }} />
            </Grid>
          ))}
        </Grid>
        <Grid item sm={12} container style={{ justifyContent: 'center' }}>


          <Button color="primary" text='Submit' variant="contained" onClick={() => { createGrid() }} disabled={!name || !gridsList.length} />

        </Grid>
      </Grid>
      <Dialog
        open={openCrop}
        // onClose={() => setOpenCrop(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: 950 }}
        className="plan-esign"
        fullScreen
      >
        <DialogTitle id="alert-dialog-title">
          {"Crop"}
        </DialogTitle>
        <DialogContent style={{ padding: 0, overflow: "hidden" }}>
          <Grid container spacing={2} >
            <Grid index sm={6}>
              {/* <ReactCrop src={cropSource} crop={crop} onImageLoaded={setCropedImage} onChange={setCrop}>

              </ReactCrop> */}
              <Cropper
                ref={cropperRef}
                style={{ height: height, width: width }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={cropSource}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                guides={true}
              // on={() => {
              //   if (typeof cropperRef.current?.cropper !== "undefined") {
              //     setCropedSource(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
              //   }
              // }}
              />
            </Grid>
            <Grid index sm={6}>
              <img src={cropedSource} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              // console.log(crop)
              getCroped();
            }}
            text="Crop"
            variant="contained"
            color="primary"
          />
          <Button
            onClick={(e) => {
              setCropedImage(cropedSource);
              setCropSource(null)
              setOpenCrop(false)
            }}
            text="Submit"
            variant="contained"
            color="primary"
          />
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCheckList}
        // onClose={() => setOpenCheckList(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: 950 }}
        className="plan-esign"
        fullScreen
      >
        <DialogTitle id="alert-dialog-title">
          {"Check List"}
        </DialogTitle>
        <DialogContent style={{ padding: 0, overflow: "hidden", }}>
          {selectedGridRow !== null && selectedGridcolumn !== null ?
            <Grid item style={{ position: 'relative', width: 200, height: 200, margin: '0 auto' }}>
              <img src={gridsList[selectedGridRow].devideList[selectedGridcolumn].imageBase64.indexOf('base64') != -1 ? gridsList[selectedGridRow].devideList[selectedGridcolumn].imageBase64 : axios.defaults.baseURL + '/file/' + gridsList[selectedGridRow].devideList[selectedGridcolumn].imageBase64} style={{ cursor: 'pointer', width: 150, height: 150 }} />
              <label style={{ position: 'absolute', left: '40%', top: '30%', fontWeight: 'bold', fontSize: 'xx-large', color: gridsList[selectedGridRow].devideList[selectedGridcolumn]?.checkList?.length ? 'green' : 'red' }}>{gridsList[selectedGridRow].devideList[selectedGridcolumn].tag}</label>
            </Grid>
            : null}
          <Table
            header={TABLE_HEADER_CHECKLIST}
            data={checkList}
            onDelete={(i, e, row) => {
              let list = checkList;
              delete list[i];
              setCheckList(processCheckList(list));
            }}
            onEdit={(i, row) => {
              // console.log(row)
            }}
            editBtn={false}
            addRow={true}
            addRowText="Add More"
            addRowMarginRight={'1rem'}
            onAddRow={(e) => {
              let list = checkList;
              list.push({ errors: {} });
              setCheckList(processCheckList(list));
            }}
          />
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              onClick={(e) => {
                let err = false;
                let list = checkList.map(a => {
                  a.errors = {};
                  // if (!a.checkPoint) {
                  //   err = true;
                  //   a.errors.checkPoint = 'Check Point is required!';
                  // }
                  if (!a.part) {
                    err = true;
                    a.errors.part = 'Part is required!';
                  }
                  return a;
                });
                console.log('list-----', list)
                if (!err) {
                  let gList = gridsList;
                  gList[selectedGridRow].devideList[selectedGridcolumn].checkList = list;
                  setGridsList(gList);
                  setCheckList([]);
                  setOpenCheckList(false);
                } else {
                  console.log(list)
                  setCheckList(processCheckList(list))
                }
              }}
              text={"Save"}
              variant="contained"
              color="primary"
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAddPart}
        onClose={() => setOpenAddPart(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: 950 }}
        maxWidth="lg"
        className="plan-esign"
      // fullScreen
      >
        <DialogTitle id="alert-dialog-title">
          {"Add Part"}
          <IconButton aria-label="close" props={{ style: { float: 'right' } }} color="primary" onClick={() => setOpenAddPart(false)} icon={<Close />}>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: 10 }}>
          <AddPart breadcumb={false} onComplete={(e) => { setOpenAddPart(false); setAddedPart(e); dispatch(listParts()) }} />
        </DialogContent>
      </Dialog>
    </Grid >
  )
}

export default Index;
