import axios from "axios";
import { decrypt } from 'n-krypta'

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }
};

export const setCompanyHeader = (id) => {
  if (id) {
    let x = decrypt(id, 'secret');
    axios.defaults.headers.common["Company"] = `${x}`;
  }else{
    axios.defaults.headers.common["Company"] = ``;
  }
};

export default setAuthToken;