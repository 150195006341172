import {
  LIST_USER,
  GET_USER,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER
} from '../action_types';

const initialState = {
  list: {
    pagination: {},
    totalCount: 0,
    data: [],
    isLoading: true,
    hasError: false
  },
  detail: {
    data: {},
    isLoading: true,
    hasError: false
  },
  add: {
    data: {},
    isLoading: true,
    hasError: false
  },
  delete: {
    data: {},
    isLoading: true,
    hasError: false
  }
}

const user = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LIST_USER:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload
        }
      };
    case GET_USER:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload
        }
      };
    case ADD_USER:
      return {
        ...state,
        add: {
          ...state.add,
          isLoading: false,
          ...payload
        }
      }
    case UPDATE_USER:
      return {
        ...state,
        update: {
          ...state.update,
          isLoading: false,
          ...payload
        }
      }
    case DELETE_USER:
      return {
        ...state,
        delete: {
          ...state.delete,
          isLoading: false,
          ...payload
        }
      }
    default: return state;
  }
}

export default user;