import axios from "axios";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function Index({ data = {}, workPlan = {} }) {

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h1>Project : iTechQ</h1>
      </div>
      <div style={{}}>
        <div style={{ marginTop: "1rem", display: "flex", width: "100%" }}>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "50%" }}>
              <b>PDSL Project No</b>
            </div>
            <div style={{ width: "23%" }}>{data?.projectNo}</div>
          </div>
          <div style={{ width: "15%" }}>
            <b>Rev O</b>
          </div>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "60%" }}>
              <b>SAP/Customer Ref</b>
            </div>
            <div>{data?.sapNo}</div>
          </div>
        </div>

        <div
          style={{
            // width: "80%",
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <b>Photo/Illustration</b>
        </div>

        <div>
          <b>Product Identification</b>
        </div>

        <div
          style={{
            // width: "80%",
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <img
            src={axios.defaults.baseURL + "/file/" + workPlan.part.image}
            style={{ width: "5rem", height: "5rem" }}
          />
        </div>

        <div style={{ marginTop: "1rem", display: "flex" }}>
          <div style={{ width: "50%", textAlign: "center" }}>
            <button
              style={{
                width: "10rem",
                padding: " .5rem",
                backgroundColor: "red",
              }}
            >
              NOK
            </button>
          </div>
          <div style={{ width: "50%", textAlign: "center" }}>
            <button
              style={{
                width: "10rem",
                padding: ".5rem",
                backgroundColor: "green",
              }}
            >
              OK
            </button>
          </div>
        </div>

        <div style={{ marginTop: "2rem" }}>
          <div>
            {workPlan?.part?.illustration?.map((itm) => {
              return (
                <div style={{ display: "flex", marginBottom: ".5rem" }}>
                  <div style={{ width: "50%", textAlign: "center" }}>
                    <img
                      src={axios.defaults.baseURL + "/file/" + itm.notOkImage}
                      style={{ width: "5rem", height: "5rem" }}
                    />
                  </div>
                  <div style={{ width: "50%", textAlign: "center" }}>
                    <img
                      src={axios.defaults.baseURL + "/file/" + itm.okImage}
                      style={{ width: "5rem", height: "5rem" }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div style={{ marginTop: "1rem" }}>
          <b>Special Care Points</b>
        </div>

        <div
          style={{
            marginTop: "1rem",
            border: "1px solid black",
            padding: "1rem",
            width: "100%",
            display: "flex",
            justifyContent: " center",
          }}
        >
          {workPlan?.specialCareDescription}
        </div>

        <div style={{ marginTop: "1rem", display: "flex" }}>
          <div style={{ display: "flex", width: "33%" }}>
            <div style={{ width: "45%" }}>
              <b>Project Configuration By</b>
            </div>
            <div>
              {workPlan?.project?.auditBy?.firstName +
                " " +
                workPlan?.project?.auditBy?.lastName}
            </div>
          </div>
          <div style={{ display: "flex", width: "33%" }}>
            <div style={{ width: "20%" }}>
              <b>Date</b>
            </div>
            <div>{data?.date}</div>
          </div>
          <div style={{ display: "flex", width: "33%" }}>
            <div style={{ width: "50%" }}>
              <b>E-Signature</b>
            </div>
            <div>{data?.signature}</div>
          </div>
        </div>

        <div style={{ marginTop: "1rem", display: "flex" }}>
          <div style={{ display: "flex", width: "33%" }}>
            <div style={{ width: "45%" }}>
              <b>Customer Approval</b>
            </div>
            <div>
              {workPlan?.project?.customer?.firstName +
                " " +
                workPlan?.project?.customer?.lastName}
            </div>
          </div>
          <div style={{ display: "flex", width: "33%" }}>
            <div style={{ width: "20%" }}>
              <b>Date</b>
            </div>
            <div>{data?.date}</div>
          </div>
          <div style={{ display: "flex", width: "33%" }}>
            <div style={{ width: "50%" }}>
              <b>E-Signature</b>
            </div>
            <div>{data.signature}</div>
          </div>
        </div>

        <div style={{ marginTop: "1rem" }}>
          <b>
            Project Training-(Training Completed & Associate Understands
            Requirements in Full)
          </b>
        </div>

        <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "20%" }}>
            <b>Associate Name/ID</b>
          </div>
          <div style={{ width: "20%" }}>
            <b>E-Signature</b>
          </div>
          <div style={{ width: "20%" }}>
            <b>Date</b>
          </div>
          <div style={{ width: "20%" }}>
            <b>Trained By</b>
          </div>
          <div style={{ width: "20%" }}>
            <b>E-Signature</b>
          </div>
        </div>

        {workPlan?.userId.map((itm) => {
          return (
            <>
              <div style={{ display: "flex", marginTop: "1rem" }}>
                <div style={{ width: "20%" }}>{itm?.firstName+' '+itm?.lastName}</div>
                <div style={{ width: "20%" }}>
                  {/* <img
                    src="https://tse1.mm.bing.net/th?id=OIP.k4lC6_WStP_TZ-Kap0EkZgHaCz&pid=Api&P=0&h=180"
                    style={{ width: "4rem", height: "1.5rem" }}
                  /> */}
                </div>
                <div style={{ width: "20%" }}>
                  <span>{data?.createdAt.split('T')[0]}</span>
                </div>
                <div style={{ width: "20%" }}>
                  <b>Leader</b>
                </div>
                <div style={{ width: "20%" }}>
                  {/* <b>
                    <img
                      src="https://tse1.mm.bing.net/th?id=OIP.k4lC6_WStP_TZ-Kap0EkZgHaCz&pid=Api&P=0&h=180"
                      style={{ width: "4rem", height: "1.5rem" }}
                    />
                  </b> */}
                </div>
              </div>
            </>
          );
        })}

        {/* <div style={{ display: "flex", marginTop: "1rem" }}>
          <div style={{ width: "20%" }}>Another Q Inspector</div>
          <div style={{ width: "20%" }}>
            <img
              src="https://tse1.mm.bing.net/th?id=OIP.k4lC6_WStP_TZ-Kap0EkZgHaCz&pid=Api&P=0&h=180"
              style={{ width: "4rem", height: "1.5rem" }}
            />
          </div>
          <div style={{ width: "20%" }}>
            <b>{data?.date}</b>
          </div>
          <div style={{ width: "20%" }}>
            <b>Leader</b>
          </div>
          <div style={{ width: "20%" }}>
            <b>
              <img
                src="https://tse1.mm.bing.net/th?id=OIP.k4lC6_WStP_TZ-Kap0EkZgHaCz&pid=Api&P=0&h=180"
                style={{ width: "4rem", height: "1.5rem" }}
              />
            </b>
          </div>
        </div> */}
      </div>
    </div>
  );
}
