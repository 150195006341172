const TABLE_HEADER = {
  contactNameView: {
    data: "Contact Name",
    width: "150px",
  },
  contactNumberView: {
    data: "Contact Number",
    width: "150px",
  },
};

export { TABLE_HEADER };
