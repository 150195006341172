import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AutoComplete, Button } from "../../../commons/mui";

const useStyles = makeStyles((theme) => ({
  divider: {
    // borderRight: "1px solid #838584",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  },
  align: {
    textAlign: "center",
  },
  space: {
    marginBottom: ".5rem",
  },
  selected: {
    cursor: "pointer",
    border: ".2rem solid green",
  },
  notSelected: {
    cursor: "pointer",
  },
}));

export default function Index() {
  const classes = useStyles();
  const [list, setlist] = useState([
    { selected: "" },
    { selected: "" },
    { selected: "" },
  ]);

  return (
    <Grid container>
      <Grid item sm={12} className={`${classes.align} ${classes.divider}`}>
        <img
          src="https://www.viewtech.com/wp-content/uploads/glossary_gear_box.jpg"
          width={120}
          height={120}
        />
        <Grid container>
          <Grid item sm={6}>
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "1.5rem" }}
            >
              Not Ok Image (Example)
            </span>
            <Grid container spacing={2}>
              {list.map((itm, i) => (
                <Grid item sm={12}>
                  <img
                    src="https://www.viewtech.com/wp-content/uploads/glossary_gear_box.jpg"
                    width={100}
                    height={100}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item sm={6}>
            <span
              style={{ color: "green", fontWeight: "bold", fontSize: "1.5rem" }}
            >
              Ok Image (Example)
            </span>
            <Grid container spacing={2}>
              {list.map((itm, i) => (
                <Grid item sm={12}>
                  <img
                    src="https://www.viewtech.com/wp-content/uploads/glossary_gear_box.jpg"
                    width={100}
                    height={100}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Button
            style={{ marginTop: "20px", marginBottom: "10px" }}
            width="150px"
            text="Submit"
            variant="contained"
            color="primary"
            onClick={() => {
            //   history.push("/workplan/illustration");
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
