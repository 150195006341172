import {
  Grid,
} from "@material-ui/core";
import React, {  useState } from "react";
import { Button, TextField } from "../../../commons/mui";
import { QUESTION_TYPE } from "./CONSTANTS";
import { useDispatch } from "react-redux";

import {
  addAssessmentQuestion,
} from "../../../redux/actions/assessmentQuestion";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


export default function Index() {
  const [field, setField] = useState({
    question: "",
    type: "",
    reference: "",
    controlMeasure: "",
  });
  const [editBtn, setEditBtn] = useState(false);
  const [errors, seterrors] = useState({});
  const dispatch = useDispatch();
  const [options, setoptions] = useState(QUESTION_TYPE);
  const history = useHistory();

  const setFields = (type, val) => {
    Object.keys(field).map((itm) => {
      if (itm == type) {
        setField({ ...field, [itm]: val });
      }
    });
  };

  // useEffect(() => {
  //   dispatch(listAssessmentQuestions());
  // }, []);
  const validate = () => {
    let temp = {};
    let error = false;
    if (!field.question) {
      error = true;
      temp.question = "Question is required";
    }
    if (!field.reference) {
      error = true;
      temp.reference = "Reference is required";
    }
    if (!field.type) {
      error = true;
      temp.type = "Type is required";
    }
    
    seterrors(temp);

    return !error;
  };

  const onSubmit = () => {
    if (validate()) {
      dispatch(addAssessmentQuestion(field));
              setField({
                question: "",
                type: "",
                reference: "",
                controlMeasure: "",
              });
              history.push("/assessment");
    }
  };

  return (
    <Grid>
      <Grid>
        <Grid>
          <h2>Add/Edit Question</h2>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={12} md={4}>
            <TextField
              variant="filled"
              label="Question"
              value={field.question}
              isError={errors.question}
              onChange={(e) => {
                setFields("question", e.target.value);
              }}
              multiline={true}
              minRows={2}
              maxRows={5}
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <TextField
              label="Type"
              variant="filled"
              color="secondary"
              value={field.type}
              select={true}
              options={options}
              fullWidth
              isError={errors.type}
              onChange={(e) => {
                setField({ ...field, type: e.target.value });
              }}
            />
          </Grid>
          <Grid item  sm={12} md={4}>
            <TextField
              variant="filled"
              label="Reference"
              value={field.reference}
              isError={errors.reference}
              onChange={(e) => {
                setFields("reference", e.target.value);
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid style={{ textAlign: "center" }}>
          <Button
            style={{ marginTop: "1.7rem" }}
            width="150px"
            text="Add"
            variant="contained"
            color="primary"
            onClick={() => {
             onSubmit();
            }}
          />
          {!editBtn ? null : (
            <Button
              style={{ marginTop: "1.7rem" }}
              width="150px"
              text="Edit"
              variant="contained"
              color="primary"
              onClick={() => {
                setEditBtn(false);
                // dispatch(updateRole(field, id));
                setField({
                  question: "",
                  type: "",
                  reference: "",
                  controlMeasure: "",
                });
                // partList[index] = field;
                // setpartList([...partList]);
              }}
            />
          )}
        </Grid>
      </Grid>
      {/* <Grid style={{ marginTop: "1rem" }}>
        <Table
          header={TABLE_HEADER}
          data={partList}
          onDelete={(i, e, row) => {
            // let arr = [];
            // partList.map((itm, index) => {
            //   if (index !== i) {
            //     arr.push(itm);
            //   }
            // });
            // setpartList(arr);
            // dispatch(deleteRole(row._id));
            // setTimeout(() => {
            //   dispatch(listRoles());
            // }, 500);
          }}
          onEdit={(i, row) => {
            setEditBtn(true);
            setIndex(i);
            setid(row._id);
            // setField({
            //   name: row?.name,
            //   description: row?.description,
            // });
          }}
        />
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        <div className="ui-component-block">
          <div className="ui-components">
            <Pagination count={totalCount} page={page} setPage={setPage} />
          </div>
        </div>
      </Grid> */}
    </Grid>
  );
}
