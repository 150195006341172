import { Grid, Link, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Pagination, Table } from "../../../commons/mui";
import { TABLE_HEADER } from "./CONSTANTS";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { deleteRole, listRoles } from "../../../redux/actions/role";
import { useDispatch, useSelector } from "react-redux";
import { listProjects, updateProject } from "../../../redux/actions/project";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: 290,
    },
  },
  input: {
    marginTop: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "1rem",
    },
  },
}));

export default function Index() {
  const classes = useStyles();
  const [id, setid] = useState(null);
  const [editBtn, setEditBtn] = useState(false);
  const [index, setIndex] = useState(null);
  const history = useHistory();
  const [data, setdata] = useState([]);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const project = useSelector((state) => state.project);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (auth.data._id) {
      dispatch(
        listProjects({
          assessmentAssignTo: auth.data._id,
          skip: (page - 1) * 10,
        })
      );
    }
  }, [auth, page]);

  useEffect(() => {
    if (project.list.data) {
      let temp = project.list.data.map((a) => {
        a.customer = a.customer
          ? (a.customer.firstName || "") +
          (a.customer.lastName ? " " + a.customer.lastName : "")
          : "";
        a.projectNoView = (
          <Grid>
            <Link onClick={() => {
              history.push(`/assessee-projects/${a._id}`);
            }} style={{ cursor: 'pointer' }}>{a.projectNo}</Link>
          </Grid>
        );
        a.action = (
          <Button
            onClick={(e) => {
              if (a.status === 'Awaiting to Start Assessment') {
                dispatch(updateProject({ status: 'Awaiting to Complete Assessment' }, a._id))
              }
  
              history.push(`/action/${a._id}`);
            }}
            text={a.status === 'Awaiting to Start Assessment' || a.status === 'Awaiting to Start Audit' ? 'Start' : "Detail"}
            variant="contained"
            color="primary"
            style={{ marginBottom: ".5rem" }}
          />
        );
        return a;
      });
      setdata(temp);
    }
  }, [project]);

  return (
    <div>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "2.5rem",
          alignItems: "center",
        }}
      >
        <h2>Project List</h2>
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        <Table
          header={TABLE_HEADER}
          data={data}
          startBtn={false}
          actions={false}
          onStart={(e, i, row) => {
            if (row.status === 'Awaiting to Start Assessment') {
              dispatch(updateProject({ status: 'Awaiting to Complete Assessment' }, row._id))
            }

            history.push(`/action/${row._id}`);
          }}
        />
      </Grid>
      <Grid style={{ marginTop: "1rem" }}>
        <div className="ui-component-block">
          <div className="ui-components">
            <Pagination
              count={project.list.totalCount}
              page={page}
              setPage={setPage}
            />
          </div>
        </div>
      </Grid>
    </div>
  );
}
