import React, { useState } from 'react';
import PrivateRoute from '../commons/interceptors/hoc/PrivateRoute';


import { AddEditGrid, ListGrid } from '../components/Grid/'
import { AddPart, AddProjectPart, ListPart, EditPart } from '../components/Part/'
import { AddJobCard, ListJobCard, EditJobcard } from '../components/JobCard';

import { AllProjects, CreateProject, ProjectDetails, DataCollection, ConfirmationTemplate, IllustrationTemplate, ProcessAudit } from '../components/Projects';
import { AddLocation, ListLocation } from '../components/Location';
import { AddCompany, AddCompanyRole, AddCompanyUser, ListCompany, ListCompanyProject, ListCompanyRole, ListCompanyUser } from '../components/Company';
import { AddRoles, EditRoles, ListRoles } from '../components/Roles';
import { AddUser, ListUser, EditUser } from '../components/Users';
import { AddAssessmentTemplate, AddEditAssessmentQuestion, ListAssessmentQuestion, ListAssessmentTemplate, } from '../components/AssessmentQuestion';
import { ActionScreen, ProjectList } from '../components/Associate';
import { Detail as AuditDetail, AuditList } from '../components/Auditor';
import { BRM, ASSOCIATE, AUDITOR, PDSLADMIN, CUSTOMER, TECHNICIAN } from '../components/Projects/AllProjects/CONSTANTS';
import { CustomerProjectList } from '../components/Customer';
import { AddWorkPlan, Illustration, PlanList } from '../components/WorkPlan';
import { GrigTechIllustration, TechIllustration, TechnicianReviewPlan } from '../components/Technician';
import { ActivityReport, PlanReport, ProjectReport, TechnicianReport, VehicleReport } from '../components/Dashboard';
import { AddAccident, ListAccident } from '../components/Accident';
// import StaticReportTable from '../components/Dashboard/StaticReportTable';
import { AddVehicle, ListVehicle } from '../components/Vehicle';
import { AddTicket, ListTickets, ViewTicket } from '../components/Ticket';

const Routes = () => {
  const [admin] = useState(BRM);
  const [pdslManager] = useState(PDSLADMIN)
  const [associate] = useState(ASSOCIATE)
  const [auditor] = useState(AUDITOR)
  const [customer] = useState(CUSTOMER)
  const [technician] = useState(TECHNICIAN)


  return (
    <>

      {/* BRM ROUTES */}
      <PrivateRoute exact path='/jobcard/create' component={AddJobCard} accessLevel={admin} />
      <PrivateRoute exact path='/jobcard/edit/:id' component={EditJobcard} accessLevel={admin} />
      <PrivateRoute exact path='/jobcard' component={ListJobCard} accessLevel={admin} />

      <PrivateRoute exact path='/assessment/create' component={AddEditAssessmentQuestion} accessLevel={admin} />
      <PrivateRoute exact path='/assessment' component={ListAssessmentQuestion} accessLevel={admin} />
      <PrivateRoute exact path='/edit/assessment/:id' component={AddEditAssessmentQuestion} accessLevel={admin} />

      <PrivateRoute exact path='/part/create' component={AddPart} accessLevel={admin} />
      <PrivateRoute exact path='/part/list' component={ListPart} accessLevel={admin} />
      <PrivateRoute exact path='/edit/part/:id' component={EditPart} accessLevel={admin} />

      <PrivateRoute exact path='/add/location' component={AddLocation} accessLevel={admin} />
      <PrivateRoute exact path='/location' component={ListLocation} accessLevel={admin} />
      <PrivateRoute exact path='/edit/location/:locationId' component={AddLocation} accessLevel={admin} />
      
      <PrivateRoute exact path='/add/accident' component={AddAccident} accessLevel={admin} />
      <PrivateRoute exact path='/accident' component={ListAccident} accessLevel={admin} />
      <PrivateRoute exact path='/edit/accident/:accidentId' component={AddAccident} accessLevel={admin} />

      <PrivateRoute exact path='/company-list' component={ListCompany} accessLevel={admin} />
      <PrivateRoute exact path='/brm-company-user-list/:companyId' component={ListCompanyUser} accessLevel={admin} />
      <PrivateRoute exact path='/brm-company-role-list/:companyId' component={ListCompanyRole} accessLevel={admin} />
      <PrivateRoute exact path='/brm-company-add-role' component={AddCompanyRole} accessLevel={admin} />
      <PrivateRoute exact path='/brm-company-edit-role/:roleId' component={AddCompanyRole} accessLevel={admin} />
      <PrivateRoute exact path='/brm-company-add-user' component={AddCompanyUser} accessLevel={admin} />
      <PrivateRoute exact path='/brm-company-edit-user/:userId' component={AddCompanyUser} accessLevel={admin} />
      <PrivateRoute exact path='/brm-company-project-list/:companyId' component={ListCompanyProject} accessLevel={admin} />
      <PrivateRoute exact path='/brm-create-project' component={CreateProject} accessLevel={admin} />
      <PrivateRoute exact path='/brm-edit-project/:projectId' component={CreateProject} accessLevel={admin} />
      <PrivateRoute exact path='/project/:projectId' component={ProjectDetails} accessLevel={admin} />
      <PrivateRoute exact path='/user' component={ListUser} accessLevel={admin} />
      <PrivateRoute exact path='/add/user' component={AddUser} accessLevel={admin} />
      <PrivateRoute exact path='/edit/user/:id' component={EditUser} accessLevel={admin} />
      <PrivateRoute exact path='/add/assessment-template' component={AddAssessmentTemplate} accessLevel={admin} />
      <PrivateRoute exact path='/edit/assessment-template/:templateId' component={AddAssessmentTemplate} accessLevel={admin} />
      <PrivateRoute exact path='/assessment-template' component={ListAssessmentTemplate} accessLevel={admin} />
      <PrivateRoute exact path='/brm-admin-audit/:projectId' component={AuditDetail} accessLevel={admin} />
      <PrivateRoute exact path='/brm-admin-action/:projectId' component={ActionScreen} accessLevel={admin} />

      <PrivateRoute exact path='/projects' component={AllProjects} accessLevel={admin} />
      <PrivateRoute exact path='/recover-projects' component={AllProjects} accessLevel={admin} />
      <PrivateRoute exact path='/create/project' component={CreateProject} accessLevel={admin} />
      <PrivateRoute exact path='/clone/project/:projectId' component={CreateProject} accessLevel={admin} />
      <PrivateRoute exact path='/edit/project/:projectId' component={CreateProject} accessLevel={admin} />
      <PrivateRoute exact path='/add/part' component={AddProjectPart} accessLevel={admin} />
      <PrivateRoute exact path='/project/illustrations' component={IllustrationTemplate} accessLevel={admin} />
      <PrivateRoute exact path='/project/confirmation' component={ConfirmationTemplate} accessLevel={admin} />
      <PrivateRoute exact path='/project/datacollection' component={DataCollection} accessLevel={admin} />
      <PrivateRoute exact path='/projects/processaudit' component={ProcessAudit} accessLevel={admin} />

      <PrivateRoute exact path='/roles' component={ListRoles} accessLevel={admin} />
      <PrivateRoute exact path='/add/roles' component={AddRoles} accessLevel={admin} />
      <PrivateRoute exact path='/edit/roles/:id' component={EditRoles} accessLevel={admin} />

      <PrivateRoute exact path='/tickets' component={ListTickets} accessLevel={admin} />
      <PrivateRoute exact path='/add/tickets' component={AddTicket} accessLevel={admin} />
      <PrivateRoute exact path='/view/tickets/:ticketId' component={ViewTicket} accessLevel={admin} />

      <PrivateRoute exact path='/brm-add/workplan/:projectId' component={AddWorkPlan} accessLevel={admin} />
      <PrivateRoute exact path='/brm-add/edit-workplan/:planId' component={AddWorkPlan} accessLevel={admin} />
      <PrivateRoute exact path='/brm-workplan/illustration' component={Illustration} accessLevel={admin} />
      <PrivateRoute exact path='/brm-workplan-list/:projectId' component={PlanList} accessLevel={admin} />
      <PrivateRoute exact path='/brm-projection' component={ActivityReport} accessLevel={admin} />
      <PrivateRoute exact path='/brm-projection/:type' component={ProjectReport} accessLevel={admin} />
      <PrivateRoute exact path='/brm-plan-report/:projectId' component={PlanReport} accessLevel={admin} />
      <PrivateRoute exact path='/brm-inspector-report-by-project/:projectId' component={TechnicianReport} accessLevel={admin} />
      <PrivateRoute exact path='/brm-inspector-report-by-plan/:planId' component={TechnicianReport} accessLevel={admin} />
      <PrivateRoute exact path='/brm-vehicle-report-by-plan/:planId' component={VehicleReport} accessLevel={admin} />
      <PrivateRoute exact path='/brm-grid/create' component={AddEditGrid} accessLevel={admin} />
      <PrivateRoute exact path='/brm-grid/edit/:gridId' component={AddEditGrid} accessLevel={admin} />
      <PrivateRoute exact path='/brm-grids' component={ListGrid} accessLevel={admin} />

      <PrivateRoute exact path='/vehicle/create' component={AddVehicle} accessLevel={admin} />
      <PrivateRoute exact path='/vehicle/edit/:id' component={AddVehicle} accessLevel={admin} />
      <PrivateRoute exact path='/vehicle' component={ListVehicle} accessLevel={admin} />
     

      {/* SUPER ADMIN Routes */}
      <PrivateRoute exact path='/super-roles' component={ListRoles} accessLevel={pdslManager} />
      <PrivateRoute exact path='/add/super-roles' component={AddRoles} accessLevel={pdslManager} />
      <PrivateRoute exact path='/edit/super-roles/:id' component={EditRoles} accessLevel={pdslManager} />
      
      <PrivateRoute exact path='/super-tickets' component={ListTickets} accessLevel={pdslManager} />
      <PrivateRoute exact path='/add/super-tickets' component={AddTicket} accessLevel={pdslManager} />
      <PrivateRoute exact path='/view/super-tickets/:ticketId' component={ViewTicket} accessLevel={pdslManager} />
      
      <PrivateRoute exact path='/super-user' component={ListUser} accessLevel={pdslManager} />
      <PrivateRoute exact path='/add/super-user' component={AddUser} accessLevel={pdslManager} />
      <PrivateRoute exact path='/edit/super-user/:id' component={EditUser} accessLevel={pdslManager} />
      <PrivateRoute exact path='/add/company' component={AddCompany} accessLevel={pdslManager} />
      <PrivateRoute exact path='/edit/company/:companyId' component={AddCompany} accessLevel={pdslManager} />
      <PrivateRoute exact path='/company' component={ListCompany} accessLevel={pdslManager} />
      <PrivateRoute exact path='/company-user-list/:companyId' component={ListCompanyUser} accessLevel={pdslManager} />
      <PrivateRoute exact path='/company-role-list/:companyId' component={ListCompanyRole} accessLevel={pdslManager} />
      <PrivateRoute exact path='/company-add-role' component={AddCompanyRole} accessLevel={pdslManager} />
      <PrivateRoute exact path='/company-edit-role/:roleId' component={AddCompanyRole} accessLevel={pdslManager} />
      <PrivateRoute exact path='/company-add-user' component={AddCompanyUser} accessLevel={pdslManager} />
      <PrivateRoute exact path='/company-edit-user/:userId' component={AddCompanyUser} accessLevel={pdslManager} />
      <PrivateRoute exact path='/company-project-list/:companyId' component={ListCompanyProject} accessLevel={pdslManager} />
      <PrivateRoute exact path='/create-project' component={CreateProject} accessLevel={pdslManager} />
      <PrivateRoute exact path='/edit-project/:projectId' component={CreateProject} accessLevel={pdslManager} />
      <PrivateRoute exact path='/clone-project/:projectId' component={CreateProject} accessLevel={pdslManager} />
      <PrivateRoute exact path='/grid/create' component={AddEditGrid} accessLevel={pdslManager} />
      <PrivateRoute exact path='/grid/edit/:gridId' component={AddEditGrid} accessLevel={pdslManager} />
      <PrivateRoute exact path='/grids' component={ListGrid} accessLevel={pdslManager} />
      <PrivateRoute exact path='/project-detail/:projectId' component={ProjectDetails} accessLevel={pdslManager} />
      <PrivateRoute exact path='/admin-audit/:projectId' component={AuditDetail} accessLevel={pdslManager} />
      <PrivateRoute exact path='/admin-action/:projectId' component={ActionScreen} accessLevel={pdslManager} />
      <PrivateRoute exact path='/add/workplan/:projectId' component={AddWorkPlan} accessLevel={pdslManager} />
      <PrivateRoute exact path='/workplan/illustration' component={Illustration} accessLevel={pdslManager} />
      <PrivateRoute exact path='/workplan-list/:projectId' component={PlanList} accessLevel={pdslManager} />


      {/* Associate Routes */}
      <PrivateRoute exact path='/associate' component={ProjectList} accessLevel={associate} />
      <PrivateRoute exact path='/action/:projectId' component={ActionScreen} accessLevel={associate} />
      <PrivateRoute exact path='/assessee-projects/:projectId' component={ProjectDetails} accessLevel={associate} />

      {/* <PrivateRoute exact path='/action' component={ActionScreen} accessLevel={associate} /> */}
      {/* Auditor Routes */}
      <PrivateRoute exact path='/audit/:projectId' component={AuditDetail} accessLevel={auditor} />
      <PrivateRoute exact path='/auditor' component={AuditList} accessLevel={auditor} />

      {/* ENGINEER ROUTES */}
      <PrivateRoute exact path='/inspector-workplan-list' component={PlanList} accessLevel={technician} />
      <PrivateRoute exact path='/inspector-review-work-plan/:planId' component={TechnicianReviewPlan} accessLevel={technician} />
      <PrivateRoute exact path='/inspector-work-plan/:planId' component={TechIllustration} accessLevel={technician} />
      <PrivateRoute exact path='/inspect-grid-work-plan/:planId' component={GrigTechIllustration} accessLevel={technician} />
      
      <PrivateRoute exact path='/tickets' component={ListTickets} accessLevel={technician} />
      <PrivateRoute exact path='/add/tickets' component={AddTicket} accessLevel={technician} />
      <PrivateRoute exact path='/view/tickets/:ticketId' component={ViewTicket} accessLevel={technician} />

      {/* CUSTOMER Routes */}
      <PrivateRoute exact path='/customer-projects' component={CustomerProjectList} accessLevel={customer} />
      <PrivateRoute exact path='/customer-projects/:projectId' component={ProjectDetails} accessLevel={customer} />

      <PrivateRoute exact path='/customer-projection' component={ActivityReport} accessLevel={customer} />
      <PrivateRoute exact path='/customer-projection/:type' component={ProjectReport} accessLevel={customer} />
      <PrivateRoute exact path='/customer-plan-report/:projectId' component={PlanReport} accessLevel={customer} />
      <PrivateRoute exact path='/customer-inspector-report-by-project/:projectId' component={TechnicianReport} accessLevel={customer} />
      <PrivateRoute exact path='/customer-inspector-report-by-plan/:planId' component={TechnicianReport} accessLevel={customer} />
      <PrivateRoute exact path='/customer-vehicle-report-by-plan/:planId' component={VehicleReport} accessLevel={customer} />

    </>
  )
}

export default Routes;
