const workType = [
    {
        label: 'Inspection',
        value: 'Inspection'
    },
    {
        label: 'Rework',
        value: 'Rework'
    },
    {
        label: 'Retrofit',
        value: 'Retrofit'
    },
    {
        label: 'Audit',
        value: 'Audit'
    },
    {
        label: 'Lab/CMM',
        value: 'Audit'
    },
    {
        label: 'Engineer',
        value: 'Engineer'
    },
    {
        label: 'CS 1/2/3/4',
        value: 'CS 1/2/3/4'
    },
    {
        label: 'Other',
        value: 'Other'
    },
];

const commodityList = [
    {
        label: 'BIW',
        value: 'BIW'
    },
    {
        label: 'CHA',
        value: 'CHA'
    },
    {
        label: 'ELEC',
        value: 'ELEC'
    },
    {
        label: 'EXT',
        value: 'EXT'
    },
    {
        label: 'INT',
        value: 'INT'
    },
    {
        label: 'POW',
        value: 'POW'
    },
    {
        label: 'STB',
        value: 'STB'
    },
    {
        label: 'SOF',
        value: 'SOF'
    }
]

export { workType, commodityList };
