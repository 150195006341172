import { 
  LIST_CARS, GET_CAR_HISTORY
} from '../action_types';

const initialState = {
  list: {
      pagination: {},
      totalCount: 0,
    data: [],
    isLoading: true,
    hasError: false
  },
  history: {
    data: [],
    isLoading: true,
    hasError: false,
  }
}

const index = (state=initialState, action)  => {
  const { type, payload } = action;

  switch(type) {
    case LIST_CARS: 
      return {
        ...state,
        list: {
          ...state.list,
          ...payload
        }
      };
    case GET_CAR_HISTORY: 
      return {
        ...state,
        history: {
          ...state.history,
          ...payload
        }
      };
    default: return state;
  }
}

export default index;