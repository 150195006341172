const TABLE_HEADER = {
  jobcardId: {
    data: 'Jobcard Id',
    width: '150px'
  },
  workshop: {
    data: 'Workshop',
    // width: '240px'
  },
  startDate: {
    data: 'Start Date',
    // width: '20px',
  },
  jobType: {
    data: 'Job Type',
    // width: '150px',
  },
  vin: {
    data: 'VIN',
  },
  status: {
    data: 'Status',
  },
  endDate: {
    data:'End Date'
  },
}

export {
  TABLE_HEADER,
}