import { 
  LIST_VEHICLE,
  GET_VEHICLE,
  ADD_VEHICLE,
  UPDATE_VEHICLE,
  DELETE_VEHICLE
} from '../action_types';
import axios from 'axios';
// import { QS } from '../../utils/urlHelper';

export const listVehicles = (params) => async dispatch => {
  // let queryString = QS(params)
  try {
    let res = await axios({
      method: 'GET',
      url: `/vehicle`,
      params
    });
    dispatch({
      type: LIST_VEHICLE,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: LIST_VEHICLE,
      payload: {
        data: [],
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const getVehicle = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'GET',
      url: `/vehicle/${id}`,
    });
    dispatch({
      type: GET_VEHICLE,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        isLoading: false,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: GET_VEHICLE,
      payload: {
        data: {},
        isLoading: false,
        hasError: true,
      }
    });
  }
}

export const addVehicle = (data) => async dispatch => {
  try {
    let res = await axios({
      method: 'POST',
      url: `/vehicle`,
      data
    });
    dispatch({
      type: ADD_VEHICLE,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: ADD_VEHICLE,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const updateVehicle = (data, id) => async dispatch => {
  try {
    let res = await axios({
      method: 'PUT',
      url: `/vehicle/${id}`,
      data
    });
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: UPDATE_VEHICLE,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}

export const deleteVehicle = (id) => async dispatch => {
  try {
    let res = await axios({
      method: 'DELETE',
      url: `/vehicle/${id}`,
    });
    dispatch({
      type: DELETE_VEHICLE,
      payload: {
        data: res.data.data,
pagination: res.data.pagination||{},
totalCount: res.data.totalCount||0,
        hasError: false,
      }
    });
  } catch(err) {
    dispatch({
      type: DELETE_VEHICLE,
      payload: {
        data: {},
        hasError: true,
      }
    });
  }
}