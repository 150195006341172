import {
  LIST_OBJECT_CHECKPOINT,
  GET_OBJECT_CHECKPOINT,
  ADD_OBJECT_CHECKPOINT,
  UPDATE_OBJECT_CHECKPOINT,
  DELETE_OBJECT_CHECKPOINT
} from '../action_types';

const initialState = {
  list: {
    pagination: {},
    totalCount: 0,
    data: [],
    isLoading: true,
    hasError: false
  },
  detail: {
    data: {},
    isLoading: true,
    hasError: false
  }
}

const project = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LIST_OBJECT_CHECKPOINT:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload
        }
      };
    case GET_OBJECT_CHECKPOINT:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload
        }
      };
    case ADD_OBJECT_CHECKPOINT:
      return {
        ...state,
        add: {
          ...state.add,
          ...payload,
          isLoading: false,
          hasError: payload.hasError,
        }
      }
    case UPDATE_OBJECT_CHECKPOINT:
      return {
        ...state,
        update: {
          ...state.update,
          ...payload
        }
      }
    case DELETE_OBJECT_CHECKPOINT:
      return {
        ...state,
        delete: {
          ...state.delete,
          ...payload
        }
      }
    default: return state;
  }
}

export default project;