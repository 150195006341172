import { 
  LIST_JOBCARD,
  GET_JOBCARD,
  ADD_JOBCARD,
  UPDATE_JOBCARD,
  DELETE_JOBCARD  
} from '../action_types';

const initialState = {
  list: {
      pagination: {},
      totalCount: 0,
    data: [],
    isLoading: true,
    hasError: false
  },
  detail: {
    data: {},
    isLoading: true,
    hasError: false
  }
}

const jobcard = (state=initialState, action)  => {
  const { type, payload } = action;

  switch(type) {
    case LIST_JOBCARD: 
      return {
        ...state,
        list: {
          ...state.list,
          ...payload
        }
      };
    case GET_JOBCARD:
      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload
        }
      };
    case ADD_JOBCARD:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: [...state.list.data, payload.data],
          hasError: payload.hasError,
        }
      }
    case UPDATE_JOBCARD:
      let updatedItem = payload.data;
      let tempList = [...state.list.data]
      tempList.forEach((item, i) => {
        if(item._id === updatedItem._id) {
          tempList[i] = updatedItem
        }
      })
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false,
          data: tempList
        }
      }
    case DELETE_JOBCARD:
      let deletedItem = payload.data;
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data.filter(item => (item._id !== deletedItem._id)),
          isLoading:false,
          hasError: payload.hasError
        }
      }
    default: return state;
  }
}

export default jobcard;