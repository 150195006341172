import axios from "axios";

let count = 0;
export const callAPI = async (dispatch, url = 'project', method = 'GET', type = '', params = {}, retry = false, data = {}) => {
  try {
    let res = await axios({
      method,
      url,
      params,
      data
    });
    if ((Array.isArray(res.data.data) && res.data.data.length) || (typeof res.data.data === 'object' && Object.keys(res.data.data).length) || count) {
      count = 0;
      dispatch({
        type,
        payload: {
          data: res.data.data,
          pagination: res.data.pagination || {},
          totalCount: res.data.totalCount || 0,
          meta: res.data.meta,
          isLoading: false,
          hasError: false,
        }
      });
    } else if (retry && count < 1) {
      count++;
      callAPI(dispatch, url, method, type, params, retry, data)
    }
  } catch (err) {
    console.log(err, retry, count)
    if (retry && count < 5) {
      count++;
      callAPI(dispatch, url, method, type, params, retry, data)
    } else {
      dispatch({
        type,
        payload: {
          data: [],
          meta: err?.data?.meta,
          isLoading: false,
          hasError: true,
        }
      });
    }
  }
}