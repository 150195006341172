import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setLocalCompany } from "../../../redux/actions/localCompany";
import { decrypt, encrypt } from "n-krypta";
import { ASSOCIATE, AUDITOR, BRM, CUSTOMER, PDSLADMIN, TECHNICIAN } from "../../../components/Projects/AllProjects/CONSTANTS";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PrivateRoute = ({
  component: Component,
  accessLevel: accessRole,
  ...rest
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector(state => state.auth.data);
  const localCompany = useSelector(state => state.localCompany.companyId.data);
  const roles = useSelector(state => state.role.list.data);
  const role = roles.find(item => item.name === accessRole);
  useEffect(() => {
    // console.log(auth)
    if (auth._id) {
      // console.log(auth?.accessToken && (!role || role?._id === (auth?.role?._id || auth?.role)), role)
      if (localCompany || auth.companyId) {
        dispatch(setLocalCompany(localCompany || encrypt(auth.companyId, 'secret')));
      }
    } else {
      if (localCompany) {
        history.push("/company-login/" + decrypt(localCompany, 'secret'));
      }
    }
  }, [auth, localCompany])
  const redirect = (role) => {
    switch (role) {
      case BRM:
        if (localCompany || auth.companyId)
          history.push("/projects");
        else
          history.push("/company-list");
        break;
      case PDSLADMIN:
        history.push("/company");
        break;
      case ASSOCIATE:
        history.push("/associate");
        break;
      case AUDITOR:
        history.push("/auditor");
        break;
      case CUSTOMER:
        history.push("/customer-projects");
        break;
      case TECHNICIAN:
        history.push('/inspector-workplan-list')
        break;
      default:
        break;
    }
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        // Todo: Redirect to access denied page instead of login if already loggedin
        auth?.accessToken && (!role || role?._id === (auth?.role?._id || auth?.role)) ?
          (
            <Component {...props} />
          ) :
          (
            auth?.accessToken ? redirect() :
              localCompany || auth.companyId ?
                <Redirect to={"/company-login/" + (auth.companyId || decrypt(localCompany, 'secret'))} />
                :
                <Redirect to="/login" />
          )
      }
    />
  )
};

export default PrivateRoute;
